.createposition {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    height: 160px;
    /* / overflow-y: hidden; / */
    padding-top: 10px;
    /* / padding: 10px; / */
    margin-bottom: 40px;
  }
  
  .cu_field2 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    font-weight: 500;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 8px;
    padding-left: 10px;
    outline: none;
  }
  
  .cu_field3 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    font-weight: 700;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 8px;
    padding-left: 10px;
    outline: none;
  }
  
  .cu_label {
    font-size: 16px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
  }
  
  .Cu_row1 {
    display: flex;
    justify-content: space-between;
    margin: 0 1%;
  }
  
  .cu_col1 {
    flex: 0 0 31%;
  }
  
  .cu_label {
    font-size: 15px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
  }
  
  .cu_col2 {
    flex: 0 0 31%;
  }
  
  .cu_col3 {
    flex: 0 0 31%;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .main_client_body {
    width: 100%;
    /* height: 90vh; */
    display: flex;
  }
  
  .titleLabel {
    grid-area: title;
  }
  
  
  
  .gridContainer label {
    margin-left: 10px;
    font-weight: 600;
    font-size: 16px;
    opacity: 1.7;
    position: relative;
    margin-top: 10px;
  }
  
  
  
  .gridContainer {
    display: grid;
    margin-top: 10px;
    grid-template-columns: 40% 60%;
    grid-template-areas:
      "title descp"
      "assign descp"
      "enddate priority";
    /* / grid-template-rows: 20px 20px 20px; / */
  }
  
  .table_div {
    width: 80%;
    padding: 0px 20px;
  
  
  }
  
  .active_btn {
    background-color: white;
    padding: 4px 15px;
    border-radius: 10px;
    border: 1px solid #fcb902;
  }
  
  .right_side {
    display: flex;
    justify-content: space-between;
  }
  
  .breadscrumb {
    font-size: 10px;
    font-weight: bold;
  }
  
  .recent_tab {
    color: rgb(252, 187, 8);
  }
  
  .arrow {
    font-size: 13px;
    font-weight: bold;
    color: rgb(252, 187, 8);
  }
  
  .second_child {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  
  .filter_btn {
    background-color: white;
    padding: 2px 17px;
    border-radius: 20px;
    outline: none;
    border: 1px solid rgb(252, 187, 8);
    color: rgb(252, 187, 8)
  }
  
  .create_btn {
    background-color: rgb(252, 187, 8);
    padding: 9px 14px;
    border-radius: 10px;
    outline: none;
    border: 1px solid rgb(252, 187, 8);
    color: white;
    border-radius: 10px;
    width: 137px;
    height: 45px;
  }
  
  
  .icon {
    color: white;
  }
  
  .btn_menu:hover .icon {
    color: rgb(252, 187, 8);
  }
  
  .plus {
    font-size: 20px;
    padding-right: 4px;
    font-weight: 700;
  }
  
  .heading {
    margin-top: 10px;
  }
  
  .form_div {
    box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
    padding: 16px;
    border-radius: 5px;
    margin-top: 25px;
    background-color: white;
  }
  
  .rightSection {
    /* / border: 2px solid blue; / */
    width: 80%;
  }
  
  .scrollAdd {
    border-radius: 5px;
    background-color: #ffffff;
    height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .scrollAdd::-webkit-scrollbar {
    width: 12px;
  }
  
  .error{
    color: red;
    font-weight: 500;
    padding-top: 4px;
      padding-left: 4px;
  
  }
  
  .scrollAdd::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 5px;
  }
  
  .scrollAdd::-webkit-scrollbar-thumb {
    background-color: #d3d1d1;
    border-radius: 20px;
    border: 4px solid #ffffff;
  }
  
  .container {
    margin-left: 2%;
    width: 96%;
    margin-top: 15px;
  }
  
  .container span {
    font-family: "poppins";
    font-weight: 600;
    opacity: 1.7;
    font-size: 18px;
  }
  
  .createTask {
    margin-top: 15px;
  
    border-collapse: collapse;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    padding: 16px;
    display: block;
    overflow-x: auto;
  }
  
  .createTask label {
    display: block;
  }
  
  .success_label {
    color: white;
    background-color: #fcb902;
    text-align: center;
    border-radius: 5px;
  }
  
  /* / The alert message box / */
  .alert {
    padding: 11px;
    border-radius: 5px;
    background-color: #fcb902;
    color: white;
    margin-bottom: 15px;
  }
  
  /* / The close button / */
  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  /* / When moving the mouse over the close button / */
  .closebtn:hover {
    color: black;
  }
  
  .gridContainer {
    display: grid;
    margin-top: 10px;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas:
      "title descp"
      "assign descp"
      "enddate priority";
    /* / grid-template-rows: 20px 20px 20px; / */
  }
  
  .gridContainer label {
    margin-left: 10px;
    font-weight: 600;
    font-size: 16px;
    opacity: 1.7;
    position: relative;
    margin-top: 10px;
  
  }
  
  .gridContainer textarea {
    resize: none;
    top: 383px;
    padding: 7px;
    left: 899px;
    width: 230%;
    height: 98px;
    background: transparent 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1.7;
    border: none;
    background-color: #F5F6FA;
  }
  
  .gridContainer textarea:focus {
    outline: none;
  }
  
  .datepickerDecoration {
    margin-top: 10px;
    transform: translate3d(333px, 30px, 0px) !important;
  }
  
  /* input[type="date"]::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1.7;
      display: block;
      background: url("../../../../assets/portal/dateldpi.png") no-repeat;
      width: 20px;
      height: 20px;
      border-width: thin;
    } */
  
  .dateDecoration {
    margin-right: 13px;
    color: orange;
    font-weight: bold;
    font-size: 14px;
    text-decoration: underline
  }
  
  /* / For BeadCrums / */
  .font14 {
    font: normal normal 600 14px/21px Poppins;
    color: #fcb902;
    margin-left: 2.3%;
  }
  
  .font15 {
    color: black;
    font: normal normal 600 14px/21px Poppins;
  }
  
  .font16 {
    color: black;
    font: normal normal 600 14px/21px Poppins;
    margin-bottom: 5px;
  }
  
  .gridContainer input {
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 65%;
    min-width: 250px;
    height: 35px;
    box-shadow: 0px 3px 6px #0000001a;
    padding-left: 10px;
    font-weight: 500;
    border-radius: 6px;
    background-color: #F5F6FA;
    color: black;
  }
  
  .input1 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1.7;
    margin-left: 0px;
    padding: 5px;
    width: 90%;
    height: 36px;
    margin-top: 6px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #F5F6FA;
  }
  
  .gridContainer input:focus {
    outline: none;
    color: black;
  }
  
  .gridContainer select {
    width: 90%;
    min-width: 250px;
    height: 30px;
    /* / padding: 0px 0px 0px 10px; / */
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    /* / border: none; / */
    border-radius: 6px;
    /* / margin-top: 8px; / */
    border: 1px solid #fcb902;
  }
  
  .gridContainer select:focus {
    outline: none;
  }
  
  .btn_div {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    /* width: 137px;
        height: 45px;*/
    border-radius: 10px;
  }
  
  .submitbtn {
    border-radius: 10px;
    width: 110px;
    height: 45px;
  }
  
  .submitbtn:disabled{
    background-color: gray;
    color: white;
    border: 1px solid gray;
  }
  .submitbtn:disabled {
    opacity: 1.7;
    cursor: not-allowed;
  }
  
  
  .gridContainer select {
    padding-left: 10px;
  }
  
  .submitButton {
    float: right;
    background-color: #fcb902;
    color: #ffffff;
    padding: 11px 32px 11px 32px;
    border-radius: 5px;
    margin-top: 15px;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    font-size: 16px;
    height: 45px;
    width: 137px;
  }
  
  .submitButton:disabled {
    opacity: 1.7;
    cursor: not-allowed;
  }
  
  .react-calendar__navigation {
    height: 28px !important;
  }
  
  .calendarBox {
    top: 57%;
    left: 23.5%;
    bottom: 0%;
    right: 42%;
    /* / position: absolute; / */
  }
  
  .req_msg {
    text-align: left;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.5px;
    color: #f84142;
    opacity: 1.7;
  }
  
  .calendarBox {
    top: 60%;
    left: 23.5%;
    bottom: 0%;
    right: 42%;
    position: absolute;
  }
  
  .req_msg {
    margin-left: 1%;
    text-align: left;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.5px;
    color: #f84142;
    opacity: 1.7;
  }
  
  .titleLabel {
    grid-area: title;
    width: 100%;
  
  }
  
  .input {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1.7;
    width: 65%;
    height: 50px;
    margin-top: 6px;
    font-weight: 500;
    background: transparent 0% 0% no-repeat padding-box;
  
  }
  
  .proSecton select {
    width: 90%;
    height: 35px;
    margin-top: 2px;
    background-color: #F5F6FA;
    border-radius: 4px;
    border: none;
  }
  
  .proSecton label {
    color: #000;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1px;
  }
  
  
  .assignLabel {
  
    width: 100%;
  }
  
  .dateLabel {
    width: 100%;
  }
  
  .descLabel {
    grid-area: descp;
    width: 70%;
  }
  
  .desc {
    margin-top: 10px;
    font-weight: 500;
  }
  
  .priorityLabel {
    grid-area: priority;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  
  .priorityLabel12 {
    grid-area: priority;
    /* display: grid;
      grid-template-rows: 70% 30%; */
  }
  
  /* .statusLabel {
      grid-area: status;
    } */
  
  @media screen and (max-width: 1090px) {
    .gridContainer textarea {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 800px) {
    .container span {
      font-family: "poppins";
      font-weight: 600;
      font-size: 18px;
      opacity: 1.7;
      margin-left: 6%;
    }
  
    .gridContainer {
      margin-top: 0px;
    }
  
    .gridContainer label {
      margin-top: 20px;
    }
  
    .text {
      top: 383px;
      left: 899px;
      width: 951px;
      height: 138px;
      background: transparent 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 9px #00000029 !important;
      border-radius: 9px;
      opacity: 1.7;
      border: none;
    }
  
    .rightSection {
      /* / border: 2px solid blue; / */
      background-color: #f5f6fa;
      width: 100%;
    }
  
    .submitButton {
      float: none;
  
      padding: 10px 50px 10px 50px;
      margin-left: 35%;
    }
  }
  
  .errorTextHidden {
    visibility: hidden;
    color: red;
    margin-top: 3px !important;
    margin-left: 5px !important;
    font-size: 15px;
    font-weight: 500;
    margin-left: 15px;
    padding: 0.8rem 0.5rem 0.2rem 0.5;
  }
  
  .errorText {
    color: red;
    margin-top: 3px !important;
    margin-left: 5px !important;
    font-size: 15px;
    font-weight: 500;
    margin-left: 15px;
    visibility: visible;
    padding: 0.8rem 0.5rem 0.2rem 0.5;
  }