* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_client_body {
  width: 100%;
  /* height: 90vh; */
  display: flex;
}
.addTaskBtn {
  border-radius: 10px;
  width: 7rem;
  height: 45px;
  margin-right: 10px;
}

.titleLabel {
  grid-area: title;
}
.description_box1 {
  /* border: 1px solid red; */
  padding: 0px;
  /* height: auto; */
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 7px;
  background-color: white;
}
.span2 {
  text-align: center;
  color: #fcb902;
  font-weight: 600;
}
.maindivContent {
  display: flex;
  flex-direction: row;
}
.p1 {
  margin-left: 450px;
  color: grey;
}
.btn_box {
  /* border: 1px solid red; */
  padding: 10px 0px;
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 2%;
}
.Add_Application_btn {
  width: 17%;
  height: 40px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  border-radius: 6px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plus {
  font-size: 20px;
  padding-right: 4px;
  font-weight: 700;
}

.gridContainer label {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  opacity: 1.7;
  position: relative;
  margin-top: 10px;
}

.gridContainer {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 40% 60%;
  grid-template-areas:
    "title descp"
    "assign descp"
    "enddate priority";
  /* / grid-template-rows: 20px 20px 20px; / */
}

.table_div {
  width: 80%;
  padding: 0px 20px;
}

.active_btn {
  background-color: white;
  padding: 4px 15px;
  border-radius: 10px;
  border: 1px solid #fcb902;
}

.right_side {
  display: flex;
  justify-content: space-between;
}

.breadscrumb {
  font-size: 10px;
  font-weight: bold;
}

.recent_tab {
  color: rgb(252, 187, 8);
}

.arrow {
  font-size: 13px;
  font-weight: bold;
  color: rgb(252, 187, 8);
}

.second_child {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.filter_btn {
  background-color: white;
  padding: 2px 17px;
  border-radius: 20px;
  outline: none;
  border: 1px solid rgb(252, 187, 8);
  color: rgb(252, 187, 8);
}

.create_btn {
  background-color: rgb(252, 187, 8);
  padding: 9px 14px;
  border-radius: 10px;
  outline: none;
  border: 1px solid rgb(252, 187, 8);
  color: white;
  border-radius: 10px;
  width: 137px;
  height: 45px;
}

.icon {
  color: white;
}

.btn_menu:hover .icon {
  color: rgb(252, 187, 8);
}

.plus {
  font-size: 20px;
  padding-right: 4px;
  font-weight: 700;
}

.heading {
  margin-top: 10px;
}

.form_div {
  box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: white;

}
.gotoListBtn {
  border-radius: 10px;
  width:104px;
  height: 45px;
}
.rightSection {
  /* / border: 2px solid blue; / */
  width: 80%;
}

.scrollAdd {
  border-radius: 5px;
  background-color: #ffffff;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollAdd::-webkit-scrollbar {
  width: 12px;
}

.scrollAdd::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 5px;
}

.scrollAdd::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
}



.container span {
  font-family: "poppins";
  font-weight: 600;
  opacity: 1.7;
  font-size: 18px;
}

.createTask {
  margin-top: 15px;

  border-collapse: collapse;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 16px;
  display: block;
  overflow-x: auto;
}

.createTask label {
  display: block;
}

.success_label {
  color: white;
  background-color: #fcb902;
  text-align: center;
  border-radius: 5px;
}

/* / The alert message box / */
.alert {
  padding: 11px;
  border-radius: 5px;
  background-color: #fcb902;
  color: white;
  margin-bottom: 15px;
}

/* / The close button / */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* / When moving the mouse over the close button / */
.closebtn:hover {
  color: black;
}

.gridContainer {
  display: grid;
  margin-top: -10px;
  grid-template-columns: 33% 33% 33%;
  grid-template-areas:
    "title descp"
    "assign descp"
    "enddate priority";
  /* / grid-template-rows: 20px 20px 20px; / */
}

.gridContainer label {
  margin-left: 0px;
  font-weight: 600;
  font-size: 16px;
  opacity: 1.7;
  position: relative;
}

.gridContainer textarea {
  resize: none;
  top: 383px;
  padding: 7px;
  left: 899px;
  width: 230%;
  height: 98px;
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  border: none;
  background-color: #f5f6fa;
}

.gridContainer textarea:focus {
  outline: none;
}

.datepickerDecoration {
  margin-top: 10px;
  transform: translate3d(333px, 30px, 0px) !important;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1.7;
    display: block;
    background: url("../../../../assets/portal/dateldpi.png") no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
  } */

.dateDecoration {
  margin-right: 13px;
  color: orange;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
}

/* / For BeadCrums / */
.font14 {
  font: normal normal 600 14px/21px Poppins;
  color: #fcb902;
  margin-left: 2.3%;
}

.font15 {
  color: black;
  font: normal normal 600 14px/21px Poppins;
}
.font16 {
  color: black;
  font: normal normal 600 14px/21px Poppins;
  margin-bottom: 5px;
}

.gridContainer input {
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 65%;
  min-width: 250px;
  height: 35px;
  box-shadow: 0px 3px 6px #0000001a;
  padding-left: 10px;
  font-weight: 500;
  border-radius: 6px;
  background-color: #f5f6fa;
  color: black;
}

.gridContainer input:focus {
  outline: none;
  color: black;
}

.gridContainer select {
  width: 90%;
  height: 35px;
  margin-top: 2px;
  background-color: #f5f6fa;
  border-radius: 4px;
  border: none;
}

.gridContainer select:focus {
  outline: none;
}

.btn_div {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  /* width: 137px;
      height: 45px;*/
  border-radius: 10px;
  margin-bottom: 15px;
}

.editbtnn {
  border-radius: 10px;
  width: 135px;
  height: 45px;
}

.addCommentbtn {
  border-radius: 10px;
  width: 160px;
  height: 45px;
  margin-right: 10px;
}
.submitbtn {
  border-radius: 10px;
  width: 90px;
  height: 45px;
}
.viewFeedback {
  border-radius: 10px;
  width: 9rem;
  height: 45px;
  margin-right: 10px;
}

.container {
  /* margin-left: 2%; */
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.gridContainer select {
  padding-left: 10px;
}

.submitButton {
  float: right;
  background-color: #fcb902;
  color: #ffffff;
  padding: 11px 32px 11px 32px;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  font-size: 16px;
  height: 45px;
  width: 137px;
}

.submitButton:disabled {
  opacity: 1.7;
  cursor: not-allowed;
}

.react-calendar__navigation {
  height: 28px !important;
}

.calendarBox {
  top: 57%;
  left: 23.5%;
  bottom: 0%;
  right: 42%;
  /* / position: absolute; / */
}

.req_msg {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #f84142;
  opacity: 1.7;
}

.calendarBox {
  top: 60%;
  left: 23.5%;
  bottom: 0%;
  right: 42%;
  position: absolute;
}

.req_msg {
  margin-left: 1%;
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #f84142;
  opacity: 1.7;
}

.titleLabel {
  grid-area: title;
  width: 100%;
}

.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  width: 65%;
  height: 50px;
  margin-top: 6px;
  font-weight: 500;
  background: transparent 0% 0% no-repeat padding-box;
}
.proSecton select {
  width: 90%;
  height: 35px;
  margin-top: 2px;
  background-color: #f5f6fa;
  border-radius: 4px;
  border: none;
}
.proSecton label {
  color: #000;
  font-weight: 500;
  font-size: 1rem;
}

.assignLabel {
  width: 100%;
}

.dateLabel {
  width: 100%;
}

.descLabel {
  grid-area: descp;
  width: 70%;
}
.desc {
  margin-top: 10px;
  font-weight: 500;
}

.priorityLabel {
  grid-area: priority;
  display: grid;
  grid-template-columns: 50% 50%;
}

.priorityLabel12 {
  grid-area: priority;
  /* display: grid;
    grid-template-rows: 70% 30%; */
}

/* .statusLabel {
    grid-area: status;
  } */

@media screen and (max-width: 1090px) {
  .gridContainer textarea {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .container span {
    font-family: "poppins";
    font-weight: 600;
    font-size: 18px;
    opacity: 1.7;
    margin-left: 6%;
  }

  .gridContainer {
    margin-top: 0px;
  }

  .gridContainer label {
    margin-top: 20px;
  }
  .text {
    top: 383px;
    left: 899px;
    width: 951px;
    height: 138px;
    background: transparent 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1.7;
    border: none;
  }

  .rightSection {
    /* / border: 2px solid blue; / */
    background-color: #f5f6fa;
    width: 100%;
  }

  .submitButton {
    float: none;

    padding: 10px 50px 10px 50px;
    margin-left: 35%;
  }
}
