.UsersList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 8rem;
  max-height: 30rem;
  /* overflow-y: scroll; */
  /* margin-top: 13px; */
  /* padding: 10px; */
  margin-left: 27px;
  margin-right: 27px;
  overflow: auto;
  /* overflow-y: auto; */
  /* box-sizing: border-box; */
  white-space: nowrap;
}

.main_create {
  min-height: 100vh;
  width: 80%;
}

.filter_btn {
  width: 120px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #289e98;
  background-color: #ffffff;
  outline: none;
  color: #289e98;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 28%;
}

.appliedcheck {
  /* position: absolute; */
  left: 63%;
  /* top: 63px; */
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.backColor {
  color: #289e98 !important;
  cursor: pointer;
  font-weight: bold !important;
}

.plus {
  font-size: 20px;
}

.main_client_body {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.s1 {
  position: relative;
  width: 220px;
  margin-left: 5px;
}

.s2 {
  position: relative;
  width: 135px;
  margin-left: 5px;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 88%;
  width: 21px;
  cursor: pointer;
}

.cp_serchIconCode {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 79%;
  width: 21px;
  cursor: pointer;
}

.coreSkillInput {
  border-radius: 9px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  width: 99%;
  height: 38px;
  border: 1px solid #00000030;
  padding: 0 30px 0px 8px;
  outline: none;
}

.coreSkillInput::placeholder {
  color: black;
}

.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  min-width: 95%;
  position: sticky;
}

/* .td {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
} */

p {
  margin: 0 !important;
}

.tableContent1 {
  width: 10%;
  text-align: center;
  font-size: 14px;
}

.tableContent1 {
  width: 15%;
  text-align: center;
  font-size: 14px;
}

.tableContent1 {
  width: 10px;
  text-align: center;
  font-size: 14px;
}


.link {
  color: #289e98;
  cursor: pointer;
}

.link:visited {
  color: #65a15e;
}

.pagination {
  margin-top: 10px;
  margin-right: 30px;
}

.filter {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #289e98;
  background-color: #ffffff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter span {
  padding-left: 12px;
  font-size: 1rem;
  color: #289e98;
  /* font-family: "Poppins"; */
}

.table_div_head {
  /* border: 1px solid red; */
  /* padding: 15px; */
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}


.second_child {
  display: flex;
  /* justify-content: space-between;  */

  /* padding-bottom: 10px;
}

.filter_btn {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #289e98;
  background-color: #ffffff;
  outline: none;
  top: 1rem;
  left:25rem ;
  color: #289e98;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 28%;
}

.appliedcheck {
  /* position: absolute; */
  left: 63%;
  /* top: 63px; */
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.create_btn {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #289e98;
  background-color: #289e98;
  border-radius: 50%;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}


.plus {
  font-size: 20px;
  font-weight: 700;
}


.extra {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

/* .filter_btn {
  background-color: white;
  padding: 2px 17px;
  border-radius: 20px;
  outline: none;
  border: 1px solid #289e98;
  color: #289e98;
  position: relative;
} */
.create {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #289e98;
  background-color: #289e98;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

a:link {
  text-decoration: none;
}

.th {
  display: flex;
  /* justify-content: space-around; */
  /* background-color: #F0F0F0; */
  border-radius: 10px;
  align-items: center;
  height: 40px;
  padding: 0px 0.5rem;
  width: 110%;
  gap: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  white-space: nowrap;

}

.th:last-child {
  border-bottom: none;
}

.th p:nth-child(1) {
  width: 5rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  padding-left: 8px;
  font-size: 12px;
  display: inline-block;
  box-sizing: border-box;
  display: inline-block;
}

.th p:nth-child(2) {
  width: 3rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  display: inline-block;
  box-sizing: border-box;
  font-size: 12px;
}

.th p:nth-child(3) {
  width: 10rem;

  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(4) {
  width: 10rem;

  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(5) {
  width: 7rem;

  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(6) {
  width: 7rem;

  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(7) {
  width: 4.5rem;

  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(8) {
  width: 6rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(9) {
  width: 6rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(10) {
  width: 6rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(11) {
  width: 7rem;

  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.th p:nth-child(12) {
  width: 4rem;

  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(13) {
  width: 4rem;

  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.tooltiptext {
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  bottom: 2.2rem;
  font-weight: 400;
  z-index: 1;
  transition: opacity 0.3s;
  border-color: black transparent transparent transparent;
}

.tooltipctainer {
  position: absolute;
  z-index: 1000;
  width: 10rem;
  height: 5rem;
  right: 3.5%;
  top: 60%;
  visibility: hidden;
  border: 1px solid #289e98;
  border-radius: 5px;
  background: #fff;
}

.td {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 142rem
}


.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 7rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  padding-left: 0px;
  font-size: 12px;
  display: inline-block;
  box-sizing: border-box;
  display: inline-block;
}

.td p:nth-child(2) {
  width: 3rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  display: inline-block;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  align-items: center;


}

.td p:nth-child(3) {
  width: 6rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-size: 12px;

}

.td p:nth-child(4) {
  width: 12rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(5) {
  width: 8rem;
  color: #666666;
  font-weight: 400;
  text-align: center;

  font-size: 12px;
}

.td p:nth-child(6) {
  width: 6rem;
  text-align: center;
  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.td p:nth-child(7) {
  width: 7rem;
  text-align: center;

  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.td p:nth-child(8) {
  width: 5rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.td p:nth-child(9) {
  width: 8rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(10) {
  width: 10rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(11) {
  width: 9rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  /* text-align: center; */
  font-size: 12px;
}

.td p:nth-child(12) {
  width: 8rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  /* text-align: center; */
  font-size: 12px;
}

.td p:nth-child(13) {
  width: 12rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
}


.td p:nth-child(14) {
  width: 11rem;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  /* text-align: center; */
  font-size: 12px;
}

.td p:nth-child(15) {
  width: 10rem;
  /* font-size: 1rem; */
  color: #289e98;
  font-weight: 400;
  font-size: 12px;
  font-weight: bold;
  /* text-align: center; */
  display: flex;

}

.td p:nth-child(16) {
  width: 10rem;
  /* font-size: 1rem; */
  color: #289e98;
  font-weight: 400;
  font-size: 12px;
  font-weight: bold;
  /* text-align: center; */
  display: flex;

}
.td p:nth-child(17) {
  width: 10rem;
  /* font-size: 1rem; */
  color: #289e98;
  font-weight: 400;
  font-size: 12px;
  font-weight: bold;
  /* text-align: center; */
  display: flex;

}


.count {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #289e98;
  /* border: 2px ; */
  margin-left: 7px !important;
  margin-top: 16px;
}

.download {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px !important;
  margin-top: 16px;
}

.backColor1 {
  color: #289e98 !important;
}

.UsersList .td:nth-child(2) p div span {
  top: 40%;

}

.UsersList .td:last-child p div span {
  bottom: -50%;
}

.modal_emp_history {
  /* width: 50rem; */
  min-height: 5rem;
  overflow: auto;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 1rem;
}

.modal_emp_history .heading {
  text-align: center;
  font-weight: 600;
}

.modal_emp_history .modal_btn_container {
  display: flex;
  justify-content: end;
}

.modal_emp_history .modal_btn {
  background-color: #289e98;
  border: 2px solid #289e98;
}

.modal_emp_history .modal_table thead tr th {
  font-weight: 400px;
}

.modal_emp_history .modal_table_container {
  max-height: 20rem;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}