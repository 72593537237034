* {
  margin: 0;
  padding: 0;
}

.mainSection {
  width: 100%;
  top: 100px;
  bottom: 0;
  display: flex;
}

.mainSection .rightSection {
  width: 80%;
}

.ATScontainer {
  width: 80%;
  min-height: 90vh;
  height: auto;
}

.header {
  /* style={{ marginLeft: "30px", marginTop: "10px" }}  */
  margin-left: 30px;
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .mainSection .rightSection {
    width: 100%;
  }
}

.ATScontainer::-webkit-scrollbar {
  width: 3px;
}

.ATScontainer::-webkit-scrollbar-track {
  background: green;
}

.ATScontainer::-webkit-scrollbar-thumb {
  background: #888;
}

@media screen and (max-width: 1280px) {
  .header {
    /* style={{ marginLeft: "30px", marginTop: "10px" }}  */
    margin-left: 30px;
    margin-top: 15px;
  }
}

/* .ATSNav {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
} */

/* .ATSNav {
  font-family: "Calibri, sans-serif";
} */