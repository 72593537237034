.breadcrumb {
  --bs-breadcrumb-margin-bottom: 0 !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  // float: left;
  padding-right: 0.1rem !important;
  // color: var(--bs-breadcrumb-divider-color); 
  content: ">" !important;
}

a {
  color: #666666;
  text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.2rem !important;
}