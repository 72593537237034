.ViewTask {
    margin-top: 30px;
    margin-left: 14px;
  }

  .DetailView {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    /* height: 500px; */
    overflow-y: hidden;
    padding-top: 10px;
    font-size: 14px;
  }
  .ViewEmployeeDetails {
    display: flex;
    justify-content: center;
    color: #9c58a5;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  }
  .ProjectEmployee {
    margin-top: 10px;
  }

  .buttonAdd {
    background-color: #9c58a5;
    border-color: #9c58a5;
    width: 145px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
  }
  .ViewCancel {
    width: 145px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
    background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
    border: 1px solid #9c58a5;

  }