.description_box1 {
  /* border: 1px solid red; */
  padding: 0px;
  /* height: auto; */
  /* margin-top: 27px; */
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 7px;
  background-color: white;
}

.span2 {
  text-align: center;
  color: #65a15e;
  font-weight: 600;
}

/* .tableBackground{
  background-color: #FAFAFA !important;
} */

.addButton {
  display: flex;
  justify-content: flex-end;
}

.add_btn {
  background-color: #65a15e;
  border: 1px solid #65a15e;
  margin: 0px 2px 2px 0px;
}

.deleteModal {
  margin: 0.5rem;
}

.btnRow {
  margin-top: 1rem;
  text-align: center;
}

.yes_btn {
  background-color: #65a15e;
  border: 1px solid #65a15e;
  margin: 0px 2px 2px 0px;
  width: 4rem;
  margin-right: 1rem;
}

.no_btn {
  background-color: #65a15e;
  border: 1px solid #65a15e;
  margin: 0px 2px 2px 0px;
  width: 4rem;
}

/* From Uiverse.io by vinodjangid07 */ 
.buttonDelete {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  /* background-color: rgb(20, 20, 20); */
  background-color: #65a15e;
  border: none;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  gap: 2px;
}

.svgIcon {
  width: 9px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.buttonDelete:hover {
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
  gap: 0;
}

.binTop {
  transform-origin: bottom right;
}
.buttonDelete:hover .binTop {
  transition-duration: 0.5s;
  transform: rotate(160deg);
}

.historyDiv{
  font-size: 25px;
  color: #65a15e;
  font-weight: 600;
}

.collapseContent {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), 
              opacity 0.4s ease-in-out;
}

.collapseContentShow {
  max-height: 500px; /* Adjust this to fit your content */
  opacity: 1;
}

.mainAction:hover .mainScrollBox {
  animation: scroll-down 1s infinite;
}

/* Animate scroll icon */
@keyframes scroll-down {
  0%, 100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  35% {
    transform: translateY(1rem);
    opacity: 0;
  }

  70% {
    transform: translateY(-1rem);
    opacity: 0;
  }
}
