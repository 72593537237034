.main_client_body {
    width: 100%;
    /* height: 95vh; */
    display: flex;
  }
  .secondDiv{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 55px;
    margin-top: 17px;
  }
  .reporticon{
    position: relative;
    left: 19.6rem;
    bottom: 4.7px;
  }
  .label {
    display: block;
    margin-bottom: 10px;
  }
  
  input[type="radio"] {
    margin-right: 5px;
  }

  .Icon{
    cursor: pointer;
    border: 1px solid rgb(252, 187, 8);
    color: rgb(252, 187, 8);
  }
  