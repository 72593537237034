.container {
  box-shadow: 0 0px 5px 2px rgb(0 0 0 / 20%);
  border-radius: 10px;
  background-color: #fff;
  padding-top: 5px;
  margin: 10px 20px;
}
.hr {
  margin: 0px;
}
.EmpBox_row {
  display: flex;
  justify-content: space-between;
  margin: 0px 1.2rem;
  overflow-x: scroll;
  scrollbar-width: thin;
  width: auto;
}
.EmpBox_col {
  flex: 1 1 auto;
}
.EmpBox_label {
  font-size: 14px;
  font-weight: 600;
  color: black;
  width: 150px;
}

.EmpBox_field {
  margin-top: 10px;
  color: #949090;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  margin-bottom: 5px;
  color:#562679 ;

}

.EmpBox_Client {
  text-align: center;
}