.card {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 104px;
  height: auto;
  margin-bottom: 20px;
}

.card2 {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;

  border-radius: 5px;
  background-color: #ffffff;
  min-height: 130px;
  height: auto;
  margin-bottom: 20px;
}

.rating,
.feedback,
.other {
  padding: 10px 20px;
}

.box {
  width: 100%;
  border: 1px solid #666;
  min-height: 100px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  margin-top: 10px;
}

.boxitem {
  display: flex;
  width: 50%;
  column-gap: 10vw;
}

.boxitem > p:nth-child(1) {
  font-weight: 600;
  color: black;
}

.boxitem > p {
  color: #666;
}

.title {
  display: flex;
  justify-content: center;
  color: #a15e5e;
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}

.cu_label {
  font-size: 15px;
  font-weight: 500;
  /* padding-left: 4px; */
  color: #000000;
}

.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 20vw;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  margin: 1% 1% 1% 1%;
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.Cu_row1 {
  display: flex;
  padding: 10px;
  column-gap: 5vw;
}

.Cu_row2 {
  padding: 10px;
}

.cu_col2 {
  display: flex;
  justify-content: space-between;
}

.add {
  border: 2px solid #a15e5e;
  color: #a15e5e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tooltip {
  cursor: pointer;
  margin-left: 10px;
}
.add > p {
  font-size: 14px;
  font-weight: bold;
}

.add:hover {
  cursor: pointer;
  background-color: #a15e5e;
  color: #ffffff;
}

.mand {
  color: red;
}

.button {
  display: flex;
  justify-content: end;
  width: 98.4%;
  grid-column-gap: 0%;
  margin-top: -1%;
}

.btn1 {
  background-color: #ffffff;
  color: #a15e5e;
  border: 1px solid #a15e5e;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  margin: 10px;
  outline: none;
}

.btn2 {
  background-color: #a15e5e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  margin: 10px;
  outline: none;
}
.btn3 {
  background-color: #fcfcfc;
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  margin: 10px;
  outline: none;
}
.cu_col11 {
  width: 25%;
}
.h {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.h1 {
  display: flex;
  justify-content: space-between;
  width: 55%;
  grid-column-gap: 19%;
  margin-bottom: 5px;
}
.h2 {
  display: flex;
  /* justify-content: space-between;
  width: 55%;
  grid-column-gap: 51%;
  margin-bottom: 5px; */
}
.h_name {
  font-weight: 700;
}
.mg {
  width: 25%;
}
.mg1 {
  width: 25%;
}
.th2 {
  display: flex;
  /* justify-content: space-around; */
  background-color: #ffffff;
  border-radius: 10px;
  align-items: center;
  height: 24px;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.th2 p:nth-child(1) {
  width: 14rem;
  text-align: start;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  font-size: 15px;
  /* padding-left: 8px; */
  /* padding-left: 8px; */
}

.th2 p:nth-child(2) {
  width: 10.5rem;
  text-align: start;
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  font-size: 15px;
}

.th2 p:nth-child(3) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  font-size: 15px;
}
.modal_container {
  width: 18rem;
  height: 8rem;
  border-radius: 5px;
}

.reactmodal {
  border-radius: 10px;
}

.textM {
  text-align: center;
}

.b_inactive {
  border: 1px solid #a15e5e;
  border-radius: 4px;
  color: white;
  background-color: #a15e5e;
  width: 5vw;
  font-size: 15px;
  cursor: pointer;
  /* height: 30px; */
}

.w_inactive {
  border: 1px solid #a15e5e;
  border-radius: 4px;
  color: #a15e5e;
  background-color: #fff;
  width: 5vw;
  font-size: 15px;
  cursor: pointer;
  /* height: 30px; */
}

.M_button {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.button1 {
  display: flex;
  width: 10rem;
  justify-content: space-around;
}
.ratingAlign {
  display: flex;
  justify-content: center;
  grid-column-gap: 30%;
}
