 @import '../../pageLayout/mixing/MixingScss.module.scss';

 .topBar {
     display: flex;
     width: 28rem;
     margin-left: 2rem;
     gap: 1rem;
 }

 .s1 {
     position: relative;
     width: 14rem;
 }

 .cp_serchIcon {
     position: absolute;
     z-index: 100;
     top: 12%;
     margin-left: 87%;
     width: 21px;
     cursor: pointer;
 }

 .coreSkillInput {
     border-radius: 5px;
     background: transparent 0% 0% no-repeat padding-box;
     background-color: #f5f6fa;
     width: 100%;
     height: 35px;
     border: 1px solid #00000061;
     padding: 0 30px 0px 8px;
     outline: none;
 }

 .th1 {
     @include headerWithoutWidth()
 }

 .th1 div:nth-child(1) {
     @include headerTH(10);
     padding-left: 0.3rem;
 }

 .th1 div:nth-child(2) {
     @include headerTH(13)
 }

 .th1 div:nth-child(3) {
     @include headerTH(8)
 }

 .th1 div:nth-child(4) {
     @include headerTH(11)
 }

 .th1 div:nth-child(5) {
     @include headerTH(11)
 }

 .th1 div:nth-child(6) {
     @include headerTH(7)
 }

 .th1 div:nth-child(7) {
     @include headerTH(12)
 }

 .td1 {
     @include tableDataWithOutWidth()
 }

 .td1 div:nth-child(1) {
     @include TableDataTD(10);
     padding-left: 0.4rem;
     text-decoration: underline;
     cursor: pointer;
     color: rgb(21, 21, 216);
 }

 .td1 div:nth-child(2) {
     @include TableDataTD(13)
 }

 .td1 div:nth-child(3) {
     @include TableDataTD(8)
 }

 .td1 div:nth-child(4) {
     @include TableDataTD(11)
 }

 .td1 div:nth-child(5) {
     @include TableDataTD(11)
 }

 .td1 div:nth-child(6) {
     @include TableDataTD(7)
 }

 .td1 div:nth-child(7) {
     @include TableDataTD(12)
 }

 .noData {
     text-align: center;
 }