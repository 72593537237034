body {
  margin: 0;
  padding: 0;
}
.table_container {
  border-radius: 10px;
  overflow: scroll;
  height: 85vh;
  scrollbar-width: none;
}
.scrollable_table {
  border-collapse: collapse;
  width: 100%;
}

.scrollable_table .th {
  border: 1px solid #ddd;
  text-align: left;
  font-weight: 700;
  background-color: #dfdfdf;
}
.scrollable_table .td {
  padding: 0px 7px 0px 2px;
  text-align: left;
  color: #2c2929;
}
.stickyTH,.stickyTD{
  display: flex;
  position: sticky;
  z-index: 1;
  left: 0rem;
}
.sticky1h,.sticky2h,.sticky3h,.sticky4h,.sticky5h {
  display: flex;
  align-items: center;
}
.sticky1d,.sticky2d,.sticky3d,.sticky4d,.sticky5d {
  display: inline-block;
  height: 33.6px;
}
.sticky1h {
  width: 66px !important;
  left: -1rem;
  min-height: 85px;

}
.sticky2h {
  min-height: 85px;
  left: 2.8rem;
  width: 105px !important;
  text-overflow: ellipsis;
  height: 33.5px;
}
.sticky3h {
  min-width: 123px;
  left: 9rem;
  width:145px !important;
  min-height: 85px;

}
.sticky4h {
  width: 65px !important;
  left: 17rem;
  min-height: 85px;

}
.sticky5h {
  min-height: 85px;
  left: 21rem;
  width: 78px !important;
}
.sticky1d {
  width: 66px;
  left: -1rem;
}
.sticky2d {
  width: 105px !important;
  text-overflow: ellipsis;
}
.sticky3d {
  width: 145px !important;
  left: 9rem;
}
.sticky4d {
  width: 65px !important;
  left: 17rem;
}
.sticky5d {
  left: 78rem ;
  width: 78px !important;
}
.th {
  width: 5rem;
  color: black;
  font-weight: 400;
  padding: 0px 8px;
  font-size: 12px;
}

.thValue {
  background-color: #ffffff;
}
.action {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.td {
  color: #666;
  font-weight: 400;
  font-size: 12px;
  text-wrap: nowrap;
  border: 1px solid #ddd;
}
.s1{
  position: relative;
  width: 250px;
}
.cp_serchIcon2 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 83%;
  width: 21px;
  cursor: pointer;
}
.filters{
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
  position: relative;

}
.upper{
  display: flex;
  width: 100%;
  justify-content:space-between;
  align-items: center;
}
.breadscrum{
  color: #562679 !important;
}
.modal{
  padding: 10px;
}
.count{
  margin-left: 5px;
  height: 30px;
  width: 35px;
  background-color: #562679;
  border-radius: 50%;
  color:#ddd;
  text-align: center;
  align-self: center;
}
.button{
  align-self: flex-end;
}
.holdComment{
  text-align: center;
}
.holdCommentValue{
  width: 200px;
  text-wrap: wrap;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.holdCommentValueP{
  text-align: center;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.submitComment{
  color: #562679;
  font-weight: 600;
}
.code {
  color: #562679 !important;
  font-weight: 700 !important;
  border: none;
}
.textarea{
  margin-top: 5px;
  margin-left: 12px;
  border:1px solid #562679 ;
  padding-bottom: 0px;
  resize: none;
}
.commentP{
  text-align: center;
}
.money{
  color: #562679;
}
.approvalState{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #562679;
}
.editIcon{
  margin-bottom: 3px;
  cursor: pointer;
}
.errorText{
  color: red;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
}
.hideError{
  visibility: hidden;
}
.showError{
  visibility: visible;
  position: relative;
  top: -12px;
  left: -1px;
  font-size: 14px;
}
.selectStyling,.input_field{
  outline: none;
  border: 2px solid #562679;
  background: transparent;
  vertical-align: middle;
  border-radius: 5px;
  height: 27px;
  position: relative;
}

/* Filter */
.filterBox {
  margin: 0px 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filterBtn {
  height: 39px;
  padding: 0px 10px;
}
.searchBox {
  margin: 0 10px;
  border-radius: 9px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  width: 99%;
  height: 38px;
  border: 1px solid #00000030;
  outline: none;
}
.searchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 87%;
  width: 21px;
  cursor: pointer;
  padding-top: 5px;
  resize: none;
}
.searchContainer {
  position: relative;
  width: 220px;
  margin-left: 5px;
}
.noUser{
  position: absolute;
  top: 50%;
  left: 50%;
}
.inputRow{
  border-bottom: 1px solid #ddd;
}
.name{
  width: 82px;
  text-align: left;
  text-overflow: ellipsis;
}
.designation{
  width: 120px;
  text-overflow: ellipsis;
}
.totalCount{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #562679;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 80%;
  width: 21px;
  cursor: pointer;
}
.pagination{
  display: flex;
  justify-content: end;
}
.link{
  font-size: 18px;
  color: #562679 !important;
  cursor: pointer;
}
.commentsE{
  font-size: 18px;
  color: #562679 !important;
  cursor: pointer;
  text-align: center !important;
}
.commentsF{
  font-size: 18px;
  color: green !important;
  text-align: center !important;
  cursor: pointer;
}
.commentModal{
  overflow: auto;
  min-height: 300px;
 min-width: 900px; 
}
.commentModal2{
  overflow: auto;
  min-height: 300px;
 min-width: 800px; 
}
.filter_btn {
  width: 120px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #562679;
  background-color: #ffffff;
  outline: none;
  color: #562679;
  align-items: center;
  margin-right: 28%;
}
.scrollable_table .actionTD{
  padding: 0px 12px 0px 10px !important;
  text-align: center !important;
}
.input_field{
    height: 31px;
    width: 150px;
    border: 2px solid #562679;
    padding: 0px 8px;
    border-radius: 12px;
}


/* Project Modal */
.projectTable{
  width: 100%;
  border-collapse: collapse;
}
.projectth{
  background-color: #f2f2f2;
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
    font-size: 14px;
}
.projecttd{
  border: 1px solid #dddddd;
    padding: 8px;
}
.projecttBody .projecttrBody:nth-child(even) {
  background-color: #f2f2f2;
}
.projectModal{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 25vh;
  width: 50vw;

}
.projectHeading{
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 700;
}
.projectheader{
  display: flex;
  justify-content: space-around;
  padding: 4px;
  font-weight: 500;
  font-size: 12px;
}
.projecttdRow{
  padding: 4px;
  font-weight: 400;
  font-size: 12px;
}
.makeCenter{
  text-align: center !important;
}
.noProjects{
  text-align: center;
  margin-top: 10px;
  position:absolute;
  top: 70%;
  left: 32%;
}
.salary{
  background-color:#3ad33a !important;
  color: #fdfbfb !important;
}
.past{
  background-color: blueviolet !important;
  color: white !important; 
}
.delete{
  cursor: pointer;
}
.bondIn2Months{
  background-color: pink !important;
}
.projectComments{
  position: relative;
  top: -35px;
  left: 155px;
  cursor: pointer;
  font-size: 20px;
}
.padding_top{
  padding-top: 7px !important;
}
.appr_btn{
  background-color: #855ea1;
  color: white;
  border: 1px solid #855ea1;
}

.titleIp{
  color:'blue'
}

.td>div>input[type="date"]::-webkit-calendar-picker-indicator{

  background: url('../../../../../../assets/Appraisal_Cal.png');
  background-size:contain;
  cursor: pointer;
  background-repeat: no-repeat;
}

.appraisalModal {
  margin: 1.5rem
} 

.checkboxesContainer {
  margin-bottom:1rem;
}

.appraisal_form_check{
  display: flex;
  align-items: center;
}

.appraisal_form_check .form_check_input {
  visibility: visible !important;
  accent-color: #562679 !important;
  height: 1.1rem !important;
    width: 1.1rem !important;
    margin-right: 0.5rem;
}

.appraisalModalHeader {
  margin-bottom: 1rem;
  font-weight: 500;
}