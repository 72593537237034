.card {
    /* box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%); */
    width: 98%;
    margin: 10px auto;
    /* border-radius: 5px; */
    background-color: #ffffff;
    height:19rem;
    /* overflow-y: hidden; */
    /* padding-top: 10px; */
    /* padding: 10px; */
    margin-bottom: 20px;
  }
  .title {
    display: flex;
    justify-content: center;
    color:#964431;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  }
  .Cu_row1 {
    display: flex;
    justify-content: space-between;
    margin: 0% 2.1%;
  }
  .Cu_row1 input[type="file"]::file-selector-button {
    border: 2px solid #65a15e;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #65a15e;
    transition: 1s;
    color: #ffffff;
  }
  
  .cu_col1 {
    flex: 0 0 24%;
  }
  
  .cu_label {
    font-size: 14px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
  }
  .cu_label1 {
    font-size: 14px;
    font-weight: 500;
    padding-left: 24px;
    color: #000000;
  }
  .cu_col2 {
    flex: 0 0 24%;
  }
  
  .cu_col2_ac {
    flex: 0 0 24%;
    /* margin-right: 33px; */
  }
  
  .cu_col2_to {
    flex: 0 0 24%;
    /* margin-left: -50px; */
  }
  
  .cu_col3 {
    flex: 0 0 24%;
  }
  .mandatory{
    color: red;
  }
  .chooseFile{
    height: 34px;
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px grey;
 }
 .main{
  width: 80%;
 }
 .buttonDiv{
  position: absolute;
  right: 0.8%;
 }
 .submitbtn{
  background-color:#964431;
  border-radius: 9px;
  height: 38px;
  width: 6rem;
  border:1px solid #964431;
}
.cancelbtn{
  background-color:white;
  border-radius: 9px;
  height: 38px;
  width: 6rem;
color: #964431;
  border:1px solid #964431;
  margin-right: 20px;
}

.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px;
}

.upload_resume {
  min-width:10%;
  border-radius: 6px;
}
 
.errorTextHidden {
  visibility: hidden;
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  /* margin-left: 15px; */
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.errorText {
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}
.errorText1 {
  color: red;
  margin-top: 3px !important;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
padding-right: 5rem !important;
  font-size: 15px;
  font-weight: 500;
  visibility: visible;
  
}
.createissue{
  font-weight: 500;
  padding-left: 15px;
}
.textArea{
  outline: none;
  width: 98%;
  padding-left:5px;
  height:8rem;
  resize: none;
}
.count2{
  width:1.6rem !important;
  height: 1.6rem !important;
  border-radius: 20px;
  /* display: flex;
  justify-content: center; */
  align-items: center;
  border: 2px solid #964431 ;
  /* border: 2px; */
  font-size: 13px;
  margin-left: 7px !important;
}
.add {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid #964431;

  align-items: center;
  justify-content: center;

  /* /* moz-border-radius: 50%; 
        WebkitBorderRadius: 50%; */
  margin-left: 0.2rem;
  display: inline-block;
  cursor: pointer;
}

.add:hover {
  cursor: pointer;
  background-color: #964431;
  color: #ffffff;
}
.plus {
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #964431;
  /* padding-bottom: 1rem; */
}

.plus:hover {
  color: #ffffff;
  background-color: #964431;
  border-radius: 50%;
}


  /* addTechnology popup css */
  .confirmPopupTitle {
    text-align: center;
    font: normal normal normal 16px/25px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
  }

  .yesBtn {
    padding: 5px 30px;
    outline: none;
    background:#964431 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 6px;
    color: #fff;
    opacity: 1;
  }
  .noBtn {
    margin-left: 10px;
    padding: 5px 30px;
    outline: none;
    font: normal normal 600 18px/27px Poppins, sans-serif;
    background: #fff;
    letter-spacing: 0px;
    color: rgb(252, 187, 8);
    opacity: 1;
    border: 1px solid rgb(252, 187, 8);
    border-radius: 6px;
  }
  .btnCont {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
.confirmPopup {
    /* top: 441px;
  left: 767px;*/
    width: 19rem;
    height: 9rem;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    /* box-shadow: 2px 4px 8px #00000029; */
    border-radius: 9px;
    opacity: 1;
  }
.delete_btn{
    width: 90px;
    height: 40px;
    border: 1px solid rgb(252, 187, 8);
    background-color: rgb(252, 187, 8);
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}
.cp_label2 {
  font-size: 15px;
  font-weight: 600;
  text-align: start;
}
.cp_field {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  /* padding-left: 10px; */
  padding: 5px 5px;
  outline: none;
}