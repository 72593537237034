.container {
    background-color: #f5f6fa;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 4px rgb(211, 209, 209);
    width: 96%;
    padding:0px 10px;
    margin: 5px 20px;
  }
  .title {
    font-weight: 700;
    font-size: 14px;
  }
  .title_Box {
    font-weight: 700;
    text-align: center;
    font-size: 12px;
    margin-bottom: 5px;
    color: #562679;
    margin-left: 10px;
  }
  .text_overflow {
    text-overflow: ellipsis;
  }
  .content {
    display: contents;
  }
  tbody {
    background-color: #fff;
  }
  .td_style {
    font-weight: 600;
    color: black;
    font-size: 14px;
    padding:10px;
  }
  .td_style2 {
    font-weight: 400;
    color: #666;
    font-size: 14px;
    padding:5px 10px;
  }
  .addButton{
    display: flex;
    justify-content: end;
    padding:0px 25px;
  }
  .add_btn {
    margin-bottom: 5px;
    padding: 4px;
    border: 0px;
  }
  .hide {
    display: none;
  }
  .button{
    font-size: 16px;
    padding: 0px;
  }
  .action{
    display: flex;
    gap: 5px;
    cursor: pointer;
  }