* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_client_body {
  width: 100%;
  display: flex;
}
.addResources {
  border-radius: 10px;
}
.modal_container {
  width: 18rem;
  height: 8rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.count {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(252, 187, 8);
  /* border: 2px ; */
  margin-left: 7px !important;
  margin-top: 16px;
}
.s1 {
  position: relative;
  width: 230px;
}
.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 84%;
  width: 21px;
  cursor: pointer;
}
.offshorelist {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  min-height: 28rem;
  margin: 10px 0px;
  /* overflow-x: scroll; */
  box-sizing: border-box;
  white-space: nowrap;
}
.noData {
  position: absolute;
  top: 35%;
  left: 55%;
}
.count1 {
  width: 18px;
  height: 17px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(252, 187, 8);
  /* border: 2px ; */
  /* margin-left: 7px !important; */
  /* margin-top: 0px; */
}
.backColor {
  color: #172a3e !important;
}
.backColor1 {
  color: rgb(252, 187, 8) !important;
}

.plus {
  font-size: 20px;
  padding-right: 4px;
  font-weight: 700;
}
.create_btn {
  width: 170px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.table_div {
  width: 80%;
  padding: 0px 20px;
}

.table_div_head {
  /* border: 1px solid red; */
  /* padding: 15px; */
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.breadscrumb {
  font-size: 10px;
  font-weight: bold;
}

.second_child {
  display: flex;
  /* justify-content: space-between; */
  padding-bottom: 10px;
}

.appliedcheck {
  /* position: absolute; */
  left: 63%;
  /* top: 63px; */
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.filter_btn {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid rgb(252, 187, 8);
  background-color: #ffffff;
  outline: none;
  color: rgb(252, 187, 8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30%;
}

.recent_tab {
  color: rgb(252, 187, 8);
}

.arrow {
  font-size: 13px;
  font-weight: bold;
  color: rgb(252, 187, 8);
}

.original_table {
  /* border: 2px solid red; */
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 10px;
}

.search_emp {
  border-radius: 20px;
  height: 40px;
  outline: none;
  border: 1px solid white;
  box-shadow: 0px 0px 5px 0px grey !important;
  padding: 0px 15px;
}
.loader {
  width: 17%;
  margin: 9% 13% 0% 42%;
}

.number {
  border: 1px solid rgb(252, 187, 8);
  margin-left: 13px;
  padding: 9px;
  border-radius: 50%;
  color: rgb(252, 187, 8);
}
.btn_add {
  width: 20%;
  padding-left: 3.5%;
}

.tr1 {
  border-bottom: 2px solid #f0f0f0;
  text-align: start;
  font-size: 13px;
  position: relative;
}

.tr1 > td {
  padding: 10px;
}

.link {
  cursor: pointer;
  text-transform: capitalize;
  font-weight: bolder;
  text-decoration: underline;
}

@media screen and (max-width: 1280px) {
  .btn_add {
    width: 20%;
    padding-left: 2.5%;
  }
}
