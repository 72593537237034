.filter {
  border: 1px solid #855ea1;
  border-radius: 4px;
  color: white;
  background-color: #fff;
  width: 7vw;
  font-size: 1rem;
  color: #855ea1;
  border-radius: 1.3rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
}

.filterActive {
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  background-color: #855ea1;
  width: 7vw;
  font-size: 1rem;
  border-radius: 1.3rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
}
