$black_header: #172a3e;
$light_background: #f5f6fa;
$sub_text: #707070;

.header {
    background-color: $black_header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 2em;
    padding-bottom: 13px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .profileSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        height: 50px;
    }

    .profileImage {
        height: 50px;
        border-radius: 50%;
        border: 4px solid #FFFFFF;
        opacity: 1;

    }

    .logout {
        font-weight: 700;
        font-family: "poppins";
        margin-left: 11px;
        color: #FFFFFF;
        text-decoration: underline;
        cursor: pointer;
        transform: scale(0.98);

    }

    .heading {
        position: relative;
        top: 4px;
        color: white;
        font-family: "Poppins", Helvetica, sans-serif;
        font-size: 0.9rem ;
        font-weight: 500;
    }
}


@media only screen and (max-width: 700px) {
    .logout {
        display: none;
    }

}