.th {
    display: flex;
    background-color: #f5f7f9;
    align-items: center;
    height: 40px;
    color: #666666;
    font-size: 12px;
    font-weight: 600;
    position: sticky;
    top: 0;
  }
  .th p:nth-child(1) {
    padding-left: 4px;
    width: 7rem;
    background-color: #f5f7f9;
    position: sticky;
    left: 0;
  }
  .th p:nth-child(2) {
    width: 10rem;
    background-color: #f5f7f9;
    position: sticky;
    left: 7rem;
  }
  .th p:nth-child(3) {
    width: 7rem;
    background-color: #f5f7f9;
    position: sticky;
    left: 17rem;
  }
  .td {
    display: flex;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 12px;
  }
  .td p:nth-child(1) {
    background-color: white;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    color: #a15e5e;
    padding-left: 4px;
    width: 7rem;
    position: sticky;
    left: 0;
  }
  .td p:nth-child(2) {
    width: 10rem;
    position: sticky;
    left: 7rem;
    background-color: white;
  }
  .td p:nth-child(3) {
    width: 7rem;
    position: sticky;
    left: 17rem;
    background-color: white;
  }

  .download {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px !important;
    margin-top: 16px;
  }

  .card{
    height: 30rem;
    overflow: auto;
  }
  .filter_btn {
    width: 120px;
    height: 40px;
    border-radius: 30px;
    border: 2px solid #289e98;
    background-color: #ffffff;
    outline: none;
    color: #289e98;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 28%;
  }
  .count{
    padding: 25px;
  }
  .ratingWrapper{
    font-weight: 400;
    font-size: 0.9rem;
  }