.vp_main {
  /* height: 100%; */
  /* min-height: 100vh; */
}

.modal_container_InActive {
  width: 20rem;
  height: auto;
  border-radius: 5px;
}

.modal_container {
  width: 25rem;
  height: auto;
  border-radius: 5px;
}

.reactmodal {
  border-radius: 10px;
}

.textM {
  text-align: center;
}

.descContainer {
  height: auto;
  max-width: 50rem;
}

.descContainer::-webkit-scrollbar {
  width: 3px;
}

.descContainer::-webkit-scrollbar-track {
  background: green;
}

.descContainer::-webkit-scrollbar-thumb {
  background: #888;
}

.modal {
  border-radius: 10px;
}

.Modaldata {
  color: limegreen;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  /* border-bottom: 1px solid limegreen; */
}

.data {
  color: #666666;
  font-weight: 400;
}

.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  padding: 01rem;
}

.th p:nth-child(1) {
  width: 9rem;
  text-align: center;
  font-weight: 600;
  color: #666666;
}

.th p:nth-child(2) {
  width: 13rem;
  text-align: center;
  font-weight: 600;
  color: #666666;
}

.th p:nth-child(3) {
  width: 14rem;
  text-align: center;
  font-weight: 600;
  color: #666666;
}

.th p:nth-child(4) {
  width: 30rem;
  font-weight: 600;
  color: #666666;
  padding-left: 1rem;
}

.td {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  color: #666666;
}

.td:last-child {
  border-bottom: none;
}

.tooltiptext {
  width: 20rem;
  background-color: #65a15e;
  color: #fff;
  right: 2rem;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
  border-color: black transparent transparent transparent;
  text-align: left;
}

.td p:nth-child(1) {
  width: 9rem;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.td p:nth-child(2) {
  width: 13rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td p:nth-child(3) {
  width: 14rem;
  text-align: center;
}

.td p:nth-child(4) {
  /* text-align: center; */
  width: 30rem;
  padding-left: 1rem;
}

.M_button {
  margin-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  width: 10rem;
  justify-content: space-around;
}

.b_inactive {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 5vw;
  font-size: 15px;
  cursor: pointer;
  /* height: 30px; */
}

.w_inactive {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: black;
  background-color: #fff;
  width: 5vw;
  font-size: 15px;
  cursor: pointer;
  /* height: 30px; */
}

.vp_main_card {
  border-radius: 9px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  width: 95%;
  margin: 10px auto;
  height: auto;
}

.vp_header {
  display: flex;
  justify-content: space-between;
  /* gap: 85px; */
  color: green;
  font-weight: 500;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}

.vp_inner_card {
  padding: 0.8rem 1.5rem;
}

.vp_field {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.6rem;
}

.vp_field1 {
  display: flex;
  justify-content: space-between;
  /* gap: 85px; */
  margin-bottom: 0.6rem;
}
.vp_field2 {
  display: flex;
  justify-content: flex-start;
  /* gap: 85px; */
  margin-bottom: 0.6rem;
}

.vp_field div:nth-child(1) {
  width: 20rem;
  font-weight: 500;
}

.vp_field div:nth-child(2) {
  width: 20rem;
  font-weight: 500;
}

.vp_field div:nth-child(3) {
  width: 17rem;
  font-weight: 500;
}

/* .vp_inner_field {
  width: 15rem;
  font-weight: 500;
} */
.vp_full_width{
  width: 100%;
}

.vp_field_value {
  color: gray;
  font-weight: 500;
}

.vp_field_value_desc {
  color: gray;
  width: 70vw;
  font-size: 13px;
  /* height: 15vh; */
  /* white-space: nowrap; */
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: auto; */
  /* display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; */
}

.vp_mandatory {
  color: red;
  font-weight: 700;
}

.vp_yoe {
  /* display: flex; */
  width: 0rem;
}

.vp_yoe_field {
  color: gray;
}

.vp_btn_last {
  display: flex;
  justify-content: end;
  width: 97%;
  grid-column-gap: 13px;
  margin-top: 15px;
  font-size: 12px;
}

.vp_btn_last1 {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 12px;
}

.right_btn {
  grid-column-gap: 3px;
  display: flex;
}

.vp_cancel {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 5vw;
  font-size: 15px;
}

.vp_addNotes {

  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 7vw;
  font-size: 15px;

}

.modal_container_watchlist2{
  width: 60rem;
}

.watchlist_inner_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vp_addComments {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 10vw;
  font-size: 15px;

}

.vp_edit {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 4vw;
  font-size: 15px;
}

.vp_desc {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 5rem;
  font-size: 15px;
  margin: 2rem 3rem 1rem 3rem;
}

.vp_allocation {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 7vw;
  font-size: 15px;
}

.vp_history {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 13vw;
  /* height: 30px; */
  font-size: 15px;
}

.vp_inactive {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 11vw;
  font-size: 15px;
  /* height: 30px; */
}
.close {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 9vw;
  font-size: 15px;
  /* height: 30px; */
}

.vp_img {
  margin-right: 30px;
  cursor: pointer;
}

.vp_main_card_history {
  border-radius: 9px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-top: 30px;
}

.vp_header_history {
  display: flex;
  justify-content: center;
  /* gap: 85px; */
  color: green;
  font-weight: 500;
  padding: 10px 0px;
}

.NoData {
  text-align: center;
  color: green;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.3rem;
}

.PositionList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  height: auto;
  overflow-y: hidden;
  padding: 0px 10px 10px 10px;
  margin-top: 20px;
  /* margin-bottom: 50px; */
}

.th {
  /* display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 50px; */
}

.td {
  /* display: flex;
  justify-content: space-around;
  margin: 10px; */
}

p {
  margin: 0 !important;
}

.pagination {
  margin-top: 25px;
  margin-right: 30px;
}

.tableContent {
  /* min-width: 155px; */
  /* width: 155px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
  font-size: 16px;
  font-weight: 500; */
}

.link {
  text-decoration: underline;
  color: limegreen;
  cursor: pointer;
}

.vp_title {
  font-size: 1.3rem;
}

/* .history {
  margin-bottom: 50px;
} */

.header {
  width: 95%;
  margin-left: 30px;
  margin-top: 10px;
}

.dateActive {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  width: 10vw;
  height: 38px;
  background-color: #f5f6fa;
  padding-top: 5px;
  margin: 10px auto;
}

.confirmPopupTitle1 {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
}

.confirmPopup {
  /* top: 441px;
left: 767px;*/
  width: 386px;
  min-height: 245px;
  /* text-align: center; */
  padding-top: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.csd {
  width: 70%;
  height: 35px;
  font-weight: 500;
  box-shadow: 0px 3px 9px #00000029 !important;
  /* align-items: center; */
  margin-left: 12%;
  border-radius: 9px;
  background-color: #f5f6fa;
}

.heading {
  margin-left: 12% !important;
  padding: 0.5rem 0px;
}

.textArea {
  width: 77%;
  margin-left: 12% !important;
  /* margin-top: 1.3rem; */

}

.statuss {
  width: 69%;
  margin-left: 11% !important;
}

.errorText {
  color: red;
  font-size: 12px;
  visibility: visible;
  margin-left: 12% !important;
}

.errorTextHidden {
  color: red;
  font-size: 12px;
  margin-left: 15px;
  visibility: hidden;
  margin-left: 12% !important;
}

.rmpdf {
  border: 1px solid #65a15e;
  width: 3rem;
  height: 3rem;
  border-radius: 10px;
  padding: 0.5rem;
  color: #65a15e;
}