.createBtn {
    width: auto;
    background-color:#C3B14B;
    color: white;
    border: #C3B14B;
    border-radius: 5px;
    padding: 0.4rem 0.7rem;
    margin-right: 10px;
  }
  .cancelBtn {
    width: 80px;
    background-color: white;
    color: var(--invoiceColor);
    border: 1px solid var(--invoiceColor);
    border-radius: 5px;
    padding: 0.4rem 0.7rem;
  }
  .createBtn1 {
    /* width: 80px; */
    background-color:#C3B14B  !important;
    color: white;
    border: #C3B14B !important;
    border-radius: 5px;
    padding: 0 0.7rem;
    margin-right: 10px;
    height: 28px;
    font-size: 14px;
  }
  