.leftSection {
  height: 100%;
  background-color: #172a3e;
}

.leftSection button {
  width: -webkit-fill-available;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  opacity: 1;
  outline: none;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.leftSection .taskTracker {
  background-color: #855ea1;
  height: 70px;
  padding-left: 15px;
  cursor: auto;
  border: none;
}

.leftSection .taskTracker span {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  /* padding-left: 15px; */
}

.selected {
  background-color: #ffffff;
  display: flex;
  height: 40px;
  color: #855ea1;
  /* padding-left: 15px; */
  border: none;
  cursor: pointer;
}

.leftSection .selected span {
  font-size: 13px;
  /* padding-left: 15px; */
}

.leftSection .assignedByMe {
  background-color: #172a3e;
  display: flex;
  height: 40px;
  color: #ffffff;
  /* padding-left: 15px; */
  border: none;
  cursor: pointer;
}

.leftSection .assignedByMe span {
  font-size: 13px;
}

.sb {
  text-align: start;
  margin: 0 6%;
}

.sb0 {
  width: 12%;
}
