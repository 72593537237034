.IssueList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 98%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 10px;
  font-size: 14px;
}
.tableContent {
  width: 9.2rem;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
}
.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-weight: 600;
}
.td {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
}
.hed {
  margin-left: 16px;
  font-weight: 600;
  width: 90% !important;
}
.laptops {
  padding-left: 23px !important;
  font-weight: 700;
  font-size: 18px;
}
.filterr {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #9c57a5;
  background-color: #ffffff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 85px;
}
.filterImg {
  position: absolute;
  left: 58%;
  top: 6%;
  width: 12px;
  border-radius: 6px;
}
.filter_img2 {
  position: absolute;
  left: 52%;
  top: 26%;
}
.main{
  width: 80%;
}
.filterr span {
  padding-left: 12px;
  font-size: 1rem;
  color: #9c57a5;
}
.btnColor {
  background-color: #9c57a5;
  border: 2px solid #9c57a5;
  font-weight: 500;
  border-radius: 9px;
}
.link{
  color: #9c57a5;
  font-size: 16px;
  cursor: pointer;
}
.myIssueP{
  font-weight: 500;
  padding-top: 10px;
}
.btn_add {
  width: 16.6666% !important;
  padding-left:3% !important;
}
@media screen and (max-width: 1280px) {
  .btn_add {
    width: 16.6666% !important;
    padding-left: 2% !important;
  }
}
.issueAllign{
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.noDataAlign{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding-top: 8px;
}
