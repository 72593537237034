*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.main_client_body{
    width: 100%;
    /* height: 90vh; */
    display: flex;
}

.table_div{
    width: 80%;
    padding: 0px 20px;
}

.breadscrumb{
    font-size: 10px;
    font-weight: bold;
}

.arrow{
    font-size: 13px;
    font-weight: bold;
}

.arrow2{
    font-size: 13px;
    font-weight: 600;
    color: rgb(252, 187, 8);
}

.recent_tab{
    color: rgb(252, 187, 8);
}

/* .original_table{
    border: 2px solid red;
    padding: 15px;
    box-shadow: 0px 0px 5px 0px grey;
    border-radius: 10px;
} */

.description_box{
    /* border: 1px solid red; */
    box-shadow: 0px 0px 10px 0px grey;
    border-radius: 7px;
    margin-top: 5px;
}

/* .select{
    width: 60%;
    position: relative;
    border: 1px solid green;
} */