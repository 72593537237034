.smallInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 93%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
    margin-left: 10px;
  }
  .add {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 2px solid #c3b14b;
    position: relative;
    top: -31px;
    align-items: center;
    justify-content: center;
    margin-left: 7.2rem;
    display: inline-block;
    cursor: pointer;
  }
  
  add:hover {
    cursor: pointer;
    background-color: #c3b14b;
    color: #ffffff;
  }
  .mandatoryP {
    padding-left: 9px;
    position: relative;
      top: -20px;
    /* cursor: pointer; */
  }
  .plus {
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c3b14b;
    /* padding-bottom: 1rem; */
  }
  
  .plus:hover {
    color: #ffffff;
    background-color: #c3b14b;
    border-radius: 50%;
  }
  .label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 12px !important;
    margin-top: 10px !important;
  }

  .label1{
    margin-top: 10px !important;
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 12px !important;
  }

  .buttonAdd {
    background-color:#C3B14B;
    border-color: #C3B14B;
    width: 100px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 36px;
    margin-bottom: 10px;
  }
  .primaryContact{
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    border-top:2px solid rgba(8,8,8,.1) ;
  }

  .SecondaryContact{
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    border-top:2px solid rgba(8,8,8,.1) ;
  }

  .errorText {
    color: red;
    font-size: 12px;
    visibility: visible;
    padding-left: 10px;
    margin-top: 3px;
    /* padding: 0.4rem 0.5rem 0.2rem 0.5rem; */
  }
  
  .errorTextHidden {
    color: red;
    font-size: 12px;
    margin-left: 15px;
    visibility: hidden;
    
  }
  .cu_field23Last {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 98%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
  }
  .upload_resume::-webkit-file-upload-button {
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  
  .upload_resume {
    min-width:10%;
    border-radius: 6px;
    
  }
  .chooseFile{
    height: 38px;
    padding-left: 0px;
    padding-top: 3px;
    margin-left: 14px;
    margin-right: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px grey;
    margin-top: 10px;
  }
  .cu_label1 {
    font-size: 14px;
    font-weight: 500;
    padding-left: 24px;
    color: #000000;
  }