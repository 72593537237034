.react-responsive-modal-modal {
    overflow: hidden;
}

.react-responsive-modal-modal {
    max-width: 1200px;
}

.custom-input {
    width: 170px;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.custom-datepicker {
    margin-top: 10px;
}
.react-datepicker__close-icon{
    top: 5px;
    right: 8px;
}
.react-datepicker__close-icon::after {
    font-size: 14px;
    width: 18px;
}
