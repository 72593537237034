@import "../mixing/MixingScss.module.scss";

.th1 {
  display: flex;
  // justify-content: space-between;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 45px;
  font-size: var(--atsFontSize);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  gap: 10px;
  width: 65rem;
}

.date{
  text-decoration: underline;
  cursor: pointer;
  color: black;
}

.date:hover{
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}

.th1 div:nth-child(1) {
  @include headerTH(8);
  padding-left: 0.5rem;
}

.th1 div:nth-child(2) {
  @include headerTH(2);
}

.th1 div:nth-child(3) {
  @include headerTH(8);
  text-align: center;
}

.th1 div:nth-child(4) {
  @include headerTH(6);
}

.th1 div:nth-child(5) {
  @include headerTH(4);
  text-align: center;
}

.th1 div:nth-child(6) {
  @include headerTH(5);
}

.th1 div:nth-child(7) {
  @include headerTH(4);
  text-align: center;
}

.th1 div:nth-child(8) {
  @include headerTH(10);
  text-align: start;
}

.th1 div:nth-child(9) {
  @include headerTH(9);
}

.th1 div:nth-child(10) {
  @include headerTH(7);
}

.th1 div:nth-child(11) {
  @include headerTH(2);
}

.td1 {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  font-size: var(--atsFontSize);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  gap: 10px;
  width: 65rem;
}

.td1 div:nth-child(1) {
  @include TableDataTD(8);
  padding-left: 0.5rem;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.td1 div:nth-child(2) {
  @include TableDataTD(2);
}

.td1 div:nth-child(3) {
  @include TableDataTD(8);
  text-align: center;
}

.td1 div:nth-child(4) {
  @include TableDataTD(6);
}

.td1 div:nth-child(5) {
  @include TableDataTD(4);
  text-align: center;
}

.td1 div:nth-child(6) {
  @include TableDataTD(5);
}

.td1 div:nth-child(7) {
  @include TableDataTD(4);
  text-align: center;
}

.td1 div:nth-child(8) {
  @include TableDataTD(10);
  text-align: start;
}

.td1 div:nth-child(9) {
  @include TableDataTD(9);
}

.td1 div:nth-child(10) {
  @include TableDataTD(7);
}

.td1 div:nth-child(11) {
  @include TableDataTD(2);
  cursor: pointer;
}

.loading {
  text-align: center;
}

.review_loading {
  position: sticky;
  left: 0rem;
  text-align: center;
}

.removeWatch {
  width: 25rem;
  // height: 8rem;
  border-radius: 10px;
  background: #fff;
  padding: 1rem;

}

.text {
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.heading {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: baseline;
}

.review_th1 {
  display: flex;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 45px;
  font-size: var(--atsFontSize);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  gap: 10px;
}

.review_th1 div:nth-child(1) {
  @include headerTH(11);
  padding-left: 0.5rem;
}

.review_th1 div:nth-child(2) {
  @include headerTH(2);
}

.review_th1 div:nth-child(3) {
  @include headerTH(8);
  text-align: center;
}

.review_th1 div:nth-child(4) {
  @include headerTH(8);
  text-align: center;
}

.review_th1 div:nth-child(5) {
  @include headerTH(8);
}

.review_th1 div:nth-child(6) {
  @include headerTH(10);
}

.review_th1 div:nth-child(7) {
  @include headerTH(8);
}

.review_th1 div:nth-child(8) {
  @include headerTH(4);
}

.review_th1 div:nth-child(9) {
  @include headerTH(5);
}

.review_th1 div:nth-child(10) {
  @include headerTH(4);
  text-align: center;
}

.review_th1 div:nth-child(11) {
  @include headerTH(10);
  text-align: start;
}

.review_th1 div:nth-child(12) {
  @include headerTH(9.5);
}

.review_th1 div:nth-child(13) {
  @include headerTH(8);
}

.review_th1 div:nth-child(14) {
  @include headerTH(2);
  text-align: center;
}

.review_td1 {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  font-size: var(--atsFontSize);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  gap: 10px;
}

.review_td1 div:nth-child(1) {
  @include TableDataTD(11);
  padding-left: 0.5rem;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.review_td1 div:nth-child(2) {
  @include TableDataTD(2);
}

.review_td1 div:nth-child(3) {
  @include TableDataTD(8);
  text-align: center;
}

.review_td1 div:nth-child(4) {
  @include TableDataTD(8);
  text-align: center;
}

.review_td1 div:nth-child(5) {
  @include TableDataTD(8);
}

.review_td1 div:nth-child(6) {
  @include TableDataTD(10);
}

.review_td1 div:nth-child(7) {
  @include TableDataTD(8);
}

.review_td1 div:nth-child(8) {
  @include TableDataTD(4);
}

.review_td1 div:nth-child(9) {
  @include TableDataTD(5);
}

.review_td1 div:nth-child(10) {
  @include TableDataTD(4);
  text-align: center;
}

.review_td1 div:nth-child(11) {
  @include TableDataTD(10);
  text-align: start;
  // padding-right: 2%;
}

.review_td1 div:nth-child(12) {
  @include TableDataTD(9.5);
}

.review_td1 div:nth-child(13) {
  @include TableDataTD(8);
}

.review_td1 div:nth-child(14) {
  @include TableDataTD(2);
  text-align: center;
  cursor: pointer;
}