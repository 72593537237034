.mainSection{
  width: 100% ;
  height: 100vh ;
  position: absolute;
  bottom: 0;
  display: flex;
  background-color: #ffffff;
}
.rightSection {
  width: 80%;
  max-width: 80%;
  height: 100vh;
  overflow-y: auto;
}