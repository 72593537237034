@use "../../../GenericComponent/css/common.module.scss" as global;
@import "../../../GenericComponent/css/common.module.scss";

.titleBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0;
}
.label{
  font-weight: 500;
}
.title {
  font-size: 1rem;
  font-weight: bold;
}

.card {
  @include card;
}

.heading {
  border-bottom: 1px solid black;
  padding: 5px;
  text-align: center;
  color: global.$client_contract_theme;
  font-weight: bold;
}

.form {
  padding: 1rem;
}

.row1,
.row2,
.row3,
.row4 {
  display: flex;
  justify-content: space-between;
}

.row6 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.textField,
.textFieldBig {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 35px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  padding: 5px;
  outline: none;
}

.textFieldBig {
  height: 200px !important;
}

.textcontainer,
.textcontainerbig,
.textcontainer_full {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 22%;
  padding-top: 5px;
}

.textcontainerbig {
  width: 100%;
}

.textcontainer_full {
  width: 48%;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 22%;
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 9px;
  opacity: 1;
  border: none;
  outline: none;
  padding-top: 5px;
  position: relative;
  width: 22%;
}

.emptyContainer {
  width: 22%;
}

.btn {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.date {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 35px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  padding: 5px;
  outline: none;
}

.btn > button {
  background-color: global.$client_contract_theme;
  border: none;
  border-radius: 10px;
  padding: 8px;
  width: 10%;
}

.errorTextHidden {
  visibility: hidden;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}

.errorText {
  visibility: visible;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}

.mand {
  color: red;
}
