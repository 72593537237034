.copy_expence {
  text-align: center;
  color: var(--invoiceColor);
  font-weight: 500;
  font-size: var(--inoiceHeading);
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  padding-bottom: 0.5rem;
}

.border {
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  margin: 1rem 0px;
}

.Container {
  width: 95%;
  margin: 0.8rem auto;
  display: flex;
  justify-content: space-between;
}

.Container2 {
  width: 95%;
  margin: 0.8rem auto;
  display: flex;
  justify-content: flex-start;
  gap: 1%;
}

.ContainerVendorNoCase {
  width: 95%;
  margin: 0.8rem auto;
  display: flex;
  /* justify-content: space-between; */
  grid-column-gap: 4%;
}

.col1 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col12 {
  width: 23.5%;
}

.col2 {
  width: 23.5%;
}

.col3 {
  width: 23.5%;
}

.col4 {
  width: 23.5%;
}

.col212 {
  width: 48.5%;
}

.col22 {
  width: 48.5%;
}

.col12_item {
  width: 13%;
}

.col2_item {
  width: 13%;
}

.col3_item {
  width: 13%;
}

.col4_item {
  width: 13%;
}

.col5_item {
  width: 13%;
}

.col6_item {
  width: 13%;
}

.col7_item {
  width: 13%;
}

.col8_item {
  width: 0%;
  margin: 3% 0 0 0;
}
.col10_item {
  margin-left: 2.7%;
  width: 18%;
}

.lastBtn_item {
  display: flex;
  justify-content: end;
  width: 96.4%;
}

.bigInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  /* margin: 1% 1% 1% 1%; */
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.smallInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  /* margin: 1% 1% 1% 1%; */
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  /* width: 130%; */
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  padding-left: 10px;
  outline: none;
  padding-top: 5px;
}

.heading {
  font-weight: 500;
  color: var(--invoiceTextColor);
}

.label {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
}

.label_item {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  font-size: 12px;
}

.radioitem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  /* margin: 10px 0 0; */
}

.radioitem label {
  cursor: pointer;
}

.radioitem input[type="radio"] {
  display: none;
}

.radioitem label {
  font-weight: 600;
}

.radioitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid var(--invoiceColor);
  background-color: transparent;
}

.radioitem input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: var(--invoiceColor);
}

.lastBtn {
  display: flex;
  justify-content: end;
  width: 97.6%;
  grid-column-gap: 2%;
  margin: 2% 0 3% 0;
}

.remove_icon {
  color: #855ea1;
  margin-top: 3%;
}

.base_amount_value {
  color: grey;
  font-weight: 500;
}

.total_amount_div {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 8%;
  gap: 10px;
}

.values {
  color: grey;
  font-weight: 500;
}
.valuesVendor {
  color: grey;
  font-weight: 500;
  width: 67vw;
}
.valuesVendor2 {
  color: grey;
  font-weight: 500;
  width: 34vw;
}
.values2 {
  color: grey;
  font-weight: 500;
  padding: 0 67px 0 0;
}
.file_container{
  width: 95%;
  margin: 0.8rem auto;
}
.file_name {
  width:25rem;
  border-bottom: 1px solid lightgrey;
  /* box-shadow: 0px 3px 9px #00000029 !important; */
  margin: 5px 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: #f5f6fa;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;
}
.file_name:hover{
  background-color: #e2e2e3;
}
.file_name span img{
    /* border: 1px solid #65a15e; */
    /* width: 2rem; */
    height: 2rem;
    /* border-radius: 10px; */
    padding: 0.5rem;
    color: #855ea1;
}
.file_name .text {
  font-weight: 500;
  color: var(--invoiceTextColor);
}