.textArea {
  outline: none;
  margin-top: 5px;
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 2;
  padding: 15px;
  border: none;
  resize: none;
  background-color: #fff;
  font-weight: 400;
  height: 200px;
  width: 100%;


}
.heading {
  color: #562679;
  font-weight: 600;
}
.main_container {
  background-color: #f5f6fa;
  margin: 10px 20px;
}
.appr_btn{
  background-color: #855ea1;
  color: white;
  border: 1px solid #855ea1;
}