.copy_expence {
  text-align: center;
  color: var(--invoiceColor);
  font-weight: 500;
  font-size: var(--inoiceHeading);
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  padding-bottom: 0.5rem;
}

.values {
  color: grey;
  font-weight: 600;
}

.border {
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  margin: 1rem 0px;
}

.Container {
  width: 95%;
  margin: 0.8rem auto;
  display: flex;
  gap: 3%;
}

.smallInputDes {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.full_col {
  width: 30%;
}

.col1 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col12 {
  width: 23.5%;
}

.col2 {
  width: 23.5%;
}

.col3 {
  width: 23.5%;
}

.col4 {
  width: 23.5%;
}

.col212 {
  width: 48.5%;
}

.col22 {
  width: 48.5%;
}
.col12_item {
  width: 13%;
}
.col2_item {
  width: 13%;
}
.col3_item {
  width: 13%;
}
.col4_item {
  width: 13%;
}
.col5_item {
  width: 13%;
}
.col6_item {
  width: 13%;
}
.col7_item {
  width: 13%;
}
.col8_item {
  margin: 0% 0 0 0;
}
.lastBtn_item {
  display: flex;
  justify-content: end;
  width: 96.4%;
  gap: 5%;
}

.bigInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  /* margin: 1% 1% 1% 1%; */
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}
.smallInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  /* margin: 1% 1% 1% 1%; */
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.smallInput1 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 90%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-left: 15px;
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  /* width: 130%; */
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  padding-left: 10px;
  outline: none;
  padding-top: 5px;
}

.heading {
  font-weight: 500;
  color: var(--invoiceTextColor);
}

.label {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
}
.label_item {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  font-size: 12px;
}

.label_item1 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  font-size: 12px;
  margin-left: 15px !important;
}
.radioitem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  /* margin: 10px 0 0; */
}

.radioitem label {
  cursor: pointer;
}

.radioitem input[type="radio"] {
  display: none;
}

.radioitem label {
  font-weight: 600;
}

.radioitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid var(--invoiceColor);
  background-color: transparent;
}

.radioitem input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: var(--invoiceColor);
}
.lastBtn {
  display: flex;
  justify-content: end;
  width: 97.6%;
  grid-column-gap: 2%;
  margin: 2% 0 3% 0;
}
.remove_icon {
  color: #855ea1;
  margin-top: 3%;
  cursor: pointer;
  transform: translate(0px, 9px);
}

.errorText {
  color: red;
  font-size: 12px;
  visibility: visible;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
}

.errorTextHidden {
  color: red;
  font-size: 12px;
  margin-left: 15px;
  visibility: hidden;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
}

.buttonAdd1 {
  float: right;
  margin-left: 55px;
  margin-right: 26px;
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 81px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.buttonCancle {
  width: 81px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  position: relative;
  left: 53%;
  margin-top: -38px;
  margin-bottom: 25px;
}

.GstList {
  width: 95%;
  margin: 0.8rem auto;
  display: flex;
  justify-content: space-between;
}

.GstList2 {
  width: 95%;
  margin: 0.8rem auto;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}

.GstInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}
