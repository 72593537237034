.main {
  padding: 20px;
  width: 100%;
}

.header {
  display: flex;
}

.heading {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}

.down_collapase {
  cursor: pointer;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.collapsible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collapsibleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0px 3px 9px #00000029 !important;
  padding: 8px 12px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #b35e09;
  color: white;
}

.collapsibleHeader1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0px 3px 9px #00000029 !important;
  padding: 8px 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 20px;
  background-color: #b35e09;
  color: white;
}

.collapsibleHeading {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.collapsibleBody {
  background-color: white;
  padding: 5px;
  width: 100%;
}

.collapsibleBody1 {
  width: 100%;
  padding: 0 15px 20px;
  background-color: white;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table {
  border-radius: 10px;
  box-shadow: 0px 3px 9px #00000029 !important;
}

.tableResource {
  border-radius: 10px;
  box-shadow: 0px 3px 9px #00000029 !important;
  margin-top: 15px;
}

.theading {
  background-color: white;
  font-size: 12px !important;
  font-weight: 700;
}

.theading th {
  padding: 10px;
}

.tableResource th {
  background-color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 10px;
}

.theadingResource {
  background-color: white;
  font-size: 12px;
  font-weight: 700;
  border-bottom: 2px solid #f0f0f0;
  padding: 10px;
}

.head1 {
  background-color: white;
  font-size: 12px;
  font-weight: 700;
  border-right: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
}

.subHead {
  background-color: white;
  font-size: 12px;
  font-weight: 500;
  border-right: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
}

.subHeadResource {
  background-color: white;
  font-size: 12px;
  font-weight: 400;
  border-right: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
  padding: 10px;
}

.subValue {
  background-color: white;
  font-size: 12px;
  font-weight: 400;
  border-left: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
  text-align: center;
}

.head2 {
  background-color: white;
  font-size: 12px;
  font-weight: 700;
  border-left: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
  text-align: center;
}

.lastHead {
  background-color: white;
  font-size: 12px;
  font-weight: 500;
  border-right: 2px solid #f0f0f0;
}

.lastHeadResource {
  background-color: white;
  font-size: 12px;
  font-weight: 400;
  border-right: 2px solid #f0f0f0;
  padding: 10px;
}

.lastValue {
  background-color: white;
  font-size: 12px;
  font-weight: 400;
  border-left: 2px solid #f0f0f0;
  text-align: center;
}

.lastRow {
  background-color: #edbe64;
  font-size: 12px;
  font-weight: 500;
  border-bottom-left-radius: 10px;
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.btnCancel {
  padding: 10px 1.5rem;
  background-color: white;
  color: #954b00;
  border: 0px;
  margin-right: 7px;
  font-size: 12px;
  box-shadow: 0px 3px 9px #00000029 !important;
}

.btnSubmit {
  padding: 5px;
  background-color: #954b00;
  color: white;
  border: 0px;
  font-size: 16px;
  box-shadow: 0px 3px 9px #00000029 !important;
  height: 35px;
  border-radius: 5px;
  font-weight: 500;
  width: 90px;
}

.headerDate {
  margin: auto 1rem auto 0;
}

.csd {
  width: 9rem;
  height: 38px;
  font-weight: 500;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  background-color: #fff;
  cursor: pointer;
}

.headerDt {
  background-color: white;
  padding: 7px 12px;
  border-radius: 10px;
  font-weight: 500;
}

.breadCrumb {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}

.breadCrumb:hover {
  text-decoration: underline;
}

.breadCrumb2 {
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}
