
/* .rce-mbox-left-notch{
    fill: #9C57A5 !important;
}
.rce-mbox-right-notch{
    fill: #9C57A5 !important;
}
.chatHeaderP{
    padding: 10px;
    text-align: center;
    color: rgb(255, 255, 255);
    max-height: 58px;
    font-size: 16px;
    font-weight: bold;
    background-color: #9C57A5;
    display: flex;
    justify-content: center;
}
.cross{
    position: relative;
    left: 14rem;
    color: white;
    cursor: pointer;
} */
.cross {
  /* position: relative; */
  /* left: 14rem; */
  color: white;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 18px;
}

.cross2 {
  color: white;
  cursor: pointer;
  position: absolute;
  right: 39px;
  top: 18px;
}

.chatbot {
  font-family: monospace;
  border-radius: 10px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background: #f5f8fb;
  text-align: center;
  display: flex;
  flex-flow: column;
  width: 500px;
  overflow: hidden;
  border: none;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 99999;
}

.header {
  padding: 20px;
  text-align: center;
  color: rgb(255, 255, 255);
  max-height: 58px;
  font-size: 16px;
  font-weight: bold;
  background-color: #9c57a5;
  display: flex;
  align-items: center;
}

.headText {
  margin-left: 10px;
}

.messages {
  width: 100%;
  height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.message-container {
  width: 100%;
}
.message-container1 {
  width: 100%;
  display: flex;
}
.message-container2 {
  width: 100%;
  display: flex;
}

.alignItem {
  display: flex;
  align-items: flex-end;
  padding: 3px;
  padding: 0 10px;
}
.usermessageShow {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  flex-direction: row-reverse;
  padding: 0 10px;
}
.userImg {
  position: relative;
  top: -10px;
}

.bot-message {
  float: left;
  padding: 15px 20px;
  margin: 5px;
  border-radius: 20px 20px 20px 1px;
  background: #9c57a5;
  color: #ffffff;
  min-width: 40px;
  text-align: start;
  white-space: pre-line;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 12px 24px 0 #9c57a526;
}

.user-message {
  float: right;
  padding: 15px 10px;
  margin: 10px;
  border-radius: 20px 20px 1px 20px;
  background: #ffffff;
  color: black;
  max-width: 90%;
  font-size: 14px;
  font-weight: 400 !important;
  box-shadow: 0 12px 24px 0 #9c57a526;
}

.inputType {
  position: relative;
}

.inputType > form > input[type="text"] {
  font-size: 16px;
  border: 0;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #eee;
  box-shadow: none;
  box-sizing: border-box;
  opacity: 1;
  outline: none;
  padding: 16px 52px 16px 10px;
  width: 100%;
  -webkit-appearance: none;
}

.inputType > form > input:focus {
  outline: none;
}
.inputType > form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  background-color: #9c57a5;
  padding: 4px;
  border-radius: 999px;
}
.btn-container-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  background-color: #b597b9;
  padding:4px;
  border-radius: 999px;
}
.mic_btn {
  background-color: white;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
  position: absolute;
  right: 8%;
  top: 19%;
  width: unset !important;
}
/* .input > form >div> button {
    background-color: transparent;
    border: 0;
    border-bottom-right-radius: 10px;
    box-shadow: none;
    cursor: pointer;
    fill: #4a4a4a;
    opacity: 1;
    outline: none;
    padding: 14px 16px 12px 16px;
    position: absolute;
    right: 0;
    top: 0px;
    left: 27rem;
} */
.css-79ws1d-MuiModal-root {
  left: 34% !important;
  top: 20px !important;
}
.chatIcon-heHelp {
  width: 54px;
  height: 54px;
  position: fixed;
  right: 15px;
  cursor: pointer;
  bottom: 10px;
  border-radius: 999px;
  z-index: 400;
}

.chatIcon-heHelp:hover ~ .assistance-popup-container .assistance-popup {
  opacity: 1; 
  transform: translateX(0rem); 
}

/* .assistance-popup-container {
  overflow: hidden;
  position: absolute;
  right: 3rem;
  bottom: 1.3rem;
  width: 13rem;
  padding-left: 14px;
} */
.assistance-popup-container {
  overflow: hidden;
  position: fixed;
  right: 3rem;
  bottom: 10px;
  width: 13rem;
  padding-left: 14px;
  z-index: 399;
}
.assistance-popup {
  color: white;
  background-color: #9c57a5;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  position: relative;
  right: 0px;
  cursor: pointer;
  bottom: 0;
  padding: 14.5px 15px;
  opacity: 0;
  transform: translateX(11rem);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.assistance-popup:hover {
  opacity: 1; 
  transform: translateX(0rem); 
}

.play-pauseBtn {
  cursor: pointer;
  margin-right: 4px;
}

.icon {
  width: 1.2rem;
}

.audio-container {
  margin-top: 12px;
  display: flex;
  gap: 1rem
}

.audio-loader-img {
  width: 2rem;
    padding: 0.5rem 0;
}

.cancel-btn {
  background-color: #9c57a5;
  color: white;
  box-shadow: 0px 0px 4px 1px black;
  cursor: pointer;
  opacity: 0.8;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #9c57a5;
  margin-right: 1rem;
}

.btn-cotainer2 {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.cancel-icon {
  color: #9c57a5;
  opacity: 0.6;
  width: 27px !important;
  height: 27px !important;
}

.mute-container {
  padding-left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disabled-btn {
  opacity: 0.64;
  margin-right: 4px;
}

.done-icon {
  cursor: pointer;
}

.mic-btn {
  background-color: #9c57a5;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
}

.send-btn {
  background-color: #9c57a5;
  color: white;
  box-shadow: 0px 0px 4px 1px black;
  cursor: pointer;
  opacity: 1;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #9c57a5;
}

.send-btn2 {
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
  color: white;
  height: 1rem !important;
  width: 1rem !important;
}

.done-playing-disabled {
  color: #fff;
  font-weight: 600;
  font-family: sans-serif;
  background-color: #9c57a5;
  outline: none;
  border: 1px solid #fff;
  /* box-shadow: 0px 0px 4px 1px black; */
  cursor: pointer;
  padding: 7px;
  border-radius: 7px;
  opacity: 0.64;
}

.done-playing {
  color: #fff;
  font-weight: 600;
  font-family: sans-serif;
  background-color: #9c57a5;
  outline: none;
  border: 1px solid #fff;
  /* box-shadow: 0px 0px 4px 1px black; */
  cursor: pointer;
  padding: 7px;
  border-radius: 7px;
  transition: 0.3s;
}
.done-playing:hover {
  color: #9c57a5;
  background-color: #fff;
  border: 1px solid #9c57a5;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32px 0;
  margin: 0 -5%;
  overflow: hidden;
  width: 3rem;
}

.loader-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  height: 2rem;
}

.loader-head {
  /* font-size: 1.2rem; */
  font-weight: 500;
  margin-right: 0.5rem;
}

.listen-text {
  font-size: 12px;
    margin: 0px 0 5px 0;
}

.recording-container {
  padding: 10px 14px;
    border-top: 2px solid rgb(221, 221, 221);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dadada;
}

.wave-image {
  width: 50%;
  background-color: #9c57a5;
  border: 1px solid #8f6096;
  border-radius: 24px;
}

canvas {
  display: block;
  width: 94%;
  height: 33px;
  margin: auto;
}

.recording-btn-container {
  display: flex;
  gap: 0.5rem;
  width: 30%;
  justify-content: space-around;
  align-items: center;
}

.wave-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
}

.bot-text-color {
  color: #9c57a5;
}

.cursor-pointer {
  cursor: pointer;
}

.wave-container::before,
.wave-container::after,
.wave {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #9c57a5;
  animation: wave-animation 3s infinite;
}

.wave-container::before {
  animation-delay: 0s;
}

.wave-container::after {
  animation-delay: 1s;
}

.wave {
  animation-delay: 1.2s;
}

.svg-icon {
  position: relative;
  z-index: 1;
}

@keyframes wave-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.btn-container-recording {
  background-color: #9c57a5;
  padding: 4px;
  border-radius: 999px;
}

.cancelRecording {
  color: #9c57a5;
}

.anchor {
  color: white !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
}

.anchor:visited {
  color: rgb(255, 255, 255) !important;
  text-decoration: underline !important;
}

.anchor:active {
  color: white !important;
  text-decoration: underline !important;
}

.anchor:hover {
  color: #dadada !important;
  text-decoration-color: #dadada !important;
  text-decoration: underline !important;
}

.mic-icon {
  color: #9c57a5;
  width: 1.2rem;
  height: 1.4rem;
  margin-right: 0.2rem;
}

.inputCross {
  color: #9c57a5;
}

.audio-loading-text {
  margin-bottom: 0.7rem;
  font-size: 15px;
  font-weight: 600;
  color: #9c57a5;
}

.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9c57a5;
  color: #9c57a5;
  box-shadow: 9984px 0 0 0 #9c57a5, 9999px 0 0 0 #9c57a5, 10014px 0 0 0 #9c57a5;
  animation: dot-carousel 1.5s infinite linear;
}

@keyframes dot-carousel {
  0% {
    box-shadow: 9984px 0 0 -1px #9c57a5, 9999px 0 0 1px #9c57a5, 10014px 0 0 -1px #9c57a5;
  }
  50% {
    box-shadow: 10014px 0 0 -1px #9c57a5, 9984px 0 0 -1px #9c57a5, 9999px 0 0 1px #9c57a5;
  }
  100% {
    box-shadow: 9999px 0 0 1px #9c57a5, 10014px 0 0 -1px #9c57a5, 9984px 0 0 -1px #9c57a5;
  }
}