.PositionList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  overflow-y: hidden;
  padding: 10px;
  font-size: 14px;
  /* margin-top: 26px; */
}

.count_div {
  display: flex;
}

.rupee_icon {
  font-size: 16px !important;
}

.small_count {
  border: 2px solid #855ea1;
  color: #855ea1;
  min-width: 4rem;
  height: 2rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 1rem;
  padding: 0.4rem;
  font-weight: 600;
}

.count_para {
  margin-left: 1rem !important;
  font-size: 12px;
  font-weight: 600;
  color: #855ea1;
}

.filter_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9%;
  width: 22%;
}

.downloadIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.vendorContent {
  color: #855ea1;
  font-weight: bolder;
  text-decoration: underline;
}

.table_div {
  background-color: white;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  overflow-x: scroll;
  box-sizing: border-box;
  white-space: nowrap;
}

.no_data {
  text-align: center;
  padding: 3%;
}
.buttonAdd1 {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 20%;
  height: 34px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  padding: 0;
}
.buttonCancle {
  width: 20%;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  height: 34px;
  padding: 0;
}

.sticky_div {
  display: flex;
  width: 24%;
  position: sticky;
  left: 0%;
  top: 0%;
  z-index: 999;
  background: white;
}

/* .th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
  width: 76rem;
} */

.th p:nth-child(1) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
  position: sticky;
  top: 0px;
  left: 0px;
  background-color: #f5f7f9;
}

.th p:nth-child(2) {
  width: 4rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  position: sticky;
  top: 0px;
  left: 5rem;
  background-color: #f5f7f9;
  height: 100%;
  display: flex;
  align-items: center;
}

.th p:nth-child(3) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  position: sticky;
  left: 9rem;
  top: 0;
  background-color: #f5f7f9;
  height: 100%;
  justify-content: start;
  display: flex;
  align-items: center;
}

.th p:nth-child(4) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  /* padding-left: 5px; */
}

.th p:nth-child(5) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  /* padding-right: 14px; */
  font-size: 12px;
}

.th p:nth-child(6) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(7) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(8) {
  width: 7rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}
.th p:nth-child(9) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}
.th p:nth-child(10) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(11) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(12) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(13) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.td {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  /* height: 40px; */
  width: 76rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: sticky;
  padding-left: 10px;
  font-size: 12px;
  left: 0;
  top: 0;
  background-color: white;
}

.td p:nth-child(2) {
  width: 4rem;
  text-align: start;
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  position: sticky;
  left: 5rem;
  top: 0;
  background-color: white;
}

.vendor_name {
  width: 31ch;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(3) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  font-size: 12px;
  padding-right: 10px;
  position: sticky;
  left: 9rem;
  top: 0;
  background-color: white;
}

.td p:nth-child(4) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* padding-left: 10px; */
  font-size: 12px;
  /* transform: translate(29px, 0px); */
}

.td p:nth-child(5) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* padding-left: 22px; */
  /* margin-right: 13px !important; */
  font-size: 12px;
}

.td p:nth-child(6) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  /* padding-left: 20px; */
}

.td p:nth-child(7) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.td p:nth-child(8) {
  width: 7rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}
.td p:nth-child(9) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  padding-right: 3px;
}

.td p:nth-child(10) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  padding-right: 3px;
}

.td p:nth-child(11) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.td p:nth-child(12) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.td p:nth-child(13) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.add {
  border: 2px solid #855ea1;
  color: #855ea1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tooltip {
  margin-left: 10px;
}

.add > p {
  font-size: 14px;
  font-weight: bold;
}

.add:hover {
  cursor: pointer;
  background-color: #855ea1;
  color: #ffffff;
}

p {
  margin: 0 !important;
}

.header {
  width: 95%;
  margin-left: 30px;
  margin-top: 10px;
}

.tableContent {
  width: 155px;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
}

.no_data_found {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
  font-weight: 600;
  color: grey;
}

.done {
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: green !important;
}

.pending {
  width: 7rem !important;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(194, 71, 92) !important;
}

.due {
  width: 7rem !important;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(47, 66, 175) !important;
}

.hold {
  width: 7rem !important;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: red !important;
}

.NA {
  width: 7rem !important;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(167, 15, 243) !important;
}

.tableContentStatus {
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 155px;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  cursor: pointer;
}

.tableContent1 {
  width: 155px;
  /* text-align: center; */
  padding-left: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
  cursor: pointer;
}

.tableContent1:hover {
  color: green;
  /* font-size: 1rem; */
}

.cross {
  position: absolute;
  right: 10%;
  top: 0%;
}

.link {
  text-decoration: underline;
  color: #855ea1;
  cursor: pointer;
}

.link:visited {
  color: #855ea1;
}

.pagination {
  margin-top: 10px;
  margin-right: 30px;
  /* margin-bottom: 150px; */
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 27rem;
}

.topBarNav {
  position: absolute;
  top: 17%;
  right: 2%;
  display: flex;
}

.input {
  margin: 0 10px;
  border-radius: 5px;
  padding: 0 20px 0 10px;
  border: 1px solid rgba(0, 0, 0, 0.192);
}

.button {
  border: 1px solid #855ea1;
  border-radius: 4px;
  color: white;
  background-color: #855ea1;
  padding: 10px;
  font-size: 14px;
  float: right;
  margin-top: -100px;
  margin-right: 3%;
}

.input1,
.input2 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 90vw;
}

.search {
  position: absolute;
  right: 6%;
  width: 15px;
  top: 20%;
  background-color: white;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 84%;
  width: 21px;
  cursor: pointer;
}

.s1 {
  position: relative;
  width: 13rem;
  margin-left: 0.6rem;
}

.coreSkillInput {
  border-radius: 5px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  width: 99%;
  height: 38px;
  border: 1px solid #00000061;
  padding: 0 19px;
  outline: none;
  /* cursor: pointer; */
}

.coreSkillInput::placeholder {
  color: hsl(0deg 2% 18%);
}

.showStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showStatus > p {
  padding: 10px;
}

.showStatus > p:hover {
  cursor: pointer;
}

.no_data {
  text-align: center;
  padding: 3%;
}

.plus {
  font-size: 18px;
}

/* media query for mac */
@media only screen and (max-width: 1280px) {
  .add {
    border: 2px solid #855ea1;
    color: #855ea1;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .plus {
    font-size: 20px;
  }
}

.filter {
  border: 1px solid #855ea1;
  border-radius: 4px;
  color: white;
  background-color: #855ea1;
  width: 7vw;
  font-size: 15px;
}
.md1 {
  text-align: center;
  margin: 7% 0 2% 0;
}
.editmodal {
  display: grid;
  place-items: center;
  margin: 6% 0;
  grid-row-gap: 10%;
}
.btnModal {
  display: flex;
  justify-content: center;
  margin: 4% 0;
  grid-column-gap: 13%;
}
.col2 {
  width: 70%;
}
.label {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
}

.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  /* width: 130%; */
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  padding-left: 10px;
  outline: none;
  padding-top: 5px;
}
.reactmodal {
  border-radius: 10px;
}
