.main {
  padding: 20px;
  width: 100%;
}

.header {
  display: flex;
  /* justify-content: space-between; */
}

.heading {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}

.tableContainer {
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px 15px;
  background-color: white;
}

.insidetableContainer {
  box-shadow: 0px 3px 9px #00000029 !important;
  margin-bottom: 1rem;
}

.tHeading {
  background-color: #f0f0f0;
}

.tSubHead {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  background-color: #fff;
  border-right: 2px solid #f0f0f0;
  width: 19%;
}

.tSubValue {
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  background-color: #fff;
  border-left: 2px solid #f0f0f0;
}

.tSubHead2 {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  background-color: #fff;
  border-right: 2px solid #f0f0f0;
  width: 19%;
  border-bottom: 2px solid #f0f0f0;
}

.tSubHead3 {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  background-color: #fff;
  border-right: 2px solid #f0f0f0;
  width: 19%;
}

.tSubValue2 {
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  background-color: #fff;
  border-bottom: 2px solid #f0f0f0;
  border-left: 2px solid #f0f0f0;
}

.tSubValue3 {
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  background-color: #fff;
  border-left: 2px solid #f0f0f0;
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-right: 0.3rem;
}

.btnCancel {
  padding: 5px;
  background-color: white;
  color: #954b00;
  border: 0px;
  margin-right: 10px;
  font-size: 16px;
  box-shadow: 0px 3px 9px #00000029 !important;
  width: 90px;
  height: 35px;
  border-radius: 5px;
  font-weight: 500;
}

.btnSubmit {
  padding: 5px 15px;
  background-color: #954b00;
  color: white;
  border: 0px;
  font-size: 16px;
  box-shadow: 0px 3px 9px #00000029 !important;
  height: 35px;
  border-radius: 5px;
  font-weight: 500;
}

.headerDate {
  margin: auto 1rem auto 0;
}

.csd {
  width: 9rem;
  height: 38px;
  font-weight: 500;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  background-color: #fff;
  cursor: pointer;
}

.headerDt {
  background-color: white;
  padding: 7px 12px;
  border-radius: 10px;
  font-weight: 500;
}

.breadCrumb {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}

.breadCrumb:hover {
  text-decoration: underline;
}

.breadCrumb2 {
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}

.noProjectData {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}