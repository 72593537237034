.label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
  }
  .msaopen{
    color: blue !important;
    text-decoration: underline;
  }
  .label1 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
    margin-top: 15px !important;
  }
.partnerlabal{
    margin-left: 12px !important;
}
.primaryContact{
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    border-top:2px solid rgba(8,8,8,.1) ;
  }

  .lastBtn_item {
    display: flex;
    justify-content: end;
    width: 96.4%;
    margin-left: 11px;
  }

  .buttonAdd4 {
    background-color: #c3b14b;
    border-color: #c3b14b;
    width: 62px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 16px;
    left: 75%;
  }
  .TDSRate{


    padding-left: 30px;
    padding-top: 14px;
    font-size: 12px;
    font-weight: 700;



  }