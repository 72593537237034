*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    }  
    .projectresource_mainSection{
      width: 100%;
      /* height: 90vh; */
      display: flex;
    }
    .projectresource_sidebarsection{
      width:20%;
   }
   .projectresource_mainsection{
    width:80%;
    margin-left:2em;
  }
  .head{
    color:#FCB902;
  }
  /* this is main */
  .maindiv{
    width: 64em;
    background-color: #fff;
    border-radius: 9px;
    box-shadow:1px 0px 5px 1px grey;

  }
  .maindivContent{
      display: flex;
      flex-direction: row;
  }
  /* .thead{
    border-color: #FCB902;
  } */
  
  .maindiv div{
    padding-top: 3px;
    
  }
  .maindiv div p{
    font-weight: bold;
    height:1px;
  }
  
  /* this is form section */
  .projectmaindiv{
    width: 100%;
    margin-top: 20px;
    display: flex;
  }
  .projectformSection{
    width: 40%;
    display: flex;
    flex-direction: column;
  }
  .projctdesSection{
    width: 60%;
  }
  .proHeader{
    display: flex;
    flex-direction: row;
  }
  
  .tablediv{
    margin-top: 2em;
    width:63em;
  }
  table{
    width: 100%;
  }
  table tr{
    /* border: 2px solid gray; */
  }
  .divbutton{
    display: flex;
    justify-content: flex-end;
    width: 90.5%;
    margin-top:5px
  }
  .divbutton button{
    margin-right: -58px;
     color:#FCB902 ;
     padding:10px;
      /* background-color: #FCB902;  */
     border:2px solid #FCB902 ;
     border-radius:10px;
  }
  
  .tabledivSecond{
    margin-top:15px;
    width:64em;
    height:150px;
    background-color: #ffff;
    border-radius: 9px;
    box-shadow:1px 0px 5px 1px grey;
  }

  .add_btn{
    padding: 4px 10px;
    border-radius: 50%;
    border: 1px solid #FCB902;
    color:white;
    font-size: 20px;
    background-color: #FCB902;
  }
  /* .tabeledataColor{
    color: #FCB902;
  } */