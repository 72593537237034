.mainSection{
    
  width: 100% ;
  /* height: 100vh ; */
  /* position: fixed; */
  /* position: absolute;
  top: 70px;
  bottom: 0; */
  display: flex;
  /* border: 1px solid red; */
}

