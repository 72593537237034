.popup{
    position: inherit;
    width: 85px;
    overflow: hidden;
    font-size: 14px;
    z-index: 10;
    background-color: rgb(0,0,0,0.6);
    color: white;
    font-weight: 400;
    transition: opacity 0.2s ease-in-out;
    padding: 2px 6px;
    border-radius: 4px;
    transform: translate(-25px);
}
.copyIcon{
    display: inline-block;
    padding-left: 5px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    transition: background-color 0.1s ease-in;
}
.copyIcon:hover{
    background-color: rgb(0,0,0,0.05);
}
.copyIcon:active{
    background-color: rgb(0,0,0,0.2);
}
.popup2{
    position: inherit;
    width: 85px;
    overflow: hidden;
    font-size: 14px;
    z-index: 10;
    background-color: rgb(0,0,0,0.6);
    color: white;
    font-weight: 400;
    transition: opacity 0.2s ease-in-out;
    padding: 2px 6px;
    border-radius: 4px;
    transform: translate(-8px);
}