.main_client_body {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.main_create {
    width: 80%;
}

.main_create .dashboard_container {
    width: 95%;
    margin: 15px auto;
}

.count_card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 1px 1px 14px rgba(0, 0, 0, .1607843137254902);
    opacity: 1;
    margin-top: 10px;
    border-radius: 1em;
    height: 12em;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.count_card_upper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
}
.count_card_img{
    font-size: 4rem;
    background-color: #fcb902;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
}
.count_card:hover,
.card:hover,
.count_card_active {
    border: 2px solid #fcbb08;
}

.heading {
    font-weight: 700;
    font-size: 1rem;
}

.count_card_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.card_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px 25px;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 15px;
}

.card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 1px 1px 14px rgba(0, 0, 0, .1607843137254902);
    opacity: 1;
    margin-top: 10px;
    border-radius: 1em;
    height: 11em;
    /* margin-bottom: 10px; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.card_upper {
    display: flex;
    align-items: center;
    height: 80%;
}

.card_upper h1 {
    margin-top: 20px;
}

.count_card_lower {
    width: 100%;
}

.count_card_lower span {
    color: #fff;
    opacity: 1;
    height: 3rem;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.6em;
    background-color: #fcbb08;
    width: 100%;
}

.card_lower {
    width: 100%;
}

.card_lower span {
    color: #fff;
    opacity: 1;
    height: 2.5rem;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.6em;
    background-color: #fcbb08;
    width: 100%;
}

.card_container .card .card_lower span {
    font-size: 1em;
    padding: 25px 10px;
}
.loading_container, .error_container{
    background: #fff;
    box-shadow: 1px 1px 14px rgba(0, 0, 0, .1607843137254902);
    border-radius: 4px;
    padding: 3px 0px;
    text-align: center;
    margin-top: 10px;
}
.error_container{
    color: red;
    padding-top: 10px;
}
@media (max-width: 1280px) {

    /* On screens with a width of 1280px or less, change to a single column layout */
    .card_container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {

    /* On screens with a width of 768px or less, change to a single column layout */
    .card_container {
        grid-template-columns: repeat(2, 1fr);
    }
}