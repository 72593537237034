.body {
  padding: 20px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.heading {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.plusIcon {
  color: #954b00;
  cursor: pointer;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.ppc {
  padding: 4px !important;
  font-size: 12px !important;
}

.body .tableContainer {
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  overflow-x: auto;
}

.body .tHeading {
  background-color: #f0f0f0;
}

.body .tableContainer th {
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
  background-color: #f0f0f0;
}

.tableContainer td {
  font-size: 12px !important;
  padding: 10px;
}

.tRow {
  background-color: white;
  border-bottom: 2px solid #f0f0f0;
}

.cmnt_icon {
  color: #954b00;
}

.editInvoice {
  width: 1.3rem;
  height: 1.3rem;
  color: #954b00;
  cursor: pointer;
}

.pagination {
  margin-top: 10px;
  margin-right: 30px;
}

.breadCrumb {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}

.breadCrumb:hover {
  text-decoration: underline;
}

.arrowbreadCrum {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}

.countPCM {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #954b00;
  margin-left: 7px !important;
  margin-top: 16px;
  color: #954b00;
}

.gap1rem {
  gap: 1rem;
}

.breadCrumb2 {
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}