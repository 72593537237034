.tooltipctainer {
  background-color: #fff;
  position: absolute;
  font-weight: 400;
  z-index: 10000;
  transition: opacity 1s;
  min-width: 5rem;
  min-height: 2rem;
  text-wrap: wrap;
  max-width: 20rem;
  border: 1px solid #289e98;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  font-size: 14px;
}
