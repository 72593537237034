.main_client_body {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.main_create {
    width: 80%;
}

.main_create .dashboard_container {
    width: 95%;
    margin: 15px auto;
}

.heading {
    font-weight: 700;
    font-size: 1rem;
}

.count_card_container {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; */
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: thin;
}