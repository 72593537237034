.label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
  }
  .label1 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
    margin-top: 15px !important;
  }
.partnerlabal{
    margin-left: 12px !important;
}
.primaryContact{
    border-bottom:2px solid rgba(8,8,8,.1) ;
    text-align: center;
    color: #C3B14B;
    padding-bottom: 10px;

  }

  
  .th {
    display: flex;
    justify-content: space-around;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    color: #666666;
    font-size: 14px;
    font-weight: 600;
  }
  
  .th p:nth-child(1) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
    padding-left: 8px;
  }
  
  .th p:nth-child(2) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
  
  .th p:nth-child(3) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
 
  .th p:nth-child(4) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
  
  .th p:nth-child(5) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
  
 

  
 
  .td {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    min-width: 95%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
  }
  
  .td:last-child {
    border-bottom: none;
  }
  
  .td p:nth-child(1) {
    width: 10rem;
    text-align: start;
    font-size: 14px;
    color: #666666;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 8px;
    color: #C3B14B !important;
    font-weight: bolder;
  }
  
  .td p:nth-child(2) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
   
  }
  
  .td p:nth-child(3) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
  }
  
  .td p:nth-child(4) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    padding-left: 2px;
    font-size: 14px;
  }
  
  .td p:nth-child(5) {
    width: 10rem;
    text-align: start;
    font-size: 14px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
  }

  .buttonAdd {
    background-color:#C3B14B;
    border-color: #C3B14B;
    width: 135px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 28px;
  }

  .buttonAdd3 {
    background-color:#C3B14B;
    border-color: #C3B14B;
    width: 135px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 2px;
    margin-bottom: 10px;
  }