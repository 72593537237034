*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.btn_menu{
    margin-top: 10px;
    display: flex;
    padding: 5%;
    justify-content: center;
    border-radius: 10px;
    font-weight: 500;
    align-items: center;
}

.dashboardImage {
    margin-left: 30px !important;
    height: 45px;
    margin: 7px 0px 10px 13px;
    cursor: pointer;
}

.btn_menu img{
    height:1em;
    width:2em;
}

.btn_menu div{
    color: white;
    margin-left : 10%;
    width: 100%;
}


.btn_menu:hover{
    cursor:pointer;
    background-color: white;
    color: red;
}

.btn_menu:hover .heading{
    color: rgb(252, 187, 8);
}

.icon{
    color:white;
}

.btn_menu:hover .icon{
    color: rgb(252, 187, 8);
}

.accordion-button {
    background-color:black !important;
  }
  
  .accordion-button:focus {
    box-shadow: none;
  }
  
  .accordion-button:not(.collapsed) {
    color: white;
  }

.side_bar{

     width:20%;
    min-height: 45rem; 
    /* padding: 5px; */
    /* height: 100%; */
    background-color: #172A3E;
}

.side_buttons_div{
    /* min-height: 20rem; */
    width: 100%;
    padding: 10px;
}

.project_heading{
    background-color: rgb(252, 187, 8);
    padding: 2%;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    margin-left: 8px;
    margin-right: 8px;
}

.img_div{
    width: 70px;
    height: 70px;
    padding: 20px;
    background-color: white;
    border-radius: 50%;
}

.img{
    width: 2em;
    height: 2em;
}

.prmheading{
    color:white;
    font-size: 20px;
    font-weight: 500;
}

.active_btn{
    background-color: white;
    margin-top: 10px;
    display: flex;
    padding: 5%;
    justify-content: center;
    border-radius: 10px;
    font-weight: 500;
    align-items: center;
}

.active_btn div{
    color:  rgb(252, 187, 8);
    margin-left : 10%;
    width: 100%;
}

.active_btn .icon{
    color:rgb(252, 187, 8);
}




