.main_create {
    min-height: 90vh;
    width: 80%;
}

.cp_cotainer {
    /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
    width: 95%;
    margin: 5px 15px;
    overflow-y: hidden;
}

.main_client_body {
    width: 100%;

    display: flex;
}

.cp_col3 a {
    color: #3535e1 !important;
}

.cp_heading {
    font-size: 18px;
    font-weight: 600;
    margin: 10px auto;
    width: 95%;
    padding: 10px;
}

.createposition {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    /* width: 64em; */
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    min-height: 5rem;
    overflow-y: hidden;
    margin-left: 30px;
    margin-right: 30px;


    /* padding: 10px; */
}

.vieUser {
    font-weight: 700;
    font-size: 1rem;
    padding-left: 30px;
    padding-top: 15px;
}

.cp_yoe {
    display: flex;
}

.cp_field {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 80%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    outline: none;
}

.cp_field2 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    /* flex: ; */
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    outline: none;
}

.cp_field3 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 98.5%;
    /* flex: ; */
    height: 130px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    outline: none;
}

.rowContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.cp_label {
    font-size: 17px;
    font-weight: 500;
    padding-left: 4px;
}

.cp_col1 {
    width: 29%;
    margin: 0px 1.5%;
}

.cp_col2 {
    width: 29%;
    margin: 0px 3%;
    position: relative;
}

.cp_col3 {
    width: 29%;
    margin: 0px 1.5%;
    position: relative;
}

.cp_col4 {
    width: 100%;
    /* margin: 0px 1.5%; */
    margin: 0px 0px 10px 5px;
}

.cp_row2 {
    margin-top: 20px;
}

.cp_rowcol1 {
    width: 28.5%;
    margin: 0px 2.8% 0px 1.5%;
}

.cp_rowcol2 {
    width: 28.8%;
    margin: 0px 5% 0px 1.7%;
}

.cp_rowcol3 {
    width: 31%;
    margin: 0px 0.5%;
}

.cp_rowcol31 {
    width: 29%;
    margin: 0px 2% 0px 2%;
}

.cp_rowcol32 {
    width: 30%;
    margin: 0px 3% 0px 1%;
}

.star {
    color: red;
    font-size: 15px;
    margin-top: -10px;
}

.rowContainer3 {
    margin-left: 1.5%;
}

.cp_button {
    display: flex;
    justify-content: flex-end;
    margin: 14px 30px 500px 0px;

    /* justify-content: space-around; */
}

.cp_cancel {
    margin: 5px;
    padding: 5px 10px;
}

.cp_submit {
    margin: 5px;
    padding: 5px 10px;
}

.create_position__control {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 30%;
    height: 32px !important;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    outline: none;
    margin: 0 0 20px;
}

.multiinput {
    display: flex;
    justify-content: space-between;

}

.cu_fieldReasone {
    /* width: 50%; */
    /* height: auto; */
    margin-top: 10px;
    padding-left: 5px;
    /* padding: 7px 5px 10px 6px; */
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}

.create_position__indicators {
    display: none;
}

.create_position__placeholder {
    display: none;
}

.create_position__menu {
    margin-top: -15px;
}

.cp_dropdown {
    position: absolute;
    z-index: 1000;
    top: 48%;
    margin-left: 90%;
    width: 20px;
}

.cp_value {
    color: gray;
    padding-left: 6px;
}

.inner_card {
    margin: 10px 15px 15px 15px;
}

.inActive_btn {
    width: 150px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}

.inActive_btn:disabled {
    background-color: gray;
    color: white;
    border: 1px solid gray;
}

.inActive_btn:disabled {
    opacity: 1.7;
    cursor: not-allowed;
}

.active_btn {
    width: 140px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}

.delete_btn:disabled {
    background-color: gray;
    color: white;
    border: 1px solid gray;
}

.delete_btn:disabled {
    opacity: 1.7;
    cursor: not-allowed;
}

.delete_btn {
    width: 90px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}

.delete_btn1 {
    width: 180px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}

.unResigned {
    width: 130px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}

.unResigned_btn {
    width: 90px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}

.edit_btn {
    width: 90px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    /* margin-right: 10px; */
    border-radius: 6px;
    color: white;
    font-weight: 500;
}

.edit_btn:disabled {
    background-color: gray;
    color: white;
    border: 1px solid gray;
}

.edit_btn:disabled {
    opacity: 1.7;
    cursor: not-allowed;
}

.inActive_btn {
    width: 150px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}

.inActive_btn:disabled {
    background-color: gray;
    color: white;
    border: 1px solid gray;
}

.container {
    margin-top: 10px;
    width: 100%;
    float: right;
    text-align: right;
    margin-right: 30px;
}

.container button {
    display: inline-block;
}

.confirmPopupTitle {
    text-align: center;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 500;
    margin-top: -35px;
    margin-right: 124px;
}

.confirmPopupTitle1 {
    text-align: center;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 500;
    margin-top: -35px;
    margin-right: 160px;
}

.error {
    color: red;
    font-weight: 500;
    padding-top: 4px;
    padding-left: 4px;

}

.csd {
    width: 68%;
    height: 38px;
    font-weight: 500;
    box-shadow: 0px 3px 9px #00000029 !important;
    margin: 8px 1% 0px 1.5%;
    border-radius: 9px;
    background-color: #F5F6FA;
}

.yesBtn {
    padding: 5px 30px;
    outline: none;
    background: rgb(252, 187, 8) 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 6px;
    color: #fff;
    opacity: 1;
}

.noBtn {
    margin-left: 10px;
    padding: 5px 30px;
    outline: none;
    font: normal normal 600 18px/27px Poppins;
    background: #fff;
    letter-spacing: 0px;
    color: rgb(252, 187, 8);
    opacity: 1;
    border: 1px solid rgb(252, 187, 8);
    border-radius: 6px;
}

.btnCont {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.confirmPopup {
    /* top: 441px;
left: 767px;*/
    width: 386px;
    height: 198px;
    text-align: center;
    padding-top: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
}

.vp_header {
    display: flex;
    justify-content: center;
    /* gap: 85px; */
    color: #289e98;
    font-weight: 500;
    padding-bottom: 8px;
    align-items: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0.7rem;
}

.buttonD {
    float: right;
    margin-right: 1.9rem;
    margin-top: 0.3rem;
}

.right_img {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    /* border-radius: 50%; */
}

.createposition_academic {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 80%;
    /* margin: 10px auto; */
    border-radius: 5px;
    background-color: #ffffff;
    height: auto;
    /* overflow-y: hidden; */
    /* padding-top: 10px; */
    /* padding: 10px; */
    margin-bottom: 10px;
}

.title {
    display: flex;
    justify-content: center;
    color: #289e98;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
    font-weight: bold;
}

.Cu_row1 {
    display: flex;
    justify-content: space-between;
    /* margin: 0 1%; */
    margin: 1% 1% -2%;
    width: auto;
}

.Cu_row2 {
    margin: 1% 1% 2% 1%;
    width: auto;
}

.cu_label {
    font-size: 14.5px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;

}

.rmpdf a {
    color: #289e98 !important;
}

cu_label {
    font-size: 14.5px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
}

.cu_field2 {
    padding: 0px 5px 5px 5px;
    color: gray;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.cu_fieldReasone {
    /* width: 50%; */
    /* height: auto; */
    padding: 0px 5px 5px 5px;
    /* padding: 7px 5px 10px 6px; */
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}