.EmpList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  margin: 10px auto;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #ffff;
  padding: 10px;
  min-height: 5rem;
  height: 80vh;
  overflow-x: hidden;
}

.EmpList table {
  border-collapse: collapse;
  width: 100%;
}

.tableContent2 {
  font-weight: bold;
  text-align: left;
  padding: 8px 5px;
  color: black;
}


.scrollable tbody td,
.scrollable tbody td>span {
  padding: 1px 5px;
  text-align: start;
  font-size: 12px;
  font-weight: 400;
}

.EmpList .link {
  cursor: pointer;
  text-decoration: underline;
  color: #964431;
}

.EmpList .td1 {
  font-size: 14px;
  font-weight: 500;
}

.EmpList .td3 {
  font-size: 14px;
  font-weight: 500;
  width: 5%;
}

.EmpList .td2 {
  font-size: 14px;
  font-weight: 500;
  width: 10%;
}

.filteralign {
  display: flex;
  justify-content: flex-end;
  width: 63.4%;
  align-items: center;
}

.tableHeader {
  position: sticky;
  top: -10px;
}

.th1 {
  font-size: 12px;
  font-weight: 700;
  background-color: #dfdfdf;
}

.main {
  width: 80%;
}

.s1 {
  position: relative;
  width: 34%;
}

.twoFilter {
  display: flex;
  justify-content: end;
  margin-right: 0.7rem;
  margin-top: 1rem;
}

.cp_serchIcon2 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 83%;
  width: 21px;
  cursor: pointer;
}

.addQuestionnairebtn {
  border-radius: 5px;
  border: 1px solid #964431;
  background-color: #964431;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.loading {
  display: flex;
  justify-content: space-around;
}

.noData {
  display: flex;
  justify-content: center;
}

.scrollable {
  overflow: scroll;
}

.symbol {
  border-radius: 50%;
  border: 1px solid #562679;
  margin-left: 3px;
}

.headerEmpList {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_checkbox {
  visibility: visible !important;
}

.showCheckbox {
  width: 10px;
}

.appr_btn {
  border: 2px solid #855ea1;
  background-color: #855ea1;
  color: white;
}

.modal {
  height: 300px;
}

.select {
  width: 250px;
}

.code {
  color: #562679 !important;
  cursor: pointer;
  font-weight: 700 !important;
  border: none;
}

.dropdown {
  display: flex;
  gap: 10px;
  align-items: center;
}

.inlineDropdown {
  display: flex;
  gap: 5px;
  height: 30px;
  width: 95px;
}

.inlineDropdown select {
  height: 30px;
  border-radius: 5px;
}



.upper {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}

.pp {
  font-weight: 700;
  align-self: center;
  padding-left: 0px;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Search */
.filterBox {
  display: flex;
  justify-content: flex-end;
}

.searchContainer {
  display: flex;
  left: 45px;
  position: relative;
  width: 13rem;
  margin-left: 0.6rem;
}

.searchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 88%;
  width: 21px;
  cursor: pointer;
  padding-top: 5px;
  resize: none;
}

.actionButtons {
  display: flex;
  gap: 5px;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 84%;
  width: 21px;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: end;
}

.inputRow {
  border-bottom: 1px solid #ddd;
  height: 33.5px;
}

.totalCount {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #562679;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.present {
  background-color: #f2f2f2;
}

.nextCycle {
  display: flex;
  gap: 5px;
}

.typeOfAppraisal {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #562679;
  color: white;
  height: 20px;
  width: 20px;
}

.typeOfPastAppraisal {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: dimgrey;
  color: white;
  height: 20px;
  width: 20px;
}