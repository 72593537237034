.main_create {
  min-height: 90vh;
  width: 80%;
  padding-left: 27px;
  padding-right: 27px;
}

.cp_cotainer {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  width: 95%;
  margin: 5px 15px;
  overflow-y: hidden;
}

.multiinput {
  display: flex;
  justify-content: space-around;
  grid-column-gap: 0;
}

.main_user_body {
  width: 100%;
  min-height: 90vh;
  display: flex;
}

.recent_tab {
  color: #289e98;
}

.arrow {
  font-size: 13px;
  font-weight: bold;
  color: rgb(252, 187, 8);
}

.breadscrumb {
  font-size: 10px;
  font-weight: bold;
}

.cp_heading {
  font-size: 18px;
  font-weight: 600;
  margin: 10px auto;
  width: 95%;
  padding: 10px;
}

.error {
  color: red;
  font-weight: 500;
  padding-top: 4px;
  padding-left: 4px;

}

.createposition {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 100%;
  /* margin-left: 60px; */
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 5rem;
  padding: 10px;
}



.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  width: 65%;
  height: 50px;
  margin-top: 6px;
  font-weight: 500;
  background: transparent 0% 0% no-repeat padding-box;

}

.Cu_row1 {
  display: flex;
  justify-content: space-between;
  margin: 0 1%;
}

.Cu_notes {
  /* display: flex; */
  /* justify-content: space-between; */
  margin: 0 1%;
}

.cu_col1 {
  flex: 0 0 31%;
}

.cu_col2 {
  flex: 0 0 31%;
}

.cu_col3 {
  flex: 0 0 31%;
}

.cu_row2_col2 {
  display: flex;
  width: 98%;
  justify-content: space-between;
}

.cu_row_field2 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 47%;
  font-weight: 500;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  padding-left: 10px;
  outline: none;

}

.cu_label {
  font-size: 15px;
  font-weight: 500;
  padding-left: 4px;
  color: black
}

.cu_field2 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 98%;
  font-weight: 500;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  padding-left: 10px;
  outline: none;
}

.readonly {
  pointer-events: none;
  /* Disable pointer events */
  background-color: #e7e7e7;
  /* Change background color to visually indicate readonly */
}

.csd {
  width: 98%;
  height: 38px;
  font-weight: 500;
  box-shadow: 0px 3px 9px #00000029 !important;
  margin: 8px 1% 0px 1.5%;
  border-radius: 9px;
  background-color: #F5F6FA;
}

.cu_button {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  /* padding-right: 2%; */
}

.creatUser {

  padding-top: 15px;
  font-weight: 700;
  font-size: 1rem;
}

.errorTextHidden {
  visibility: hidden;
  /* color: red; */
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.errorTextHidden2 {
  display: none;
}

.errorText {
  margin-top: 3px !important;
  margin-left: 5px !important;
  color: red;
  font-size: 12px;
  font-weight: 400;
  margin-left: 15px;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.Cu_row1 input[type="file"]::file-selector-button {
  border: 2px solid rgb(40, 158, 152);
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: rgb(40, 158, 152);
  transition: 1s;
  color: #ffffff;
  height: 38px;
}

.Cu_resume {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 1%;
  position: relative;
  grid-column-gap: 5px;
  width: 100%;

}

.rmpdf {
  border: 1px solid rgb(40, 158, 152);
  margin-top: 7px;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
  padding-right: 50px;
  color: rgb(40, 158, 152);
  height: 38px;
}

.imageupload {
  display: block;
  display: flex;

  position: relative;
}

.rmpdfcross {
  color: black;
  border: 1px solid #000000;
  background-color: rgb(40, 158, 152);
  position: absolute;
  right: -10%;
  top: 0%;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;

}

.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px;
}

.upload_resume {
  width: 100%;
  border: 1px solid #999;
  border-radius: 10px;
  margin-top: 5px;

}

.notes {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 99.3%;
  height: 4rem;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 8px;
  padding-left: 10px;
  outline: none;
}

.candidateAcademics {
  position: relative;
}

.btn_remove {
  position: absolute;
  left: 96%;
  top: -3%;
  background-color: transparent;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn_remove1 {
  left: 96%;
  top: -3%;
  background-color: transparent;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn_add {
  background-color: rgb(40, 158, 152);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 35px;
}

.cu_fieldAC {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  /* width: 130%; */
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 5px;
  padding-left: 10px;
  outline: none;
  padding-top: 5px;
}

.cu_field233 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 98%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  padding-left: 10px;
  outline: none;
}

.title {
  display: flex;
  justify-content: center;
  color: rgb(40, 158, 152);
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}

.bigInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  margin: 1% 1% 1% 1%;
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.workExpButton {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 16px;
  height: 25px;
}

.radioitem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin-left: 1%;
  /* margin: 10px 0 0; */
}

.radioitem label {
  cursor: pointer;
}

.radioitem input[type="radio"] {
  display: none;
}

.radioitem label {
  font-weight: 600;
}

.radioitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid rgb(40, 158, 152);
  background-color: transparent;
}

.radioitem input[type="radio"]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #65a15e;
  border: 2px solid #65a15e;
}

.cu_field23 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 8vw;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  padding-left: 10px;
  outline: none;
  padding-top: 5px;
}