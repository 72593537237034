* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.leftSection {
  min-width: 20%;
  background-color: #172a3e;
  min-height: 40rem;
}

.dashboardImage {
  height: 45px;
  margin: 10px 20px;
  cursor: pointer;
}

.leftSection .btn {
  width: -webkit-fill-available;
  border-radius: 15px;
  opacity: 1;
  outline: none;
  margin: 0 10px 10px 10px;
  display: flex;
  align-items: center;
  background-color: #964b00;
  height: 70px;
  padding-left: 15px;
  cursor: auto;
  border: none;
}

.leftSection .btn span {
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left;
}

.leftSection .btn div {
  background-color: white;
  width: 54px;
  height: 54px;
  border-radius: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deactivate_btn img,
.active_btn img {
  height: 24px;
  width: 24px;
}

.heading {
  margin-left: 10%;
  width: 100%;
}

.deactivate_btn .heading {
  color: white;
}

.active_btn .heading {
  color: white;
}

.side_buttons_div {
  width: 100%;
  padding: 10px;
}

.deactivate_btn,
.active_btn {
  margin-top: 10px;
  display: flex;
  padding: 5%;
  justify-content: center;
  border-radius: 10px;
  font-weight: 500;
  align-items: center;
}

.active_btn {
  background-color: #964b00;
}
