.container {
  z-index: 10;
  top:65px;
  position: absolute;
  width: 57%;
  height: 250px;
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background-color: white;
  left: 30%;
  opacity: 1;
}


.one{
  accent-color:#0ca30c;
}

.spann{
  font-size: 16px;
  font-weight: 400;
  padding-left: 5px;
}

.containerr {
  z-index: 10;
  top:65px;
  position: absolute;
  width: 45%;
  height: 250px;
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background-color: white;
  left: 35%;
  opacity: 1;
}
.container2 {
  z-index: 10;
  top: 75px;
  position: absolute;
  width: 92%;
  height:16.5rem;
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background-color: white;
  left: 7%;
  opacity: 1;
}
.container4 {
  z-index: 10;
  top: 75px;
  position: absolute;
  width:60%;
  height:16.5rem;
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background-color: white;
  left: 25%;
  opacity: 1;
}

.container3 {
  z-index: 10;
  top:75px;
  position: absolute;
  width:95%;
  height:27rem;
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background-color: white;
  left:4%;
  opacity: 1;
}

.container1 {
  z-index: 10;
  top: 65px;
  position: absolute;
  width: 19%;
  height: 250px;
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 9px;
  background-color: white;
  left: 47%;
  opacity: 1;
}

.subContainer {
  display: flex;
  justify-content: space-evenly;
  opacity: 1;
}

.subContainer1 {
  display: flex;
  gap: 20px;
  opacity: 1;
  margin-right: 16px;
  margin-left: 20px;
}

.subContainer2 {
  display: flex;
  justify-content: space-evenly;
  opacity: 1;
}
.filterSet{
  position: absolute;
  top: 12.5rem;
  left: 0px;
  width: 15% !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.subContainer3 {
  display: flex;
  justify-content: space-evenly;
  opacity: 1;
}

.subContainer div {
  width: 48%;
}

.subContainer1 div {
  width: 98%;
  height: 12rem;
}

.subContainer2 div {
  width: 24%;
}

.subContainer3 div {
  width: 48%;
}

.addScroll {
  height: 146px;
  overflow-y: auto;
}

.addScroll::-webkit-scrollbar {
  width: 15px;
}

.addScroll::-webkit-scrollbar-track {
  background: #ffffff;
  /* color of the tracking area */
}

.addScroll::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
}

.mobileRes {
  display: none;
}

@media screen and (max-width: 800px) {
  .mobileRes {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .container {
    z-index: 10;
    top: 4%;
    position: absolute;
    width: 76%;
    height: auto;
    box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    background-color: white;
    left: auto;
    right: 0;
  }

  .subContainer {
    display: block;
    justify-content: space-evenly;
  }

  .subContainer div {
    width: 98%;
  }
}

.container .subContainer header {
  height: 30px;
  background-color: #cbc9c9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  opacity: 1.85;
}

.container1 .subContainer1 header {
  height: 30px;
  background-color: #cbc9c9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  opacity: 1.85;
}

.container2 .subContainer2 header {
  height: 30px;
  background-color: #cbc9c9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  opacity: 1.85;
}

.container4 .subContainer2 header {
  height: 30px;
  background-color: #cbc9c9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  opacity: 1.85;
}
.container3 .subContainer3 header {
  height: 30px;
  background-color: #cbc9c9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  opacity: 1.85;
}

.container .subContainer ul {
  list-style-type: none;
  padding-left: 15px;
}

.container1 .subContainer1 ul {
  list-style-type: none;
  padding-left: 15px;
}

.container2 .subContainer2 ul {
  list-style-type: none;
  padding-left: 5px;
}
.container4 .subContainer2 ul {
  list-style-type: none;
  padding-left: 5px;
}

.container3 .subContainer3 ul {
  list-style-type: none;
  padding-left: 15px;
}

.buttonSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  padding-right: 14px;
  outline: none;
  padding-bottom: 10px;
}

.clearAll {
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
  background-color: #ffffff;
  color: rgb(252, 187, 8);
  outline: none;
  border: 1px solid rgb(252, 187, 8);
  border-radius: 10px;
  font-size: 18px;
}

.clearAll1 {
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
  background-color: #ffffff;
  color:#289e98;
  outline: none;
  border: 1px solid #289e98;
  border-radius: 10px;
  font-size: 18px;
}

.apply {
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
  background-color: rgb(252, 187, 8);
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  margin-left: 10px;
}

.apply1 {
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
  background-color:#289e98;
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  margin-left: 10px;
}

.addToPoolRadioList {
  padding-top: 10px;
  font-size: 14px;
  color:rgb(0, 0, 0);
  opacity: .7;
  font-weight: 500;
}