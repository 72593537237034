.createBtn {
  width: auto;
  background-color:#C3B14B;
  color: white;
  border: #C3B14B;
  border-radius: 5px;
  padding: 0.4rem 0.7rem;
}
.createBtn:disabled {
  color: #fff;
  background-color: #bab5b5;
}
.cancelBtn {
  width: 80px;
  background-color: white;
  color: var(--invoiceColor);
  border: 1px solid var(--invoiceColor);
  border-radius: 5px;
  padding: 0.4rem 0.7rem;
}
.cancelBtn1 {
  width: auto;
  background-color:white;
  color: #C3B14B;
  border: 1px solid #C3B14B;
  border-radius: 5px;
  padding: 0.4rem 0.7rem;
}

.createBtn1 {
width: 50px;
padding-bottom: -8px !important;
padding-top: -6px;
background-color: #C3B14B;
color: white;
border: #C3B14B;
border-radius: 5px;
font-size: 14px;
/* padding: 0.4rem 0.7rem; */
margin-right: 8px;
margin-top: -5px;
}
