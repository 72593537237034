.timesheetdate {
  margin-top: 15px;
}

.TimeSheet {
  color: black;
  font-weight: 700;
}
.searchbar {
  position: relative;
  width: 13rem;
  margin-left: 0.6rem;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 27rem;
  margin-left: 65px;
}

.topBar1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
}
.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 84%;
  width: 21px;
  cursor: pointer;
}
.s1 {
  position: relative;
  width: 13rem;
  margin-left: 0.6rem;
}

.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 70%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 15px;
  padding-left: 10px;
  margin-left: 16px;
  outline: none;
  padding-top: 5px;
}

.timesheetdate {
  margin-top: 30px;
  margin-left: 14px;
}

.th {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}

.th p:nth-child(1) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  padding-left: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  /* transform: translate(-66px, 0px); */
}

.th p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  padding-left: 3px;
}

.th p:nth-child(3) {
  width: 10rem;
  text-align:center;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  /* padding-left: 114px; */
}

.tableContent {
  width: 80px;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
}

.td {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 10rem;
  text-align: start;
  padding-left: 15px;
  font-size: 15px;
  color: #855ea1 !important;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bolder;
  /* transform: translate(-62px, 0px); */
}

.td p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  transform: translate(8px, 0px);
}

.td p:nth-child(3) {
  width: 10rem;
  text-align: center;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  /* padding-left: 60px; */
  /* transform: translate(115px, 0px); */
}

.buttonAdd {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 15px;
  margin-top: 15px;
  float: right;
}
.buttonAdd2 {
  background-color: #999999;
  border-color: #999999;
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 15px;
  margin-top: 15px;
}
.plus {
  font-size: 20px;
}
.no_data {
  text-align: center;
  padding: 3%;
}

.buttonAdd1 {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  position: relative;
  margin-top: 20px;
  left: 32%;
}
.buttonCancle {
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  position: relative;
  left: 67%;
  margin-top: -40px;
  margin-bottom: 25px;
}

.plus {
  font-size: 20px;
  font-size: 20px;
  margin-top: -2px;
  padding-right: 3px;
}
