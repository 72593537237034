.label {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-left: 12px !important;
}
.label1 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-left: 12px !important;
  margin-top: 15px !important;
}
.partnerlabal {
  margin-left: 12px !important;
}
.primaryContact {
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  text-align: center;
  color: #c3b14b;
  padding-bottom: 10px;
}

.copy_expence {
  text-align: center;
  color: #c3b14b;
  font-weight: 500;
  font-size: var(--inoiceHeading);
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  padding-bottom: 0.5rem;
}

.border {
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  margin: 1rem 0px;
}

.Container {
  width: 95% !important;
  margin: 0.2rem auto;
  display: flex;
  justify-content: space-between;
}

.Container1 {
  width: 95% !important;
  /* margin: 0.2rem auto; */
  display: flex;
  justify-content: space-between;
}

.col12_item {
  width: 19%;
}
.col2_item {
  width: 19%;
  margin-left: -10px;
}
.col3_item {
  width: 19%;
  margin-top: -1px;
}
.col4_item {
  width: 19%;
  margin-top: -1px;
}
.col5_item {
  width: 14%;
}
.col6_item {
  width: 13%;
}
.col7_item {
  width: 13%;
}
.col8_item {
  width: 0%;
  margin: 3% 0 0 0;
}
.lastBtn_item {
  display: flex;
  justify-content: end;
  width: 96.4%;
}

.bigInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  /* margin: 1% 1% 1% 1%; */
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}
.smallInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  /* margin: 1% 1% 1% 1%; */
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.smallInput1 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 90%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-left: 15px;
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.cu_field23Last1 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 96%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  padding-left: 10px;
  margin-left: 7px;
  outline: none;
  padding-top: 5px;
}
.heading {
  font-weight: 500;
  color: var(--invoiceTextColor);
}

.label {
  font-weight: 500;
  color: var(--invoiceTextColor);
}
.label_item_resource {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  /* font-size: 12px; */
  padding-left: 15px;
}
.label_item_resource1 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  /* font-size: 12px; */
}
.label_item_resource2 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  padding-left: 12px;
}
.label_item_resource3 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  margin-left: 7px !important;
}
.label_item_resource4 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  margin-left: 5px !important;
}
.label_item_resource_type {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  /* font-size: 12px; */
  padding-left: 21px;
}

.label_item5 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  padding-left: 14px;
}

.label_item6 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  padding-left: 10px;
}

.label_item9 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  margin-top: 26px !important;
}

.lastBtn {
  display: flex;
  justify-content: end;
  width: 97.6%;
  grid-column-gap: 2%;
  margin: 2% 0 3% 0;
}
.remove_icon {
  color: #c3b14b;
  margin-top: 3%;
  cursor: pointer;
  transform: translate(0px, -22px);
}

.errorText {
  color: red;
  font-size: 12px;
  visibility: visible;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
}

.errorTextHidden {
  color: red;
  font-size: 12px;
  margin-left: 15px;
  visibility: hidden;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
}

.buttonAdd {
  background-color: #c3b14b;
  border-color: #c3b14b;
  width: 100px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-bottom: 12px;
  margin-top: 25px;
}

.buttonAdd1 {
  background-color: #c3b14b;
  border-color: #c3b14b;
  width: 100px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-bottom: 12px;
  margin-right: 22px !important;
}
.buttonAdd4 {
  background-color: #c3b14b;
  border-color: #c3b14b;
  width: 100px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-bottom: 12px;
  margin-right: 25px !important;
}

.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
}

.th p:nth-child(1) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  /* padding-left: 8px; */
}

.th p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(4) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  transform: translate(-16px, 0px);
}
/* .th p:nth-child(6) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
} */

.td {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* padding-left: 8px; */
  font-size: 14px;
  color: #c3b14b !important;
  font-weight: bolder;
}

.td p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td p:nth-child(4) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  padding-left: 2px;
  font-size: 14px;
}

.td p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  font-size: 14px;
  transform: translate(-11px, 0px);
}

.th1 {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
}

.th1 p:nth-child(1) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  /* padding-left: 8px; */
}

.th1 p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th1 p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  /* padding-left: 9px; */
}

.th1 p:nth-child(4) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  /* padding-left: 12px; */
}

.th1 p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  /* padding-left: 10px; */
}

.td1 {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.td1:last-child {
  border-bottom: none;
}

.td1 p:nth-child(1) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* padding-left: 8px; */
  font-size: 14px;
  color: #c3b14b !important;
  font-weight: bolder;
}

.td1 p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  /* transform: translate(-4px, 0px); */
}

.td1 p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td1 p:nth-child(4) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  /* padding-left: 12px; */
  font-size: 14px;
}

.td1 p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  font-size: 14px;
  /* padding-left: 20px; */
}

.no_data {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px !important;
}
