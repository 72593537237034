@use "../../../GenericComponent/css/common.module.scss" as global;
@import "../../../GenericComponent/css/CommonTable.module.scss";

.th p:nth-child(1),
.td p:nth-child(1) {
  padding-left: 1rem;
}

.th p:nth-child(1),
.th p:nth-child(2),
.td p:nth-child(1),
.th p:nth-child(5),
.td p:nth-child(5),
.th p:nth-child(6),
.td p:nth-child(6),
.td p:nth-child(2) {
  min-width: 15%;
}
.td p:nth-child(3),
.th p:nth-child(3) {
  min-width: 25%;
}

.td p:nth-child(4),
.th p:nth-child(4) {
  min-width: 15%;
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.titleBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 7px;
}

.titleBtn button {
  border: 1px solid global.$client_contract_theme; /* Assuming $client_contract_theme is a variable */
  color: global.$client_contract_theme;
  background-color: transparent;
  border-radius: 50%;
  height: 2rem; /* Increase the height for a larger circle */
  width: 2rem; /* Increase the width for a larger circle */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem; /* Adjust  the font size to center content */
}

.link {
  color: global.$client_contract_theme !important;
}

.titleBtnRight,
.titleBtnLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}
