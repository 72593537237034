.invoicecontainer {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 100%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px 0px;
  font-size: 14px;
  z-index: 0;
}
