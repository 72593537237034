.mainSection {
    width: 100%;
    min-height:100vh;

    height: auto;
    /* position: fixed; */
    /* position: absolute; */
    top: 100px;
    bottom: 0;
    display: flex;
  }
  