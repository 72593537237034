.offerContainer {
  height: 85vh;
}

.card {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 150px;
  overflow-y: hidden;
  padding: 10px;
}

.th11>p {
  text-align: center;
  color: rgb(44, 116, 44);
  font-weight: bold;
}

.td_item>a {
  color: #48729e !important;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 7vw;
  row-gap: 2vw;
  padding: 0 20px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.td_item {
  width: 150px;
}

.td_item_2 {
  width: 150px;
}

.td_item_1 {
  font-weight: 600;
}

.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  min-width: 95%;
}

.th p:nth-child(1) {
  width: 5rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
  padding-left: 9px;
}

.th p:nth-child(2) {
  width: 7rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.th p:nth-child(3) {
  width: 6rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.th p:nth-child(4) {
  width: 4rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.th p:nth-child(5) {
  width: 6rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.th p:nth-child(6) {
  width: 9rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.th p:nth-child(7) {
  width: 9rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.th p:nth-child(8) {
  width: 6rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.th p:nth-child(9) {
  width: 6rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.th p:nth-child(10) {
  width: 7rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.th p:nth-child(11) {
  width: 8rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
}

.td {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2px 0 10px;
  font-size: 12px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 5rem;
  text-align: center;
  /* color: limegreen; */
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding-left: 9px;
  /* text-decoration: underline; */
}

.td p:nth-child(2) {
  width: 7rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(3) {
  width: 6rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(4) {
  width: 4rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(5) {
  width: 6rem;
  text-align: center;
  color: green !important;
  text-decoration: underline;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(6) {
  width: 9rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(7) {
  width: 9rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(8) {
  width: 6rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(9) {
  width: 6rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(10) {
  width: 7rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(11) {
  width: 8rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tableContent {
  width: 120px;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableContent1 {
  width: 120px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.td1 {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  min-width: 95%;
}

.td1:last-child {
  border-bottom: none;
}

.btn {
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  padding: 6px 10px;
}

/*when  disabled */
.btn:disabled {
  background-color: gray;
  color: white;
  border: 1px solid gray;
}

.buttonContainer {
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
  margin-right: 27px;
}

.link {
  color: rgb(44, 116, 44);
  cursor: pointer;
  font-weight: 600;
}

.cu_col1 {
  flex: 0 0 19%;
  width: 19%;
}

.cu_col2 {
  flex: 0 0 19%;
  width: 19%;
}

.cu_col3 {
  flex: 0 0 19%;
  width: 19%;
}

.cu_col4 {
  flex: 0 0 19%;
  width: 19%;
}

.cu_col5 {
  flex: 0 0 19%;
  width: 19%;
}

.Cu_rowCard {
  display: flex;
  justify-content: space-between;
  width: auto;
  margin: 0px 0.5rem;
}

.Cu_row1 {
  display: flex;
  /* justify-content: space-between; */
  margin: 0px 0.5rem;
}

.cu_label {
  font-size: 15px;
  font-weight: 500;
  padding-left: 4px;
  color: #000000;
}

.cu_field2 {
  /* width: 100%; */
  /* height: 38px; */
  margin-top: 10px;
  padding-left: 5px;
  padding: 7px 5px 10px 4px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cu_field3 {
  /* width: 90%; */
  /* height: 38px; */
  margin-top: 10px;
  padding-left: 5px;
  padding: 7px 5px 10px 4px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cu_field4 {
  margin-top: 10px;
  padding-left: 5px;
  padding: 7px 5px 10px 4px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.confirmPopup {
  width: 386px;
  height: 198px;
  text-align: center;
  padding-top: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}


.confirmPopup1 {
  width: 386px;
  max-height: 50rem;
  min-height: 32rem;
  height: auto;
  text-align: center;
  padding-top: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.confirmPopup2 {
  width: 386px;
  height: 198px;
  text-align: center;
  padding-top: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.confirmPopupTitle1 {
  display: flex;
  justify-content: center;
  color: rgb(123, 187, 123);
  font-size: 1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  padding-bottom: 8px;
}

.csd {
  width: 68%;
  height: 38px;
  font-weight: 500;
  box-shadow: 0px 3px 9px #00000029 !important;
  margin: 0% 1% 0px 1.5%;
  border-radius: 9px;
  background-color: #f5f6fa;
}

.csd1 {
  width: 85%;
  height: 38px;
  font-weight: 500;
  box-shadow: 0px 3px 9px #00000029 !important;
  margin: 0% 1% 0px 1.5%;
  border-radius: 9px;
  background-color: #f5f6fa;
}

.error {
  color: red;
  font-weight: 500;
  padding-top: 4px;
  padding-left: 4px;
}

.errorText {
  color: red;
  display: flex;
  font-size: 15px;
  margin-left: 30px !important;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.errorText1 {
  color: red;
  display: flex;
  font-size: 14px;
  margin-left: 61px !important;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.button_btn {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  width: 83%;
}

.button_btn1 {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  margin-top: 33px;
  width: 92%;
}

.button_btn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 33px;
  width: 83%;
}

.noBtn {
  background-color: white;
  color: #65a15e;
  border: 1px solid #65a15e;
  border-radius: 5px;
  width: 75px;
}

.noBtn1 {
  background-color: white;
  color: #65a15e;
  border: 1px solid #65a15e;
  border-radius: 5px;
  width: 75px;
  margin-bottom: 10px;
}

.yesBtn {
  background-color: #65a15e;
  color: white;
  border: 1px solid #65a15e;
  border-radius: 5px;
  width: 75px;
  /* margin-bottom: 10px; */
}

.yes1Btn {
  background-color: #65a15e;
  color: white;
  border: 1px solid #65a15e;
  border-radius: 5px;
  width: 75px;
}

.yesBtn2 {
  background-color: #65a15e;
  color: white;
  border: 1px solid #65a15e;
  border-radius: 5px;
  width: 75px;
  margin-bottom: 10px;
}

.modal_container1 {
  width: 20rem;
  height: 5rem;
  border-radius: 5px;
}

.textM {
  text-align: left;
  white-space: pre-wrap;
}

.M_button1 {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.date {
  display: flex;
  justify-content: end;
  width: 24%;
  font-weight: 600;
}

.date1 {
  display: flex;
  justify-content: end;
  width: 42%;
  font-weight: 600;
}

.note {
  display: flex;
  justify-content: end;
  width: 19%;
  font-weight: 600;
  margin: 10px 0;
  color: #666;
}

.text_area {
  /* box-shadow: 0px 3px 9px #00000029 !important; */
  /* background: transparent 0% 0% no-repeat padding-box; */
  /* background-color: #f5f6fa; */
  display: flex;
  justify-content: end;
  width: 95%;
  margin-left: -8px;
  font-weight: 600;
}

.text_box {
  outline: none !important;
}

.docOffer {
  display: flex;
  justify-content: end;
  width: 52%;
  font-weight: 600;
  margin: 10px 0;
  color: #666;
}

.docOffer1 {
  display: flex;
  width: 92%;
  justify-content: end;
}

.upload_resume {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 92%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* padding-left: 10px; */

  outline: none;
}

.docOffer1 input[type="file"]::file-selector-button {
  border: 2px solid #65a15e;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #65a15e;
  transition: 1s;
  color: #ffffff;
}

.upload_resume input[type="file"]::file-selector-button:hover {
  /* background-color: green; */
  /* border: 2px solid #00cec9; */
}

.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px -10px;
  height: 38px;
  /* width: 70px; */
}

.rmpdf {
  width: 300px;
  border: 1px solid black;
  border-radius: 10px;
  text-align: start;
  font-size: 15px;
  color: #65a15e;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 5px;
}

.Cu_resume {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 1%;
  position: relative;
  grid-column-gap: 5px;
  width: 90%;
}

.inner_box {
  margin-top: 4%;
}

.btn_dicline {
  background-color: white;
  color: rgb(236, 4, 4);
  border: 1px solid rgb(226, 0, 0);
  border-radius: 5px;
  width: 130px;
}

.rmpdfcross {
  color: black;
  border: 1px solid #000000;
  background-color: #65a15e;
  position: absolute;
  right: -3%;
  top: -20%;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
}

.errorText3 {
  color: red;
  font-size: 12px;
  margin-left: 6px !important;
  visibility: visible;
  padding: 2px 5px;
}

.errorTextHidden {
  visibility: hidden;
  color: red;
  font-size: 12px;
  margin-left: 15px;
  padding-top: 2px;
}

.noteText {
  color: limegreen;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
}