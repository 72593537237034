.body {
  width: 100%;
  height: 100%;
}

.card {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  height: 200px;
  margin: 80px auto;
  border-radius: 5px;
  background-color: #ffffff;
  overflow-y: hidden;
  padding: 20px;
  font-size: 14px;
}

.desc {
  font-size: 22px;
  color: #000000;
  font-weight: 500;
}

.btn {
  padding: 0px 10px;
  background: rgba(44, 116, 44, 0.815);
  color: rgb(255, 255, 255);
  border: none;
  height: 37px;
  width: 150px;
  margin-top: 50px;
  border-radius: 5px;
  cursor: pointer;
}

.submitted {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitted > p {
  font-size: 4vw;
}
.expired{
  height: 90vh;
  display: flex;
  text-align: center;
  font-size: larger;
  justify-content: center;
  align-items: center;
}