.AutoCompleteText {
  width: 100%;
  /* border-radius: 4px; */
  box-shadow: 0px 1px 2px solid grey;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75) !important;
  position: relative;
}

.AutoCompleteText input {
  /* width: 100%;
  border: none;
  box-sizing: border-box;
  outline: none; */
  /* font-weight: 500; */
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 6px;
  border: none;
  opacity: 1;
  width: 364px;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #F5F6FA;
  /* margin-bottom: 10px; */
}

.AutoCompleteText ul::before {
  content: "";
}

.AutoCompleteText ul {
  position: absolute;
  width: 85%;
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  /* border-top: 1px solid grey; */
  height: fit-content;
  max-height: 400px;
  overflow-y: auto;

}

.AutoCompleteText ul.first {
  z-index: 99;
  box-shadow: 2px 2px 7px rgb(54, 54, 54);
}

.AutoCompleteText ul.second {
  z-index: 10;
}

.AutoCompleteText li {
  margin: 4px;
  padding: 4px 8px;
  cursor: pointer;
}

.AutoCompleteText li:hover {
  background-color: #e9ecef42;
}

.AutoCompleteText h6 {
  font-size: 14px;
  color: black;
  margin: 0;
}

.AutoCompleteText p {
  font-size: 10px;
  margin: 0;
}

.AutoCompleteText ul .suggestion-active {
  background-color: #e9ecef;
}

.AutoCompleteText ul.backdrop {
  background-color: white;
  width: 100%;
  height: 200px;
  z-index: 1;
  box-shadow: 2px 2px 7px rgb(54 54 54 / 76%);
}

.AutoCompleteText img {
  height: 12px;
  width: 12px;
}

.AutoCompleteText img.inverted {
  transform: rotate(180deg);
}