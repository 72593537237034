.main_div {
  padding: 2%;
}

.breadcrum {
  font-weight: 600;
  font-size: 1rem;
}

.cursor {
  cursor: pointer;
  color: #c3b14b;
}

.inner_main_div {
  padding: 1% 2%;
  border-radius: 8px;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
  margin-top: 3%;
  background-color: white;
}

.div_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #c3b14b;
  margin-bottom: 1%;
}

.first_div {
  display: flex;
  justify-content: center;
  gap: 5%;
}

.small_div {
  width: 25%;
}

.small_div2 {
  width: 33%;
}

.big_div {
  width: 100%;
}

.para {
  font-weight: 600;
  margin-bottom: 1.5% !important;
  font-size: 1rem;
}

.value {
  font-weight: 400;
  margin-bottom: 1% !important;
  font-size: 0.8rem;
}

.margin_top {
  margin-top: 2%;
}

.btn_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1%;
  margin-top: 1%;
}

.btn {
  background-color: #c3b14b;
  border: 2px solid #c3b14b;
}

.disable_btn {
  background-color: #808080;
  border: 2px solid #808080;
}

.edit_btn {
  padding: 0.5% 3% !important;
}

.table_div {
  width: 100%;
  border-radius: 5px;
  overflow-x: auto;
  box-sizing: border-box;
  white-space: nowrap;
  margin: 1.5% 0%;
}

.table {
  width: 100%;
  font-size: 12px;
  border: none;
}

.table_heading {
  position: sticky;
  top: 0px;
  color: #666666;
}

.table_head {
  padding: 10px;
  font-size: 0.8rem;
}

.table_column {
  padding: 10px;
  font-size: 0.8rem;
}

.input_box {
  box-shadow: 0px 3px 9px #00000029 !important;
  background-color: #f5f6fa;
  padding: 5px 5px;
  width: 100%;
  height: 2.3rem;
  outline: none;
  border: none;
  border-radius: 9px;
}

.hold_main_div {
  width: 30vw;
}

.date {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 35px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  padding: 5px;
  outline: none;
}

.textarea {
  box-shadow: 0px 3px 9px #00000029 !important;
  background-color: #f5f6fa;
  width: 100%;
  padding: 5px 5px;
  outline: none;
  border: none;
  border-radius: 9px;
  resize: none;
}

.important_star {
  color: red;
}

.errorMsg {
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
}

.no_data_para {
  text-align: center;
}
