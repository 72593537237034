.tooltiptext {
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    bottom: 2.2rem;
    font-weight: 400;
    z-index: 10000;
    transition: opacity 0.3s;
    /* right: 50%; */
    /* left: 5%; */
    /* top: -1%; */
    border-color: black transparent transparent transparent;
}