.main_client_body {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.main_create {
    min-height: 100vh;
    width: 80%;
}

.Exit {
    width: 95%;
    margin: 10px auto;
    /* font-weight: 700; */
    font-size: 1rem;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dropdownContainer1 {
    width: 16rem;
  }
  .upcoming{
    gap: 10px;
    display: flex;
    align-items: center;
  }
.UsersList {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    min-height: 4rem;
    margin-left: 27px;
    margin-right: 27px;
    overflow-x: auto;
    position: relative;
}

.th {
    display: flex;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    padding: 0px 0.5rem;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: #F0F0F0;
}

.th p:nth-child(1) {
    font-weight: 600;
    width: 13rem;
    font-size: 14px;
    color: #666666;
}

.th p:nth-child(2) {
    font-weight: 600;
    width: 18rem;
    font-size: 14px;
    color: #666666;
}

.th p:nth-child(3) {
    font-weight: 600;
    width: 14rem;
    font-size: 14px;
    color: #666666;
}

.th p:nth-child(4) {
    font-weight: 600;
    width: 14rem;
    font-size: 14px;
    color: #666666;
}

.th p:nth-child(5) {
    font-weight: 600;
    width: 5rem;
    font-size: 14px;
    color: #666666;
}

.td {
    display: flex;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    padding: 0px 0.5rem;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.td p:nth-child(1) {
    font-weight: 400;
    width: 13rem;
    font-size: 14px;
    color: #289e98 !important;
    text-decoration: underline;
    cursor: pointer;
}

.td p:nth-child(2) {
    font-weight: 400;
    width: 18rem;
    font-size: 14px;
    color: #666666;
}

.td p:nth-child(3) {
    font-weight: 400;
    width: 14rem;
    font-size: 14px;
    color: #666666;
}

.td p:nth-child(4) {
    font-weight: 400;
    width: 14rem;
    font-size: 14px;
    color: #666666;
}

.td p:nth-child(5) {
    font-weight: 400;
    width: 5rem;
    font-size: 14px;
    color: #666666;
    cursor: pointer;
}

.confirmPopup {
    width: 386px;
    min-height: 4rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
    max-height: 600px;
    overflow: auto;
    padding: 1rem;
}

.delete_btn {
    width: 90px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 6px;
    color: #289e98;
    font-weight: 500;

}

.delete {
    width: 90px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;

}

.textarea {
    width: 100%;
    min-height: 5rem;
    outline: none;
}