* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.projectresource_mainSection {
  width: 100%;
  /* height: 90vh; */
  display: flex;
}

.projectresource_mainsection h6 {
  color: #fcb902;
  margin-top: 20px;
  margin-left: 5px;
}

.projectresource_mainsection h1 {
  color: #172a3e;
}

.projectresource_sidebarsection {
  width: 20%;
}

.projectresource_mainsection {
  width: 80%;
}

.imgSection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  padding: 0 1.5rem 1rem;
}

.imgOfferAccepted {
  width: 95%;
  display: flex;
  flex-direction: row;
  padding: 50px 0 0 0;
  /* justify-content: center; */
  align-items: center;
}

.imgSection3 {
  width: 57%;
  display: flex;
  flex-direction: row;
  /* column-gap: 50px; */
  padding: 50px 0 30px 0;
  justify-content: center;
  align-items: center;
}

.imgcontentSection {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 1px 1px 14px #00000029;
  opacity: 1;
  border-radius: 1em;
  height: 12em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.offeraccepted {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 1px 1px 14px #00000029;
  opacity: 1;
  border-radius: 1em;
  height: 12em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.imgcontentSection3 {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 1px 1px 14px #00000029;
  opacity: 1;
  margin-top: -18px;
  border-radius: 1em;
  width: 22em;
  height: 12em;
  margin-left: 45px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}

.imgdiv {
  background-color: #fcb902;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.imgdiv img {
  height: 50%;
  width: 80%;
}

.imgcontentSection h1 {
  color: #fcb902;
}

.offeraccepted h1 {
  color: #fcb902;
}

.imgcontentSection span {
  color: grey;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
}

.offeraccepted span {
  color: grey;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
}