.resource_details_main_div {
  display: flex;
  background-color: white;
  height: 90vh;
}

.sidebar_div {
  width: 20%;
}

.main_div {
  width: 80%;
  padding: 2%;
  height: 90vh;
  overflow-y: auto;
}

.first_breadcrum {
  font-weight: 600;
  color: #00979d;
  cursor: pointer;
}

.arrow {
  font-size: 1.2rem;
  font-weight: 600;
  color: #00979d;
}

.details_div {
  border-radius: 8px;
  box-shadow: 0px 3px 13px #00000029;
  margin-top: 2%;
}

.details_heading {
  text-align: center;
  border-bottom: 1px solid #00000029;
  padding: 1% 0%;
  font-weight: 600;
  color: #00979d;
}

.second_div {
  padding: 1% 2%;
  display: flex;
  gap: 6%;
}

.single_div {
  width: 15%;
}

.resource_head {
  font-weight: 600;
  font-size: 0.8rem;
}

.resource_para {
  font-weight: 400;
  color: grey;
  font-size: 0.9rem;
}

.button_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1% 0%;
  gap: 1%;
}

.btn {
  background-color: #00979d;
  font-weight: 600;
  border: 2px solid #00979d;
  padding: 0.7% 1.5%;
  font-size: 0.8rem;
}

.disable_btn {
  background-color: #a8b1b1;
  font-weight: 600;
  border: 2px solid #a8b1b1;
  padding: 0.7% 1.5%;
  font-size: 0.8rem;
}

.btn2 {
  background-color: white;
  font-weight: 600;
  border: 2px solid red;
  color: red;
  padding: 0.7% 1.5%;
  font-size: 0.8rem;
}