.container{
    width: 80%;
    padding: 0px 15px;
}
.mainSection {
    min-height:100vh;
    height: auto;
    display: flex;
    gap: 10px;
  }
.button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    width: 95%;
    margin-left: 25px;

}
.breadscrum{
    color: #562679 !important;

}
.history{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #562679;
    border: 0px solid #562679;
    padding: 0px;
    font-weight: bolder;
    font-size: large;
}
.type{
    width: auto;
    padding: 5px;
    background-color: #855ea1;
    color: #fff;
    border-radius: 10px;
}
.historyAction{
    display: flex;
    gap: 10px;
}