* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.main {
    background-color: white;
}

.error {
    text-align: center;
    margin-top: 3rem;
    font-size: 2rem;
}

.clientName {
    display: flex;
    justify-content: start;
    gap: 24rem;
    padding-left: 3rem;
}

.generalComments {
    background-color: #dbdbdb;
    margin-top: 3rem;
}

.questions {
    margin-top: 3rem;
    background-color: white;
}