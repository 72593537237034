.taskTracker {
  /* border: 1px solid red; */
  width: 80%;
  /* overflow-x: hidden; */
}

.dateImg1 {
  margin-left: 89%;
  margin-top: -9px;
}

.dateImg2 {}

.taskTracker .header {
  display: flex;
  width: 96%;
  margin-left: 2%;
  margin-top: 2%;
  justify-content: space-between;
}

.underline {
  text-decoration-line: underline;
}

.day {
  color: black;
}

.taskTracker .header div span {
  font-size: 1rem;
  font-weight: 500;
}

.taskTracker .header div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.taskTracker .header div p {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #65a15e;
  /* border: 2px ; */
  margin-left: 7px !important;
  margin-top: 16px;
}

.taskTracker .header div p span {
  color: #65a15e;
}

.taskTracker .header .filter {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #65a15e;
  background-color: #ffffff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.taskTracker .header .filter span {
  padding-left: 12px;
  font-size: 1rem;
  color: #65a15e;
  /* font-family: "Poppins"; */
}

.taskTracker .header .createTask {
  width: 210px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  background-color: #65a15e;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.container {
  box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
  width: 96%;
  margin-left: 2%;
  border-radius: 5px;
  margin-top: 15px;
  background-color: #ffffff;
  
  margin-bottom: 20px;
}

/* .container::-webkit-scrollbar {
  width: 15px;
} */



/* .container::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
} */

.mobileHeader {
  display: none !important;
}

/* 
.taskTracker table{
    margin-top: 10px !important;
  transition: 0.3s;
  border-collapse: collapse;
  display: block;
    overflow-x: auto;
} */
.mobileContainer {
  display: none;
}

.buttonsContainer{
  display: flex; 
  justify-content: space-between; 
  width: 96%; 
  margin: 1% 2% 0 2%
}

.backButton{
  background-color: white;
  border-radius: 9px;
  height: 38px;
  width: 5rem;
  color: #65a15e;
  border: 1px solid #65a15e;
}

.paginationmobileContainer {
  display: block;
}

.desk {
  display: flex;
}

.responsivearrow {
  display: none;
}

.noTasks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noTasks img {
  background: transparent 0% 0% no-repeat padding-box;
  margin-left: 27%;
  height: 77px;
  margin-top: 20px;
}

.scrollAdd {
  overflow-y: hidden;
  /* box-shadow: 0 4px 8px 8px rgba(0,0,0,0.2); */
  /* width: 96%;
    margin-left: 2%;
    padding: 0px 0px 0px 0px; */
  border-radius: 5px;
  /* margin-top: 15px; */
  background-color: #ffffff;
  /* height: 401px; */
  height: auto;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  /* display: none; */
  z-index: -1;
}

.scrollAddTH {
  position: sticky;
  top: 0;
  z-index: 10000;
}

/* .scrollAdd::-webkit-scrollbar {
  width: 12px;
} */

/* .scrollAdd::-webkit-scrollbar-track {
  background: #ffffff;
} */

/* .scrollAdd::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
} */

.noTasks h2 {
  font-size: 26px;
  opacity: 0.35;
}

.Closed_color {
  color: #163bbe;
  /* #296d98 */
}

.loader {
  width: 17%;
  margin: -2% 42% -3% 41%;
}

.loaderPara {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  color: #65a15e;
  margin-top: -4%;
  font-style: italic;
  padding-left: 36%;
  margin-left: 34.5%;
}

@media screen and (max-width: 800px) {
  .container {
    width: 86%;
    margin-left: 7%;
  }

  .responsivearrow {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: center;
    height: 35px;
  }

  .responsivearrow img {
    margin-right: 30px;
    /* background-color: #65A15E; */
    height: 35px;
    border-radius: 17.5px;
  }

  .desk {
    display: none;
  }

  /* .taskTracker table {
    display: none;
  } */

  .mobileContainer {
    display: block;
    padding: 20px;
  }

  .paginationmobileContainer {
    display: none;
  }
}

/* 
.taskTracker table thead {
   
    width: 100%;
}

.taskTracker table thead tr {
    width: 100%;
    background-color: #F5F6FA;
    border-radius: 10px !important; 
    
}

.taskTracker table th {
    text-align: left;
    padding: 16px;
    font-size: 15px;
    font-weight: 500;
}
.taskTracker table td {
    text-align: left;
    padding: 12px;
    font-size: 14px;
}

.taskTracker table tbody tr {
    
    border-bottom: 1px solid #F5F6FA;
} */

/* .taskId {
    color: #65A15E;
    font-style: italic;
    font-size: 14px;
}

.taskTitle {
    color: #65A15E;
    font-style: italic;
    font-size: 14px;
} */

.appliedcheck {
  /* position: absolute; */
  left: 56%;
  /* top: 120px; */
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.polygon {
  display: block;
  position: absolute;
  left: 58%;
  top: 76px;
  z-index: 1;
}

@media screen and (max-width: 800px) {
  .polygon {
    display: none;
  }

  .taskTracker .header .filter {
    display: none;
  }

  .taskTracker .header .createTask {
    display: none;
  }

  .mobileHeader {
    display: flex !important;
  }
}

/* .container table {
  border-collapse: collapse;
  width: 100%;
} */

/* .container table thead {
  background-color: #f5f6fa;
}

.container table thead tr {
  text-align: left;
  opacity: 0.85;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
} */



.taskid {
  width: 6%;
  /* padding-left: 11px; */
}

.creationDate {
  width: 12%;
}

.taskTitle {
  /* width: 20%; */
  width: 20%;
  /* padding-left: 13px; */
}

.taskdesc {
  /* width: 28%; */
  /* width: 23%; */
  /* padding-left: 12px; */
}

.taskAssigned {
  width: 15%;
  /* padding-left: 13px; */
}

.taskStatus {
  width: 10%;
  /* padding-left: 13px; */
}

.taskPriority {
  width: 9%;
  /* padding-left: 13px; */
}

.taskplanned {
  width: 12%;
  /* padding-left: 15px; */
}

/* .taskAction {
    width: 10%; 
} */

/* teble row */
.tabletaskId {
  width: 3%;
  /* padding-left: 18px; */
}

.tabletaskTitle {
  /* max-width: 100px; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.tabletaskTitle b {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tabtaskpriority p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.desc {
  /* width: 28%; */
  /* width: 23%; */
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  width: 13%;
}

.tabtaskpriority {
  width: 9%;
}

.planned {
  width: 10%;
}

/* end */

.tabledata {
  border-bottom: 2px solid #f5f6fa;
}

/* 
.container table tbody .tabledata td {
  padding-left: 23px;
  padding-top: 15px;
  padding-bottom: 5px;
  padding-right: 6px;
} */

.tabletaskId {
  /* color: #65a15e;
    font-style: italic;
    /* font-size: 14px; */
  /* text-decoration-line: underline;  */
}

.tabletaskTitle {
  color: #65a15e;
  cursor: pointer;
  text-decoration-line: underline;
  text-underline-position: under;
}

/* .container table tbody tr {
  font-size: 15px;

} */

/* .container table tbody .tableaction img {
  cursor: pointer;
  padding: 0 10px;
} */

.asigned_color {
  color: #4c41e4;
}

.done_color {
  color: #65a15e;
}

.onhold_color {
  color: #f94244;
}

.redColor {
  color: 'red'
}

.font14 {
  color: #65a15e;
  font: normal normal 600 14px/21px Poppins;
  margin-left: 2.3%;
}

.font15 {
  color: black;
  font: normal normal 600 14px/21px Poppins;
}


.mainSection {
  width: 100%;
  height: 100vh;
  position: absolute;
  bottom: 0;
  display: flex;
}


.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  /* Ensure the header stays on top */
}

.th p:nth-child(1) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  font-size: var(--atsFontSize);
  padding-left: 8px;
  position: sticky;
  left: 0;
  top: 0;
  background-color: #f5f7f9;
}

.th p:nth-child(2) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  font-size: var(--atsFontSize);
  position: sticky;
  left: 3rem !important;
  top: 0 !important;
  background-color: #f5f7f9;
  height: 20px;
}

.th p:nth-child(3) {
  width: 20rem;
  text-align: start;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  font-size: var(--atsFontSize);
  position: sticky;
  left: 4.9rem !important;
  top: 0 !important;
  background-color: #f5f7f9;
  text-decoration: underline;
}

.th p:nth-child(4) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  font-size: var(--atsFontSize);
  position: sticky;
  left: 14rem !important;
  top: 0 !important;
  background-color: #f5f7f9;
}

.th p:nth-child(5) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  font-size: var(--atsFontSize);
  position: sticky;
  left: 19rem !important;
  top: 0 !important;
  background-color: #f5f7f9;
  text-decoration: underline;
}

.th p:nth-child(6) {
  width: 7rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #000000;
  font-weight: 600;
  font-size: var(--atsFontSize);
  text-decoration: underline;
}

.th p:nth-child(7) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  font-size: var(--atsFontSize);
}

.td {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  width: 100%;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 3rem;
  text-align: start;
  font-size: 15px;
  color: var(--atsLinkColor);
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 8px;
  font-size: var(--atsFontSize);
  background-color: #fff;
}

.td p:nth-child(2) {
  width: 2rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
  background-color: #fff;
}

.td p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
  background-color: #fff;
}

.td p:nth-child(4) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
  background-color: #fff;
}

.td p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
  background-color: #fff;
}

.td p:nth-child(6) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: var(--atsFontSize);
}

.td p:nth-child(7) {
  width: 6.5rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
}

input[type="checkbox"] {
  accent-color: "#65A15E";
}

.toggleButtons{
  display: inline-block;
  height: 30px;
  border: 1px solid rgb(56, 52, 52);
  padding: 2px 8px;
}
.toggleButtonSelected{
  display: inline-block;
  height: 30px;
  border: 1px solid rgb(56, 52, 52);
  padding: 2px 8px;
  background-color: #65A15E;
  color: white;
}
  