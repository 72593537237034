.text {
    font-weight: 500;
    color: var(--invoiceTextColor);
}

.file_name {
    width: 25rem;
    border-bottom: 1px solid lightgrey;
    /* box-shadow: 0px 3px 9px #00000029 !important; */
    margin: 5px 10px;
    padding: 5px;
    border-radius: 5px;
    background-color: #f5f6fa;
    display: flex;
    justify-content: space-between;
}
.file_name span{
    margin-right: 5px;
    cursor: pointer;
}