.container {
  box-shadow: 0 0px 5px 2px rgb(0 0 0 / 20%);
  border-radius: 10px;
  background-color: #fff;
  padding-top: 5px;
  margin: 10px 20px;
}
.hr {
  margin: 0px;
}
.EmpBox_row {
  display: flex;
  justify-content: space-between;
  margin: 0px 1.2rem;
  overflow-y: hidden;

  width: auto;
}
.EmpBox_col {
  flex: 1 1 auto;
}
.EmpBox_label {
  font-size: 14px;
  font-weight: 600;
  color: #585656;
  text-wrap: nowrap;
  width: 10%;
}

.EmpBox_field {
  margin-top: 10px;
  color: #949090;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  margin-bottom: 5px;
  color:#562679 ;

}

.EmpBox_Client {
  text-align: center;
}
.completeSection{
  overflow-x: scroll;
  scrollbar-width: thin;
}
.noHistory{
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}
.table{
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.th{
  text-wrap: nowrap;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    color: black;
}
.td{
  font-size: 14px;
    font-weight: 400px;
    text-wrap: nowrap;
    padding: 5px;
    color: #585656;

}
.headRow{
  padding: 5px;
}
.commentModal{
  min-height: 300px;
 min-width: 900px; 
}
.comment{
  font-size: 16px;
  text-align: center;
  color: #562679;
}