.filter,
.createBtn {
  border: 1px solid #a15e5e;
  border-radius: 4px;
  color: white;
  background-color: #a15e5e;
  width: 7vw;
  font-size: 15px;
}
.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 17%;
  margin-left: 82%;
  width: 21px;
  cursor: pointer;
}
.cancelBtn {
  border: 1px solid #a15e5e;
  border-radius: 4px;
  color: #a15e5e;
  background-color: #fcfcfc;
  width: 7vw;
  font-size: 15px;
}

.filterBtn {
  display: flex;
  margin-top: 1rem;
  gap: 20px;
  justify-content: flex-end;
  align-items: flex-end;
}

.filterModal {
  min-width: 34rem;
  height: 15rem;
  color: black !important;
}

.filterCards {
  display: flex;
  height: 100%;
  justify-content: space-between;
  gap: 20px;
}

.filte_heading {
  background-color: #e0d8d8;
  color: black;
  font-weight: 600;
  justify-content: center;
  display: flex;
  align-items: center;
  /* margin: -5px -5px 5px -5px; */
  width: 100%;
  height: 2rem;
  font-size: 15px;
  border-radius: 5px;
}

.firstCard {
  width: 17rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #e0d8d8;
  /* padding: 5px; */
  border-radius: 5px;
}

.SearchBox {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
  border: 1px solid grey;
}
