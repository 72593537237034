.cp_serchIcon1 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 81%;
  width: 21px;
  cursor: pointer;
}

.cp_serchIcon2 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 85%;
  width: 21px;
  cursor: pointer;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 81%;
  width: 21px;
  cursor: pointer;
}

.cp_Skill {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 81%;
  width: 21px;
  cursor: pointer;
}

.cp_EmpName {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 84%;
  width: 21px;
  cursor: pointer;
}

.cp_status {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 85%;
  width: 21px;
  cursor: pointer;
}

.s1 {
  position: relative;
  /* width: 10rem; */
  margin-left: 0.6rem;
}

.sEmpName {
  position: relative;
  margin-left: 0.6rem;
}

.topBar {
  width: 9rem;
  /* width: 14rem; */
  margin-top: 15px;
  padding-left: 16px;
}

.topBarName {
  width: 12rem;
  margin-top: 15px;
}

.topBarSKill {
  width: 10rem;
  margin-top: 15px;

  /* margin-right: 40px; */
}

.topBarRating {
  width: 7rem;
  margin-top: 15px;
}

.topPanBar {
  width: 13rem;
  /* width: 14rem; */
  /* margin-top: 15px; */
  padding-left: 16px;
}

.topVendor {
  width: 14rem;
  /* margin-top: 15px; */
}

.topGST {
  width: 13rem;
  /* margin-top: 15px; */
}

.information_icon {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.topBarTDS {
  width: 14rem;
  /* margin-top: 15px; */
}

.PositionList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  /* height: 500px; */
  overflow-y: hidden;
  padding: 10px;
  font-size: 14px;
  /* margin-top: 26px; */
}

.buttonAdd1 {
  float: right;
  margin-left: 55px;
  margin-right: 26px;
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 81px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.buttonCancle {
  width: 81px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  position: relative;
  left: 53%;
  margin-top: -38px;
  margin-bottom: 25px;
}

.th {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  width: 100rem;
}

.th p:nth-child(1) {
  width: 3rem;
  text-align: center;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  /* padding-left: 8px; */
}

.th p:nth-child(2) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  padding-left: 8px;
}

.th p:nth-child(3) {
  width: 12rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(4) {
  width: 2rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(6) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(7) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(8) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(9) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(10) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(11) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(12) {
  width: 12rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.td {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  width: 100rem;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 3rem;
  text-align: center;
  font-size: 12px;
  color: #666666;
  cursor: pointer;
}

.td p:nth-child(2) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  font-size: 12px;
  color: #c3b14b !important;
  font-weight: bolder;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.td p:nth-child(3) {
  width: 12rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td p:nth-child(4) {
  width: 2rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td p:nth-child(6) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 2px;
  font-size: 14px;
}

.td p:nth-child(7) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  /* padding-left: 40px; */
}

.td p:nth-child(8) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td p:nth-child(9) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
}

.td p:nth-child(10) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
}

.td p:nth-child(11) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td p:nth-child(12) {
  width: 12rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manage {
  margin-left: 27px;
  font-size: 16px;
  font-weight: 500;
}

.resourceType {
  font-size: 500 !important;
  font-size: 16px;
}

.createPartner {
  /* margin-top: 15px !important; */
  cursor: pointer;
  position: relative;
}

.partnertop {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 8px;
}

.buttonAdd {
  background-color: #c3b14b;
  border-color: #c3b14b;
  width: 8rem;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  margin-left: 57px;
  margin-right: 2px;
}

.add_btn {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c3b14b;
  border-color: #c3b14b;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 2rem;
  padding-bottom: 16%;
  margin-left: 5%;
}

.add_btn:hover {
  background-color: white;
  color: #c3b14b;
  border: 2px solid #c3b14b;
}

@media only screen and (max-width: 1246px) and (min-width: 1160px) {
  .buttonAdd {
    background-color: #c3b14b;
    border-color: #c3b14b;
    width: 8rem;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    margin-left: 17px;
  }
}

.showStatus>p {
  padding: 10px;
}

.showStatus>p:hover {
  cursor: pointer;
}

.no_data {
  text-align: center;
  padding: 3%;
}

.plus {
  font-size: 18px;
}

.pagination {
  margin-top: 10px;
  /* margin-right: 30px; */
  width: 98%;
  margin: 0 auto;
}

.VenderType {
  display: flex;
  margin-top: 10px;
}

.VenderRadio {
  margin: 0px auto;
  display: flex;
}

.partnerlist {
  display: flex;
  align-items: center;
  gap: 1%;
}

.vh {
  position: absolute !important;
  /* clip: rect(1px, 1px, 1px, 1px); */
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
  /* cursor: pointer; */
}

.label:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #f2f2f2;
  border: 1px solid rgba(75, 101, 132, 0.3);
  display: inline-block;
  margin-right: 16px;
}


.container {
  min-height: 20rem;
  min-width: 10rem;
  background: #fff;
  border-radius: 10px;
}

.mt_container {
  width: 40rem;
  padding: 1rem;
  overflow-y: auto;
}

.preview_container {
  min-width: 10rem;
  background: #fff;
  border-radius: 10px;
  width: 70rem;
  margin: 1rem;
  padding: 1rem;
  max-height: 38rem;
  overflow-y: auto;
}

.subject {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 9px;
  padding-left: 10px;
  outline: none;
}

.textHidden {
  visibility: hidden;
  font-size: 14px;
  color: red;
}

.textError {
  font-size: 14px;
  color: red;
}

.textBold {
  font-weight: 600;
}

.bgvInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  min-height: 8rem;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 9px;
  padding-left: 10px;
  outline: none;
}

.btn_container {
  display: flex;
  justify-content: end;
  gap: 1rem;
  margin-top: 1rem;
}

.value {
  color: #666666
}

.modal_container_watchlist2 {
  width: 60rem;
}


.th1 {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  width: 81rem;
}

.th1 p:nth-child(1) {
  width: 3rem;
  text-align: center;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  /* padding-left: 8px; */
}

.th1 p:nth-child(2) {
  width: 11rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  padding-left: 8px;
}

.th1 p:nth-child(3) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th1 p:nth-child(4) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th1 p:nth-child(5) {
  width: 11rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th1 p:nth-child(6) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th1 p:nth-child(7) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th1 p:nth-child(8) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}


.td1 {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  width: 81rem;
}

.td1:last-child {
  border-bottom: none;
}

.td1 p:nth-child(1) {
  width: 3rem;
  text-align: center;
  font-size: 12px;
  color: #666666;
  cursor: pointer;
  color: #c3b14b !important;
  font-weight: bolder;
  cursor: pointer;
  text-decoration: underline;
}

.td1 p:nth-child(2) {
  width: 11rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  font-size: 12px;
  color: #c3b14b !important;
  font-weight: bolder;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.td1 p:nth-child(3) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td1 p:nth-child(4) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td1 p:nth-child(5) {
  width: 11rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td1 p:nth-child(6) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 2px;
  font-size: 14px;
}

.td1 p:nth-child(7) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  /* padding-left: 40px; */
}

.td1 p:nth-child(8) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}