.emp_details {
  text-align: center;
  color: #4b59c3;
  font-weight: 500;
  font-size: var(--inoiceHeading);
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  padding-bottom: 0.5rem;
}

.border {
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  margin: 1rem 0px;
}

.lastBtn_item {
  display: flex;
  justify-content: end;
  width: 96.4%;
}

.remove_icon {
  color: #855ea1;
  margin-top: 3%;
  cursor: pointer;
}

.col8_item {
  width: 0%;
  margin: 4% 0 0 0;
}

.bigInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  margin: 1% 1% 1% 1%;
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.bigInput2 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 97%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  margin: 3% 1% 1% 2%;
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.bigInputFolderLink {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 146%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  margin: 6% 1% 1% -271%;
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.bigInput3 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 97%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  margin: 3% 1% 1% 1%;
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.SubmitBtn {
  /* margin-left: 84%; */
  margin: 20px -29% 43px;
}

.cancelBTN {
  margin: 20px 0 43px 84%;
}

.Container {
  width: 95%;
  margin: 0.8rem auto;
  display: flex;
  justify-content: space-between;
}

.values {
  /* color: grey;
  font-weight: 500; */
  margin-top: 17px !important;
}

.values2 {
  /* color: grey;
  font-weight: 500; */
  margin-top: 25px !important;
}

.values3 {
  /* color: grey;
  font-weight: 500; */
  margin-top: 22px !important;
}

.values4 {
  /* color: grey;
  font-weight: 500; */
  margin-top: 24px !important;
}

.values5 {
  /* color: grey;
  font-weight: 500; */
  margin-top: 8px !important;
}

.values6 {
  /* color: grey;
  font-weight: 500; */
  margin-top: 22px !important;
}

.values7 {
  /* color: grey;
  font-weight: 500; */
  margin-top: 19px !important;
}

.values8 {
  /* color: grey;
  font-weight: 500; */
  margin-top: 30px !important;
}

.values9 {
  margin-top: 22px !important;
}

.values10 {
  margin-top: 20px !important;
}

.label {
  font-weight: bold;
  margin-top: 17px !important;
}

.labelFolderlink {
  font-weight: bold;
  margin-top: 17px !important;
  margin-left: -32em !important;
}

.label2 {
  font-weight: bold;
  margin-top: 17px !important;
  margin-left: 20px !important;
}

.heading {
  margin-left: 28px;
  font-weight: 500;
}

.col212 {
  width: 23.5%;
}

.col213 {
  width: 17.5%;
}

.col215 {
  width: 70.5%;
}

.col214 {
  width: 23.5%;
}

.col1 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col2 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col3 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col4 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  /* width: 130%; */
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  padding-left: 10px;
  outline: none;
  padding-top: 5px;
}

.radioitem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  /* margin: 10px 0 0; */
}

.radioitem label {
  cursor: pointer;
}

.radioitem input[type="radio"] {
  display: none;
}

.radioitem label {
  font-weight: 600;
}

.radioitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid blue;
  background-color: transparent;
}

.radioitem input[type="radio"]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: blue;
}

/* .payroll {
  display: flex;
  justify-content: space-around;
  width: 95%;
} */
.payrollName {
  width: 70%;
}

.payrollStatus {
  width: 23.5%;
  /* display: block; */
  display: flex;
  align-items: center;
}

.statusContainer {
  display: flex;
  align-items: center;
}

.readonly {
  pointer-events: none;
  /* Disable pointer events */
  background-color: #e7e7e7;
  /* Change background color to visually indicate readonly */
}

.headWithBtn {
  display: flex;
  width: 97%;
  justify-content: space-between;
}

.crtBtn {
  width: auto;
  background-color: #4B59C3;
  color: white;
  border: 1px solid var(--invoiceColor);
  border-radius: 5px;
  padding: 0.2rem 0.75rem;
}