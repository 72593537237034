.label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
  }
  .label1 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
    margin-top: 15px !important;
  }
.partnerlabal{
    margin-left: 12px !important;
}
.primaryContact{
    border-bottom:2px solid rgba(8,8,8,.1) ;
    text-align: center;
    color: #C3B14B;
    padding-bottom: 10px;

  }

  .lastBtn_item {
    display: flex;
    justify-content: end;
    width: 96.4%;
  }
  .cu_field23Last {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 6px;
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
  }

  
  
  .th {
    display: flex;
    justify-content: space-around;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    color: #666666;
    font-size: 14px;
    font-weight: 600;
  }
  
  .th p:nth-child(1) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
    padding-left: 8px;
  }
  
  .th p:nth-child(2) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
  
  .th p:nth-child(3) {
    width: 10.75rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
 
  .th p:nth-child(4) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
  
  .th p:nth-child(5) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
  
  .th p:nth-child(6) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
 

  
 
  .td {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    min-width: 95%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
  }
  
  .td:last-child {
    border-bottom: none;
  }
  
  .td p:nth-child(1) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 8px;
    font-size: 14px;
    color: #C3B14B !important;
    font-weight: bolder;
  }
  
  .td p:nth-child(2) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
   
  }
  
  .td p:nth-child(3) {
    width: 10.75rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
  }
  
  .td p:nth-child(4) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    padding-left: 2px;
    font-size: 14px;
  }
  
  .td p:nth-child(5) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .td p:nth-child(6) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .errorText {
    color: red;
    font-size: 12px;
    visibility: visible;
    padding: 0.8rem 2.5rem 0.2rem 0.1rem;
  }
  
  .errorTextHidden {
    color: red;
    font-size: 12px;
    margin-left: 15px;
    visibility: hidden;
    padding: 0.4rem 0.5rem 0.2rem 0.5rem;
  }


  .onboardStatus {
    transition: 0.3s;
    background-color: white;
 width: 24%;
    margin-left: 32px;
    width: 21em;
    height: 28px;
    border-radius: 7px;
    box-shadow: 1px 1px 14px rgba(0,0,0,.1607843137254902);
    opacity: 1;

}
.label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
  }
  .label1 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
    margin-top: 15px !important;
  }
.partnerlabal{
    margin-left: 12px !important;
}
.primaryContact{
    border-bottom:2px solid rgba(8,8,8,.1) ;
    text-align: center;
    color: #C3B14B;
    padding-bottom: 10px;

  }

  .smallInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 93%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
    margin-left: 10px;
  }
  .label3 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 12px !important;
  }
  .label4 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 4px !important;
  }

  .cu_field23Last {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 96%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 6px;
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
  }

  .cu_field23Last1 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 96%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 6px;
    padding-left: 10px;
    margin-left: 7px;
    outline: none;
    padding-top: 5px;
  }
  .buttonAdd {
    background-color:#C3B14B;
    border-color: #C3B14B;
    width: 145px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 36px;
  }
  .DateStatus{
    font-size: 14px;
    font-weight: 500;
    padding-left: 5px;
  }

  .buttonAdd {
    background-color:#C3B14B;
    border-color: #C3B14B;
    width: 100px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
    margin-bottom: 10px;
  }

  .buttonAdd1 {
    background-color:#C3B14B;
    border-color: #C3B14B;
    width: 100px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
  }

  .OnBoardingFont{
font-weight: 600;
font-size: 14px;
padding-left: 5px;
  }
  .label5{
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 2px !important;
  }