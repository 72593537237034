.confirmHead{
    width: 25rem;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
}
.confirmBtns{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 2rem;
}
.confirmNoBtn{
    background-color: white;
    color: #65a15e;
    border: 1px solid #65a15e;
    border-radius: 5px;
    width: 75px;
}
.confirmYesBtn{
    background-color: #65a15e;
    color: white;
    border: 1px solid #65a15e;
    border-radius: 5px;
    width: 75px;
  }