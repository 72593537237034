.main_create {
  /* height: 100vh; */
}

.createposition {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  height: 102px;
  /* overflow-y: hidden; */
  /* padding-top: 10px; */
  /* padding: 10px; */
  margin-bottom: 10px;
}

.cp_value {
  cursor: pointer;
}

.createpositiondrop {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 2rem
}

.createposition3 {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  /* overflow-y: hidden; */
  /* padding-top: 10px; */
  /* padding: 10px; */
  margin-bottom: 10px;
}

.createposition_WE {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  height: auto;
  /* overflow-y: hidden; */
  /* padding-top: 10px; */
  /* padding: 10px; */
  margin-bottom: 10px;
}

.createposition_academic {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  height: auto;
  /* overflow-y: hidden; */
  /* padding-top: 10px; */
  /* padding: 10px; */
  margin-bottom: 10px;
}

.createposition_other {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  height: 275px;
  /* padding-top: 10px; */
  margin-bottom: 10px;
}

.createposition_resume {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  height: 90px;
  /* padding-top: 10px; */
  margin-bottom: 15px;
}

.Cu_row1 {
  display: flex;
  justify-content: space-between;
  /* margin: 0 1%; */
  margin: 1% 1% -2%;
  width: auto;
}

.Cu_row14 {
  display: flex;
  justify-content: space-between;
  margin: 3% 1% 1%;
}

.Cu_row11 {
  display: flex;
  justify-content: space-between;
  /* margin: 3% 1%; */
  margin: 3% 1% -1%;
}

.Cu_row11Reasone {
  display: flex;
  justify-content: space-between;
  /* margin: 3% 1%; */
  margin: 3% 1% -1%;
}

.cu_col1 {
  flex: 0 0 31%;
  width: 30%;
}

.cu_col1Reasone {
  /* flex: 0 1; */
  width: 100%;
  /* height: auto; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: auto;
}

.cu_label {
  font-size: 14.5px;
  font-weight: 500;
  padding-left: 4px;
  color: #000000;

  /* Aliging the i-button with <p></p> */
  display: flex;
  align-items: center;
  
}

.CGPA {
  font-size: 15px;
  font-weight: 500;
  padding-left: 4px;
  color: #000000;
  width: 90px;
}

.cu_col2 {
  flex: 1 0 31%;
}

.cu_col3 {
  flex: 0 0 31%;
}

.cu_field2 {
  padding: 0px 5px 5px 5px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.cu_fieldReasone {
  /* width: 50%; */
  /* height: auto; */
  margin-top: 10px;
  padding-left: 5px;
  /* padding: 7px 5px 10px 6px; */
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.cu_field2_link {
  width: 288px;
  height: 38px;
  margin-top: 10px;
  padding-left: 5px;
  padding: 7px 5px 0 6px;
  color: #0089ff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rmpdf {
  padding-top: 9px;
  color: #0089ff;
  color: #65a15e;
}

.rmpdf a {
  color: rgb(123, 187, 123) !important;
}

.multiinput {
  display: flex;
  justify-content: space-between;

}

.multiinput2 {
  display: flex;
  justify-content: start;
  grid-column-gap: 30%;
}

.multiinput3 {
  display: flex;
  justify-content: start;
  /* grid-column-gap: 6%; */
}

.cu_field22 {
  height: 38px;
  margin-top: 10px;
  padding-left: 10px;
  padding: 0px 6px 0 6px;
  color: #666;
}

.cu_field23 {
  width: 130%;
  height: 38px;
  border: none;
}

.cu_field233 {
  width: 85%;
  height: 38px;
  border: none;
  margin-top: 10px;
  padding-left: 10px;
  padding: 8px 6px 0 4px;
  color: #666;
}

.hr {
  width: 100% !important;
}

.title {
  display: flex;
  justify-content: center;
  color: rgb(123, 187, 123);
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  font-weight: bold;
}

.btn_add_acde {
  background-color: #65a15e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 35px;
  /* margin-top: -25px; */
}

.last_btn {
  display: flex;
  /* justify-content: ; */
  flex-direction: column;
  gap: 0.5rem;

  /* float: right; */
  /* margin-bottom: 20px; */
  /* width: 77vw; */
  /* display: block; */
}
.last_btn_row{
  display: flex;
  gap: 0.5rem;
}

.topbutton {
  display: flex;
  align-items: center;
  gap: 1rem
}

/* .cancel_btn {
  background-color: #ffff;
  color: #65a15e;
  border: 1px solid #65a15e;
  border-radius: 5px;
  width: 90px;
} */

.create_btn {
  background-color: #65a15e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 85px;
}

.cal_img {
  position: relative;
  top: -34px;
  left: 275px;
}

.cal_img2 {
  position: relative;
  top: -34px;
  left: 115px;
}

.dp_div {
  display: block;
}

.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px;
}

.upload_resume {
  width: 100%;
  border: 1px solid #999;
  border-radius: 10px;
}

.workExpButton {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.btn_add {
  background-color: #65a15e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 35px;
}

.Cu_resume {
  display: flex;
  justify-content: start;
  margin: 1% 1%;
  grid-column-gap: 5px;

}

.edit {
  /* padding: 0px 10px; */
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  height: 37px;
  width: 60px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.delete {
  /* padding: 0px 10px; */
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  height: 37px;
  width: 70px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.view_int {
  /* padding: 0px 0px; */
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  height: 37px;
  width: 130px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.Screening {
  /* padding: 0px 10px; */
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  height: 37px;
  /* width: 150px; */
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.create_btn {
  padding: 0px 10px;
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  height: 37px;
  width: 6rem;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.view_ass {
  padding: 0px 10px;
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  height: 37px;
  width: 170px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.offers {
  padding: 0px 10px;
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  height: 37px;
  width: 70px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.modal_container {
  width: 18rem;
  height: 8rem;
  border-radius: 5px;
}

.reactmodal {
  border-radius: 10px;
}

.textM {
  text-align: center;
}

.M_button {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  width: 10rem;
  justify-content: space-around;
}

.b_inactive {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 5vw;
  font-size: 15px;
  cursor: pointer;
  /* height: 30px; */
}

.w_inactive {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: black;
  background-color: #fff;
  width: 5vw;
  font-size: 15px;
  cursor: pointer;
  /* height: 30px; */
}

.btnCotainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 10px auto;
  width: 95%;
}

.buttonDropped {
  background: red;
  color: rgb(255, 255, 255);
  border: 1px solid red;
  height: 37px;
  width: 10.8rem;
  margin: 5px 0px;
  border-radius: 5px;
  cursor: pointer;
}

.buttonDroppedAct {
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  height: 37px;
  width: 15rem;
  margin: 5px 0px;
  border-radius: 5px;
  cursor: pointer;
}
.okay{
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;

}



.center {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #65a15e;
  font-weight: 500;
  border-bottom: 1px solid #d6d2d2;
  padding-bottom: 0.3rem;
}



.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 8px;
  padding-left: 10px;
  outline: none;
  padding-top: 5px;
  z-index: 1000;
}

.textarea {
  width: 100%;
  margin-top: 0.5rem;
  min-height: 4.7rem;
  outline: none;
}

.cancelBtn {
  background: #fff;
  color: #65a15e;
  border: 1px solid #65a15e;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px 10px;
}



.createOfferBtn {
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px 10px;
  margin-left: 1rem;
}

.docOffer1 input[type="file"]::file-selector-button {
  border: 2px solid #65a15e;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #65a15e;
  transition: 1s;
  color: #ffffff;
}

.docOffer1 {
  display: flex;
  width: 108%;
  margin-top: 6px;
}

.Cu_resume {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 1%;
  position: relative;
  grid-column-gap: 5px;
  width: 90%;
  margin-top: 0.9rem !important;
}

.rmpdf {
  border: 1px solid #65a15e;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 10px;
  padding: 0.5rem;
  color: #65a15e;
}

.imgClass {
  cursor: pointer;
}


.rmpdfcross {
  color: black;
  border: 1px solid #000000;
  background-color: #65a15e;
  position: absolute;
  right: -3%;
  top: -20%;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
}

.upload_resume {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 92%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* padding-left: 10px; */
  outline: none;
}

.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px -10px;
  height: 38px;
  /* width: 70px; */
}

.errorText {
  color: red;
  font-size: 12px;
  margin-left: 7px !important;
  visibility: visible;
  padding-top: 2px;
}

.errorTextHidden {
  color: red;
  font-size: 12px;
  margin-left: 7px !important;
  visibility: hidden;
  padding-top: 2px;
}

.errorText2 {
  color: red;
  font-size: 12px;
  /* margin-left: 7px !important; */
  visibility: visible;
  padding-top: 0.7rem;
}

.errorTextHidden2 {
  color: red;
  font-size: 12px;
  /* margin-left: 7px !important; */
  visibility: hidden;
  padding-top: 0.7rem;
}

.confirmPopup1 {
  width: 46rem;
  max-height: 50rem;
  min-height: 32rem;
  overflow-y: auto;
  padding-top: 0.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.recuiterCantainer {
  width: 22rem;
  height: 11rem;
  padding-top: 0.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.recuiterData {
  width: 18rem;
  margin: 1rem auto;
}

.recuiter {
  font-weight: 600;
  padding-bottom: 0.2rem;
}

.modalBtn1 {
  float: right;
  margin: 0px 1.5rem 1rem 1rem;
}

.cantent {
  margin: 0.7rem 1.7rem;
  width: 20rem;
}

.FutureHire {
  display: flex;
  justify-content: space-between;
}

.confirmPopup3 {
  width: 23.7rem;
  height: 20rem;
  overflow-y: auto;
  padding-top: 0.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
  overflow: hidden;
}

.icon {
  color: green;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
}

.positionOpenedOn {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 95%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  /* margin-left: 5px; */
  margin: 0% 0% 0 1%;
  padding-left: 12px;
  outline: none;
  padding-top: 5px;
}

.modal_container_share {
  width: 20rem;
  min-height: 8rem;
  border-radius: 5px;
  padding: 1rem;
  background: #fff;

  /* padding: 0px 2rem; */
}

.clientShare {
  font-weight: 600;
  padding-left: 0.4rem;
}

.modal_container_screen_reject {
  width: 40rem;
  min-height: 8rem;
  border-radius: 5px;
  padding: 1rem;
  background: #fff;

  /* padding: 0px 2rem; */
}

.modal_container_screen_reject .heading {
  font-weight: 600;
  margin-bottom: 10px !important;
  text-align: center;
}

.modal_container_screen_reject .textarea {
  width: 100%;
  margin-top: 0.5rem;
  min-height: 4.7rem;
  outline: none;
}

.modal_container_screen_reject .modal_btn {
  margin-top: 0rem;
  display: flex;
  justify-content: center;
}

.bigInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 95%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  margin: 1% 1% 1% 1%;
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.socialResume {
  display: flex;
  gap: 1rem;
}
.modal_container_watchlist {
  width: 30rem;
  min-height: 8rem;
  border-radius: 5px;
  padding: 1rem;
  background: #fff;
  /* height: 22rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 0px 2rem; */
}
.modal_container_watchlist2{
  width: 60rem;
}
/* .watchlist_inner_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */
.modal_container_watchlist .heading {
  font-weight: 600;
  margin-bottom: 10px !important;
  text-align: center;
}
.modal_container_watchlist .w_label{
  font-weight: 500;
}
.modal_container_watchlist .modal_btn {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.modal_container_watchlist .radiocontainer{
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}
.modal_container_watchlist .radiocontainer .radioitem label{
  cursor: pointer;
}
.confirmPopup11>P {
  text-align: start;
  width: 85%;
  /* margin: 10px auto !important; */
  padding-left: 30px;
}
.confirmPopup11 {
  width: 22rem;
  max-height: 36rem;
  overflow-y: auto;
  padding-top: 0.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.list {
  display: flex;
  align-items: center;
}

.list label:before {
  content: "";
  width: 16px;
  height: 16px;
  background: #f2f2f2;
  border: 1px solid rgba(75, 101, 132, 0.3);
  display: inline-block;
  margin-right: 16px;
}

.list input[type="checkbox"]:checked ~ label:before {
  background: #20bf6b no-repeat center;
  background-size: 12px 12px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

/*  Show when label and checkbox have focus, to make this keyboard accessible > press spacebar to select the checkbox */

.list input[type="checkbox"]:focus ~ label {
  color: #20bf6b;
}

.handleZindex{
  z-index: 2;
}
.twoButtons{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  padding-top: 6px;
}
