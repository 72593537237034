.main_client_body {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.main_create {
  min-height: 100vh;
  width: 80%;
}

.title {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;

}

.topFlex {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 10px auto;
  align-items: center;
}
.containerss{
  display: flex;
  justify-content: space-between;
  gap:1rem
}
.f1 {
  position: relative;
  width: 230px;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 88%;
  width: 21px;
  cursor: pointer;
}

.f1Input {
  border-radius: 9px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  min-width: 99%;
  height: 38px;
  border: 1px solid #00000030;
  padding: 0 8px;
  outline: none;
}

.dropdownContainer1 {
  display: flex;
  gap: 10px;
  // width: 250px;
}
.dropdownBox{
  width: 15rem;
}

.UsersList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 4rem;
  margin-left: 27px;
  margin-right: 27px;
  overflow-x: auto;
  position: relative;
}

.th {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  padding: 0px 0.5rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #f0f0f0;
}

.th p:nth-child(1) {
  font-weight: 600;
  width: 8rem;
  font-size: 14px;
  color: #666666;
}

.th p:nth-child(2) {
  font-weight: 600;
  width: 14rem;
  font-size: 14px;
  color: #666666;
}

.th p:nth-child(3) {
  font-weight: 700;
  width: 8rem;
  font-size: 14px;
  color: #666666;
}

.th p:nth-child(4) {
  font-weight: 600;
  width: 6rem;
  font-size: 14px;
  color: #666666;
}
.th p:nth-child(5) {
  font-weight: 700;
  width: 6rem;
  font-size: 14px;
  color: #666666;
}

.th p:nth-child(6) {
  font-weight: 600;
  width: 6rem;
  font-size: 14px;
  color: #666666;
  text-align: center;
}
.th p:nth-child(7) {
  font-weight: 600;
  width: 10rem;
  font-size: 14px;
  color: #666666;
  text-align: center;
}
.th p:nth-child(8) {
  font-weight: 700;
  width: 5rem;
  font-size: 14px;
  color: #666666;
  text-align: center;
}
.th p:nth-child(9) {
  font-weight: 600;
  width: 5rem;
  font-size: 14px;
  color: #666666;
}

.td {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  padding: 0px 0.5rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.td p:nth-child(1) {
  font-weight: 400;
  width: 8rem;
  font-size: 14px;
  color: #289e98 !important;
  text-decoration: underline;
  cursor: pointer;
}

.td p:nth-child(2) {
  font-weight: 400;
  width: 14rem;
  font-size: 14px;
  color: #666666;
}

.td p:nth-child(3) {
  font-weight: 400;
  width: 8rem;
  font-size: 14px;
  color: #666666;
}

.td p:nth-child(4) {
  font-weight: 400;
  width: 6rem;
  font-size: 14px;
  color: #666666;
}
.td p:nth-child(5) {
  font-weight: 400;
  width: 6rem;
  font-size: 14px;
  color: #666666;
}

.td p:nth-child(6) {
  font-weight: 400;
  width: 6rem;
  font-size: 14px;
  color: #666666;
  text-align: center;
}
.td p:nth-child(7) {
  font-weight: 400;
  width: 10rem;
  font-size: 14px;
  color: #666666;
}
.td p:nth-child(8) {
  font-weight: 400;
  width: 5rem;
  font-size: 14px;
  color: #666666;
  text-align: center;
}
.td p:nth-child(9) {
  font-weight: 400;
  width: 5rem;
  font-size: 14px;
  color: #666666;
}

.confirmPopup {
  width: 386px;
  min-height: 4rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
  max-height: 600px;
  overflow: auto;
  padding: 1rem;
}
.confirmPopup1 {
  width: 30rem;
  min-height: 16rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
  max-height: 600px;
  // overflow: auto;
  padding: 1rem;
}
.confirmPopup1 > div{
  margin-bottom: 10px;
}

.delete_btn {
  width: 90px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #fff;
  margin-right: 10px;
  border-radius: 6px;
  color: #289e98;
  font-weight: 500;
}

.delete {
  width: 90px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
}

.textarea {
  width: 100%;
  height: 8rem;
  outline: none;
}
.modalMainHeading{
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px!important;
}
.heading{
  // text-align: center;
  font-weight: 500;
  margin-bottom: 10px!important;
}
.countCircle{
  border: 2px solid #289e98;
  padding: 2px 7px;
}
.fnf_count_container {
  width: 95%;
  margin: 0 auto;
  border-radius: 15px;
  display: flex;
  justify-content: start;
  gap: 30px;
}
.empDetails{
  font-weight: 500;
  font-size: .9rem;
}