* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_client_body {
  width: 100%;
  /* height: 95vh; */
  display: flex;
}
.modal_container {
  width: 18rem;
  height: 8rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space:pre-line;
  overflow: auto;
}
.addResources {
  border-radius: 10px;
}
.noData{
  position: absolute;
  top: 35%;
  left: 55%;
}
.count{
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(252, 187, 8);

}
.coreSkillInput {
  border-radius: 9px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  min-width: 95%;
  height: 38px;
  border: 1px solid #00000030;
  padding: 0 8px;
  outline: none;
}

.backColor{
  color: #172A3E !important;
  }
  .backColor1{
    color: rgb(252, 187, 8) !important;
    }
.coreSkillInput::placeholder {
  color: #00000030;
}

.onsiteList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  min-height: 26.5rem;
  margin: 10px 0px;
  overflow-x: auto;
  box-sizing: border-box;
  white-space: nowrap;
}

.plus {
  font-size: 20px;
  padding-right: 4px;
  font-weight: 700;
}
.create_btn {
  width: 170px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.table_div {
  width: 80%;
  padding: 0px 20px;
}

.table_div_head {
  /* border: 1px solid red; */
  /* padding: 15px; */
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.breadscrumb {
  font-size: 10px;
  font-weight: bold;
}

.second_child {
  display: flex;
  justify-content:space-between;
  width: 100%;
  height: 3rem;
  align-items: center;
}

.appliedcheck {
  /* position: absolute; */
  left: 63%;
  /* top: 63px; */
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.filter_btn {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid rgb(252, 187, 8);
  background-color: #ffffff;
  outline: none;
  color: rgb(252, 187, 8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30%;
}

.recent_tab {
  color: rgb(252, 187, 8);
}

.arrow {
  font-size: 13px;
  font-weight: bold;
  color: rgb(252, 187, 8);
}

.original_table {
  /* border: 2px solid red; */
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 10px;
}

.search_emp {
  border-radius: 20px;
  height: 40px;
  outline: none;
  border: 1px solid white;
  box-shadow: 0px 0px 5px 0px grey !important;
  padding: 0px 15px;
}
.loader {
  width: 17%;
  margin: 9% 13% 0% 42%;
}

.number {
  border: 1px solid rgb(252, 187, 8);
  margin-left: 13px;
  padding: 9px;
  border-radius: 50%;
  color: rgb(252, 187, 8);
}
.title {
display: flex;
padding: 2px 0px;
  font-weight: 600;
 align-items: center;
}
.mainFilter {
  width: 18%;
  /* margin-left: 8%; */
}
.twoFilter {
  display: flex;
  justify-content: end;
  /* grid-column-gap: 2%; */
  width:64%;
}
.gotoListBtn {
  border-radius: 10px;
  width:5rem;
  height: 45px;
}

.s1 {
  position: relative;
  width: 12rem;
}
.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 83%;
  width: 21px;
  cursor: pointer;
}
.s2 {
  position: relative;
  width:12rem;
}
.s22 {
  position: relative;
  width:16rem;
}
.cp_serchIcon2 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 83%;
  width: 21px;
  cursor: pointer;
}
.AddBtn {
  width: 19%;
  text-decoration: none;
}
.button{
  border-radius: 10px;
  height: 38px;
  cursor: pointer;
  background-color:rgb(252, 187, 8);
  margin-left:10px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeButton{
  border-radius: 10px;
  height: 38px;
  cursor: pointer;
  background-color: white;
  color: rgb(252, 187, 8);
  margin-left: 10px;
  border: 1px solid rgb(252, 187, 8);
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}
.count1{
  width: 31px;
  height: 31px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  /* border: 2px; */
  margin-left: 7px !important;
}
.count2{
  width: 31px;
  height: 31px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(252, 187, 8) ;
  /* border: 2px; */
  font-size: 13px;
  margin-left: 7px !important;
}

