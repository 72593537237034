*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_client_body{
  width: 100%;
  /* height: 95vh; */
  display: flex;
}
.coreSkillInput {
  border-radius: 9px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  min-width: 95%;
  height: 38px;
  border: 1px solid #00000030;
  padding: 0 8px;
  outline: none;
}

.coreSkillInput::placeholder {
  color:grey;
}
.s1 {
  position: relative;
  width: 230px;
}
.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 84%;
  width: 21px;
  cursor: pointer;
}

.paginationmobileContainer {
  display: block;
}
.responsivearrow {
  display: none;
}
.project_heading .prmheading{
  color:white;
  font-size: 20px;
  font-weight: 500;
}

.side_bar .side_buttons_div{
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  padding: 10px;
}

.dashboard_btn{
  border: 1px solid red;
}

.heading{
  color:white;
  font-weight: 400;
}

.table_div{
  width: 80%;
  padding: 0px 20px;
}

.active_btn{
  background-color: white;
  padding: 4px 15px;
  border-radius: 10px;
  border: 1px solid  green;
}


.table_div_head{
  /* border: 1px solid red; */
  /* padding: 15px; */
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.original_table{
  /* border: 2px solid red; */
  /* padding: 15px; */
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 10px;
}

.right_side{
  display: flex;
  justify-content: space-between;
}

.breadscrumb{
  font-size: 10px;
  font-weight: bold;
}

.loader {
  width: 17%;
  margin: 9% 13% 0% 42%;
}
.recent_tab{
  color: rgb(252, 187, 8);
}

.arrow{
  font-size: 13px;
  font-weight: bold;
  color: rgb(252, 187, 8);
}

.second_child{
  display: flex;
  /* justify-content: space-between; */
  padding-bottom:10px;
}
.loader {
  width: 17%;
  margin: 9% 13% 0% 42%;
}

.filter_btn {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid rgb(252, 187, 8);
  background-color: #ffffff;
  outline: none;
  color: rgb(252, 187, 8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 28%;
}


.create_btn {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.appliedcheck {
  /* position: absolute; */
  left: 63%;
  /* top: 63px; */
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}


.icon{
  color: white;
}

.btn_menu:hover .icon{
  color: rgb(252, 187, 8);
}
.back_btn_img {
  padding-right: 5px;
  
}

.back_btn {
  float: right;
  /* margin-right: 3%; */
  margin-top: 7px;
  font-size: 20px;
  font-style: italic;
  text-decoration: underline;
  color:rgb(252, 187, 8);
  margin-right: 22px;
  margin-bottom: 10px;
}

.plus{
  font-size: 20px;
  padding-right: 4px;
  font-weight: 700;
}