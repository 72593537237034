.main_client_body {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.main_create {
    min-height: 100vh;
    width: 80%;
}

.report {
    width: 95%;
    margin: 10px auto;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.report span {
    font-weight: 700;
}

.UsersList {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    /* min-height: 8rem; */
    height: 15.7rem;
    margin-left: 27px;
    margin-right: 27px;
    overflow-x: auto;
    position: relative;
    margin-bottom: 20px;
}

.delevery {
    display: flex;
    align-items: center;
    height: 40px;
    /* padding: 0px 0.5rem; */
    /* width: 400rem; */
    /* justify-content: space-between; */
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    font-size: 12px;
    gap: 0.2rem;
    white-space: normal;

}

.countdata {
    width: 9rem;
    text-overflow: white-space;
    text-align: center;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

}

.countdata1 {
    width: 9rem;
    text-overflow: white-space;
    text-align: center;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 500;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.countdata2 {
    width: 9rem;
    text-overflow: white-space;
    text-align: center;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 9rem;
    z-index: 500;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;


}

.td {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    position: sticky;
    z-index: 1000;
}

.nodata {
    text-align: center;
}

.td:last-child {
    border-bottom: none;
}

.s2 {
    position: relative;
    width: 14rem;
    margin-left: 0.6rem;
    z-index: 2000;
}

/* .td p:nth-child(1) {
    width: 5rem;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    padding-left: 8px;
    font-size: 12px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;

}

.td p:nth-child(2) {
    width: 5rem;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    display: inline-block;
    box-sizing: border-box;
    font-size: 12px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 5rem;
    z-index: 9999;

}

.td p:nth-child(3) {
    width: 5rem;

    color: #666666;
    font-weight: 400;
    font-size: 12px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.td p:nth-child(4) {
    width: 5rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.td p:nth-child(5) {
    width: 5rem;
    color: #666666;
    font-weight: 400;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
}

.td p:nth-child(6) {
    width: 5rem;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #666666;
    font-weight: 400;

    font-size: 12px;
}

.td p:nth-child(7) {
    width: 5rem;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
} */

.empDetailsContainer {
    width: 95%;
    margin: 0 auto;
    padding: 2px 10px;
    border-radius: 15px;
}

.empDetails {
    font-weight: 500;
    margin: 15px 0px !important;
}

.countCircle {
    border: 2px solid #289e98;
    padding: 2px 7px;
}

.genderCount {
    margin: 10px auto;
    width: 95%;

}

.innerContainer {
    width: 15rem;
    border: 2px solid #289e98;
    display: flex;
    justify-content: start;
    gap: 30px;
    padding: 10px;
}

.genderCount img {
    width: 100px;
}

.genderCount_box {
    width: 6rem;
    text-align: center;

}

.table_container {
    /* margin: 10px; */
    box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0px;
    overflow-y: visible !important;
    width: 95% !important;
    margin: 0 auto;
    margin-top: 10px;
}
.table_container2 {
    box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0px;
    width: 95% !important;
    margin-top: 10px;
}

.tableHead {
    font-weight: bold;
    background-color: rgb(240, 240, 240);
}
.table_heading{
    font-weight: 600!important;
    padding: 0 10px!important;
    font-size: 13.5px!important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif!important;
    color: #666!important;
    border-bottom: none!important;
}
.tableBody{
    /* height: 500px; */
    overflow: auto;
}
.tableBody tr{
    height: 40px;
}
.tableBody tr td, .tableBody tr th{
    padding: 0px 10px;
    font-size: 13.5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
    color: #666666;
}
.tooltip_text{
    /* width: 14rem; */
    text-overflow: ellipsis;
}
.main_heading_container2{
    width: 95%;
    margin-top: 17px;
}
.main_heading{
    font-weight: 700;
}
.table_scroll{
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.table_scroll1{
    height: 272px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.fixedHeader {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .tableHeadDPsalary th {
    position: sticky;
    top: 0;
  }
  .dpSalaryWithCounts{
    display: flex;
    width: 95% !important;
    margin-left: 1.1rem;
    margin-right: 0px;
  }
  .dpSalaryDiv{
    margin-left: 12px;
    margin-right: 0px;
    width: 50% !important;
  }
  .main_heading_container{
margin-left: 1.6rem;
  }
  .makeCircle{
    height: 25px;
    width: 25px;
    border: 1px solid #289e98;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .functionalAreaC{
    display: flex;
    align-items: center;
    gap:4px;
  }