$client_contract_theme: #562679;
$ums_theme: #289e98;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin card {
  background: white;
  -webkit-box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.06);
  box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
}

@mixin textField {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 35px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  padding: 5px;
  outline: none;
}

@mixin datePickerContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 9px;
  opacity: 1;
  border: none;
  outline: none;
  padding-top: 5px;
  position: relative;
  width: 22%;
}

@mixin date {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 35px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  padding: 5px;
  outline: none;
}

.errorTextHidden {
  visibility: hidden;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}

.errorText {
  visibility: visible;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}
