.ViewTeamTask {
  margin-top: 30px;
  margin-left: 14px;
  font-weight: 700;
}

.checkbox {
  visibility: visible !important;
}

.checkbox:checked {
  accent-color: rgb(156, 88, 165);
}

.DetailTeamView {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  padding-top: 10px;
  font-size: 14px;
  padding-bottom: 7px;
}
.ViewTeamEmployeeDetails {
  display: flex;
  justify-content: center;
  color: #9c58a5;
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}
.ProjectTeamEmployee {
  margin-top: 20px;
}
.taskTeam {
  margin-top: 20px;
}

.Approved {
  float: right;
  transform: translate(85px, 0px);
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -16px;
  left: -50px;
  height: 15px;
  width: 15px;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  /* background-color: #ccc; */
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: black;
  border: 1px solid black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.buttonAdd {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
}
.buttonCancle {
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
}

.buttonAdd3 {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 62px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  position: relative;
  margin-top: 20px;
  left: 48%;
}
.buttonCancle3 {
  width: 62px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  position: relative;
  left: 72%;
  margin-top: -30px;
  margin-bottom: 25px;
}
.widthModal {
  width: 75%;
}
.no_data {
  text-align: center;
  padding: 3%;
}

.buttonAdd4 {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 62px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  position: relative;
  margin-top: 20px;
  left: 28%;
}
.buttonCancle4 {
  width: 62px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  position: relative;
  left: 58%;
  margin-top: -30px;
  margin-bottom: 25px;
}
.datetime {
  font-weight: 700;
}

.th {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 0.5rem;
  /* min-width: 85rem; */
  gap: 1rem;
  box-sizing: border-box;
  white-space: nowrap;
  font-weight: 700;
}

.th:last-child {
  border-bottom: none;
}

.th p:nth-child(1) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.th p:nth-child(2) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.th p:nth-child(3) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.th p:nth-child(4) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  /* transform: translate(30px,0px); */
}

.th p:nth-child(5) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.th p:nth-child(6) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  text-align: start;
}

.th p:nth-child(7) {
  width: 12%;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(8) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  padding-left: 44px;
}

.th p:nth-child(9) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.th p:nth-child(10) {
  width: 8rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.th p:nth-child(11) {
  width: 2rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}
.th p:nth-child(12) {
  width: 3rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}
.td {
  display: flex;
  border-radius: 10px;
  /* align-items: center; */
  justify-content: space-between;
  height: 28px;
  padding: 0px 0.5rem;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  /* white-space: nowrap; */
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.td p:nth-child(2) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}
.td p:nth-child(3) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* padding-left: 11px; */
}

.td p:nth-child(4) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(5) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(6) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  text-align: start;
  /* padding-left: 2rem; */
}

.td p:nth-child(7) {
  width: 12%;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.td p:nth-child(8) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  padding-left: -25px;
}

.td p:nth-child(9) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(10) {
  width: 12%;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(11) {
  width: 2rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}
.td p:nth-child(12) {
  width: 3rem;
  color: #666666;
  font-weight: 400;
  font-size: 12;
}
