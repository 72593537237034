.main_div {
  box-shadow: 0px 3px 13px #00000029;
  border-radius: 8px;
}

.heading {
  text-align: center;
  border-bottom: 1px solid #00000029;
  padding: 1% 0%;
  font-weight: 600;
  color: #00979d;
}

.table_div {
  padding: 1% 2%;
}

.th {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  width: 100%;
  font-weight: 600;
  padding: 0% 2%;
}

.th p:nth-child(1) {
  width: 9rem;
  font-size: 0.8rem;
}

.th p:nth-child(2) {
  width: 9rem;
  font-size: 0.8rem;
}

.th p:nth-child(3) {
  width: 9rem;
  font-size: 0.8rem;
}

.th p:nth-child(4) {
  width: 9rem;
  font-size: 0.8rem;
}

.th p:nth-child(5) {
  width: 3rem;
  font-size: 0.8rem;
}

.td {
  display: flex;
  justify-content: space-between;
  padding: 0% 2%;
  height: 40px;
  width: 100%;
  align-items: center;
}

.td p:nth-child(1) {
  width: 9rem;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
}

.td p:nth-child(2) {
  width: 9rem;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
}

.td p:nth-child(3) {
  width: 9rem;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
}

.td p:nth-child(4) {
  width: 9rem;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
}

.td p:nth-child(5) {
  width: 3rem;
  text-align: start;
}

.tableHead{
  background-color: #f5f7f9;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  font-weight: 600;
  padding: 0% 2%;
}

.tableHead .thPriceList {
  font-size: 0.8rem;
}

.tableRow {
  padding: 0% 2%;
  height: 40px;
  width: 100%;
}

.tableRow .tdPriceList {
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
}

.para {
  font-size: 0.9rem;
  font-weight: 600;
  color: grey;
}

.rupee_icon {
  font-size: 0.9rem !important;
}

.reason_img_div {
  width: 18px;
  height: 21px;
}

.reason_img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal_para_div {
  padding: 1% 2%;
  text-align: start;
}

.width_2rem {
  width: 2rem;
}

.pl_3rem {
  padding-left: 0.3rem;
}