.main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .header .heading {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  
  .headerDt {
    background-color: white;
    padding: 7px 12px;
    border-radius: 10px;
    font-weight: 500;
  }
  
  .headerDate {
    margin: auto 1rem auto 0;
    font-size: 16px;
    font-weight: 700;
  }
  
  .body {
    width: 100%;
    padding: 0 20px 20px;
  }
  
  .body .tableContainer {
    border-radius: 5px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    overflow-x: auto;
  }
  
  .body .tHeading {
    background-color: #f0f0f0;
  }
  
  .body .tableContainer th {
    font-size: 12px;
    font-weight: 600;
    padding: 10px !important;
    background-color: #f0f0f0;
  }
  
  .tableContainer td {
    font-size: 12px !important;
    padding: 10px;
  }
  
  .tRow {
    background-color: white;
    border-bottom: 2px solid #f0f0f0;
  }
  
  .addText {
    color: #b58250 !important;
    font-weight: 700;
    font-size: 12px !important;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .ppc {
    padding: 4px !important;
    font-size: 12px !important;
  }
  
  .editPrBody {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }
  
  .editPrBody .heading {
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-row-gap: 20px;
    grid-column-gap: 10px;
    margin-top: 20px;
  }
  
  .gridSubItem {
    margin-top: 30px;
  }
  
  .rowHead {
    font-size: 15px;
    font-weight: 500;
  }
  
  .rowValue {
    font-size: 14px;
    color: #b6b6b8;
  }
  
  .btnCancel {
    padding: 5px;
    background-color: white;
    color: #954b00;
    border: 0px;
    margin-right: 10px;
    font-size: 16px;
    box-shadow: 0px 3px 9px #00000029 !important;
    width: 90px;
    height: 35px;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .btnSubmit {
    padding: 5px;
    background-color: #954b00;
    color: white;
    border: 0px;
    font-size: 16px;
    box-shadow: 0px 3px 9px #00000029 !important;
    width: 90px;
    height: 35px;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .rowVal {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 80%;
    font-weight: 500;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: white;
    border: none;
    margin-top: 10px;
    padding: 5px;
    outline: none;
  }
  
  .rowValModal {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 80%;
    font-weight: 500;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: white;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    outline: none;
    margin: 1rem 0;
  }
  
  .modal {
    text-align: center;
  }
  
  .csd {
    width: 7.625rem;
    height: 38px;
    font-weight: 500;
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    background-color: #fff;
    cursor: pointer;
  }
  
  .editInvoice {
    width: 1.3rem;
    height: 1.3rem;
    color: #954b00;
    cursor: pointer;
  }
  
  .filters {
    display: flex;
  }
  
  .errorText {
    margin-top: 3px !important;
    margin-left: 5px !important;
    color: red;
    font-size: 12px;
    font-weight: 400;
    margin-left: 15px;
    visibility: visible;
    padding: 0.8rem 0.5rem 0.2rem 0.5;
  }
  
  .cmnt_icon_container {
    position: relative;
    right: 21px;
    cursor: pointer;
  }
  
  .cmnt_icon {
    color: #954b00;
  }
  
  .breadCrumb {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    color: #954b00;
  }
  
  .breadCrumb:hover {
    text-decoration: underline;
  }
  
  .radioGroup {
    display: flex;
    gap: 2rem;
  }
  
  .radioContainer {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    font-size: 16px;
  }
  
  .radioContainer input[type="radio"] {
    cursor: pointer;
  }
  
  .noProjectData {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  
  .countPCM {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #954b00;
    margin-left: 7px !important;
    margin-top: 16px;
  }
  
  .gap1rem {
    gap: 1rem;
  }
  
  .allProjectDropswitch {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }