.dashboardOptions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    flex-wrap: wrap;
}

.taskTracker {
    width: 14.6rem;
    height: 4rem;
    border: 1px solid #48729e;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    background-color: #fff;
    color: 172a3e;
    color: black;
    font-weight: 600;
    background: #48729e;
    color: #fff;
}

.taskTracker:hover {
    border: 1px solid #48729e;
    background: #fff;
    color: #48729e;
}

.taskTracker:hover {
    transform: scale(0.989);
    cursor: pointer;
}