.cp_serchIcon1 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 81%;
  width: 21px;
  cursor: pointer;
}

.cp_serchIcon2 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 85%;
  width: 21px;
  cursor: pointer;
}

.table_div {
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  border-radius: 5px;
  background-color: #fff;
  overflow-x: auto;
  box-sizing: border-box;
  white-space: nowrap;
  margin: 1.5% auto;
}

.table {
  width: 100%;
  font-size: 12px;
  border: none;
}

.table_heading {
  position: sticky;
  top: 0px;
  background-color: #f5f6fa;
  color: #666666;
}

.table_head {
  padding: 10px;
  font-size: 0.8rem;
}

.table_column {
  padding: 10px;
  font-size: 0.8rem;
}

.no_data_para {
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  width: 95%;
  margin: 1.5% auto !important;
  background-color: white;
  border-radius: 2%;
}

.client_name {
  font-size: 0.8rem;
  color: #c3b14b;
  text-decoration: underline;
  cursor: pointer;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 81%;
  width: 21px;
  cursor: pointer;
}

.cp_Skill {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 85%;
  width: 21px;
  cursor: pointer;
}

.cp_EmpName {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 87%;
  width: 21px;
  cursor: pointer;
}

.cp_status {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 85%;
  width: 21px;
  cursor: pointer;
}

.s1 {
  position: relative;
  /* width: 10rem; */
  margin-left: 0.6rem;
}

.sEmpName {
  position: relative;
  margin-left: 0.6rem;
}

.topBar {
  width: 14rem;
  /* width: 14rem; */
  margin-top: 15px;
  padding-left: 16px;
}

.topBarName {
  width: 13rem;
}

.redBackground {
  background-color: #fedede;
}

.topBarName2 {
  width: 8rem;
}

.dropdown_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
}

.topBarSKill {
  width: 14rem;
  margin-top: 15px;

  /* margin-right: 40px; */
}

.topBarRating {
  width: 7rem;
  margin-top: 15px;
}

.topPanBar {
  width: 13rem;
  /* width: 14rem; */
  margin-top: 15px;
  padding-left: 16px;
}

.topVendor {
  width: 14rem;
  margin-top: 15px;
}

.topGST {
  width: 13rem;
  margin-top: 15px;
}

.topBarTDS {
  width: 15rem;
  margin-top: 15px;
}

.PositionList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  /* height: 500px; */
  overflow-y: hidden;
  padding: 10px;
  font-size: 14px;
  /* margin-top: 26px; */
}

.buttonAdd1 {
  float: right;
  margin-left: 55px;
  margin-right: 26px;
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 81px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.buttonCancle {
  width: 81px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  position: relative;
  left: 53%;
  margin-top: -38px;
  margin-bottom: 25px;
}

.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
}

.th p:nth-child(1) {
  width: 7rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  padding-left: 8px;
}

.th p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(4) {
  width: 15rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(6) {
  width: 15rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(7) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

/* .th p:nth-child(8) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  } */

.td {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 7rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  font-size: 12px;
  color: #c3b14b !important;
  font-weight: bolder;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.td p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.td p:nth-child(4) {
  width: 15rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  padding-left: 2px;
  font-size: 14px;
}

.td p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  font-size: 14px;
  /* padding-left: 40px; */
}

.td p:nth-child(6) {
  width: 15rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  font-size: 14px;
}

.td p:nth-child(7) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
}

/* .td p:nth-child(8) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    font-size: 14px;
  } */

.manage {
  margin-left: 27px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.resourceType {
  font-size: 500 !important;
  font-size: 16px;
}

.createPartner {
  margin-top: 15px !important;
  cursor: pointer;
  position: relative;
}

.partnertop {
  display: flex;
  justify-content: end;
  margin-right: 22px;
}

.buttonAdd {
  background-color: #c3b14b;
  border-color: #c3b14b;
  width: 8rem;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  margin-right: 2px;
}

@media only screen and (max-width: 1246px) and (min-width: 1160px) {
  .buttonAdd {
    background-color: #c3b14b;
    border-color: #c3b14b;
    width: 8rem;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    margin-left: 17px;
  }
}

.showStatus > p {
  padding: 10px;
}

.showStatus > p:hover {
  cursor: pointer;
}

.no_data {
  text-align: center;
  padding: 3%;
}

.plus {
  font-size: 18px;
}

.pagination {
  margin-top: 10px;
  margin-right: 30px;
}

.VenderType {
  display: flex;
  margin-top: 10px;
}

.VenderRadio {
  margin: 0px auto;
  display: flex;
}

.partnerlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 97.6%;
}

.add {
  border: 2px solid #c3b14b;
  color: #c3b14b;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 1rem;
}

.add:hover {
  cursor: pointer;
  background-color: #c3b14b;
  color: #ffffff;
}

@media only screen and (max-width: 1280px) {
  .add {
    border: 2px solid #c3b14b;
    color: #c3b14b;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.oppp {
  display: flex;
  justify-content: start;
}
.coreSkillInput {
  border-radius: 5px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  width: 100%;
  height: 38px;
  border: 1px solid #00000061;
  padding: 0 8px;
  outline: none;
}

.coreSkillInput::placeholder {
  color: hsl(0deg 2% 18%);
}
