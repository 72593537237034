/* Updated CSS */
.s1 {
    position: relative;
    width: 13rem;
    margin-left: 0.6rem;
    display: flex;
    gap: 10px;
}

.cp_serchIcon {
    position: absolute;
    z-index: 1;
    top: 15%;
    margin-left: 84%;
    width: 21px;
    cursor: pointer;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
}

.modal.open {
    display: block;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border: 1px solid #888;
    width: 34%;
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.close {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 26px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.priorityButton {
    padding: 7px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    color: rgb(71, 70, 70);
    cursor: pointer;
}

.priorityButton.selected {
    border: 2px solid black;
}

.submit-button {
    margin-top: 20px;
    width: 88px;
    padding: 7px;
    font-size: 14px;
    cursor: pointer;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    float: right;
    margin-left: 177px;
}

.submit-button:hover {
    background-color: darkblue;
}
