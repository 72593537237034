.note_container{
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    min-height: 104px;
    height: auto;
    /* margin-bottom: 20px; */
}
.note_heading{
    display: flex;
    justify-content: center;
    color: #a15e5e;
    font-weight: bold;
    font-size: 1.1rem;
    /* border-bottom: 2px solid rgba(8, 8, 8, 0.1); */
}
.note_content{
    width: 98%;
    margin: 0px auto;
    padding-bottom: 5px;
}
.text{
    background-color: rgb(245, 246, 250);
    width: 100%;
    outline: none;
    border: none;
    resize: none;
    height: 100px;
    /* box-shadow: 0 3px 9px rgba(0,0,0,.1607843137254902); */
    /* margin-bottom: 15px; */
    padding-left: 5px;
}
.error {
    color: red;
    font-weight: 500;
    /* padding-top: 4px; */
    /* padding-left: 14px; */
    font-size:14px!important;
  }
.btn_div{
    display: flex;
    justify-content: end;
    width: 95%;
    margin: 0px auto;
}
.btn_edit, .btn_submit{
    color: white;
    /* background-color: #a15e5e; */
    border: none;
    margin-left: 5px;
    width: 70px;
}
.btn_edit{
    background-color: transparent;
    border: 1px solid #a15e5e;
    color:#a15e5e;
}
.btn_submit{
    background-color: #a15e5e;
}