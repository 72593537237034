.copy_expence {
    text-align: center;
    color: var(--invoiceColor);
    font-weight: 500;
    font-size: var(--inoiceHeading);
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
    padding-bottom: 0.5rem;
  }
  
  .border {
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
    margin: 1rem 0px;
  }
  
  .Container {
    width: 95%;
    margin: 0.8rem auto;
    display: flex;
    justify-content: space-between;
  }
  
  .col1 {
    width: 23.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .col12 {
    width: 23.5%;
  }
  
  .col2 {
    width: 23.5%;
  }
  
  .col3 {
    width: 23.5%;
  }
  
  .col4 {
    width: 23.5%;
  }
  
  .col212 {
    width: 48.5%;
  }
  
  .col22 {
    width: 48.5%;
  }
  .col12_item {
    width: 13%;
  }
  .col2_item {
    width: 13%;
  }
  .col3_item {
    width: 13%;
  }
  .col4_item {
    width: 13%;
  }
  .col5_item {
    width: 13%;
  }
  .col6_item {
    width: 13%;
  }
  .col7_item {
    width: 13%;
  }
  .col8_item {
    width: 0%;
    margin: 3% 0 0 0;
  }
  .lastBtn_item {
    display: flex;
    justify-content: end;
    width: 96.4%;
  }
  
  .bigInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    /* margin-top: 10px; */
    /* margin: 1% 1% 1% 1%; */
    /* padding-left: 10px; */
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
  }
  .smallInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    /* margin-top: 10px; */
    /* margin: 1% 1% 1% 1%; */
    /* padding-left: 10px; */
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
  }
  
  .cu_field23Last {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    /* width: 130%; */
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    /* margin-top: 10px; */
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
  }
  
  .heading {
    font-weight: 500;
    color: var(--invoiceTextColor);
  }
  
  .label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
  }
  .label_item {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    font-size: 12px;
  }
  
  .radioitem {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    /* margin: 10px 0 0; */
  }
  
  .radioitem label {
    cursor: pointer;
  }
  
  .radioitem input[type="radio"] {
    display: none;
  }
  
  .radioitem label {
    font-weight: 600;
  }
  
  .radioitem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid var(--invoiceColor);
    background-color: transparent;
  }
  
  .radioitem input[type="radio"]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: var(--invoiceColor);
  }
  .lastBtn {
    display: flex;
    justify-content: end;
    width: 97.6%;
    grid-column-gap: 2%;
    margin: 2% 0 3% 0;
  }
  .remove_icon {
    color: #855ea1;
    margin-top: 3%;
  }
  .values {
    color: grey;
    font-weight: 500;
  }
  
  
  
  .th {
    display: flex;
    justify-content: space-around;
    /* background-color: #f5f7f9; */
    border-radius: 10px;
    align-items: center;
    height: 40px;
    /* color: #666666; */
    font-size: 12px;
    font-weight: 600;
  }
  
  .th p:nth-child(1) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
    padding-left: 8px;
  }
  
  .th p:nth-child(2) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(3) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(4) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
    /* padding-left: 5px; */
  }
  
  .th p:nth-child(5) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    /* padding-right: 14px; */
    font-size: 12px;
  }
  
  .th p:nth-child(6) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    display: block;
  
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(7) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    display: block;
    /* color: #666666; */
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(8) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    display: block;
    /* color: #666666; */
    font-weight: 600;
    font-size: 12px;
  }
  
  .td {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    min-width: 95%;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    font-size: 12px;
  }
  
  .td:last-child {
    border-bottom: none;
  }
  
  .td p:nth-child(1) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding-left: 8px;
    font-size: 12px;
  }
  
  .td p:nth-child(2) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #855ea1 !important;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    cursor: pointer;
    transform: translate(-117px,0px);
  }
  
  .td p:nth-child(3) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    transform: translate(-100px,0px);
  
  }
  
  .td p:nth-child(4) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* padding-left: 8px; */
    font-size: 12px;
    transform: translate(-80px,0px);
  
  }
  
  .td p:nth-child(5) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* margin-right: 13px !important; */
    font-size: 12px;
    transform: translate(-68px,0px);
  
  }
  
  .td p:nth-child(6) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    transform: translate(-40px,0px);
  
  }
  
  .td p:nth-child(7) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    display: block;
  
    color: #666666;
    font-weight: 600;
    font-size: 12px;
    transform: translate(-26px,0px);
  
  }
  
  .td p:nth-child(8) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    display: block;
  
    color: #666666;
    font-weight: 600;
    font-size: 12px;
    transform: translate(-9px,0px);
  
  }
  
  