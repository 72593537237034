*{
    margin: 0;
    padding: 0;
}

.mainSection{
    
    width: 100% ;
    height: 100vh ;
    /* position: fixed; */
    /* position: absolute; */
    top: 100px;
    bottom: 0;
    display: flex;
}


.mainSection .rightSection {
    /* border: 2px solid blue; */
    width: 80%;
}

@media screen and (max-width: 800px) {
    .mainSection .rightSection {
        /* border: 2px solid blue; */
        width: 100%;
    }
}
/* 
  .createTask {
    margin-top: 30px;
    margin-left: 2%;
    border-collapse: collapse;
    width: 96%;
    
    box-shadow: 0 4px 8px 8px rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;

  display: block;
    overflow-x: auto;
  }

  .createTask label{
      display: block;
  }

  .gridContainer {
      display: flex
  }

  .gridContainer label {
      margin-left: 10px;
  }

  .gridContainer input {
      width: 400px;
      height: 30px;
      background-color: #0000001A;
      border: none;
  }

  .gridContainer textarea{
    width: 300px;
    height: 30px;
    background-color: #0000001A;
    border: none;
  }

  .taskAssigned {
    
    width: 96%;
    margin-left: 2%;
    box-shadow: 0 4px 8px 8px rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-top: 30px;
  
  }
  .taskAssigned .container {
    display: flex;
   
    position: relative;
    
  } */