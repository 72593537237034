.card {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 130px;
  height: auto;
  margin-bottom: 20px;
}

.title {
  display: flex;
  justify-content: center;
  color: #a15e5e;
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}

.cu_label {
  font-size: 15px;
  font-weight: 500;
  padding-left: 4px;
  color: #000000;
}

.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 17vw;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin: 1% 1% 1% 1%;
  margin-top: 10px;
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.Cu_row1,
.Cu_row2,
.Cu_row3 {
  display: flex;
  padding: 10px;
  column-gap: 2vw;
}
.cu_col1 {
  flex: 0 0 22%;
}
.cu_col1 {
  flex: 0 0 22%;
}

.Cu_row2 {
  /* flex-wrap: wrap; */
  /* grid-row-gap: 2vw; */
  /* row-gap: 2vw; */
  display: flex;
  justify-content: space-around;
}
.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  /* width: 130%; */
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  padding-left: 10px;
  outline: none;
  padding-top: 5px;
}

.button {
  display: flex;
  justify-content: end;
  width: 98.4%;
  margin-top: -1%;
}

.btn1 {
  background-color: #ffffff;
  color: #a15e5e;
  border: 1px solid #a15e5e;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  margin: 10px;
  outline: none;
}

.btn2 {
  background-color: #a15e5e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  margin: 10px;
  outline: none;
}

.Cu_feedbackType {
  display: flex;
  padding: 10px;
  flex-direction: column;
  column-gap: 10vw;
  row-gap: 15px;
}

/* file upload */
.Cu_resume {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 -0.5%;
  position: relative;
  grid-column-gap: 5px;
}

.rmpdf {
  border: 1px solid black;
  width: 40vw;
  border-radius: 10px;
  padding: 5px;
  padding-right: 50px;
  color: #a15e5e;
}

.rmpdfcross {
  color: black;
  border: 1px solid #000000;
  background-color: #a15e5e;
  position: absolute;
  right: -1%;
  top: -20%;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
}

.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px;
}

.upload_resume {
  width: 40vw;
  border: 1px solid #999;
  border-radius: 10px;
  margin-left: -10px;
}

.Cu_row1 input[type="file"]::file-selector-button {
  border: 2px solid #a15e5e;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #a15e5e;
  transition: 1s;
  color: #ffffff;
}
.radio {
  display: flex;
  grid-gap: 50px;
  gap: 5px;
  font-weight: 600;
  padding-left: 0.5%;
  margin-top: -22px;
}
.radio2 {
  display: flex;
  grid-gap: 50px;
  gap: 24px;
  font-weight: 600;
  padding-left: 0.5%;
  /* margin-top: -22px; */
}

.radioitem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  /* margin: 10px 0 0; */
}

.radioitem label {
  cursor: pointer;
}

.radioitem input[type="radio"] {
  display: none;
}

.radioitem label {
  font-weight: 600;
}

.radioitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #a15e5e;
  background-color: transparent;
}

.radioitem input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #a15e5e;
}

/* test */
.Cu_testCol1 {
  display: flex;
  column-gap: 20px;
  margin-left: -0.4rem;
}

.Cu_testCol2 {
  display: flex;
  column-gap: 10vw;
  flex-direction: column;
}

.one > p:first-child,
.two > p:first-child,
.three > p:first-child {
  font-weight: 600;
}

/*  */
.add {
  border: 2px solid #a15e5e;
  color: #a15e5e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tooltip {
  cursor: pointer;
  margin-left: 10px;
}

.add > p {
  font-size: 14px;
  font-weight: bold;
}

.add:hover {
  cursor: pointer;
  background-color: #a15e5e;
  color: #ffffff;
}

.textarea {
  min-height: 100px;
  width: 66%;
  outline: none;
  margin-top: 10px;
  margin-left: 0.7%;
}

.feedback {
  display: flex;
  align-items: center;
  grid-column-gap: 2%;
}

.feedback > p:first-child {
  width: 150px;
}

.mand {
  color: red;
}
.errorText {
  color: red;
  font-size: 12px;
  visibility: visible;
  padding: 0.1rem 1rem 0.1rem 0.5rem;
}

.errorTextHidden {
  color: red;
  font-size: 12px;
  margin-left: 15px;
  visibility: hidden;
  /* display: none; */
  padding: 0.1rem 1rem 0.1rem 0.5rem;
}

.cp_field {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  padding-left: 10px;
  /* padding: 5px 5px; */
  outline: none;
}
.h {
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding-left: 0.5%;
}
.h_box{
  display: flex;
  flex-direction: column;
  width: 55%;
}
.h1 {
  display: flex;
  justify-content: space-between;
  width: 55%;
  grid-column-gap: 19%;
  margin-bottom: 5px;
}
.h_name {
  font-weight: 700;
}

.circleDesign {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a15e5e;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.circleClick {
  width: 2.5rem;
  height: 2.5rem;
  background: #a15e5e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: #fff;
}

.skillRating {
  width: 25vw;
  text-align: center;
}
