.catainer {
    width: 20rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 0.3rem;

}

.circle {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border-color: #C3B14B;
    cursor: pointer;
}

.b_inactive {
    border: 1px solid #C3B14B;
    border-radius: 4px;
    color: white;
    background-color: #C3B14B;
    width: 5vw;
    font-size: 15px;
    cursor: pointer;
    margin-left: 18px;
}