.mainSection {

  width: 100%;
  height: 100vh;
  /* position: fixed; */
  /* position: absolute; */
  top: 100px;
  bottom: 0;
  display: flex;
}
.rightSection{
width: 80%;
}