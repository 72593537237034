.note_table th{
    font-size: 14px;
    font-weight: 600;
}
.note_table .review_notes_table_text{
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
.note_table .table_icon_active{
    font-size: 23px;
    color: #a15e5e;
    cursor: pointer;
}
.note_table .table_icon{
    font-size: 23px;
    color: grey;
    cursor: pointer;
}

.confirmPopup {
    /* top: 441px;
  left: 767px;*/
    width: 386px;
    height: 198px;
    text-align: center;
    padding-top: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
  }

  .confirmPopupTitle {
    text-align: center;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
  }
  
  .yesBtn {
    width: auto;
    background-color: #a15e5e;
    color: white;
    border: 1px solid #a15e5e;
    border-radius: 5px;
    padding: 0.4rem 2.75rem;
  }
  
  .noBtn {
    opacity: 1;
    margin-left: 10px;
    width: auto;
    background-color: #fff;
    color: #a15e5e;
    border: 1px solid #a15e5e;
    border-radius: 5px;
    padding: 0.4rem 2.75rem;
  }
  .no_notes{
    text-align: center;
  }