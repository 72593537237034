.card {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 97%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    /* height: 19rem; */
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .title {
    display: flex;
    justify-content: center;
    color: #9c57a5;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  }
  .Cu_row1 {
    display: flex;
    justify-content: space-between;
    margin: 1% 2.1%;
  }
  .Cu_row1 input[type="file"]::file-selector-button {
    border: 2px solid #65a15e;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #65a15e;
    transition: 1s;
    color: #ffffff;
  }
  
  .cu_col1 {
    flex: 0 0 24%;
  }
  
  .cu_label {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }
  .cu_label1 {
    font-size: 14px;
    font-weight: 500;
    padding-left: 24px;
    color: #000000;
  }
  .cu_col2 {
    flex: 0 0 24%;
  }
  
  .cu_col2_ac {
    flex: 0 0 24%;
  }
  
  .cu_col2_to {
    flex: 0 0 24%;
  }
  
  .cu_col3 {
    flex: 0 0 24%;
  }
  .cu_col4 {
    flex: 0 0 24%;
  }
  .mandatory {
    color: red;
  }
  .chooseFile {
    height: 32px;
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    border: 2px solid white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px grey;
  }
  .main {
    width: 80%;
  }
  .buttonDiv {
    position: absolute;
    right: 1.5%;
  }
  .submitbtn {
    background-color: #9c57a5;
    border-radius: 9px;
    height: 38px;
    width: 6rem;
    border: 1px solid #9c57a5;
    margin-right: 20px;
  }
  .Done {
    background-color: #9c57a5;
    border-radius: 9px;
    height: 38px;
    width: 6rem;
    border: 1px solid #9c57a5;
  }
  .deletebtn {
    background-color: #9c57a5;
    border-radius: 9px;
    height: 38px;
    width: 6rem;
    border: 1px solid #9c57a5;
    margin-right: 20px;
  }
  .description{
      width: 98%;
      margin-bottom: 20px;
      min-height: 2rem;
  }
  .file{
      text-decoration: underline;
  }
  .viewIssuP{
    font-weight: 500;
    padding-left: 15px;
  }
  
  .confirmPopupTitle {
    text-align: center;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
  }
  .confirmPopup {
    width: 386px;
    height: 198px;
    text-align: center;
    padding-top: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
  }
  .yesBtn {
    padding: 5px 30px;
    outline: none;
    background:#9c57a5 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 6px;
    color: #fff;
    opacity: 1;
  }
  .noBtn {
    margin-left: 10px;
    padding: 5px 30px;
    outline: none;
    font: normal normal 600 18px/27px Poppins;
    background: #fff;
    letter-spacing: 0px;
    color:#9c57a5;
    opacity: 1;
    border: 1px solid #9c57a5;
    border-radius: 6px;
  }