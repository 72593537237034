.tooltiptext {
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    height: 2rem;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    bottom: 2.2rem;
    font-weight: 400;
    z-index: 10000;
    transition: opacity 0.3s;
    /* right: 5%; */
    /* top: -1%; */
    border-color: black transparent transparent transparent;
}

.atsButton {
    background-color: #65a15e;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    border: 2px solid #65a15e;
    box-shadow: none;
    outline: none;
}

.atsButtonCancel {
    background-color: #fff;
    color: #65a15e;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    border: 2px solid #65a15e;
    box-shadow: none;
    outline: none;
}

.atsButton:disabled {
    color: #fff;
    background-color: #bab5b5;
    border: 2px solid #bab5b5;
}

.containers {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    /* height: 485px; */
    /* overflow-y: hidden; */
    /* padding: 10px; */
    min-height: 5rem;

}

.card {
    width: 95%;
    margin: 10px auto;
}

.modal_container1 {
    min-width: 18rem;
    max-width: 40rem;
    min-height: 8rem;
    border-radius: 5px;
}

.textM {
    text-align: center;
}

.M_button {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
}

.button {
    display: flex;
    width: 10rem;
    justify-content: space-around;
}
.tooltipEmail {
    display: inline;
    padding: 0 !important;
    margin: 0 !important;
    width: 200px !important;
}
.tooltiptextNew{
    width: auto !important;
    background-color: rgb(24, 23, 23);
    color: #fff;
    text-align: center;
    height: auto;
    border-radius: 6px;
    padding: 5px 5px !important;
    position: absolute;
    z-index: 2;
}
.emailTooltipContainer{
    padding: 0 !important;
    margin: 0 !important;
}