.main {
    display: block;
    position: relative;
    padding-left: 14px;
    /* padding-bottom: 15px; */
    cursor: pointer;
    font-size: 16px;
}
 input[type=checkbox] {
    visibility: hidden;
}
  
 .geekmark {
    position: absolute;
    margin-top: 4px;
    top: 0;
    left: 0;
    height: 18px;
    border: 1px solid #707070;
    border-radius: 4px;
    width: 18px;
    background-color: #ffffff;
}
  
 .main input:checked ~ .geekmark {
    background-color: green;
}

 .geekmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
 .main input:checked ~ .geekmark:after {
    display: block;
}
  
 .main .geekmark:after {
    left: 5px;
    bottom: 4px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
