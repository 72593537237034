.card {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 98%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    min-height:21rem;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .paragraph{
    font-weight: 500;
    padding-left: 15px;
    padding-top: 5px;
  }
  .title {
    display: flex;
    justify-content: center;
    color:#9C57A5;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  }
  .Cu_row1 {
    display: flex;
    justify-content: space-between;
    margin: 1% 2.1%;
  }
  .Cu_row1 input[type="file"]::file-selector-button {
    border: 2px solid #65a15e;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #65a15e;
    transition: 1s;
    color: #ffffff;
  }
  
  .cu_col1 {
    flex: 0 0 31%;
  }
  
  .cu_label {
    font-size: 14px;
    font-weight: 500;
    padding-left: 4px;
    padding-bottom: 5px;
    color: #000000;
  }
  .cu_label1 {
    font-size: 14px;
    font-weight: 500;
    padding-left: 24px;
    padding-bottom: 5px;
    color: #000000;
  }
  .cu_col2 {
    flex: 0 0 31%;
  }
  
  .cu_col2_ac {
    flex: 0 0 31%;
  }
  
  .cu_col2_to {
    flex: 0 0 31%;
  }
  
  .cu_col3 {
    flex: 0 0 31%;
  }
  .mandatory{
    color: red;
  }
  .chooseFile{
    height: 34px;
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px grey;
 }
 .main{
  width: 80%;
 }
 .buttonDiv{
  position: absolute;
  right: 1%;
 }
 .submitbtn{
  background-color:#9C57A5;
  border-radius: 9px;
  height: 38px;
  width: 6rem;
  border:1px solid #9C57A5;
}
.cancelbtn{
  background-color:white;
  border-radius: 9px;
  height: 38px;
  width: 6rem;
color: #9C57A5;
  border:1px solid #9C57A5;
  margin-right: 20px;
}
 
.errorTextHidden {
  visibility: hidden;
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.errorText {
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}
.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px;
  cursor: pointer;
}

.upload_resume {
  min-width:10%;
  border-radius: 6px;
}