.createBtn {
  width: 150px;
  background-color: #65a15e;
  color: white;
  border: 1px solid #65a15e;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  align-items: left;
  cursor: pointer;
}

.createBtn:disabled,
.createBtn[disabled]{
border: 1px solid #999999;
background-color: #cccccc;
color: #666666;
}
.cancelBtn {
  width: 150px;
  background-color: red;
  color: white;
  border: 1px solid red;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.cancelBtn:disabled,
.cancelBtn[disabled]{
border: 1px solid #999999;
background-color: #cccccc;
color: #666666;
}
