.label {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-left: 12px !important;
}
.label1 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-left: 12px !important;
  margin-top: 15px !important;
}
.msaopen {
  color: blue !important;
  text-decoration: underline !important;
}
.partnerlabal {
  margin-left: 12px !important;
  word-break: break-word !important;
}
.partnerlabal1 {
  margin-left: 12px !important;
  color: blue !important;
  text-decoration: underline !important;
}
.primaryContact {
  margin-top: 15px;
  margin-left: 7px;
  padding-left: 4px;
  margin-right: 15px;
  padding-bottom: 4px;
  border-top: 2px solid rgba(8, 8, 8, 0.1);
}

.lastBtn_item {
  display: flex;
  justify-content: end;
  width: 97.5%;
}

.buttonAdd4 {
  background-color: #c3b14b;
  border-color: #c3b14b;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 16px;
  left: 3.5%;
}
.buttonAdd5 {
  background-color: #c3b14b;
  border-color: #c3b14b;
  height: 38px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 16px;
  left: 3.5%;
  cursor: none !important;
}
.TDSRate {
  padding-left: 11px;
  padding-top: 12px;
  font-size: 16px;
  font-weight: 600;
}

.currentRating {
  display: flex;
  justify-content: end;
  margin-right: 64px;
  margin-top: -28px;
}
.circle {
  width: 40px !important;
  position: absolute;
  height: 40px;
  font-size: 30px;
  margin-top: -10px;
  padding-top: 1px;
  align-items: center;
  border-radius: 50%;
  background-color: #c3b14b;
  color: white;
  text-align: center;
}
.circle1 {
  width: 38px !important;
  height: 38px;
  padding-left: 12px;
  font-size: 22px;
  margin-top: -10px;
  padding-top: 2px;
  align-items: center;
  border-radius: 50%;
  background-color: #c3b14b;
  color: white;
}

.circle2 {
  width: 38px !important;
  height: 38px;
  font-size: 22px;
  margin-top: -10px;
  padding-top: 1px;
  align-items: center;
  border-radius: 50%;
  /* background-color: #666666; */
  border: 2px solid #c3b14b;
  color: #c3b14b;
  text-align: center;
}
.currentRatingView {
  margin-right: 22px;
  color: #c3b14b;
}

.th {
  display: flex;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  padding-left: 4px;
}

.th p:nth-child(1) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  padding-left: 8px;
}

.th p:nth-child(2) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(3) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(4) {
  /* width: 10rem; */
  text-align: start;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.th p:nth-child(5) {
  /* width: 10rem; */
  text-align: start;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}
.tooltips{
  text-overflow: ellipsis;
  width:30rem;
  overflow: hidden;
}

.td {
  display: flex;
  /* justify-content: space-around; */
  border-radius: 10px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 34srem;
  text-align: start;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  color: black !important;
}
.paragraphfirst {
  width: 10rem;
  text-align: start;
  font-size: 14px;
  color: #666666;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  color: #c3b14b !important;
  font-weight: bolder;
}

.td p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(4) {
  /* width: 10rem; */
  text-align: start;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  padding-left: 2px;
}

.td p:nth-child(5) {
  /* width: 10rem; */
  text-align: start;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
}
.OverallRating {
  display: flex;
}
.ratingValue {
  margin-left: 15px !important;
  display: flex;
  gap: 25px;
  cursor: pointer;
}
.RatingOverall {
  margin-left: 11px !important;
  font-size: 15px;
  font-weight: 600;
}
.RatingOverall1 {
  margin-left: 11px !important;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px !important;
}
.SkillRating {
  margin-top: 10px !important;
  font-size: 15px;
}
.addNote {
  padding-top: 10px;
  padding-left: 11px;
  font-size: 15px;
  font-weight: 600;
}
.RatingTextArea {
  width: "95%" !important;
  margin-left: 12px;
  margin-top: 10px;
}

.add_comment_div {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 93%;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 1%;
  border-radius: 5px;
  padding: 1% 2%;
  background-color: white;
  padding-bottom: 0px;
}

.comment_para {
  text-align: center;
  font-weight: 600;
  color: #c3b14b;
}

.textarea {
  width: 100%;
  resize: none;
  margin-top: 1%;
  background-color: rgb(245, 246, 250);
  outline: none;
}

.submit_btn_div {
  width: 93%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2%;
}

.linkedin_icon {
  color: #0072b1;
  margin-left: 5%;
  font-size: 2rem;
  cursor: pointer;
}

.pdf_icon {
  color: #de2429;
  font-size: 2rem;
  cursor: pointer;
}

.company_icon {
  margin-left: 5%;
  color: #0072b1;
  font-size: 2rem;
  cursor: pointer;
}

.table_head {
  font-size: 14px;
}

.edit_icon {
  cursor: pointer;
  color: #c3b14b;
}

.edit_icon_blur {
  color: #c3b14b;
  opacity: 0.5;
}

.modal_para_div {
  padding: 2%;
}

.dropdown_div {
  width: 100%;
}

.button_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5%;
}
