.rightSection {
  /* border: 2px solid blue; */
  width: 80%;
}

.scrollAdd {
  border-radius: 5px;
  background-color: #ffffff;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}
.scrollAdd::-webkit-scrollbar {
  width: 12px;
}

.scrollAdd::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 5px;
}

.scrollAdd::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
}

.container {
  margin-left: 2%;
  width: 96%;
  margin-top: 15px;
}

.container span {
  font-family: "poppins";
  font-weight: 600;
  opacity: 0.85;
  font-size: 18px;
}

.createTask {
  margin-top: 15px;
  /* border-collapse: collapse; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 16px;
  display: block;
  /* overflow-x: auto; */
}

.createTask label {
  display: block;
}

.success_label {
  color: white;
  background-color: #65a15e;
  text-align: center;
  border-radius: 5px;
}

/* The alert message box */
.alert {
  padding: 11px;
  border-radius: 5px;
  background-color: #65a15e;
  color: white;
  margin-bottom: 15px;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}

.gridContainer {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 30% 100%;
  grid-template-areas:
    "title valuetitle"
    "user userval"
    "descp area";
  /* grid-template-rows: 20px 20px 20px; */
}

/* .gridContainer .valueTitle {
  margin-left: 10px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  margin-top: 10px;
} */

.text-area-container {
  width: 60%;
  background-color: white;
  /* background-color: #f8f9fb; */
  /* box-shadow: 0px 3px 6px #0000001a; */
  border-radius: 10px;
  /* padding-left: 10px; */
  padding-top: 5px;
  font-size: 15px;
  font-family: sans-serif;
  /* #172A3E */
  /* border: 1px solid #65a15e; */
}
.gridContainer textarea {
  width: 100%;
  border: none;
  resize: none;
  padding-left: 7px;
}

.gridContainer textarea:focus {
  outline: none;
}

.gridContainer input {
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 90%;
  min-width: 250px;
  height: 30px;
  /* padding: 0px 0px 0px 10px; */
  /* background-color: #f9fafc; */

  box-shadow: 0px 3px 6px #0000001a;
  padding-left: 5px;
  /* padding-top: 5px; */
  /* border: none; */
  /* border-color: #65A15E; */
  border: 1px solid #65a15e;
  border-radius: 6px;
  /* margin-top: 8px; */
  background-color: white;
}

.gridContainer input:focus {
  outline: none;
}

.gridContainer select {
  width: 90%;
  min-width: 250px;
  height: 30px;
  /* padding: 0px 0px 0px 10px; */
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #0000001a;
  /* border: none; */
  border-radius: 6px;
  /* margin-top: 8px; */
  border: 1px solid #65a15e;
}

.gridContainer select:focus {
  outline: none;
}

.gridContainer select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.backButton {
  float: right;
  background-color: #fcb902;;
  color: #ffffff;
  padding: 11px 32px 11px 32px;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  font-size: 16px;
  margin-right: 10px;
}

.submitButton {
  float: right;
  background-color: #fcb902;;
  color: #ffffff;
  padding: 11px 32px 11px 32px;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  font-size: 16px;
}
.react-calendar__navigation {
  height: 28px !important;
}

.calendarBox {
  top: 57%;
  left: 23.5%;
  bottom: 0%;
  right: 42%;
  /* position: absolute; */
}

.req_msg {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #f84142;
  opacity: 1;
}

.calendarBox {
  top: 60%;
  left: 23.5%;
  bottom: 0%;
  right: 42%;
  position: absolute;
}

.req_msg {
  margin-left: 1%;
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #f84142;
  opacity: 1;
}

.titleLabel {
  grid-area: title;
  margin-left: 10px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  margin-top: 10px;
}

.valueTitle {
  grid-area: valuetitle;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  margin-top: 10px;
}

.userLabel {
  grid-area: user;
  margin-left: 10px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  margin-top: 10px;
}

.valueUser {
  grid-area: userval;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  margin-top: 10px;
}

.descLabel {
  grid-area: descp;
  margin-left: 10px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  margin-top: 10px;
}

.areaLabel {
  grid-area: area;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  margin-top: 10px;
}

/* .statusLabel {
  grid-area: status;
} */

@media screen and (max-width: 1090px) {
  .gridContainer textarea {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .container span {
    font-family: "poppins";
    font-weight: 600;
    font-size: 18px;
    opacity: 0.85;
    margin-left: 6%;
  }

  .gridContainer {
    margin-top: 0px;
  }

  .gridContainer label {
    margin-top: 20px;
  }

  .rightSection {
    /* border: 2px solid blue; */
    background-color: #f5f6fa;
    width: 100%;
  }
  .submitButton {
    float: none;

    padding: 10px 50px 10px 50px;
    margin-left: 35%;
  }
}
