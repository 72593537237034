.filter,
.cancelBtn,
.createBtn {
    border: 1px solid #289e98;
    border-radius: 4px;
    color: white;
    background-color: #289e98;
    width: 7vw;
    font-size: 15px;
}

.filterBtn {
    /* display: flex;
  grid-gap: 20px;
  height: 20%;
  gap: 20px;
  justify-content: flex-end;
  align-items: flex-end; */
    display: flex;
    /* grid-gap: 20px; */
    /* height: 20%; */
    margin-top: 1rem;
    gap: 20px;
    justify-content: flex-end;
    align-items: flex-end;
}

.filterModal {
    min-width: 20rem;
    min-height: 16rem;
    max-width: 67rem;
    /* max-width: 50rem; */
    overflow: hidden;

}

.filterCards {
    display: flex;
    /* justify-content: space-between; */
    gap: 1rem;
    flex-wrap: wrap;
    /* overflow-y: scroll; */
}

.filte_heading {
    background-color: #e0d8d8;
    color: black;
    font-weight: 600;
    width: inherit;
    justify-content: center;
    display: flex;
    align-items: center;
    /* margin: -5px -5px 5px -5px; */
    width: 100%;
    height: 2rem;
    font-size: 15px;
    border-radius: 5px;
    margin-bottom: 0.5rem;
}

.firstCard {
    width: 16rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #e0d8d8;
    /* padding: 5px; */
    height: 16rem;
    border-radius: 5px;
}

.checkbox {
    visibility: visible !important;
}

.list {
    display: flex;
    gap: 10px;
    padding-left: 0.7rem;
    /* margin-top: 0.5rem; */
    display: flex;
    /* align-items: center;
    justify-content: center; */
    /* overflow-y: scroll; */
    /* height: inherit; */
}
.listRadio {
    display: flex;
    gap: 10px;
    padding-left: 0.7rem;
    display: flex;
 
}
.vh {
    position: absolute !important;
    /* clip: rect(1px, 1px, 1px, 1px); */
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    /* overflow: hidden; */
}

/*  Add a :before to each label and style this as the checkbox you want to have */

.list .label:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #f2f2f2;
    border: 1px solid rgba(75, 101, 132, 0.3);
    display: inline-block;
    margin-right: 16px;
    /* margin-top: -5px; */
}

/*  Change the styling of the :before when the hidden checkbox is checked, you also can add a background-image  */

.list input[type="checkbox"]:checked~label:before {
    background: #289e98 no-repeat center;
    background-size: 12px 12px;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

/*  Show when label and checkbox have focus, to make this keyboard accessible > press spacebar to select the checkbox */

.list input[type="radio"]:focus~label {
    color: #289e98;
}

.list input[type="radio"]:checked~label:before {
    background: #289e98 no-repeat center;
    background-size: 12px 12px;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

/*  Show when label and checkbox have focus, to make this keyboard accessible > press spacebar to select the checkbox */

.list input[type="checkbox"]:focus~label {
    color: #289e98;
}
.radioitem {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
}

.radioitem label {
    cursor: pointer;
}

/* .radioitem input[type="radio"] {
    display: none;
} */

.radioitem label {
    color: #666;
    font-weight: normal;
}

.radioitem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #289e98;
    background-color: transparent;
}

.radioitem input[type="radio"]:checked+label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #289e98;
}

input[type="checkbox"]:disabled~label:before {
    background: #ccc
}
input[type="radio"]:disabled~label:before {
    background: #ccc
}
.customRadioLabel{
    font-size: 1rem;
}


.date {
    border: 1px solid #00000061;
    border-radius: 5px;
    padding: 0.3rem 0.5rem;
    background-color: #f5f6fa;
    width: 80%;
}

.datePicker {
    padding: 4px;
    background: #F5F6FA;
    border: 0.2px solid #929292;
    border-radius: 5px;
    outline: none;
}