.active{
    background-color: rgb(40, 158, 152);
    cursor: pointer;
    color: white;
    border: 1px solid rgb(40, 158, 152);
    border-radius: 5px;
    padding: 3px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 5px;
}
.inactive{
    border: 1px solid rgb(40, 158, 152);
    border-radius: 5px;
    padding: 3px;
    font-size: 12px;
    margin-top: 5px;
    color: black;
    background-color: rgb(240, 240, 240);
}
.divCon ul>li{
    list-style-type: inherit;
}
