.main_div {
  box-shadow: 0px 3px 13px #00000029;
  border-radius: 8px;
}

.heading {
  text-align: center;
  border-bottom: 1px solid #00000029;
  padding: 1% 0%;
  font-weight: 600;
  color: #00979d;
}

.no_data_div {
  padding: 1% 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  color: grey;
  font-weight: 600;
}
