.main {
  padding: 20px;
  width: 100%;
}

.header {
  display: flex;
}

.heading {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

.inputRow {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  margin-top: 20px;
}

.grid_item {
  width: 11rem;
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-right: 1.7rem;
}

.rowHead {
  font-size: 15px;
  font-weight: 500;
}

.btnCancel {
  padding: 5px;
  background-color: white;
  color: #954b00;
  border: 0px;
  margin-right: 10px;
  font-size: 16px;
  box-shadow: 0px 3px 9px #00000029 !important;
  width: 90px;
  height: 35px;
  border-radius: 5px;
  font-weight: 500;
}

.btnSubmit {
  padding: 5px;
  background-color: #954b00;
  color: white;
  border: 0px;
  font-size: 16px;
  box-shadow: 0px 3px 9px #00000029 !important;
  width: 90px;
  height: 35px;
  border-radius: 5px;
  font-weight: 500;
}

.rowVal {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 90%;
  font-weight: 500;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: white;
  border: none;
  margin-top: 10px;
  padding: 5px;
  outline: none;
}

.csd {
  width: 100%;
  height: 38px;
  font-weight: 500;
  box-shadow: 0px 3px 9px #00000029 !important;
  margin-top: 10px;
  border-radius: 9px;
  background-color: #fff;
}

.errorText {
  margin-top: 3px !important;
  margin-left: 5px !important;
  color: red;
  font-size: 12px;
  font-weight: 400;
  margin-left: 15px;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.breadCrumb {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}

.breadCrumb:hover {
  text-decoration: underline;
}

.arrowbreadCrum {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}

.breadCrumb2 {
  font-weight: 700;
  font-size: 16px;
  color: #954b00;
}
