.active_partner_main_div {
  padding: 2%;
}

.heading {
  font-size: 1rem;
}

.top_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_div {
  width: 68%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2%;
}

.drop_down_div {
  width: 10vw;
}

.drop_down_div2 {
  width: 15vw;
}

.table_div {
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  overflow-x: auto;
  box-sizing: border-box;
  white-space: nowrap;
  margin: 1.5% 0%;
}

.table {
  width: 100%;
  font-size: 12px;
  border: none;
}

.table_heading {
  position: sticky;
  top: 0px;
  background-color: #f5f6fa;
  color: #666666;
}

.table_head {
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
}

.table_column {
  padding: 10px;
  font-size: 0.8rem;
}

.client_name_para {
  width: 19ch;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.employee_name {
  width: 20ch;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.empName {
  width: 21ch;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.skill_name {
  width: 22ch;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
