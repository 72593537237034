  .header {
    display: flex;
    width: 96%;
    margin-left: 2%;
    margin-top: 2%;
    gap: 2rem;
  }
  .header div span {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .header div {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .header div p {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #65a15e;
    /* border: 2px ; */
    margin-left: 7px !important;
    margin-top: 16px;
  }
  
  .header div p span {
    color: #65a15e;
  }
  .searchInput{
    width: 80%;
    border-radius: 5px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    width: 100%;
    height: 35px;
    border: 1px solid #00000061;
    padding: 0 1.75rem 0 8px;
    outline: none;
  }
  .cp_serchIcon {
    position: absolute;
    z-index: 100;
    margin-right: 8px;
    width: 21px;
    cursor: pointer;
  }
  .btnCantainer {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 5px 5px 5px 5px;
    position: relative;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.cantainer {
    position: relative;
    width: 100%;
}
.innerBtn1 {
    display: flex;
    overflow-x: auto;
    scrollbar-width: thin;
    white-space: nowrap;
    width: 100%;
    padding: 4px 2px;
}
.crossIcon {
  display : flex;
  width : 2rem;
  justify-content : center;
  align-items : center;
  background-color : white;
  position : sticky;
  right : 0;
  cursor : pointer;
}
.IssueList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 98%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  height : 62vh;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.th {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 14px;
}
.td {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
}
.asigned_color {
  color: #fcb902;
}

.done_color {
  color: #65a15e;
}

.onhold_color {
  color: #f94244;
}
.Closed_color {
  color: red;
}