.main_div {
  box-shadow: 0px 3px 13px #00000029;
  border-radius: 8px;
}

.heading {
  text-align: center;
  border-bottom: 1px solid #00000029;
  padding: 1% 0%;
  font-weight: 600;
  color: #00979d;
}

.new_data_div {
  padding: 1% 2%;
}

.first_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.small_div {
  width: 25%;
}

.para {
  font-weight: 600;
  margin-bottom: 4% !important;
  font-size: 0.8rem;
}

.para2 {
  font-weight: 600;
  margin-bottom: 1% !important;
  font-size: 0.8rem;
}

.margin_top {
  margin-top: 2%;
}

.input_box {
  box-shadow: 0px 3px 9px #00000029 !important;
  background-color: #f5f6fa;
  padding: 5px 5px;
  width: 100%;
  height: 2.3rem;
  outline: none;
  border: none;
  border-radius: 9px;
}

.textarea {
  box-shadow: 0px 3px 9px #00000029 !important;
  background-color: #f5f6fa;
  width: 100%;
  padding: 5px 5px;
  outline: none;
  border: none;
  border-radius: 9px;
  resize: none;
}

.second_div {
  margin-top: 2%;
}

.errorText {
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 12px;
  font-weight: 500;
  margin-left: 15px;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.errorTextHidden {
  visibility: hidden;
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}