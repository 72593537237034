/* .rightSection {
  width: 80%;
} */


.scrollAdd {
  border-radius: 5px;
  background-color: #ffffff;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;

}

.scrollAdd::-webkit-scrollbar {
  width: 12px;

}
.errorTextHidden {
  visibility: hidden;
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.errorText {
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.inputt {
  /* box-shadow: 0px 3px 9px #00000029 !important; */
  border-radius: 5px;
  opacity: 1.7;
  width: 88%;
  height: 38px;
  margin-top: -37px;
  box-shadow: 0px 3px 9px #00000029 !important;
  background: transparent 0% 0% no-repeat padding-box;
  margin-left: 7px;
  background-color: #F5F6FA;
  /* background-color: #F5F6FA; */
}

.scrollAdd::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 5px;
}

.scrollAdd::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
}


.container {
  margin-left: 2%;
  width: 96%;
  margin-top: 15px;
}

.container span {
  font-family: "poppins";
  font-weight: 600;
  opacity: 1.7;
  font-size: 18px;
}

.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  width: 90%;
  height: 38px;
  margin-top: 6px;
  border: none;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #F5F6FA;
}
.text {
  top: 383px;
  left: 899px;
  height: 138px;
  margin-top: 5px;
  font-weight: 400;
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  border: none;
  /* resize: none; */
  height: 31px;
  width: 475px;
  background-color: #F5F6FA;
}

.createTask {
  margin-top: 15px;
/* height: 22rem; */
  border-collapse: collapse;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 16px;
  display: block;
  /* overflow-x: auto; */
}

.createTask label {
  display: block;
}

.success_label {
  color: white;
  background-color: #65a15e;
  text-align: center;
  border-radius: 5px;
}

.alert {
  padding: 11px;
  border-radius: 5px;
  background-color: #65a15e;
  color: white;
  margin-bottom: 15px;
}


.react-datepicker-wrapper {
  padding: 0;
  border: 0;
  width: 10%;
}

.react-datepicker__input-container {
  position: fixed;
  display: inline-block;
  border: 1px solid black;
  margin-top: 10px !important;
  width: 362px;
  /* margin-left: 100px; */
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}

.gridContainer {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 40% 60%;
  grid-template-areas: "title descp"
    "assign descp"
    "enddate priority";
  /* grid-template-rows: 20px 20px 20px; */

}

.gridContainer label {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  opacity: 1.7;
  position: relative;
  margin-top: 3px;
}

.gridContainer textarea {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 6px;
  width: 95%;
  /* opacity: 1.7; */
  height: 8.7rem;
  background-color: #F5F6FA;
  font-weight: 500;
  padding: 5px 0px 0px 10px;
  font-weight: 500;
  border: none;
  resize: none;
}

.gridContainer textarea:focus {
  outline: none;
}

.gridContainer input {
  background: #F5F6FA 0% 0% no-repeat padding-box;
  width: 90%;
  font-weight: 400;
  min-width: 250px;
  height: 38px;
  padding: 0px 0px 0px 10px;
  background-color: #F5F6FA;
  box-shadow: 0px 3px 6px #0000001A;
  border: none;
  font-weight: 500;
  border-radius: 6px;
  margin-top: 8px;
  /* border: 1px solid #65A15E; */
}

.gridContainer input:focus {
  outline: none;
}

.gridContainer select {
  width: 91%;
  height: 38px;
  font-weight: 500;
  padding: 0px 0px 0px 5px;
  background-color: #F5F6FA;
  box-shadow: 0px 3px 6px #0000001a;
  border: none;
  border-radius: 6px;
  margin-top: 8px;
}

.project{
  width: 100%;
  height: 38px;
  font-weight: 500;
  padding: 0px 0px 0px 5px;
  background-color: #F5F6FA;
  box-shadow: 0px 3px 6px #0000001a;
  border: none;
  border-radius: 6px;
  margin-top: 8px;
}

.gridContainer select option {
  /* padding-left: 5px; */
  /* background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
  /* style={{fontSize:'15px'}} */
  font-size: 15px;
}

.gridContainer select {
  padding-left: 10px;
}

.gridContainer select:focus {
  outline: none;
}

.submitButton {
  float: right;
  background-color:rgb(252, 187, 8);
  color: #ffffff;
  padding: 11px 32px 11px 32px;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  font-size: 16px;
}

.titleLabel {
  grid-area: title;
}

.assignLabel {
  grid-area: assign;
}

.dateLabel {
  grid-area: enddate;
  /* margin-bottom: -10px; */
}

.descLabel {
  grid-area: descp;
}

.priorityLabel {
  grid-area: priority;
  display: grid;
  grid-template-columns: 50% 50%;

}

.priorityLabel1 {
  /* grid-area: priority; */
  display: grid;
  grid-template-columns: 50% 50%;

}
.backButton {
  float: right;
  background-color:rgb(252, 187, 8);
  color: #ffffff;
  padding: 11px 32px 11px 32px;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  font-size: 16px;
  margin-right: 10px;
}

.calendarBox {
  top: 49%;
  left: 23.5%;
  /* bottom: 0%; */
  right: 40%;
  position: absolute;
}

.req_msg {
  margin-left: 1%;
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #F84142;
  opacity: 1.7;
  top: 47%;
  left: 23.5%;
  /* bottom: 0%; */
  right: 40%;
  /* position: absolute; */
}

@media screen and (max-width: 1090px) {
  .gridContainer textarea {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .container span {
    font-family: "poppins";
    font-weight: 600;
    font-size: 18px;
    opacity: 0.85;
    margin-left: 6%;
  }

  .gridContainer {
    margin-top: 0px;
  }

  .gridContainer label {
    margin-top: 20px;
  }

  .gridContainer textarea {
    width: 90%;
    border-radius: 5px;
    box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
    height: 150px;
    font-family: sans-serif;
    border: 1px solid #65A15E;
  }

  .gridContainer textarea:focus {
    outline: none;
  }

  .rightSection {
    background-color: #f5f6fa;
    width: 100%;
  }

  .submitButton {
    float: none;

    padding: 10px 50px 10px 50px;
    margin-left: 35%;
  }
}



/* code end */