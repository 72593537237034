.label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
  }

  .label3 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    /* margin-left: 12px !important; */
    transform: translate(-6px,0px);
  }
  .label1 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
    margin-top: 15px !important;
  }
.partnerlabal{
    margin-left: 12px !important;
}
.primaryContact{
    border-bottom:2px solid rgba(8,8,8,.1) ;
    text-align: center;
    color: #C3B14B;
    padding-bottom: 10px;

  }

  .SkillStatus{
    transition: 0.3s;
    background-color: white;
    margin-left: 27px;
    min-width: 41em;
    min-height: 10em;
    border-radius: 7px;
    box-shadow: 1px 1px 14px rgba(0,0,0,.1607843137254902);
    opacity: 1;
  }

  .label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
  }
  .label1 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
    margin-top: 15px !important;
  }
.partnerlabal{
    margin-left: 12px !important;
}
.primaryContact{
    border-bottom:2px solid rgba(8,8,8,.1) ;
    text-align: center;
    color: #C3B14B;
    padding-bottom: 10px;

  }
  .lastBtn_item {
    display: flex;
    justify-content: end;
    width: 96.4%;
margin-left: 22px;
  }