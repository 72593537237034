.leftSection {
    width: 20%;
    min-height: 100vh;
    background-color: #172A3E;
    /* border: 2px solid blue; */
}


.dashboardImage {
    margin-left: 30px !important;
    height: 45px;
    margin: 7px 0px 10px 13px;
    cursor: pointer;
}

.leftSection button {
    
    width: -webkit-fill-available;
    margin-left: 10px;
    margin-right: 10px;
    
    border-radius: 15px;
    opacity: 1;
    outline: none;
   /* border: none; */
    margin-bottom: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

/* .leftSection button:hover {
    border-style: solid;
    border-color: white;
} */
.leftSection .myTask:hover {
    border-style: solid;
    border-color: white;
}

.leftSection .assignedByMe:hover {
    border-style: solid;
    border-color: white;
}

 .leftSection .taskTracker {
    background-color: #65A15E;
    height: 70px; 
    padding-left: 15px;
}

 .leftSection .taskTracker span {
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    padding-left: 15px;
}

 .leftSection .taskTracker div {
    background-color: white;
    width: 54px;
    height: 54px;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
}

 .leftSection .myTask {
    background-color:#172A3E;
    display: flex;
    height: 50px;
    color: #ffffff;
    padding-left: 15px;
    border: none;
    
}
.selected{
color : #65A15E;
background-color: #ffffff;
width: 54px;
height: 54px;
font-size: 18px;
padding-left: 10px;


}
.selected span{
    margin-left: 15px;
}


 .leftSection .myTask span {
   
    font-size: 18px;
    padding-left: 15px;
}

 .leftSection .assignedByMe {
    
    background-color:#172A3E;
    display: flex;
    height: 50px;
    color: #ffffff;
    padding-left: 15px;
    border: none;
}

 .leftSection .assignedByMe span{
    /* color: #ffffff; */
    font-size: 18px;
    padding-left: 15px;
}

@media screen and (max-width: 800px) {
    .leftSection {
        display: none;
    }
}