.rightSection {
  /* border: 2px solid blue; */
  width: 80%;
}

.scrollAdd {
  border-radius: 5px;
  background-color: #ffffff;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollAdd::-webkit-scrollbar {
  width: 12px;
}

.css-1i9fq18-ValueContainer {
  display: none !important;
}

.errorTextHidden {
  visibility: hidden;
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.errorText {
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.scrollAdd::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 5px;
}

.scrollAdd::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
}

.container {
  margin-left: 2%;
  width: 96%;
  margin-top: 15px;
}

.container span {
  font-family: "poppins";
  font-weight: 600;
  opacity: 0.85;
  font-size: 14px;
  border-radius: 6px;
}

.createTask {
  margin-top: 15px;

  border-collapse: collapse;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0px 3px 6px rgb(0 0 0 / 5%);
  transition: 0.3s;
  border-radius: 5px;
  padding: 16px;
  display: block;
  /* overflow-x: auto; */
}

.createTask label {
  display: block;
}

.success_label {
  color: white;
  background-color: #65a15e;
  text-align: center;
  border-radius: 5px;
}

/* The alert message box */
.alert {
  padding: 11px;
  border-radius: 5px;
  background-color: #65a15e;
  color: white;
  margin-bottom: 15px;

}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}

.gridContainer {
  margin-top: 10px;
  grid-template-areas:
    "title descp"
    "assign descp"
    "enddate priority";
  /* grid-template-rows: 20px 20px 20px; */
}

.gridContainer label {
  margin-left: 0px;
  /* font-weight: 600; */
  font-size: 16px;
  opacity: 1.7;
  margin-bottom: 12px;
  position: relative;
  margin-top: -5px;
  top: 7px;
}



.gridContainer textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 6px;
  opacity: 1;
  font-weight: 500;
  margin-top: 6px;
  /* padding: 0px 0px 0px 10px;
  margin-top: 6px; */
  padding-left: 10px;
  padding-top: 5px;
  
  background-color: #F5F6FA;
  width: 95.5%;

  height: 8.5rem
}

.gridContainer textarea:focus {
  outline: none;
}

/* For BeadCrums */
.font14 {
  font: normal normal 600 14px/21px Poppins;
  color: #65A15E;
  margin-left: 2.3%;
}


.font15 {
  color: black;
  font: normal normal 500 14px/21px Poppins;

}

.gridContainer input {
  width: 100%;
  height: 38px;
  font-weight: 500;
  padding-left: 10px;
  border-radius: 6px;
  border: none;
  background-color: #F5F6FA;
}


.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 90%;
  padding-left: 11px;
  height: 38px;
  margin-top: 6px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #F5F6FA;
}

.date {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 285px;
  height: 32px;
  margin-top: 6px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #F5F6FA;
}

.text {
  top: 383px;
  left: 899px;
  height: 138px;
  margin-top: 5px;
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  border: none;
  /* resize: none; */
  height: 31px;
  width: 475px;
  background-color: #F5F6FA;
}

.inputt {
  /* box-shadow: 0px 3px 9px #00000029 !important; */
  border-radius: 5px;
  opacity: 1.7;
  width: 88%;
  height: 38px;
  margin-top: -37px;
  box-shadow: 0px 3px 9px #00000029 !important;
  background: transparent 0% 0% no-repeat padding-box;
  margin-left: 7px;
  background-color: #F5F6FA;
  /* background-color: #F5F6FA; */
}

.inputt {
  /* box-shadow: 0px 3px 9px #00000029 !important; */
  border-radius: 5px;
  opacity: 1.7;
  width: 88%;
  height: 38px;
  margin-top: -37px;
  box-shadow: 0px 3px 9px #00000029 !important;
  background: transparent 0% 0% no-repeat padding-box;
  margin-left: 7px;
  background-color: #F5F6FA;
  /* background-color: #F5F6FA; */
}

.gridContainer input:focus {
  outline: none;
  color: black;
}

.gridContainer select {
  width: 90%;
  height: 38px;
  margin-top: 9px;
  /* padding: 0px 0px 0px 10px; */
  background-color: #F5F6FA;
  box-shadow: 0px 3px 6px #0000001a;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  /* margin-top: 8px; */
  /* border: 1px solid #65a15e; */
}


.gridContainer select:focus {
  outline: none;
}

.gridContainer select option {
  /* padding-left: 5px; */
  /* background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
  /* style={{fontSize:'15px'}} */
  font-size: 15px;
}

.gridContainer select {
  padding-left: 10px;
}

.submitButton {
  float: right;
  background-color: #65a15e;
  color: #ffffff;
  padding: 11px 32px 11px 32px;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  font-size: 16px;
}
.cancelButton {
  float: right;
  background-color: #ffffff;
  color: #65a15e;
  padding: 11px 32px 11px 32px;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #65a15e;
  font-size: 16px;
}

.submitButton:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.react-calendar__navigation {
  height: 28px !important;
}

.calendarBox {
  top: 57%;
  left: 23.5%;
  bottom: 0%;
  right: 42%;
  /* position: absolute; */
}

.req_msg {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #f84142;
  opacity: 1;
}

.calendarBox {
  top: 60%;
  left: 23.5%;
  bottom: 0%;
  right: 42%;
  position: absolute;
}

.req_msg {
  margin-left: 1%;
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #f84142;
  opacity: 1;
}

.titleLabel {
  grid-area: title;
}

.assignLabel {
  grid-area: assign;
}

.dateLabel {
  grid-area: enddate;
}

.descLabel {}

.priorityLabel {
  grid-area: priority;
  display: grid;
  grid-template-columns: 50% 50%;
}

/* .statusLabel {
  grid-area: status;
} */

@media screen and (max-width: 1090px) {
  .gridContainer textarea {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .container span {
    font-family: "poppins";
    font-weight: 600;
    font-size: 18px;
    opacity: 0.85;
    margin-left: 6%;
  }


  .gridContainer {
    margin-top: 0px;
  }

  .gridContainer label {
    margin-top: 20px;
  }

  .gridContainer textarea {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 6px;
    width: 95%;
    /* opacity: 1.7; */
    height: 7.4rem;
    background-color: #F5F6FA;
    font-weight: 500;
    padding: 5px 0px 0px 10px;
    font-weight: 500;
    border: none;
    resize: none;
  }


  .rightSection {
    /* border: 2px solid blue; */
    background-color: #f5f6fa;
    width: 100%;
  }

  .submitButton {
    float: none;

    padding: 10px 50px 10px 50px;
    margin-left: 35%;
  }
}

.react-datepicker__input-container {
  position: fixed;
  display: inline-block;
  border: 1px solid black;
  margin-top: 10px !important;
  width: 362px;
  /* margin-left: 100px; */
}

.react-datepicker-wrapper {
  padding: 0;
  border: 0;
  width: 10%;


}

.frequencyContainer {
  margin-top: 20px;
}

.radioGroup {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.radioLabel {
  margin-right: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.radioLabel input[type="radio"] {
  margin-right: 8px;
  /* Adds some space between the radio button and the label text */
  transform: scale(1.1);
  /* Slightly larger radio buttons */
}

.daysOfWeekContainer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.weekday {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.selectedDay {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.daysOfMonthContainer {
  display: flex;
  width: 100%;
  margin-top: 10px;
  gap: 2px;
}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.day.selectedDay {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}



.datePickerContainer {
  margin-top: 10px;
}

.datePicker {
  width: 100%;
  padding: 8px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}