.confirmPopup {
    width: 23rem;
    height: 18rem;
    padding-top: 2rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
    align-items: center;
}

.image {
    display: flex;
    justify-content: center;
}

.mark_btn {
    /* width: 90px; */
    height: 40px;
    padding: 0px 1rem;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}

.btn {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.container {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    border: 2px solid #289e98;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #289e98;
}