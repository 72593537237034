.addNoteInput {
    width: 20rem;
    height: 7rem;
    padding: 4px;
}

.btnRow{
    margin-top: 1rem;
    text-align: center;
}

.addbtn {
    margin-right: 1rem;
}

.noteHead {
    text-align: center;
    font-size: 16px;
}

.viewNotebtn {
    margin-top: 1rem;
    text-align: center
}