.dashboardOptions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-top: 30px;
  width: 95%;
  margin: 10px auto;
  flex-wrap: wrap;
}

.taskTracker {
  width: 14.6rem;
  height: 8rem;
  border: 1px solid #172a3e;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #fff;
  color: 172a3e;
  color: black;
  font-weight: 600;
}

.taskTracker:hover {
  transform: scale(0.989);
  cursor: pointer;
}
