.cycleList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  margin: 10px auto;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #ffff;
  padding: 10px;
  min-height: 5rem;
}

.thead th {
  width: 300px;
}


.cycleList td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: start;
}

.cycleList .td1 {
  font-size: 12px;
  font-weight: 500;
}

.filteralign {
  display: flex;
  justify-content: flex-end;
  width: 63.4%;
  align-items: center;
}

.cycleList .th1 {
  font-size: 12px;
  font-weight: bold;
  background-color: #dfdfdf;
  text-wrap: nowrap;
  color: black;
  padding: 8px;
}

.pp {
  font-weight: 700;
  align-self: center;
}

.s1 {
  position: relative;
  width: 34%;
}

.twoFilter {
  display: flex;
  justify-content: end;
  margin-right: 0.7rem;
  margin-top: 1rem;
}

.cp_serchIcon2 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 83%;
  width: 21px;
  cursor: pointer;
}

/* MODAL */
.modal {
  width: 25vw;
  padding: 10px;
  background-color: #f5f6fa;
  border-radius: 10px;
  border: 2px solid #562679;
  text-align: center;
}

.date {
  display: flex;
  gap: 10px;
  text-align: left;
}

.title {
  text-align: left;
}

.title input {
  width: 290px;
  height: 35px;
  border: 2px solid #562679;
  padding: 5px;
}

.button {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 5px;
}

.titleIp {
  border-radius: 8px;
  height: 35px;
  border: 2px solid #562679;
  padding: 5px;
}
.cycle {
  color: #562679;
  font-weight: 700;
}

.next {
  color: #2949D3;
}

.current {
  color: #8DC63F;
}

.past {
  color: #E22727;
}

.closed {
  color: #666666;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.open {
  color: #562679;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.upper {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}

.heading {
  font-size: 16px;
  font-weight: 700;
}

.warningHeading {
  font-size: 16px;
  font-weight: 600;
  color: red;
}

.delete {
  background-color: #fff;
  cursor: pointer;
}

.actionButtons {
  display: flex;
  gap: 5px;
}

.typeInput {
  height: 35px;
  padding: 5px;
  border: 2px solid #562679;
  border-radius: 8px;
}

.star {
  color: red;
}

.statusClass {
  cursor: pointer;
}

.cycleDetails table td {
  padding: 5px;
  margin: 1px;
  border: 1px solid gray;
}

.errorText {
  color: red;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
}

.hideError {
  display: none;
}

.showError {
  display: block;
}

.closeWarningModal {
  width: 25vw;
  padding-right: 10px;
  background-color: #f5f6fa;
  border-radius: 10px;
  text-align: center;
}

.totalCount {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #562679;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ppTotal {
  display: flex;
  gap: 10px;
}

.paginationContainer {
  display: flex;
  justify-content: end;
}

.noCycle {
  position: relative;
  left: 290%;
  top: 5px;
}

.appr_btn {
  background-color: #855ea1;
  color: white;
  border: 1px solid #855ea1;
}
.date > .startDate > input[type="date"]::-webkit-calendar-picker-indicator,.date > .endDate > input[type="date"]::-webkit-calendar-picker-indicator {
  background: url('../../../../assets/Appraisal_Cal.png');
  background-size:contain;
  cursor: pointer;
  background-repeat: no-repeat;
}