.container {
  box-shadow: 0 0px 5px 2px rgb(0 0 0 / 20%);
  border-radius: 10px;
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 10px;
  margin: 10px 20px;
}
.hr {
  margin: 0px;
}
.EmpBox_row {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 1.2rem;
  width: auto;
}
.EmpBox_col {
  width: 16%;
}
.EmpBox_label {
  font-size: 14px;
  font-weight: 600;
  color: black;
  text-align: left;
}

.EmpBox_field {
  margin-top: 10px;
  color: #949090;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  margin-bottom: 5px;
  color:#562679 ;

}
.link{
  color: #562679 !important;
  cursor: pointer;
}
.modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 25vh;
  width: 50vw;

}
.heading{
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 700;
}
.header{
  display: flex;
  justify-content: space-around;
  padding: 4px;
  font-weight: 500;
  font-size: 12px;
}
.tdRow{
  display: flex;
  justify-content: space-around;
  padding: 4px;
  font-weight: 400;
  font-size: 12px;
}

.projectDetailsMainDiv{
  border-radius: 8px;
  background:#dfdfdf;
  padding: 10px;
  height: 50vh;
  width: 50vw;
 background-color: #dfdfdf;
}
.noProjects{
  text-align: center;
  margin-top: 10px;
  position:absolute;
  top: 70%;
  left: 32%;
}
.table{
  width: 100%;
  border-collapse: collapse;
}
.th{
  background-color: #f2f2f2;
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}
.td{
  border: 1px solid #dddddd;
  padding: 2px;
  font-size: 14px;
}
.tbody .trBody:nth-child(even) {
  background-color: #f2f2f2;
}
.projectComments{
  position: relative;
  top: -30px;
  left: 135px;
  cursor: pointer;
  font-size: 20px;
}
.projectHeading{
  font-size: 16px;
  font-weight: 700;
}