.totalCount {
    border: 2px solid #C3B14B;
    color: #C3B14B;
    /* border-radius: 50%; */
    min-width: 4rem;
    height: 2rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.4rem;
}

.count {
    font-size: 15px;
    font-weight: bold;
}