.timesheetdate {
  margin-top: 30px;
  margin-left: 14px;
  font-weight: 700;
}
.datetime{
    font-weight: 700;
    padding-left: 17px;
}
.timesheetdate1 {
  margin-top: 30px;
  margin-left: 26px;
}
.datetime1{
    font-weight: 700;
    padding-left: 26px;
}



.DetailView {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  overflow-y: hidden;
  padding-top: 10px;
  font-size: 14px;
  transform: translate(1px, 10px);
}



.ProjectEmployee {
  margin-top: 10px;
}

.cu_col1 {
  flex: 0 0 22%;
}
.cu_label {
  font-size: 15px;
  font-weight: 500;
  padding-left: 4px;
  color: #000000;
}
.mand {
  color: red;
}
.Container {
  width: 95%;
  margin: 0.8rem auto;
  display: flex;
  justify-content: space-between;
}

.copy_expence {
  text-align: center;
  color: var(--invoiceColor);
  font-weight: 500;
  font-size: var(--inoiceHeading);
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  padding-bottom: 0.5rem;
}

.smallInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 90%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  /* margin: 1% 1% 1% 1%; */
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.smallInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 90%;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  /* margin-top: 10px; */
  /* margin: 1% 1% 1% 1%; */
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.label {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
}
.label1 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  margin-left: 43px !important;
}
.label2 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  margin-left: 20px !important;
}
.label_item {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  font-size: 12px;
}

.label_item5 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  font-size: 12px;
  margin-right: 42px !important;
  float: right;
}
.label_item1 {
  font-weight: 500;
  color: var(--invoiceTextColor);
  margin-bottom: 0.5rem !important;
  font-size: 12px;
  margin-left: 30px !important;
}
.radioitem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  /* margin: 10px 0 0; */
}

.radioitem label {
  cursor: pointer;
}

.radioitem input[type="radio"] {
  display: none;
}

.radioitem label {
  font-weight: 600;
}

.radioitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid var(--invoiceColor);
  background-color: transparent;
}

.radioitem input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: var(--invoiceColor);
}
.lastBtn {
  display: flex;
  justify-content: end;
  width: 97.6%;
  grid-column-gap: 2%;
  margin: 2% 0 3% 0;
}
.remove_icon {
  color: #855ea1;
  margin-top: 3%;
  cursor: pointer;
  transform: translate(33px, 9px);
  width: 15px;
}

.errorText {
  color: red;
  font-size: 12px;
  visibility: visible;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
}


.errorText1 {
  color: red;
  font-size: 12px;
  visibility: visible;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
  transform: translate(90px,0px) !important;
}

.errorText2 {
  color: red;
  font-size: 12px;
  visibility: visible;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
  transform: translate(26px,0px) !important;
  width: 120%;
}

.errorText3 {
  color: red;
  font-size: 12px;
  visibility: visible;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
  transform: translate(23px,0px) !important;
}
.errorText4 {
  color: red;
  font-size: 12px;
  visibility: visible;
  /* padding: 0.4rem 0.5rem 0.2rem 0.5rem; */
  transform: translate(16px,0px) !important;
  width: 121%;
}

.errorText5 {
  color: red;
  font-size: 12px;
  visibility: visible;
  transform: translate(41px,5px) !important;
  width: 120%;

}

.errorText6 {
  color: red;
  font-size: 12px;
  visibility: visible;
  width: 120%;
  transform: translate(31px, 5px);
}



.errorTextHidden {
  color: red;
  font-size: 12px;
  margin-left: 15px;
  visibility: hidden;
  /* padding: 0.4rem 0.5rem 0.2rem 0.5rem; */
  width: 121%;
}

.buttonAdd {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
}

.buttonAdd2 {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  margin: 10px 23px 10px 0px;
}
.EditAdd2{
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  margin: 24px -2px 10px 0px;
}
.buttonCancle {
  width:6rem;
  height: 40px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
}

.Container {
  width: 97%;
  margin: 0.8rem auto;
  display: flex;
  justify-content: space-between;
}

.col1 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col12 {
  width: 23.5%;
}

.col2 {
  width: 23.5%;
}

.col3 {
  width: 23.5%;
}

.col4 {
  width: 23.5%;
}

.col212 {
  width: 48.5%;
}

.col22 {
  width: 48.5%;
}
.col12_item {
  width: 20%;
}
.col2_item {
  width: 20%;
  transform: translate(-57px, 0px);
}
.col3_item {
  width: 13%;
}
.col4_item {
  width: 13%;
}
.col5_item {
  width: 13%;
}
.col6_item {
  width: 13%;
}
.col7_item {
  width: 13%;
}
.col8_item {
  width: 0%;
  margin: 3% 0 0 0;
}
.lastBtn_item {
  display: flex;
  justify-content: end;
  width: 96.4%;
}

.btnAddItem{
  float: right;
  transform: translate(-9px, -12px);
  padding: 5px 5px 5px 5px;
  height: 10px;
  font-size: 12px;
}
.ViewsheetcolorPos {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  overflow-y: hidden;
  padding-top: 10px;
  font-size: 14px;

}
.ViewEmployeeDetails {
  display: flex;
  justify-content: center;
  color: #9c58a5;
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  width: 64rem;
  position: absolute;
  
}
.projectheight {
  margin-top: 10px;
}

.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 70%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 15px;
  padding-left: 10px;
  margin-left: 16px;
  outline: none;
  padding-top: 5px;
}

.tableList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 8rem;
  /* overflow-y: scroll; */
  /* margin-top: 13px; */
  /* padding: 10px; */
  margin-left: 27px;
  margin-right: 27px;
  overflow-x: auto;
  overflow-y: hidden;
  /* box-sizing: border-box; */
}

.th {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  padding: 0px 0.5rem;
  /* min-width: 85rem; */
  gap: 1rem;
  box-sizing: border-box;
  white-space: nowrap;
  margin-top: 18px;
}

.th:last-child {
  border-bottom: none;
}

.th p:nth-child(1) {
  width: 11rem;
  color: #666666;
  font-weight: 700;
  font-size: 12px;
}

.th p:nth-child(2) {
  width: 7rem;
  color: #666666;
  font-weight: 700;
  font-size: 12px;
}

.th p:nth-child(3) {
  width: 7rem;
  color: #666666;
  font-weight: 700;
  font-size: 12px;
  transform: translate(18px, 0px);
}

.th p:nth-child(4) {
  width: 7rem;
  color: #666666;
  font-weight: 700;
  font-size: 12px;
  transform: translate(16px, 0px);
}

.th p:nth-child(5) {
  width: 6rem;
  color: #666666;
  font-weight: 700;
  font-size: 12px;
}

.th p:nth-child(6) {
  width: 8rem;
  color: #666666;
  font-weight: 700;
  font-size: 12px;
  text-align: start;
}

.th p:nth-child(7) {
  width: 5.5rem;
    color: #666666;
    font-weight: 700;
    transform: translate(3px, 0px);
  font-size: 12px;
}

.th p:nth-child(8) {
  width: 0rem;
  color: #666666;
  font-weight: 700;
  font-size: 12px;
  transform: translate(20px, 0px);
}

.th p:nth-child(9) {
  width: 3rem;
  color: #666666;
  font-weight: 700;
  font-size: 12px;
}

.th p:nth-child(10) {
  width: 8rem;
  color: #666666;
  font-weight: 700;
  font-size: 12px;
}

.th p:nth-child(11) {
  width: 2rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}
.th p:nth-child(12) {
  width: 3rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}
.td {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 28px;
  padding: 0px 0.5rem;
  /* min-width: 85rem; */
  gap: 1rem;
  box-sizing: border-box;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 12rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(2) {
  width: 9rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(3) {
  width: 8rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  transform: translate(-9px, 0px);}

.td p:nth-child(4) {
  width: 7rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  transform: translate(-22px, 0px);
}

.td p:nth-child(5) {
  width: 5rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(6) {
  width: 8rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  text-align: start;
  transform: translate(-10px, 0px);
}

.td p:nth-child(7) {
  width: 5.5rem;
    color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(8) {
  width: 2rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  padding-left: 12px;
}

.td p:nth-child(9) {
  width: 1rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(10) {
  width: 8rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(11) {
  width: 2rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}
.td p:nth-child(12) {
  width: 3rem;
  color: #666666;
  font-weight: 400;
  font-size: 12px;
}
.Approved {
  float: right;
  transform: translate(-45px, 0px);
}

.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-top: 10px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.buttonAdd {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width:6rem;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  margin-bottom: 15px;
}


.buttonAdd4 {
  float: right;
    margin-left: 55px;
    margin-right: 26px;
    background-color: #9c58a5;
    border-color: #9c58a5;
    width: 81px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.buttonCancle4 {
  width: 81px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  position: relative;
  left: 53%;
  margin-top: -38px;
  margin-bottom: 25px;
}



.DetailView {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  /* height: 500px; */
  overflow-y: hidden;
  padding-top: 10px;
  font-size: 14px;
}
.ViewEmployeeDetails {
  display: flex;
  justify-content: center;
  color: #9c58a5;
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}

.buttonAdd3 {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 62px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  position: relative;
  margin-top: 20px;
  left: 72%;
  margin-bottom: 10px;
}