.createTask {
  margin-top: 15px;
  border-collapse: collapse;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0px 3px 6px rgb(0 0 0 / 5%);
  transition: 0.3s;
  border-radius: 5px;
  padding: 16px;
  display: block;
}
.container {
  margin-left: 2%;
  width: 96%;
  margin-top: 15px;
}
.members{
  margin-top: 0.5rem;
  font-weight: 600;
  margin-bottom: 8px;
}
.existingMembersList{
  display: flex;
  justify-content: space-between;
}
.memberName{
  width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.memberEmail{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20rem;
}
.notify{
  margin-bottom: 0.5rem;
}
.role{
  width: 6rem;
}
.twoButtons{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 1rem;
}
.cancelButton {
  background-color: #ffffff;
  color: #65a15e;
  border-radius: 5px;
  padding: 0.5rem;
  border: 2px solid #65a15e;
}
.twoButtonsAdd{
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 1rem;
}
.errorTextHidden {
  visibility: hidden;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}

.errorText {
  visibility: visible;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}
.memberModalHeading{
  display: flex;
  justify-content: center;
  width: 23rem;
  font-size: 24px;
  font-weight: 600;
}
.doneButton{
  width: 60px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  background-color: #65a15e;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.deleteModalBtns{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
}
.cancelButton{
  width: 60px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  background-color: #ffffff;
  outline: none;
  color: #65a15e;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.modal_group_member {
  width: 25rem;
  min-height: 8rem;
  border-radius: 5px;
  padding: 1rem;
  background: #fff;
}
.modal_group_member2 {
  min-height: 8rem;
  border-radius: 5px;
  padding: 1rem;
  background: #fff;
}
.saveButton{
  width: 60px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
    background-color: #65a15e;
    color: #ffffff;
    padding: 0.5rem;
}
.goBack{
  background-color: white;
  border-radius: 9px;
  height: 38px;
  width: 5rem;
  color: #65a15e;
  border: 1px solid #65a15e;
  margin-top: 20px;
}
.paginationalign{
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
}