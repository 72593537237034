.main_client_body {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.main_create {
  min-height: 100vh;
  width: 80%;
}

.Exit {
  width: 95%;
  margin: 10px auto;
  font-weight: 700;
  font-size: 1rem;
}

.UsersList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 4rem;
  overflow-x: auto;
  position: relative;
}

.th,
.td {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 0.5rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}



.th {
  background: #f0f0f0;
  border-radius: 10px;
}

.th p:nth-child(1) {
  font-weight: 600;
  width: 6rem;
  font-size: 14px;
  color: #666666;
}

.th p:nth-child(2) {
  font-weight: 600;
  width: 9rem;
  font-size: 14px;
  color: #666666;
}

.th p:nth-child(3) {
  font-weight: 600;
  width: 12rem;
  font-size: 14px;
  color: #666666;
}

.th p:nth-child(4) {
  font-weight: 600;
  width: 13rem;
  font-size: 14px;
  color: #666666;
}

.th p:nth-child(5) {
  font-weight: 600;
  width: 14rem;
  font-size: 14px;
  color: #666666;
}

.th p:nth-child(6) {
  font-weight: 600;
  width: 6rem;
  font-size: 14px;
  color: #666666;
}

.td p:nth-child(1) {
  font-weight: 400;
  width: 6rem;
  font-size: 14px;

}

.td p:nth-child(2) {
  font-weight: 400;
  width: 9rem;
  font-size: 14px;
  color: #666666;
  text-overflow: ellipsis;
}

.td p:nth-child(3) {
  font-weight: 400;
  width: 12rem;
  font-size: 14px;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}

.td p:nth-child(4) {
  font-weight: 400;
  width: 13rem;
  font-size: 14px;
  color: #666666;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(5) {
  font-weight: 400;
  width: 14rem;
  font-size: 14px;
  color: #666666;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td p:nth-child(6) {
  font-weight: 400;
  width: 6rem;
  font-size: 14px;
  color: #666666;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.title {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.topFlex {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 10px auto;
  align-items: center;
}

.f1 {
  position: relative;
  width: 230px;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 88%;
  width: 21px;
  cursor: pointer;
}

.f1Input {
  border-radius: 9px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  min-width: 99%;
  height: 38px;
  border: 1px solid #00000030;
  padding: 0 8px;
  outline: none;
}

.dropdownContainer {
  width: 32rem;
  display: flex;
  gap: 10px;
}

.dropdownContainer1 {
  width: 16rem;
}