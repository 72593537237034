.inputBox {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 5px;
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
    width: 100%;
}



.list {
    display: flex;
    gap: 10px;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
}

.vh {
    position: absolute !important;
    /* clip: rect(1px, 1px, 1px, 1px); */
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

/*  Add a :before to each label and style this as the checkbox you want to have */

.label:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #f2f2f2;
    border: 1px solid rgba(75, 101, 132, 0.3);
    display: inline-block;
    margin-right: 3px;
}

/*  Change the styling of the :before when the hidden checkbox is checked, you also can add a background-image  */

.list input[type="checkbox"]:checked~label:before {
    background: #20bf6b no-repeat center;
    background-size: 12px 12px;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

/*  Show when label and checkbox have focus, to make this keyboard accessible > press spacebar to select the checkbox */

.list input[type="checkbox"]:focus~label {
    color: #20bf6b;
}



.upload_resume::-webkit-file-upload-button {
    border: none;
    padding: 5px -10px;
    height: 38px;
    /* width: 70px; */
  }
  
  .upload_resume {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    /* padding-left: 10px; */
    outline: none;
  }
  
  .uploadFiles input[type="file"]::file-selector-button {
    border: 2px solid #65a15e;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #65a15e;
    transition: 1s;
    color: #ffffff;
  }
  
  .cu_label {
    font-size: 15px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
  }
.rmpdf {
    width: 300px;
    border: 1px solid black;
    border-radius: 10px;
    text-align: start;
    font-size: 15px;
    color: #65a15e;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding: 5px;
  }
  
  .rmpdfcross {
    color: black;
    border: 1px solid #000000;
    background-color: #65a15e;
    position: absolute;
    right: -2%;
    top: 25%;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
  }
  .errorText {
    color: red;
    font-size: 12px;
    margin-left: 2px !important;
    visibility: visible;
    padding-top: 5px;
  }
  
  
  
  .errorTextHidden {
    color: red;
    font-size: 12px;
    margin-left: 2px !important;
    visibility: hidden;
    padding-top: 5px;
    padding-left: 5px;
  }