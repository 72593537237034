@import '../../../pageLayout/mixing/MixingScss.module.scss';

.mainHeading {
    font-weight: 500;
    width: 95%;
    margin: 0 auto !important;
}

.th1 {
    display: flex;
    justify-content: space-between;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 50px;
    font-size: var(--atsFontSize);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.th1 p:nth-child(1) {
    @include headerTH(14);
    padding-left: 0.4rem;
    position: sticky;
    top: 0;
    left: 0.1rem;
    background-color: #f5f7f9;

}

.th1 p:nth-child(2) {
    @include headerTH(11)
}

.th1 p:nth-child(3) {
    @include headerTH(11)
}

.th1 p:nth-child(4) {
    @include headerTH(11)
}

.th1 p:nth-child(5) {
    @include headerTH(11)
}



.td1 {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    font-size: var(--atsFontSize);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.td1 p:nth-child(1) {
    @include TableDataTD(14);
    padding-left: 0.4rem;
    cursor: pointer;
    position: sticky;
    top: 0;
    left: 0.1rem;
    background-color: #fff;
    // text-decoration: underline;
}



.td1 p:nth-child(2) {
    @include TableDataTD(11)
}

.td1 p:nth-child(3) {
    @include TableDataTD(11)
}

.td1 p:nth-child(4) {
    @include TableDataTD(11)
}

.td1 p:nth-child(5) {
    @include TableDataTD(11)
}

.th {
    @include header(105);
    border-bottom-left-radius: 0px; 
    border-top-left-radius: 0px; 
}

.th p:nth-child(1) {
    @include headerTH(8);
    padding-left: 0.1rem;
    position: sticky;
    top: 0;
    left: 0rem;
    background-color: #f5f7f9;

}

.th p:nth-child(2) {
    @include headerTH(13)
}

.th p:nth-child(3) {
    @include headerTH(2)
}

.th p:nth-child(4) {
    @include headerTH(13)
}

.th p:nth-child(5) {
    @include headerTH(12)
}

.th p:nth-child(6) {
    @include headerTH(12)
}

.th p:nth-child(7) {
    @include headerTH(12)
}

.th p:nth-child(8) {
    @include headerTH(6);
    // text-align: center;
}

.th p:nth-child(9) {
    @include headerTH(6);
    // text-align: center;
}

.th p:nth-child(10) {
    @include headerTH(6);
    text-align: center;
}

.th p:nth-child(11) {
    @include headerTH(10);
    text-align: center;
}

.th p:nth-child(12) {
    @include headerTH(4)
}


.td {
    @include tableData(105);
    border-bottom-left-radius: 0px; 
    border-top-left-radius: 0px; 
}

.td p:nth-child(1) {
    @include TableDataTD(8);
    padding-left: 0.1rem;
    cursor: pointer;
    color: blue;
    position: sticky;
    top: 0;
    left: 0rem;
    background-color: #fff;
    text-decoration: underline;
}


.td p:nth-child(2) {
    @include TableDataTD(13)
}

.td p:nth-child(3) {
    @include TableDataTD(2)
}

.td p:nth-child(4) {
    @include TableDataTD(13)
}

.td p:nth-child(5) {
    @include TableDataTD(12)
}

.td p:nth-child(6) {
    @include TableDataTD(12)
}

.td p:nth-child(7) {
    @include TableDataTD(12)
}

.td p:nth-child(8) {
    @include TableDataTD(6);
}

.td p:nth-child(9) {
    @include TableDataTD(6);
}

.td p:nth-child(10) {
    @include TableDataTD(6);
    text-align: center;

}

.td p:nth-child(11) {
    @include TableDataTD(10);
    text-align: center;

}

.td p:nth-child(12) {
    @include TableDataTD(4);
    cursor: pointer;
}

.noData {
    text-align: center;
}

.containerNotes {
    width: 30rem;
    min-height: 5rem;
    max-height: 25rem;
    overflow: auto;
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
}

.content {
    min-height: 5rem;
}

.btnNotes {
    text-align: center;
}

.pagination {
    margin-top: -20px;
    margin-right: 30px;
}

.dropdowns {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    // justify-content: end;
    // width: 95%;
    // margin: 0 auto;
}

.filterContainer {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 95%;
}

.s1 {
    position: relative;
    width: 20rem;
    margin-left: 0.6rem;
}

.s2 {
    position: relative;
    width: 20rem;
    margin-left: 0rem;
}

.cp_serchIcon {
    position: absolute;
    z-index: 100;
    top: 15%;
    margin-left: 84%;
    width: 21px;
    cursor: pointer;
}

.coreSkillInput {
    border-radius: 5px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    width: 100%;
    height: 38px;
    border: 1px solid #00000061;
    padding: 0 8px;
    outline: none;
}

.coreSkillInput::placeholder {
    color: hsl(0deg 2% 18%);
}

.countData {
    border: 2px solid #65a15e;
    padding: 0px 7px;
}

.modal_container_watchlist2 {
    width: 60rem;
  }