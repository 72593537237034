.main_create {
  width: 80%;
  /* height: auto; */
}

.cp_rowcol122 a {
  color: #3535e1 !important;
}

.errorTextHidden {
  visibility: hidden;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 12px !important;
  font-weight: 500;
  margin-left: 15px;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.errorText {
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 12px !important;
  font-weight: 500;
  margin-left: 15px;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}

.cp_cotainer {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  width: 95%;
  margin: 5px 15px;
  overflow-y: hidden;
}

.main_client_body {
  width: 100%;
  /* height: 90vh; */
  display: flex;
}

.cp_heading {
  font-size: 12px !important;
  font-weight: 600;
  margin: 10px auto;
  width: 95%;
  padding: 10px;
}

.createPositionLeft {
  width: 50%;
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 5rem;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 30px;
  margin-right: 20px;
  /* padding: 0px 10px; */
}

.createPositionRight {
  width: 45%;
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 5rem;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 30px;
  /* padding: 0px 10px; */
}

.createposition {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 5rem;
  margin-left: 30px;
  margin-right: 30px;
  /* padding: 0px 10px; */
}

.multiinput {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no_data {
  text-align: center;
}

.cu_fieldReasone {
  /* width: 50%; */
  /* height: auto; */
  margin-top: 10px;
  padding-left: 5px;
  /* padding: 7px 5px 10px 6px; */
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  font-size: 12px;
}

.loading{
  font-size: "24px";
  font-weight: "bold";
  padding-left: "38%";
  position: "absolute";
  color: "#289e98";
  margin-top: "-4px";
  font-style: "italic";
  margin-left: "33.5%";
}

.vieUser {
  font-weight: 700;
  font-size: 15px !important;
  padding-left: 30px;
}

.cp_yoe {
  display: flex;
}

.cp_field {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 80%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  padding-left: 10px;
  outline: none;
}

.cp_field2 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  /* flex: ; */
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  padding-left: 10px;
  outline: none;
}

.cp_field3 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 98.5%;
  /* flex: ; */
  height: 130px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  padding-left: 10px;
  outline: none;
}

.rowContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rowContainerHeading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 0.4rem;
  padding-top: 15px;
}

.rowContainerNew {
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.rowContainerSalaryTable{
  display: flex;
  justify-content: left;
  font-size: 15px !important;
  padding-left: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.salaryBreakupButton{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.rowContainernew {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 0.4rem;
  padding-top: 10px;
}

.cp_label {
  font-size: 14px !important;
  font-weight: 500;
  padding-left: 4px;
}

.cp_col1 {
  /* width: 29%;
  margin: 0px 1.5%; */
  width: 20rem;
}

.cp_col2 {
  /* width: 30.5%;
  margin: 0px 3%; */
  position: relative;
  width: 20rem;
}

.cp_col3 {
  /* width: 30.5%;
  margin: 0px 1.5%; */
  position: relative;
  width: 20rem;
}

.cp_row2 {
  margin-top: 20px;
}

.cp_rowcol1 {
  /* width: 28.5%;
  margin: 0px 2.8% 0px 1.5%; */
  width: 25%;
}

.cp_rowcol122 {
  /* width: 28.5%;
  margin: 0px 2.8% 0px 1.5%; */
  width: 24%;
}

.cp_rowcol123 {
  /* width: 28.5%;
  margin: 0px 2.8% 0px 1.5%; */
  width: 21%
}

.cp_rowcol2 {
  /* width: 28.8%;
  margin: 0px 5% 0px 1.7%; */
  width: 20rem;
}

.cp_rowcol3 {
  /* width: 31%;
  margin: 0px 0.5%; */
  width: 20rem;
}

.cp_rowcol31 {
  width: 29%;
  margin: 0px 2% 0px 2%;
}

.cp_rowcol32 {
  width: 30%;
  margin: 0px 3% 0px 1%;
}

.star {
  color: red;
  font-size: 12px !important;
  margin-top: -10px;
}

.rowContainer3 {
  margin-left: 1.5%;
}

.cp_button {
  display: flex;
  justify-content: flex-end;
  margin: 12px 30px 500px 0px;

  /* justify-content: space-around; */
}

.cp_cancel {
  margin: 5px;
  padding: 5px 10px;
}

.cp_submit {
  margin: 5px;
  padding: 5px 10px;
}

.create_position__control {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 30%;
  height: 32px !important;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  outline: none;
  margin: 0 0 20px;
}

.create_position__indicators {
  display: none;
}

.create_position__placeholder {
  display: none;
}

.create_position__menu {
  margin-top: -15px;
}

.cp_dropdown {
  position: absolute;
  z-index: 1000;
  top: 48%;
  margin-left: 90%;
  width: 20px;
}

.cp_value {
  color: gray;
  padding-left: 6px;
  font-size: 14px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cp_value_salary_details {
  color: gray;
  padding-left: 4px;
  font-size: 14px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cp_value_Notes {
  color: gray;
  padding-left: 6px;
  font-size: 14px !important;
}

.inner_card {
  margin: 15px;
}

.inActive_btn {
  width: 150px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.assignClient {
  width: 125px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.Exit_btn {
  width: 180px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.back_btn {
  width: 80px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.Salary {
  width: auto;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  text-wrap: nowrap;
}

.inActive_btn:disabled {
  background-color: gray;
  color: white;
  border: 1px solid gray;
}

.inActive_btn:disabled {
  opacity: 1.7;
  cursor: not-allowed;
}

.active_btn {
  width: 140px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.delete_btn:disabled {
  background-color: gray;
  color: white;
  border: 1px solid gray;
  font-size: 12px;
}

.delete_btn:disabled {
  opacity: 1.7;
  cursor: not-allowed;
}

.delete_btn {
  width: 90px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.mark_btn {
  /* width: 90px; */
  height: 40px;
  padding: 0px 1rem;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.delete_btn1 {
  width: 180px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.unResigned {
  width: 130px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
}

.unResigned_btn {
  width: 90px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.edit_btn {
  width: 90px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  /* margin-right: 10px; */
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.edit_btn:disabled {
  background-color: gray;
  color: white;
  border: 1px solid gray;
}

.edit_btn:disabled {
  opacity: 1.7;
  cursor: not-allowed;
}

.inActive_btn {
  width: 150px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.inActive_btn:disabled {
  background-color: gray;
  color: white;
  border: 1px solid gray;
}

.container {
  margin-top: 10px;

  /* float: right;
  text-align: right; */
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
}

.container1 {
  float: right;
  margin-left: 30px;
  margin-right: 21px;
}

.container button {
  display: inline-block;
}

.confirmPopupTitle {
  /* text-align: center; */
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin-top: -35px;
  margin-right: 124px;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 31%;
  margin-left: 66%;
  width: 18px;
  cursor: pointer;
}

.cp_crossIcon {
  position: absolute;
  z-index: 100;
  top: 31%;
  margin-left: 66%;
  width: 15px;
  cursor: pointer;
}

.confirmPopupTitle1 {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin-top: -35px;
  margin-right: 160px;
}

.error {
  color: red;
  font-weight: 500;
  padding-top: 4px;
  padding-left: 4px;
}

.csd {
  width: 68%;
  height: 38px;
  font-weight: 500;
  box-shadow: 0px 3px 9px #00000029 !important;
  margin: 8px 1% 0px 1.5%;
  border-radius: 9px;
  background-color: #f5f6fa;
}

.yesBtn {
  padding: 5px 30px;
  outline: none;
  background: rgb(252, 187, 8) 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  color: #fff;
  opacity: 1;
  font-size: 12px;
}

.noBtn {
  margin-left: 10px;
  padding: 5px 30px;
  outline: none;
  font: normal normal 600 18px/27px Poppins;
  background: #fff;
  letter-spacing: 0px;
  color: rgb(252, 187, 8);
  opacity: 1;
  border: 1px solid rgb(252, 187, 8);
  border-radius: 6px;
  font-size: 12px;
}

.btnCont {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 12px;
}

.confirmPopup {
  /* top: 441px;
left: 767px;*/
  width: 386px;
  height: 198px;
  /* text-align: center; */
  padding-top: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.confirmPopup1 {
  width: 386px;
  min-height: 249px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
  max-height: 600px;
  overflow: auto;
}

.exitTitle {
  padding: 1rem 0px 0px 3rem;
  font-size: 12px !important;
}

.confirmPopupLead {
  /* top: 441px;
left: 767px;*/
  width: 386px;
  height: 198px;
  /* text-align: center; */
  padding-top: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.confirmPopupTitleLead {
  /* text-align: center; */
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin-top: -35px;
  margin-left: 2rem;
}

.cp_value a {
  color: #289e98 !important;
}

.Cu_row2 {
  margin: 1% 1% 2% 1.5%;
  width: auto;
}

.title {
  display: flex;
  justify-content: left;
  color: #289e98;
  font-size: 15px !important;
  font-weight: bold;
  padding-left: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Cu_row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 1%; */
  margin: 1% 1% -2%;
  width: auto;
}

.Cu_row2 {
  /* margin: 1% 1% 2% 1%; */
  width: auto;
}

.cu_label {
  font-size: 12px !important;
  font-weight: 500;
  padding-left: 4px;
  color: #000000;
}

.textarea {
  width: 84%;
  outline: none;
}

.textarea:disabled {
  background: #8f8b8b69;
}

.cu_label {
  font-size: 12px !important;
  font-weight: 500;
  padding-left: 4px;
  color: #000000;
}

.cu_fieldReasone {
  /* width: 50%; */
  /* height: auto; */
  padding: 0px 5px 5px 5px;
  /* padding: 7px 5px 10px 6px; */
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  font-size: 14px;
}

.candidateDetails {
  text-align: center;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  padding: 0.5rem;
  color: var(--atsColor);
  font-weight: 600;
}

.noData {
  text-align: center;
}

.Cu_row1 {
  display: flex;
  justify-content: space-between;
  /* margin: 0 1%; */
  margin: 0px 1rem;
  /* padding-top: 0.5rem; */
  width: auto;
}

.cu_col1 {
  flex: 0 0 23%;
  width: 23%;
}

.cu_col2 {
  flex: 1 0 29%;
  width: 29%;
}

.cu_col3 {
  flex: 0 0 25%;
  width: 25%;
}

.cu_col4 {
  flex: 0 0 23%;
  width: 23%;
}

.cu_label {
  font-size: 12px !important;
  font-weight: 500;
  padding-left: 4px;
  color: #242424;
}

.cu_field2, .cu_filed23 {
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  padding: 2px 5px 7px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.label {
  font-size: 14px !important;
  font-weight: 500;
  /* padding-left: 4px; */
  color: #242424;
}

.errorTextHidden {
  visibility: hidden;
  color: red;
  font-size: 12px !important;
  margin-left: 15px;
  padding-top: 2px;
}

.errorText {
  visibility: visible;
  color: red;
  font-size: 12px !important;
  margin-left: 15px;
  padding-top: 2px;
}

.cross {
  position: absolute;
  right: -5px;
  top: -16px;
  cursor: pointer;
}

.bgvcantainer {
  margin: 0.4rem;
  margin-top: 0px;
  position: relative;
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
}

.bgvrow1 {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}

.bgvrow1 div:nth-child(1) {
  width: 14rem;
}

.bgvrow1 div:nth-child(2) {
  width: 9rem;
}

.bgvrow1 div:nth-child(3) {
  width: 11rem;
}

.bgvrow1 div:nth-child(4) {
  width: 2rem;
  display: flex;
  padding-top: 1rem;
  cursor: pointer;
}

.reset {
  cursor: pointer;
}

.bgvrow1 div:nth-child(5) {
  width: 6rem;
  position: relative;
}

.bgvrow1 div:nth-child(6) {
  width: 17rem;
}

.bgvInput {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 97%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 9px;
  padding-left: 10px;
  outline: none;
}

.bgvdoc {
  /* box-shadow: 0px 3px 9px #00000029 !important; */
  /* border-radius: 9px;
    opacity: 1;
    width: 97%;
    height: 38px; */
  /* background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa; */
  border: none;
  margin-top: 9px;
  /*  padding-left: 10px;
    outline: none; */
}

.fileInputLabel {
  display: inline-block;
  width: 40px;
  height: 35px;
  background-image: url("../../../../assets/fileUpload.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  /* transform: rotate(90deg); */

  margin-left: 1rem;
}

.fileInput {
  display: none;
}

.rmpdf {
  color: #0089ff;
  border: 1px solid var(--atsColor);
  width: auto;
  width: 10px;
  border-radius: 10px;
  padding: 5px;
  padding-right: 50px;
  color: #65a15e;
}

.rmpdfcross {
  position: absolute;
  left: 50px;
  top: -8px;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 12px !important;
  color: red;
  font-weight: 700;
}

.rmpdfcross1 {
  position: absolute;
  left: 54px;
  top: -2px;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 12px !important;
  color: red;
  font-weight: 700;
}

.radiocantainer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding-top: 0.6rem;
}

.bgvbutton {
  display: flex;
  justify-content: end;
  padding-right: 1rem;
}

.modal_container1 {
  min-width: 18rem;
  max-width: 40rem;
  min-height: 8rem;
  border-radius: 5px;
}

.textM {
  text-align: center;
}

.bgvbutton1 {
  display: flex;
  justify-content: end;
}

.M_button {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.M_button button {
  /* width: 1px; */
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}

.bgvrow2 {
  width: 100%;
}

.radioitem {
  display: inline-block;
  position: relative;
  width: inherit;
  font-size: 12px;
  /* padding: 0 6px; */
  /* margin: 10px 0 0; */
}

.radioitem label {
  cursor: pointer;
}

.radioitem input[type="radio"] {
  display: none;
}

.radioitem label {
  font-weight: 600;
}

.radioitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #65a15e;
  background-color: transparent;
}

.radioitem input[type="radio"]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: #65a15e;
}

/* Disabled radio item */
.radioitem input[type="radio"]:disabled+label:before {
  border: 2px solid #ccc;
  /* Change the border color for disabled */
  cursor: not-allowed;
  /* Change cursor to not-allowed for disabled */
}

/* Checked and disabled radio item */
.radioitem input[type="radio"]:checked:disabled+label:after {
  background-color: #ccc;
  /* Change the background color for checked and disabled */
  border: 2px solid #ccc;
  /* Change the border color for checked and disabled */
  cursor: not-allowed;
  /* Change cursor to not-allowed for checked and disabled */
}

/* Additional styling for disabled label text (optional) */
.radioitem input[type="radio"]:disabled+label {
  color: #ccc;
  /* Change the text color for disabled */
}

.bgvbutton {
  display: flex;
  justify-content: end;
  padding-right: 1rem;
}

.bgvbutton button {
  /* width: 1px; */
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  margin-bottom: 1rem;
  font-size: 12px;
}

.th {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f9;
  border-radius: 5px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  padding: 0px 1rem;
}

.th div:nth-child(1) {
  width: 12rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
  /* padding-left: 8px; */
}

.th div:nth-child(2) {
  width: 5rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
  /* padding-left: 8px; */
}

.th div:nth-child(3) {
  width: 8rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
}

.th div:nth-child(4) {
  width: 7rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
}

.th div:nth-child(5) {
  width: 28rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
}

.th div:nth-child(6) {
  width: 1.5rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
}

.th div:nth-child(7) {
  width: 1.5rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
}

.td {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  align-items: center;
  height: 45px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 0px 1rem;
}

.td:last-child {
  border-bottom: none;
}

.td div:nth-child(1) {
  width: 12rem;
  text-align: start;
  font-size: 14px !important;
  font-weight: 400;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* overflow: hidden; */
  position: relative;
}

.td div:nth-child(2) {
  width: 5rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td div:nth-child(3) {
  width: 8rem;
  text-align: start;
  font-size: 14px !important;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}

.td div:nth-child(4) {
  width: 7rem;
  /* text-align: center; */
  padding-left: 20px;
  font-size: 14px !important;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td div:nth-child(5) {
  width: 28rem;
  text-align: start;
  font-size: 14px !important;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td div:nth-child(6) {
  width: 1.5rem;
  text-align: start;
  font-size: 14px !important;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.td div:nth-child(7) {
  width: 1rem;
  text-align: start;
  font-size: 14px !important;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.salaryth {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  padding: 0px 0.5rem;
}

.salaryth p:nth-child(1) {
  width: 8rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
  /* padding-left: 8px; */
}

.salaryth p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
  /* padding-left: 8px; */
}

.salaryth p:nth-child(3) {
  width: 6rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
}

.salaryth p:nth-child(4) {
  width: 32rem;
  text-align: start;
  font-size: 14px !important;
  color: #242424;
  font-weight: 600;
}

.salarytd {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  height: 45px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 0px 0.5rem;
}

.salarytd p:nth-child(1) {
  width: 8rem;
  text-align: start;
  font-size: 14px !important;
  font-weight: 400;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

.salarytd p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 14px !important;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

.salarytd p:nth-child(3) {
  width: 6rem;
  text-align: start;
  font-size: 14px !important;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 0.5rem;
}

.salarytd p:nth-child(4) {
  width: 32rem;
  text-align: start;
  font-size: 14px !important;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.salaryHistoryHeading {
  display: flex;
  justify-content: center;
  color: #289e98;
  font-size: 14px !important;
  font-weight: bold;
}

.salaryTable {
  /* box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%); */
}

.salaryTableHeader {
  background-color: rgb(240, 240, 240);
  position: sticky;
  top: 0px;
  /* z-index: 8; */
}

.salaryTableHeader th {
  background-color: rgb(240, 240, 240) !important;
  color: #666666;
  font-size: 12px;
  white-space: nowrap;
}

.salaryTable td {
  color: #666666;
  font-size: 12px;
  white-space: nowrap;
}

.salaryTable tr:last-child {
  border-bottom: 0px solid transparent !important;
}

.salaryTable tr {
  border-top: 0px solid transparent !important;
}

.salaryTable> :not(:first-child) {
  border-top: 0px solid transparent !important;
}

.salaryTable th:nth-child(1),
.salaryTable td:nth-child(1),
.salaryTable th:nth-child(2),
.salaryTable td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}

.salaryTable th:nth-child(2),
.salaryTable td:nth-child(2) {
  left: 94px;
  z-index: 2;
}

.containerNotes {
  width: 30rem;
  min-height: 5rem;
  /* overflow: auto; */
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
  z-index: 999 !important;
}

.content {
  min-height: 5rem;
  word-wrap: break-word;
}

.btnNotes {
  text-align: center;
  margin-top: 5px;
}