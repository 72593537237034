.conatiner {
  background-color: #ffffff;
  width: 96%;
  margin-left: 1%;
  box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-top: 10px;
  overflow-y: auto;
  height: 86%;
}
.mainSection {
  width: 100%;
  height: 100vh;
  position: absolute;
  bottom: 0;
  display: flex;
}

.rightSection {
  /* border: 2px solid blue; */
  width: 80%;
  background-color: rgb(246, 250, 243);
}

.taskAssigned {
  background-color: #ffffff;
  width: 96%;
  margin-left: 1%;
  box-shadow: 0 4px 8px 8px rgba(66, 65, 65, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow-y: auto;
}
.rowAlign{
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}
.grpus{
    width: 100%;
    font-size:1rem;
    padding: 12px 32px 8px 12px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.loader {
  width: 17%;
  margin: 9% 13% 0% 42%;
}

.loaderPara {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  color: #65a15e;
  margin-top: -8%;
  transform: translateY(-10%);
  font-style: italic;
  padding-left: 36%;
  margin-left: 34.5%;
}
.noDataFound{
  display: flex;
  justify-content: center;
  padding: 38px;
  font-size: 22px;
  font-weight: 600;
}
.droupsData {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 8rem;
  width: 12rem;
  box-shadow: 0px 0px 30px rgb(207, 197, 197);
  border-radius: 5px;
  margin: 25px 15px;
}
.customCol{
    padding: 10px;
}
.groupName{
  display: flex;
  height: 45px;
  width: 192px;
  padding: 0px 5px;
  font-weight: 700;
  font-size: 16px;
}
.groupNameChild{
  flex: 1;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.countData{
  display: flex;
  justify-content: space-between;
}
.count1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 93px;
  height: 59px;
  align-items: center;
  background-color: #65a15e;
  border-bottom-left-radius: 5px;
  color: #ffffff;
}
.count2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 93px;
  height: 59px;
  align-items: center;
  background-color: #65a15e;
  border-bottom-right-radius: 5px;
  color: #ffffff;
}
.imgContainer{
  display: flex;
  justify-content: flex-end;
  height: 20px;
}
.addSpaceBtn{
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  background-color: #65a15e;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.memberModalHeading{
  display: flex;
  justify-content: center;
  width: 25rem;
  font-size: 24px;
  font-weight: 600;
}
.addMembers{
  margin-top: 0.5rem;
  font-weight: 600;
}
.doneButton{
  width: 60px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  background-color: #65a15e;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.errorTextHidden {
  visibility: hidden;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}

.errorText {
  visibility: visible;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}

.container table {
  border-collapse: collapse;
  width: 100%;
}

.container table thead {
  background-color: #f5f6fa;
}

.container table thead tr {
  text-align: left;
  opacity: 0.85;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
  padding-left: 4px;
}
/* 
.container table thead tr th {
  
} */
.container table thead tr th:nth-child(1){
  padding-left: 4px;
  width: 3%;
}
.container table thead tr th:nth-child(2){
  padding-left: 2px;
  width: 47%;
}
.container table thead tr th:nth-child(3){
  text-align: center;
  width: 15%;
}
.container table thead tr th:nth-child(4){
  text-align: center;
  width: 15%;
}
.container table thead tr th:nth-child(5){
  text-align: center;
  width: 15%;
}

.td {
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  width: 100%;
}
.td td:nth-child(1) {
  color: #65a15e;
}
.td td:nth-child(2) {
  padding-left: 2px;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #65a15e;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: #fff;
  text-decoration: underline;
}

.td td:nth-child(3) {
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
  background-color: #fff;
}

.td td:nth-child(4) {
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
  background-color: #fff;
}

.td td:nth-child(5) {
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
  background-color: #fff;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  margin-top: 5px;
  margin-left: 21%;
  width: 21px;
  cursor: pointer;
}
.coreSkillInput {
  border-radius: 5px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  width: 100%;
  height: 35px;
  border: 1px solid #00000061;
  padding: 0 8px;
  outline: none;
}

.buttonsContainer{
  display: flex; 
  justify-content: space-between; 
  width: 96%; 
  /* margin: 2% 2% 0 2%; */
  margin-top: 10px;
  margin-left: 1%;
}

.backButton{
  background-color: white;
  border-radius: 9px;
  height: 38px;
  width: 5rem;
  color: #65a15e;
  border: 1px solid #65a15e;
}

.pagination{

  display: flex;
  justify-content: flex-end;
  width: 97%;
  margin-top: 1%;
}