.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 5px;
}

.salTab {
    border: 1px solid #ddd;
}

.salTH {
    padding: 4px 10px;
    text-align: center;
}
.salMinTH{
    padding: 4px 10px;
}

thead .salTab {
    background-color: #f2f2f2;
}

.salary {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    /* padding-left: 10px; */
    padding: 5px 5px;
    outline: none;
}
.hike{
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 80%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-left: 10px;
    padding: 5px 5px;
    outline: none;
}

.salNote{
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 4rem;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 8px;
    padding-left: 10px;
    outline: none;
}

.annSal{
    width: 100%;
    text-overflow: ellipsis;
}

th[colspan="2"] {
    background-color: #f2f2f2;
}

.createSalBtn{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.hikeOpts{
    display: flex;
    gap: 1rem;
}
.salNoottes{
    height: 2rem;
}

.Cu_row1 {
    display: flex;
    justify-content: space-between;
    margin: 0px 0.5rem;
    width: auto;
  }
  
  .cu_label {
    font-size: 15px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
  }

  .cu_field4 {
    margin-top: 10px;
    padding-left: 5px;
    padding: 7px 5px 10px 4px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
  .cu_col1 {
    flex: 0 0 19%;
    width: 19%;
  }
  
  .cu_col2 {
    flex: 0 0 19%;
    width: 19%;
  }
  
  .cu_col3 {
    flex: 0 0 19%;
    width: 19%;
  }
  
  .cu_col4 {
    flex: 0 0 19%;
    width: 19%;
  }
  
  .cu_col5 {
    flex: 0 0 19%;
    width: 19%;
  }
  .twobuttons{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-right: 2rem;
    margin-bottom: 14px;
  }