.main_client_body {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.main_create {
    width: 80%;
}

.vieUser {
    font-weight: 700;
    font-size: 1rem;
    padding-left: 30px;
    padding-top: 15px;

}

.upcoming_celebration {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.createposition {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    border-radius: 5px;
    background-color: #ffffff;
    min-height: 5rem;
    overflow-y: hidden;
    margin-left: 30px;
    // margin-right: 30px;
    margin-top: 0.8rem;

}

.celebrations {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    border-radius: 5px;
    background-color: #ffffff;
    min-height: 5rem;
    max-height: 20rem;
    overflow-y: hidden;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0.8rem;
    width: 22rem;
    overflow: auto;
    /* padding: 10px; */
}

.celebrations_th {
    display: flex;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    padding: 0px 0.5rem;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

}

.celebrations_th div:nth-child(1) {
    font-weight: 400;
    width: 2rem;
    font-size: 14px;
    color: #666666;
}

.celebrations_th div:nth-child(2) {
    font-weight: 400;
    width: 8rem;
    font-size: 14px;
    color: #666666;
}

.celebrations_th div:nth-child(3) {
    font-weight: 400;
    width: 12rem;
    font-size: 14px;
    color: #666666;
}

.th {
    display: flex;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    padding: 0px 0.5rem;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: #F0F0F0;
}

.th p:nth-child(1) {
    font-weight: 600;
    width: 13rem;
    font-size: 14px;
    color: #666666;
}

.th p:nth-child(2) {
    font-weight: 600;
    width: 6rem;
    font-size: 14px;
    color: #666666;
}
.th p:nth-child(3) {
    font-weight: 600;
    width: 13rem;
    font-size: 14px;
    color: #666666;
}
.th p:nth-child(4) {
    font-weight: 600;
    width: 5rem;
    font-size: 14px;
    color: #666666;
}
.td {
    display: flex;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    padding: 0px 0.5rem;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    // background: #F0F0F0;
}

.td p:nth-child(1) {
    font-weight: 400;
    width: 13rem;
    font-size: 14px;
    color: var(--atsLinkColor);
    text-decoration: underline;
    cursor: pointer;

}

.td p:nth-child(2) {
    font-weight: 400;
    width: 6rem;
    font-size: 14px;
    color: #666666;
}
.td p:nth-child(3) {
    font-weight: 400;
    width: 13rem;
    font-size: 14px;
    color: #666666;
}
.td p:nth-child(4) {
    font-weight: 400;
    width: 5rem;
    font-size: 14px;
    color: #666666;
}
.noData {
    display: flex;
    justify-content: center;

}