.main_div {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 0 0 -3%;
  height: auto;
  min-height: 400px;
  border-radius: 1px solid gray;
  outline: none;
}
.note_textarea {
  border: none;
  outline: none;
  width: 100%;
  padding-left: 10px;
  margin-left: 59px;
}
.last_btn {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
  width: 76.5vw;
  grid-column-gap: 20px;
  margin-top: 2%;
}

.cancel_btn {
  background-color: #ffff;
  color: #65a15e;
  border: 1px solid #65a15e;
  border-radius: 5px;
  width: 90px;
}

.create_btn {
  background-color: #65a15e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
}
