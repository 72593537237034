.card {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 104px;
  height: auto;
  margin-bottom: 20px;
}

.title {
  display: flex;
  justify-content: center;
  color: #a15e5e;
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}

.cu_label {
  font-size: 15px;
  font-weight: 500;
  /* padding-left: 4px; */

  color: #000000;
}
/*  */

.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 20vw;
  height: 36px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  margin: 1% 1% 1% 1%;
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
}

.Cu_row1 {
  display: flex;
  padding: 10px 10px 10px 10px;
  grid-column-gap: 0vw;
  column-gap: 2vw;
  flex-wrap: wrap;
}

.cu_col1 {
  width: 20%;
  margin: 0% 1%;
}

.Cu_row2 {
  padding: 10px;
}

.cu_col2 {
  /* display: flex; */
  justify-content: space-between;
}

.add {
  border: 2px solid #a15e5e;
  color: #a15e5e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tooltip {
  cursor: pointer;
  margin-left: 10px;
}
.add > p {
  font-size: 14px;
  font-weight: bold;
}

.add:hover {
  cursor: pointer;
  background-color: #a15e5e;
  color: #ffffff;
}

.FeedbackTable > p:first-child {
  font-weight: 500;
}
.modal_container {
  width: 18rem;
  height: 8rem;
  border-radius: 5px;
}
.textM {
  text-align: center;
}

.M_button {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  width: 10rem;
  justify-content: space-around;
}
.b_inactive {
  border: 1px solid #a15e5e;
  border-radius: 4px;
  color: white;
  background-color: #a15e5e;
  width: 5vw;
  font-size: 15px;
  cursor: pointer;
  /* height: 30px; */
}
.main_div {
  display: flex;
  justify-content: space-around;
  width: 74vw;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}

.employee_name {
  color: red;
  font-size: 0.8rem;
  cursor: pointer;
}

.overall_rating {
  font-size: 0.8rem;
  font-weight: 600;
}

.employee_name:hover {
  text-decoration: underline;
}

.span_cursor {
  cursor: pointer;
  font-size: 0.8rem;
}

.span_cursor:hover {
  text-decoration: underline;
}

.no_data {
  text-align: center;
  padding: 3%;
}
.PositionList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%); */
  /* width: 95%; */
  /* margin: 10px auto; */
  border-radius: 5px;
  background-color: #ffffff;
  /* height: 500px; */
  overflow-y: hidden;
  padding: 10px;
  font-size: 14px;
  /* margin-top: 26px; */
  overflow-x: scroll;
}

.th {
  display: flex;
  justify-content: flex-start;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}

.th p:nth-child(1) {
  width: 7rem;
  text-align: start;
  font-weight: 600;
  padding-left: 13px;
}

.th p:nth-child(2) {
  width: 5rem;
  text-align: start;
  font-weight: 600;
}

.th p:nth-child(3) {
  width: 5rem;
  text-align: start;
  font-weight: 600;
}

.th p:nth-child(4) {
  width: 7rem;
  text-align: start;
  font-weight: 600;
  /* padding-left: 5px; */
}

.th p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-weight: 600;
  /* padding-right: 14px; */
}

.th p:nth-child(6) {
  width: 8rem;
  text-align: start;
  display: block;
  font-weight: 600;
}

.th p:nth-child(7) {
  width: 8rem;
  text-align: start;
  display: block;
  font-weight: 600;
}

.th p:nth-child(8) {
  width: 8rem;
  text-align: start;
  display: block;
  font-weight: 600;
}

.th p:nth-child(9) {
  width: 8rem;
  text-align: start;
  display: block;
  font-weight: 600;
}

.td {
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 7rem;
  text-align: start;
  color: #a15e5e !important;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding-left: 11px;
  font-size: 12px;
}

.td p:nth-child(2) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.td p:nth-child(3) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.td p:nth-child(4) {
  width: 7rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* padding-left: 8px; */
  font-size: 12px;
}

.td p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* margin-right: 13px !important; */
  font-size: 12px;
}

.td p:nth-child(6) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.td p:nth-child(7) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.td p:nth-child(8) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.td p:nth-child(9) {
  width: 8rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.link {
  text-decoration: underline;
  color: #a15e5e;
  cursor: pointer;
}

.btn {
  background-color: #a15e5e;
  border: none;
  margin-left: 5px;
}

 
.description_box1 {
  /* border: 1px solid red; */
  padding: 0px;
  /* height: auto; */
  margin: 10px auto;
  margin-top: 15px;
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 7px;
  background-color: white;
  width: 95%;
}
.title {
  display: flex;
  justify-content: center;
  color: #a15e5e;
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}
.maindivContent {
  display: flex;
  flex-direction: row;
}
.p1 {
  margin-left: 450px;
  color: grey;
}
.note_heading{
  font-weight: 600;
  font-size: 14px;
}
.pagination {
  margin-top: 10px;
  /* margin-right: 30px; */
  /* margin-bottom: 150px; */
  /* background-color: #a15e5e; */
  /* height: 50px; */
}