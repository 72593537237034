.mainSection {
    width: 100%;
    min-height:100vh;
    height: auto;
    top: 100px;
    bottom: 0;
    display: flex;
    gap: 10px;
  }
  .table{
    overflow-y: hidden;
    width: 100%;
    margin:0px;
  }
  .upper{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .rightSide{
    width: 80%;
    padding: 0px 15px;

  }
  .category{
    display: flex;
    justify-content: end;
    padding: 5px 15px;
    gap: 5px;
   }
  .appraisals,.rewards,.promotions{
    height: 25px;
    width: 100px;
    font-size: 16px;
    color:#666666;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    background-color:#dfdfdf;
    border-radius: 5px;

  }
  
  .active{
    background-color: #562679;
    color: #fff;
    }
  .breadscrum{
    color: #562679;
  }