$blue_primary: #0f5ef7;
$green_secondary: #65a15e;
$yellow_ternary: #fcb902;
$black_header: #172a3e;
$light_background: #f5f6fa;
$sub_text: #707070;
.container {
  display: grid;
  grid-template-columns: 55% 45%;
  .image {
    height: 100vh;
    background-color: $black_header;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 86%;
    }
  }
}
.content {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    width: 290px;
    height: 90px;
    position: relative;
    object-fit: contain;
    top: -40px;
  }
  .logoDark {
    width: 301px;
    height: 90px;
    position: relative;
    display: none;
  }
  .landingImage {
    height: 250px;
    width: 80%;
    display: none;
  }
  h1 {
    font-family: "Poppins";
    font-size: 2rem;
    color: $black_header;
    margin-bottom: 10px;
  }
  .subText {
    font-family: "Poppins";
    color: $sub_text;
    font-size: 1rem;
    margin-bottom: 2em;
  }
  .googleButton {
    background: transparent url("./../../assets/btn_google_dark.png") 0% 0%
      no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    width: 240px;
    height: 58px;
    background-size: cover;
    outline: none;
    border: none;
    cursor: pointer;
    // border: 2px solid red;
  }
  .errormsg{
    color: #F84142;
    font-size: 14px;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    grid-template-columns: 100%;
    .image {
      display: none;
    }
  }
  .content {
    height: 100vh;
    background-color: $black_header;
    color: white;
    .logo {
      display: none;
     
    }
    .logoDark {
    
      display: block;
    }
    .landingImage {
      display: block;
    }
    h1 {
      color: #fff;
      font-size: 1.8rem;
    }
    .subText {
      color: #fff;
      font-size: .8rem;
    }
    // .googleButton {
    //   background:url("./../../assets/btn_google_light.png")
    //     no-repeat padding-box;
    //     mix-blend-mode: normal;
    //     margin-left: 35px;  
      
    // }
  }
}
