.tooltiptext {
    width: auto;
    background-color: #855ea1;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    /* bottom: 2.2rem; */
    font-weight: 400;
    z-index: 10000;
    transition: opacity 0.3s;
    /* right: 5%; */
    /* top: -1%; */
    border-color: #9c58a5 transparent transparent transparent;
}