.ViewTeamTask{
    margin-top: 30px;
    margin-left: 14px;
}

.DetailTeamView {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    overflow-y: hidden;
    padding-top: 10px;
    font-size: 14px;
    padding-bottom: 30px;
  }
  .ViewTeamEmployeeDetails {
    display: flex;
    justify-content: center;
    color: #9c58a5;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  }
  .ProjectTeamEmployee {
    margin-top: 20px;
  }
  .taskTeam{
    margin-top: 20px;
  }
  .PlBtn{
      display: flex;
      justify-content: end;
      width: 97.6%;
      grid-column-gap: 2%;
      margin: 2% 0 3% 0;
      
  }

  .buttonAdd {
    background-color: #9c58a5;
    border-color: #9c58a5;
    width: 145px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
    position: relative;
    margin-top: 20px;
    left: 32%;
  }
  .buttonCancle {
    width: 145px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
    background: transparent 0% 0% no-repeat padding-box;
    color: #9c58a5;
    border: 1px solid #9c58a5;
    position: relative;
    left: 67%;
    margin-top: -40px;
    margin-bottom: 25px;
  }

  