.description_box1 {
  /* border: 1px solid red; */
  padding: 0px;
  /* height: auto; */
  /* margin-top: 27px; */
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 7px;
  background-color: white;
}

.span2 {
  text-align: center;
  color: #65a15e;
  font-weight: 600;
}

.addButton {
  display: flex;
  justify-content: flex-end;
}

.add_btn {
  background-color: #65a15e;
  border: 1px solid #65a15e;
  margin: 0px 2px 2px 0px;
  outline: none;
}

.deleteModal {
  margin: 0.5rem;
}

.btnRow {
  margin-top: 1rem;
  text-align: center;
}

.yes_btn {
  background-color: #65a15e;
  border: 1px solid #65a15e;
  margin: 0px 2px 2px 0px;
  width: 4rem;
  margin-right: 1rem;
}

.no_btn {
  background-color: #65a15e;
  border: 1px solid #65a15e;
  margin: 0px 2px 2px 0px;
  width: 4rem;
}
