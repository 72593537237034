.container {
    z-index: 10;
    top: 88px;
    position: absolute;
    width: 77%;
    height: 250px;
    box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 9px;
    background-color: white;
    left: 21.5%;
    opacity: 1;
}

.subContainer {
    display: flex;
    justify-content: space-evenly;
    opacity: 1;
}

.subContainer div {
    width: 32%;
}

.addScroll {
    height: 146px;
    overflow-y: auto;
}

.addScroll::-webkit-scrollbar {
    width: 15px;
}

.addScroll::-webkit-scrollbar-track {
    background: #ffffff;
    /* color of the tracking area */
}

.addScroll::-webkit-scrollbar-thumb {
    background-color: #d3d1d1;
    border-radius: 20px;
    border: 4px solid #ffffff;
}

.mobileRes {
    display: none;
}

@media screen and (max-width: 800px) {
    .mobileRes {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .container {
        z-index: 10;
        top: 4%;
        position: absolute;
        width: 76%;
        height: auto;
        box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px;
        background-color: white;
        left: auto;
        right: 0;
    }

    .subContainer {
        display: block;
        justify-content: space-evenly;
    }

    .subContainer div {
        width: 98%;
    }
}

.container .subContainer header {
    height: 30px;
    background-color: #CBC9C9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    opacity: .85;

}

.container .subContainer ul {
    list-style-type: none;
    padding-left: 15px;
}

.buttonSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
    padding-right: 10px;
    outline: none;
    padding-bottom: 10px;
}

.clearAll {
    padding-left: 20px;
    padding-right: 20px;
    height: 42px;
    background-color: #ffffff;
    color: #65A15E;
    outline: none;
    border: 1px solid #65A15E;
    border-radius: 10px;
    font-size: 18px;
}

.apply {
    padding-left: 20px;
    padding-right: 20px;
    height: 42px;
    background-color: #65A15E;
    color: #ffffff;
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    margin-left: 10px;
}