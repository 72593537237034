.main_client_body {
    width: 100%;
    display: flex;
  }
  .secondDiv {
    width: 80%;
    margin-top: 12px;
  }
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
  }
  
  .buttonContainer button {
    margin: 5px;
  }
  
  .Icon {
    cursor: pointer;
    border: 1px solid rgb(252, 187, 8);
    color: rgb(252, 187, 8);
  }
  .Icon1 {
    cursor: pointer;
    border: 1px solid rgb(252, 187, 8);
    color:white;
    background-color:rgb(252, 187, 8);
  }
  .iconset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 26px;
    margin-left: 10px;
  }
  
  .div{
    box-shadow: 0 0 5px 1px rgba(0,0,0,.2);
      border-radius: 5px;
      background-color: #fff;
      /* height: 9rem; */
      margin: 10px 0;
      /* overflow-y: scroll; */
      box-sizing: border-box;
      white-space: nowrap;
      margin-left: 10px;
  }
  .thead{
    background-color: rgb(240, 240, 240);
    position: sticky;
    top: 0px;
  }
  .button{
    border-radius: 10px;
    height: 38px;
    cursor: pointer;
    background-color:rgb(252, 187, 8);
    margin-left:10px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.activeButton{
    border-radius: 10px;
    height: 38px;
    cursor: pointer;
    background-color: white;
    color: rgb(252, 187, 8);
    margin-left: 10px;
    border: 1px solid rgb(252, 187, 8);
    display: flex;
    font-size: 13px;
    justify-content: center;
    align-items: center;
}
  
.backButton{
    border-radius: 10px;
    height: 38px;
    cursor: pointer;
    min-width: 7%;
    background-color: white;
    color: rgb(252, 187, 8);
    margin-left: 10px;
    border: 1px solid rgb(252, 187, 8);
    display: flex;
    justify-content: center;
    align-items: center;
}
  .thead{
    background-color: rgb(240, 240, 240);
    height: 30px;
  }

  .count{
    width: 31px;
    height: 31px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(252, 187, 8);
    /* border: 2px; */
    margin-left: 7px !important;

}

.count1{
    width: 31px;
    height: 31px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    /* border: 2px; */
    margin-left: 7px !important;
}
.count2{
    width: 31px;
    height: 31px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(252, 187, 8) ;
    /* border: 2px; */
    font-size: 13px;
    margin-left: 7px !important;
}
.projectHeading{
    font-weight: 500;
    padding-left: 10px;
}
.th{
    padding-left: 10px;
}
.noRecordPara{
    display: flex;
    justify-content: center;
    font-weight: 500;
}
.td1{
    padding-left: 10px;
    width: 38%;
  }
  .td2{
    padding-left: 10px;
    width: 20%;
  }
  .td21{
    padding-left: 10px;
    width: 22%;
  }
  .td3{
    padding-left: 10px;
  }
  .td4{
    padding-left:0px;

  }
  .loadingP{
    position:absolute;
    top: 53%;
    left:56%
  }