.filter {
  border: 1px solid #a15e5e;
  background-color: #fff;
  width: 7vw;
  font-size: 1rem;
  color: #a15e5e;
  border-radius: 1.3rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
}

.filterActive {
  border: 1px solid white;
  color: white;
  background-color: #65a15e;
  width: 7vw;
  font-size: 1rem;
  border-radius: 1.3rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
}
