.heading{
    font-size: 18px;
    font-weight: 700;
    width: 25rem;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 5px;
}

.salTab {
    border: 1px solid #ddd;
    padding: 4px 10px;
    text-align: center;
}