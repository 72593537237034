.th {
    display: flex;
    justify-content: space-around;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 45px;
    min-width: 95%;
    font-size: var(--atsFontSize);
  }
  
  
  .th p:nth-child(1) {
    width: 12rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 9px;
  }
  
  .th p:nth-child(2) {
    width: 5.5rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .th p:nth-child(3) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .th p:nth-child(4) {
    width: 4rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .th p:nth-child(5) {
    width: 6rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .th p:nth-child(6) {
    width: 7rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .th p:nth-child(7) {
    width: 8rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .th p:nth-child(8) {
    width: 7.5rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .th p:nth-child(9) {
    width: 8rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .th p:nth-child(10) {
    width: 5rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .td {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    min-width: 95%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: var(--atsFontSize);
    /* padding: 2px 0 10px; */
  }
  
  
  
  .td:last-child {
    border-bottom: none;
  }
  
  .td p:nth-child(1) {
    width: 12rem;
    text-align: start;
    color: var(--atsLinkColor);
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    padding-left: 9px;
    text-decoration: underline;
  }
  
  .td p:nth-child(2) {
    width: 5.5rem;
    /* text-align: center; */
    /* padding-left: 7px; */
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  
  
  
  
  .td p:nth-child(3) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .td p:nth-child(4) {
    width: 4rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .td p:nth-child(5) {
    width: 6rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .td p:nth-child(6) {
    width: 7rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .td p:nth-child(7) {
    width: 8rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .td p:nth-child(8) {
    width: 7.5rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .td p:nth-child(9) {
    width: 8rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .td p:nth-child(10) {
    width: 5rem;
    text-align: start;
    color: #666666;
    /* font-weight: 600; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .no_data{
    text-align: center;
  }
  