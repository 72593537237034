.mainSection{
    display: flex;
}
.upper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-left: 27px;
  }
  .rightSide{
    width: 80%;
  }
  .body{
    margin-top: 10px;
  }
  .editables{
    overflow-y: auto;
    height: 75vh;
    scrollbar-width: none;
  }
  .action{
    display: flex;
    justify-content: end;
    margin-right: 27px;
  }
  .button{
    width: auto;
    background-color: #4B59C3;
    color: white;
    border: 1px solid var(--invoiceColor);
    border-radius: 5px;
    padding: 0.4rem 2.75rem;
  }
   .emp_details {
    text-align: center;
    color: #4b59c3;
    font-weight: 500;
    font-size: var(--inoiceHeading);
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
    padding-bottom: 0.5rem;
  }
  .label {
    font-weight: bold;
  }
  .border {
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
    margin: 1rem 0px;
  }
  
  .Container {
    width: 95%;
    margin: 0.8rem auto;
    display: flex;
    justify-content: space-between;
  }
  .col1 {
    width: 23.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col2 {
    width: 23.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .col3 {
    width: 23.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .col4 {
    width: 23.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col212 {
    width: 60%;
  }
  .col213 {
    width: 25.5%;
  }
  .values1 {
    border-left: 4px solid red;
    margin-top: 11px !important;
  }
  .values2 {
    border-left: 4px solid green;
    margin-top: 11px !important;
  }
  .values {
    margin-top: 11px !important;
  }
  .labelFolderLink {
    font-weight: bold;
  }
  .heading {
    margin-left: 27px;
    font-weight: 500;
  }
  .lastBtn {
    display: flex;
    justify-content: end;
    grid-column-gap: 2%;
    margin: 2% 1% 2% 0;
  }
  .addNoteBtn{
    background-color: #4B59C3;
    color: #fff;
    border: 1px solid var(--invoiceColor);
  }
  .confirmPopup {
    width: 386px;
    min-height: 4rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
    max-height: 600px;
    overflow: auto;
    padding: 1rem;
  }
  .delete {
    width: 90px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
  }