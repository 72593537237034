.main_create {
    /* height: 100vh; */
  }
  
  .createposition {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    height: 130px;
    /* overflow-y: hidden; */
    /* padding-top: 10px; */
    /* padding: 10px; */
    margin-bottom: 20px;
  }
  
  .searchLoader {
    position: absolute;
    /* left: 60%; */
    top: 7.3%;
    right: 3.05%;
    z-index: 1000;
  }
  .companyLoader{
    position: absolute;
  left: 41px;
    z-index: 1000;
  }
  
  .detailsCantainer {
    min-width: 20rem;
    min-height: 7rem;
    background: white;
    border: 1px solid gray;
    border-radius: 10px;
    position: absolute;
    right: 2%;
  
    /* background: red; */
  }
  
  .roeww {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0px 0.5rem;
  }
  
  .label {
    font-size: 1rem;
    font-weight: 600;
    width: 9rem;
  }
  
  .value {
    font-size: 1rem;
    font-weight: 600;
    min-width: 11rem;
  }
  
  .cu_fieldAC {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    /* width: 130%; */
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 5px;
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
  }
  
  .createposition_resume {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    height: 100px;
    /* overflow-y: hidden; */
    /* padding-top: 10px; */
    /* padding: 10px; */
    margin-bottom: 15px;
  }
  
  .createposition_WE {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    height: auto;
    /* overflow-y: hidden; */
    /* padding-top: 10px; */
    /* padding: 10px; */
    margin-bottom: 20px;
  }
  
  .createposition_academic {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    height: auto;
    /* overflow-y: hidden; */
    /* padding-top: 10px; */
    /* padding: 10px; */
    margin-bottom: 20px;
  }
  
  .createposition_other {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    /* min-height: 320px; */
    min-height: 429px;
    margin-bottom: 20px;
  }
  
  .cu_col11,
  .cu_col21,
  .cu_col31,
  .cu_col41 {
    flex: 0 0 20%;
  }
  
  .Cu_row1 {
    display: flex;
    justify-content: space-between;
    /* margin: 1% 2%; */
    margin: 0% 2% 0% 1%;
  }
  
  .Cu_row14 {
    display: flex;
    justify-content: space-between;
    /* margin: 0% 2%; */
    margin: 1% 2% 1% 1%;
  }
  
  .Cu_row11 {
    display: flex;
    justify-content: space-between;
    margin: 1% 1%;
  }
  
  .Cu_row11Reason {
    display: flex;
    justify-content: space-between;
    margin: 1% 2% 1% 1%;
  }
  
  .cu_col1 {
    flex: 0 0 31%;
  }
  
  .cu_col1_reason {
    flex: 0 0 31%;
  }
  
  .cu_label {
    font-size: 15px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
  }
  
  .cu_col2 {
    flex: 0 0 31%;
  }
  
  .cu_col2_ac {
    flex: 0 0 31%;
  
    /* margin-right: 33px; */
  }
  
  .cu_label {
    font-size: 15px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
  }
  
  .cu_label1 {
    font-size: 17px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
    padding-top: 3px;
  }
  
  .cu_col2_to {
    flex: 0 0 31%;
    /* margin-left: -50px; */
  }
  
  .cu_col3 {
    flex: 0 0 31%;
  }
  
  /* .cu_col4 {
    flex: 0 0 31%;
  } */
  .cu_field2 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin: 10px 0px;
    /* padding-left: 10px; */
    padding: 5px 5px;
    outline: none;
  }
  
  .multiinput {
    display: flex;
    justify-content: space-around;
    grid-column-gap: 0;
  }
  
  .multiinput2 {
    display: flex;
    justify-content: center;
    margin-right: -50px;
    /* grid-column-gap: 21%; */
  }
  
  .gr {
    margin: 0 0 0 -7%;
  }
  
  .multiinput3 {
    display: flex;
    justify-content: start;
    grid-column-gap: 6%;
  }
  
  .cu_field22 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 47%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    outline: none;
  }
  
  .cu_field23 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 7vw;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
  }
  
  .cu_field23Last {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    /* width: 130%; */
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
  }
  
  .cu_field233 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 75%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    outline: none;
  }
  
  .hr {
    width: 100% !important;
  }
  
  .title {
    display: flex;
    justify-content: center;
    color: rgb(123, 187, 123);
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  }
  
  .btn_add_acde {
    background-color: #65a15e;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    width: 90px;
    height: 35px;
    /* margin-top: -25px; */
  }
  
  .last_btn {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    width: 77vw;
    grid-column-gap: 20px;
  }
  
  .cancel_btn22 {
    background-color: #ffff;
    color: #65a15e;
    border: 1px solid #65a15e;
    border-radius: 5px;
    width: 90px;
  }
  
  .create_btn {
    background-color: #65a15e;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    width: 90px;
  }
  
  .create_btn:disabled {
    background-color: gray;
    color: white;
    border: 1px solid gray;
  }
  
  .cal_img {
    position: relative;
    top: -34px;
    left: 275px;
  }
  
  .cal_img2 {
    position: relative;
    top: -34px;
    left: 115px;
  }
  
  .dp_div {
    display: block;
  }
  
  .upload_resume::-webkit-file-upload-button {
    border: none;
    padding: 5px;
  }
  
  .upload_resume {
    width: 100%;
    border: 1px solid #999;
    border-radius: 10px;
  }
  
  .Cu_row1 input[type="file"]::file-selector-button {
    border: 2px solid #65a15e;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #65a15e;
    transition: 1s;
    color: #ffffff;
  }
  
  .workExpButton {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-right: 10px;
    padding-bottom: 1.5rem;
    height: 25px;
  }
  
  .workExperiences,
  .candidateAcademics {
    position: relative;
  }
  
  .btn_remove {
    position: absolute;
    right: 1%;
    top: 2%;
    background-color: transparent;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn_add {
    background-color: #65a15e;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    width: 90px;
    height: 35px;
  }
  
  .workExperiences {
    position: relative;
  }
  
  .candidateAcademics {
    position: relative;
  }
  
  .errorText {
    color: red;
    font-size: 12px;
    margin-left: 15px;
    padding-top: 2px;
    visibility: visible;
  }
  
  .errorTextA {
    color: red;
    font-size: 12px;
    margin-left: 10px !important;
    padding-top: 2px;
    display: block;
  }
  
  .errorTextHidden {
    display: none;
    color: red;
    font-size: 12px;
    margin-left: 15px;
    padding-top: 2px;
  }
  
  .errorText2 {
    color: red;
    font-size: 12px;
    margin: 5px !important;
    visibility: visible;
    padding-top: 2px;
  }
  
  .errorText3 {
    color: red;
    font-size: 12px;
    margin-left: 15px;
    visibility: visible;
    padding: 2px 5px;
  }
  
  .errorText4 {
    color: red;
    font-size: 12px;
    margin: 0px 2px !important;
    visibility: visible;
  }
  
  .errorText5 {
    color: red;
    font-size: 12px;
    margin: 0px 7px !important;
    visibility: visible;
  }
  
  .errorText6 {
    color: red;
    font-size: 12px;
    margin-left: 41px !important;
    visibility: visible;
    padding-top: 2px;
  }
  
  .Cu_resume {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0 1%;
    position: relative;
    grid-column-gap: 5px;
  }
  
  .rmpdf {
    border: 1px solid #65a15e;
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    padding: 0.5rem;
    color: #65a15e;
  }
  
  .rmpdfcross {
    color: black;
    border: 1px solid #000000;
    background-color: #65a15e;
    position: absolute;
    left: 77%;
    top: -20%;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
  }
  
  .upload_resume::-webkit-file-upload-button {
    border: none;
    padding: 5px;
  }
  
  .upload_resume {
    width: 100%;
    border: 1px solid #999;
    border-radius: 10px;
    margin-top: 0.6rem;
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .mandatory {
    color: red;
  }
  
  .bigInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    /* padding-left: 10px; */
    padding: 5px 5px;
    outline: none;
  }
  
  .bigInput:disabled {
    background-color: #504c4c !important;
    opacity: 0.2;
  }
  
  .smallInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 47%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    outline: none;
  }
  
  /* other info */
  .info_first_row {
    display: flex;
    justify-content: space-between;
    margin: 1% 1.5% 0 1.5%;
  }
  
  .info_third_row {
    display: flex;
    justify-content: space-between;
    margin: 2% 2.2% 0 2%;
  }
  
  .bigInput:disabled {
    background-color: #999;
    opacity: 0.2;
  }
  
  .modal_container2 {
    width: 20rem;
    height: 6rem;
    border-radius: 5px;
  }
  
  .reactmodal {
    border-radius: 10px;
  }
  
  .textM {
    text-align: center;
  }
  
  .M_button1 {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .okay {
    background-color: #65a15e;
    border-radius: 5px;
    border: 1px;
    width: 50px;
  }
  
  .radioitem {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin-left: 1%;
    /* margin: 10px 0 0; */
  }
  
  .radioitem label {
    cursor: pointer;
  }
  
  .radioitem input[type="radio"] {
    display: none;
  }
  
  .radioitem label {
    font-weight: 600;
  }
  
  .radioitem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #65a15e;
    background-color: transparent;
  }
  
  .radioitem input[type="radio"]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #65a15e;
    border: 2px solid #65a15e;
  }
  
  .list {
    /* display: flex;
    align-items: center; */
    margin-top: 1rem;
  }
  
  .list label:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #f2f2f2;
    border: 1px solid rgba(75, 101, 132, 0.3);
    display: inline-block;
    margin-right: 16px;
  }
  
  .list input[type="checkbox"]:checked ~ label:before {
    background: #20bf6b no-repeat center;
    background-size: 12px 12px;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  }
  
  /*  Show when label and checkbox have focus, to make this keyboard accessible > press spacebar to select the checkbox */
  
  .list input[type="checkbox"]:focus ~ label {
    color: #20bf6b;
  }
  
  .errorTextFrom {
    color: red;
    font-size: 12px;
    visibility: visible;
    padding-top: 0.5rem;
  }
  
  .errorTextexp {
    color: red;
    font-size: 12px;
    visibility: visible;
    padding-top: 0.5rem;
    margin-left: 7px !important;
  }
  
  .errorTextPassing {
    color: red;
    font-size: 12px;
    visibility: visible;
    padding-top: 0.9rem !important;
  }
  
  .btnRow {
    text-align: right;
  }

  .incrementDiv{
    margin: 1%;
    margin-top: 10px;
    padding: 5px 5px 5px 10px;
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 2.3rem;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
  }