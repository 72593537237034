.UsersList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 8rem;
  max-height: 30rem;
  margin-left: 27px;
  margin-right: 27px;
  overflow: auto;
  white-space: nowrap;
}

.date {
  border: 1px solid #00000061;
  border-radius: 5px;
  background-color: #f5f6fa;
  height: 38px;
  padding: 4.5px;
}



.main_create {
  min-height: 100vh;
  width: 80%;
}

.filter_btn {
  width: 120px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #289e98;
  background-color: #ffffff;
  outline: none;
  color: #289e98;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 28%;
}

.appliedcheck {
  left: 63%;
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.backColor {
  color: #289e98;
  cursor: default;
}

.plus {
  font-size: 20px;
}

.main_client_body {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.s1 {
  position: relative;
  width: 140px;
  margin-left: 5px;
}

.s2 {
  position: relative;
  width: 135px;
  margin-left: 5px;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 88%;
  width: 21px;
  cursor: pointer;
}

.cp_serchIconCode {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 79%;
  width: 21px;
  cursor: pointer;
}

.coreSkillInput {
  border-radius: 9px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  width: 99%;
  height: 38px;
  border: 1px solid #00000030;
  padding: 0 8px;
  outline: none;
}

.coreSkillInput::placeholder {
  color: black;
}


p {
  margin: 0 !important;
}

.tableContent1 {
  width: 10%;
  text-align: center;
  font-size: 14px;
}

.tableContent1 {
  width: 15%;
  text-align: center;
  font-size: 14px;
}

.tableContent1 {
  width: 10px;
  text-align: center;
  font-size: 14px;
}

.link {
  color: #289e98;
  cursor: pointer;
}

.link:visited {
  color: #65a15e;
}

.pagination {
  margin-top: 10px;
  margin-right: 30px;
}

.filter {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #289e98;
  background-color: #ffffff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter span {
  padding-left: 12px;
  font-size: 1rem;
  color: #289e98;
}

.table_div_head {
  justify-content: space-between;
  align-items: center;
}

.second_child {
  display: flex;
  left: 63%;
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.create_btn {
  width: 40px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  border-radius: 50%;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.plus {
  font-size: 20px;
  font-weight: 700;
}

.extra {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.create {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #289e98;
  background-color: #289e98;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

a:link {
  text-decoration: none;
}

.th {
  display: flex;
  /* justify-content: space-between; */
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 45px;
  width: 230.5rem;
  font-size: var(--atsFontSize);
  position: sticky;
  top: 0px;
  z-index: 100;
  left: 0;
}

.th:last-child {
  border-bottom: none;
}

.th p:nth-child(1) {
  width: 9rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  padding-left: 6px;
  position: sticky;
  left: 0px;
  background-color: #f5f7f9;
  z-index: 100;
  top: 12px;
  height: 38px;

}

.th p:nth-child(2) {
  width: 5.5rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: sticky;
  left: 9rem;
  top: 12px;
  height: 38px;
  background-color: #f5f7f9;
}


.th p:nth-child(3) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: sticky;
  left: 14.5rem;
  top: 12px;
  height: 38px;
  background-color: #f5f7f9;

}

.th p:nth-child(4) {
  width: 7rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: sticky;
  left: 24.5rem;
  top: 12px;
  height: 38px;
  background-color: #f5f7f9;



}

.th p:nth-child(5) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #f5f7f9;
  padding-left: 1rem;
  text-align: center;
}

.th p:nth-child(6) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(7) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(8) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(9) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(10) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(11) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(12) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(13) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(14) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(15) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(16) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(17) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(18) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(19) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(20) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(21) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(22) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(23) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(24) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(25) {
  width: 7rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.th p:nth-child(26) {
  width: 7rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.th p:nth-child(27) {
  width: 7rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.tooltiptext {
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  bottom: 2.2rem;
  font-weight: 400;
  z-index: 1;
  transition: opacity 0.3s;
  border-color: black transparent transparent transparent;
}

.tooltipctainer {
  position: absolute;
  z-index: 1000;
  width: 10rem;
  height: 5rem;
  right: 3.5%;
  top: 60%;
  visibility: hidden;
  border: 1px solid #289e98;
  border-radius: 5px;
  background: #fff;
}

.td {
  display: flex;
  /* justify-content: space-between; */
  border-radius: 10px;
  align-items: center;
  height: 40px;
  width: 230.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: var(--atsFontSize);
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 9rem;
  text-align: start;
  color: var(--atsLinkColor);
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding-left: 6px;
  position: sticky;
  left: 0px;
  top: 0px;
  background-color: #fff;
  z-index: 1;
}

.td p:nth-child(2) {
  width: 5.5rem;
  /* text-align: center; */
  /* padding-left: 7px; */
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: sticky;
  left: 9rem;
  top: 0px;
  background-color: #fff;

}

.td p:nth-child(3) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: sticky;
  left: 14.5rem;
  top: 0px;
  background-color: #fff;
}

.td p:nth-child(4) {
  width: 7rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: sticky;
  left: 24.5rem;
  top: 0px;
  background-color: #fff;

}

.td p:nth-child(5) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: #fff;
  padding-left: 1rem;
  text-align: center;
}

.td p:nth-child(6) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(7) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(8) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(9) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(10) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(11) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(12) {
  width: 10rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(13) {
  width: 10rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(14) {
  width: 10rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(15) {
  width: 8rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(16) {
  width: 8rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(17) {
  width: 8rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(18) {
  width: 8rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(19) {
  width: 8rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(20) {
  width: 8rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(21) {
  width: 8rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(22) {
  width: 8rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(23) {
  width: 10rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(24) {
  width: 8rem;
  text-align: start;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(25) {
  width: 7rem;
  text-align: center;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.td p:nth-child(26) {
  width: 7rem;
  text-align: center;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.td p:nth-child(27) {
  width: 7rem;
  text-align: center;
  color: #666666;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.count {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #289e98;
  margin-left: 7px !important;
  margin-top: 16px;
}

.download {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 33px;
  margin-top: -5px;
  cursor: pointer;
}

.note_modal {
  margin: 0.5rem;
}

.notes_desc {
  text-align: center;
}

.btnRow {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.edit_btn {
  margin-right: 0.7rem;
  background-color: #289e98 !important;
  border: #289e98 !important;
  width: 4rem;
}

.cancel_btn {
  background-color: white;
  color: black;
  border: 2px solid #289e98 !important;
  width: 4rem;
}

.backColor1 {
  color: #289e98 !important;
}

/* .UsersList .td:nth-child(2) p div span {
  top: 40%;
}

.UsersList .td:last-child p div span {
  bottom: -50%;
} */

.modal_emp_history {
  min-height: 5rem;
  overflow: auto;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 1rem;
}

.modal_emp_history .heading {
  text-align: center;
  font-weight: 600;
}

.modal_emp_history .modal_btn_container {
  display: flex;
  justify-content: end;
}

.modal_emp_history .modal_btn {
  background-color: #289e98;
  border: 2px solid #289e98;
}

.modal_emp_history .modal_table thead tr th {
  font-weight: 400px;
}

.modal_emp_history .modal_table_container {
  max-height: 20rem;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}
.search {
    position: relative;
    width: 14rem;
}

.cp_serchIcon {
    position: absolute;
    z-index: 100;
    top: 12%;
    margin-left: 88%;
    width: 21px;
    cursor: pointer;
}