.UsersList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 8rem;
  max-height: 30rem;
  margin-left: 27px;
  margin-right: 27px;
  overflow: auto;
  white-space: nowrap;
}

.main_create {
  min-height: 100vh;
  width: 80%;
}

.filter_btn {
  width: 120px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #289e98;
  background-color: #ffffff;
  outline: none;
  color: #289e98;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 28%;
}

.appliedcheck {
  left: 63%;
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.backColor {
  color: #289e98;
  cursor: default;
}

.plus {
  font-size: 20px;
}

.main_client_body {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.s1 {
  position: relative;
  width: 220px;
  margin-left: 5px;
}

.s2 {
  // position: relative;
  width: 135px;
  margin-left: 5px;
  padding: 10px;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 88%;
  width: 21px;
  cursor: pointer;
}

.cp_serchIconCode {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 79%;
  width: 21px;
  cursor: pointer;
}

.coreSkillInput {
  border-radius: 9px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  width: 50%;
  height: 38px;
  border: 1px solid #00000030;
  padding: 0 8px;
  outline: none;
  margin-bottom: 10px;
}

.coreSkillInput::placeholder {
  color: black;
}

.coreSkillInput1 {
  border-radius: 9px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  width: 90%;
  height: 76px;
  border: 1px solid #00000030;
  padding: 0 8px;
  outline: none;
}

.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  min-width: 95%;
  position: sticky;
}

p {
  margin: 0 !important;
}

.tableContent1 {
  width: 10%;
  text-align: center;
  font-size: 14px;
}

.tableContent1 {
  width: 15%;
  text-align: center;
  font-size: 14px;
}

.tableContent1 {
  width: 10px;
  text-align: center;
  font-size: 14px;
}

.link {
  color: #289e98;
  cursor: pointer;
}

.link:visited {
  color: #65a15e;
}

.pagination {
  margin-top: 10px;
  margin-right: 30px;
}

.filter {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #289e98;
  background-color: #ffffff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter span {
  padding-left: 12px;
  font-size: 1rem;
  color: #289e98;
}

.table_div_head {
  justify-content: space-between;
  align-items: center;
}

.second_child {
  display: flex;
  left: 63%;
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.create_btn {
  width: 40px;
  height: 40px;
  border: 1px solid #289e98;
  background-color: #289e98;
  border-radius: 50%;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.plus {
  font-size: 20px;
  font-weight: 700;
}

.extra {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.create {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #289e98;
  background-color: #289e98;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

a:link {
  text-decoration: none;
}

.th {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  padding: 0px 0.5rem;
  width: 100%;
  gap: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  white-space: nowrap;
}

.th:last-child {
  border-bottom: none;
}

.th p:nth-child(1) {
  width: 5rem;
  color: #666666;
  font-weight: 400;
  padding-left: 8px;
  font-size: 12px;
  box-sizing: border-box;
  display: inline-block;
}

.th p:nth-child(2) {
  width: 3rem;

  color: #666666;
  font-weight: 400;
  display: inline-block;
  box-sizing: border-box;
  font-size: 12px;
}

.th p:nth-child(3) {
  width: 10rem;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(4) {
  width: 10rem;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(5) {
  width: 7rem;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(6) {
  width: 7rem;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(7) {
  width: 4.5rem;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(8) {
  width: 6rem;
  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(9) {
  width: 6rem;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(10) {
  width: 6rem;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(11) {
  width: 7rem;

  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.th p:nth-child(12) {
  width: 4rem;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.th p:nth-child(13) {
  width: 4rem;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.tooltiptext {
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  bottom: 2.2rem;
  font-weight: 400;
  z-index: 1;
  transition: opacity 0.3s;
  border-color: black transparent transparent transparent;
}

.tooltipctainer {
  position: absolute;
  z-index: 1000;
  width: 10rem;
  height: 5rem;
  right: 3.5%;
  top: 60%;
  visibility: hidden;
  border: 1px solid #289e98;
  border-radius: 5px;
  background: #fff;
}

.td {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  padding: 0px 0.5rem;
  min-width: 64rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 7rem;

  color: #666666;
  font-weight: 400;
  padding-left: 0px;
  font-size: 12px;
  box-sizing: border-box;
  display: inline-block;
}

.td p:nth-child(2) {
  width: 3rem;

  color: #666666;
  font-weight: 400;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.td p:nth-child(3) {
  width: 6rem;

  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(4) {
  width: 12rem;

  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(5) {
  width: 12rem;
  color: #666666;
  font-weight: 400;
  text-align: center;

  font-size: 12px;
}

.td p:nth-child(6) {
  width: 6rem;
  text-align: center;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.td p:nth-child(7) {
  width: 7.3rem;
  text-align: center;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.td p:nth-child(8) {
  width: 5rem;

  color: #666666;
  font-weight: 400;

  font-size: 12px;
}

.td p:nth-child(9) {
  width: 7rem;

  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(10) {
  width: 13rem;

  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(11) {
  width: 9rem;

  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(12) {
  width: 11rem;

  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(13) {
  width: 10rem;

  color: #666666;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
}

.td p:nth-child(14) {
  width: 8rem;

  color: #666666;
  font-weight: 400;
  font-size: 12px;
}

.td p:nth-child(15) {
  width: 10rem;
  color: #289e98;
  font-size: 12px;
  font-weight: bold;
  display: flex;
}

.td p:nth-child(16) {
  width: 10rem;
  color: #289e98;
  font-size: 12px;
  font-weight: bold;
  display: flex;
}

.count {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #289e98;
  margin-left: 7px !important;
  margin-top: 16px;
}

.download {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 33px !important;
  margin-top: -5px;
  cursor: pointer;
}

.note_modal {
  margin: 0.5rem;
}

.notes_desc {
  text-align: center;
}

.btnRow {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.edit_btn {
  margin-right: 0.7rem;
  background-color: #289e98 !important;
  border: #289e98 !important;
  width: 4rem;
}

.cancel_btn {
  background-color: white;
  color: black;
  border: 2px solid #289e98 !important;
  width: 4rem;
}

.backColor1 {
  color: #289e98 !important;
}

.UsersList .td:nth-child(2) p div span {
  top: 40%;
}

.UsersList .td:last-child p div span {
  bottom: -50%;
}

.modal_emp_history {
  width: 32rem;
  min-height: 5rem;
  overflow: auto;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 1rem;
}

.modal_emp_history1 {
  min-height: 5rem;
  overflow: auto;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 1rem;
}

.modal_emp_history .heading {
  text-align: center;
  font-weight: 600;
}

.modal_emp_history .modal_btn_container {
  display: flex;
  justify-content: end;
}

.modal_emp_history .modal_btn {
  background-color: #289e98;
  border: 2px solid #289e98;
}

.modal_emp_history .modal_table thead tr th {
  font-weight: 400px;
}

.modal_emp_history .modal_table_container {
  max-height: 20rem;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}





.modal_emp_history1 .heading {
  text-align: center;
  font-weight: 600;
}

.modal_emp_history1 .modal_btn_container {
  display: flex;
  justify-content: end;
}

.modal_emp_history1 .modal_btn {
  background-color: #289e98;
  border: 2px solid #289e98;
}

.modal_emp_history1 .modal_table thead tr th {
  font-weight: 400px;
}

.modal_emp_history1 .modal_table_container {
  max-height: 20rem;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}


@mixin daywiseTH($value) {
  width: $value + rem;
  text-align: center;
  color: #666666;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // flex: 0 0 $value+rem;
  background-color: #f5f7f9;
  height: inherit;
  align-items: center;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@mixin daywiseTD($value) {
  width: $value + rem;
  text-align: center;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // flex: 0 0 $value+rem;
  height: inherit;
  align-items: center;
  display: flex;
}

.topBar {
  display: flex;
  justify-content: space-between;
  width: 28rem;
}

.s1 {
  position: relative;
  width: 14rem;
  margin-left: 0.6rem;
}

.s2 {
  position: relative;
  width: 14rem;
  margin-left: 0.6rem;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100000;
  top: 15px;
  margin-left: 80%;
  width: 21px;
  cursor: pointer;
}

.th {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  // width: 80rem;
  font-size: var(--atsFontSize);
  position: sticky;
  top: 0;
  z-index: 201;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.th div:nth-child(1) {
  @include daywiseTH(14);
  text-align: start;
  padding: 0.3rem;
  position: sticky;
  z-index: 200;
  top: 0;
  left: 0;
  background-color: #f5f7f9;
  display: flex;
  align-items: center;
  height: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.th div:nth-child(2) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(3) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(4) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(5) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(6) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(7) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(8) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(9) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(10) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(11) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(12) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(13) {
  @include daywiseTH(5);
  justify-content: center;
}

.th div:nth-child(14) {
  @include daywiseTH(6);
  // border: none;
  justify-content: center;
}

.no_data {
  text-align: center;
}

.td {
  display: flex;
  justify-content: space-between;
  // background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  // width: 80rem;
  font-size: var(--atsFontSize);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.td div:nth-child(1) {
  @include daywiseTD(14);
  text-align: start;
  padding: 0.3rem;
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.td div:nth-child(2) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(3) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(4) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(5) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(6) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(7) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(8) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(9) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(10) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(11) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(12) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(13) {
  @include daywiseTD(5);
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.td div:nth-child(14) {
  @include daywiseTD(6);
  border: none;
  justify-content: center;
}

.th1 {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 5rem;
  font-size: var(--atsFontSize);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 99rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.th1 div:nth-child(1) {
  @include daywiseTH(6);
  padding-left: 1rem;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;
}

.th1 div:nth-child(2) {
  @include daywiseTH(12);
  justify-content: start;
  position: sticky;
  white-space: nowrap;
  overflow: hidden;
  left: 83px;
}

.th1 div:nth-child(3) {
  @include daywiseTH(7);
  text-align: start;
}

.th1 div:nth-child(4) {
  @include daywiseTH(10);
  justify-content: center;
}

.th1 div:nth-child(5) {
  @include daywiseTH(12);
  text-align: start;
}

.th1 div:nth-child(6) {
  @include daywiseTH(7);
  text-align: start;
}

.th1 div:nth-child(7) {
  @include daywiseTH(6);
  text-align: start;
}

.th1 div:nth-child(8) {
  @include daywiseTH(6);
  justify-content: center;
}

.th1 div:nth-child(9) {
  @include daywiseTH(10);
  justify-content: center;
}

.th1 div:nth-child(10) {
  @include daywiseTH(7);
  justify-content: center;
}

.th1 div:nth-child(11) {
  @include daywiseTH(7);
  justify-content: center;
}

.th1 div:nth-child(12) {
  @include daywiseTH(7);
  justify-content: center;
}


.th1 div:nth-child(13) {
  @include daywiseTH(7);
  justify-content: center;
}


.th1 div:nth-child(14) {
  @include daywiseTH(7);
  justify-content: center;
}

.th1 div:nth-child(15) {
  @include daywiseTH(3);
  text-align: start;
}

.th1 div:nth-child(16) {
  @include daywiseTH(3);
  text-align: start;
}



.td1 {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 5rem;
  font-size: var(--atsFontSize);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 99rem;

}

.td1 div:nth-child(1) {
  @include daywiseTD(6);
  padding-left: 1rem;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: white;
  height: 15px;
}

.td1 div:nth-child(2) {
  @include daywiseTD(12);
  justify-content: start;
  position: sticky;
  white-space: nowrap;
  overflow: hidden;
  left: 83px;
  z-index: 1;
  background-color: white;
  height: 20px;
}

.td1 div:nth-child(3) {
  @include daywiseTD(7);
  text-align: start;
}

.td1 div:nth-child(4) {
  @include daywiseTD(10);
  justify-content: center;
}

.td1 div:nth-child(5) {
  @include daywiseTD(12);
  text-align: start;
}

.td1 div:nth-child(6) {
  @include daywiseTD(7);
  text-align: start;
}

.td1 div:nth-child(7) {
  @include daywiseTD(6);
  text-align: start;
}

.td1 div:nth-child(8) {
  @include daywiseTD(6);
  justify-content: center;
}

.td1 div:nth-child(9) {
  @include daywiseTD(10);
  justify-content: center;
}

.td1 div:nth-child(10) {
  @include daywiseTD(7);
  justify-content: center;
}

.td1 div:nth-child(11) {
  @include daywiseTD(7);
  justify-content: center;
}

.td1 div:nth-child(12) {
  @include daywiseTD(7);
  justify-content: center;

}

.td1 div:nth-child(13) {
  @include daywiseTD(7);
  justify-content: center;

}

.td1 div:nth-child(14) {
  @include daywiseTD(7);
  justify-content: center;
}

.td1 div:nth-child(15) {
  @include daywiseTD(3);
  justify-content: center;

}

.td1 div:nth-child(16) {
  @include daywiseTD(3);
  justify-content: center;
}

.modal_emp_history {
  /* width: 50rem; */
  min-height: 5rem;
  overflow: auto;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 1rem;
}

.modal_emp_history .heading {
  text-align: center;
  font-weight: 600;
}

.modal_emp_history .modal_btn_container {
  display: flex;
  justify-content: end;
}

.modal_emp_history .modal_btn {
  background-color: #289e98;
  border: 2px solid #289e98;
}

.modal_emp_history .modal_table thead tr th {
  font-weight: 400px;
}

.modal_emp_history .modal_table_container {
  max-height: 20rem;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.link {
  color: #289e98 !important;
  cursor: pointer;
}

.custom {
  width: 200px;
  /* Adjust the width as needed */
  color: #666666;
  /* Set the color */
  border: 1px solid #ccc;
  /* Add border */
  border-radius: 5px;
  /* Add rounded corners */
  padding: 5px;
  /* Add padding for better appearance */
}