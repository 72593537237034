.textArea{
    width: 60rem;
    height: 10rem;
}
.heading {
    font-weight: 600;
    text-align: center;
  }
  .btn_container{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .btn{
    background-color: #C3B14B;
    border: 1px solid #C3B14B;
  }