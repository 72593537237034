.projectresource_mainsection h6 {
    color: #48729ed5;
    margin-top: 20px;
    margin-left: 5px;
  }
/* .imgdiv {
    background-color:#48729ed5;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
  } */
  
  /* .imgdiv img {
    height: 50%;
    width: 80%;
  } */
  .imgcontentSection {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 1px 1px 14px #00000029;
    opacity: 1;
    margin-top: -18px;
    border-radius: 1em;
    width: 14em;
    height: 12em;
    /* margin-left: 45px; */
    justify-content: center;
    align-items: center;
    
    cursor: pointer;
  }
  .imgcontentSection h1 {
    color:white;
  }
  
  .imgcontentSection span {
    color: grey;
    opacity: 1;
    font-size: 16px;
    font-weight: 500;
    margin: 10px;
    margin-top:20px;
  }
  .allCards{
    display: flex;
  }
  .imgSection {
    width: 95%;
    display: flex;
    flex-direction: row;
    padding: 30px 0 0 0;
    align-items: center;
    margin-left: 25px;
    gap: 40px;
    flex-wrap: wrap;
  }
  .circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: #48729ed5;
    border-radius: 50%;
    text-align: center;   
    }
    .count{
      margin-bottom: 0px;
    }
    .upper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
      margin-top: 15px;
      margin-right: 25px;
    }
    .error{
      position: absolute;
      top: 50%;
      left: 50%;
      color: red;
    }
    .s1 {
      position: relative;
      width: 13rem;
      margin-left: 0.6rem;
    }
    .cp_serchIcon {
      position: absolute;
      z-index: 100;
      top: 15%;
      margin-left: 84%;
      width: 21px;
      cursor: pointer;
    }