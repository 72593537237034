.search {
    position: relative;
    width: 14rem;
}

.cp_serchIcon {
    position: absolute;
    z-index: 100;
    top: 12%;
    margin-left: 88%;
    width: 21px;
    cursor: pointer;
}