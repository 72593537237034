*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_client_body{
    width: 100%;
    /* height: 90vh; */
    display: flex;
}

.table_div{
    width: 80%;
    padding: 0px 20px;
}

.table_div_head{
    /* border: 1px solid red; */
    /* padding: 15px; */
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
}

.breadscrumb{
    font-size: 10px;
    font-weight: bold;
}

.second_child{
    display: flex;
    justify-content: space-between;
    padding-bottom:10px;
}

.filter_btn{
    background-color: white;
    padding: 2px 17px;
    border-radius: 20px;
    outline: none;
    border: 1px solid  rgb(252, 187, 8);
    color:rgb(252, 187, 8)
}

.recent_tab{
    color: rgb(252, 187, 8);
}

.arrow{
    font-size: 13px;
    font-weight: bold;
}

.original_table{
    /* border: 2px solid red; */
    padding: 15px;
    box-shadow: 0px 0px 5px 0px grey;
    border-radius: 10px;
}

.search_emp{
    border-radius: 20px;
    height: 40px;
    outline: none;
    border: 1px solid white;
    box-shadow: 0px 0px 5px 0px grey !important;
    padding: 0px 15px;
}

.number{
    border: 1px solid rgb(252, 187, 8);
    margin-left: 13px;
    padding: 9px;
    border-radius: 50%;
    color: rgb(252, 187, 8);
}

.button{
    width: 90px;
    height: 35px;
    border: 1px solid rgb(252, 187, 8);
    background-color: rgb(252, 187, 8);
    margin-left: 12px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}