@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

$blue_primary: #0f5ef7;
$green_secondary: #65a15e;
$yellow_ternary: #fcb902;
$black_header: #172a3e;
$light_background: rgb(246, 250, 243);
;
$sub_text: #707070;
$danger: #f94244;

:root {
  --invoiceColor: #855ea1;
  --invoiceHeading: 1rem;
  --invoiceTextColor: rgb(29, 28, 28);
}

html {
  tab-size: 4;
  line-height: 1.5;
}

button:hover {
  transform: scale(0.97);
  cursor: pointer;
  opacity: 0.7;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light_background !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Poppins", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  background-repeat: no-repeat;
}

img {
  max-width: 100%;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  // z-index: 300 !important;
}

[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.field-required {
  color: #f84142;
}

.react-calendar {
  line-height: 0.125em !important;
}

.react-calendar__navigation {
  height: 37px !important;
}

element.style {
  animation: 300ms ease 0s 1 normal none running react-responsive-modal-modal-in;
}

.react-responsive-modal-modal {
  overflow: hidden;
}

.react-responsive-modal-modal {
  border-radius: 10px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.css-i4bv87-MuiSvgIcon-root {
  margin-top: -5px !important;
}

// .react-responsive-modal-modal {
//   max-width: 998px !important;
//   min-width: 998px !important;
// }

// .UsersList_s1__3Kct3{
//   width: 140px !important;
// }

.react-datepicker__month-container {
  float: left;
  width: 240px;
}

.react-datepicker-popper {
  z-index: 10000 !important;
}