*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    }  
    .projectresource_mainSection{
      width: 100%;
      /* height: 90vh; */
      display: flex;
      /* border: 1px solid red; */
    }
    .projectresource_sidebarsection{
      /* border: 10px solid red; */
   }
   .projectresource_mainsection{
    width:80%;
    /* margin-left:2em; */
    padding: 0px 20px;
    /* border: 1px solid red; */
  }
  .head{color:#fcb902}
  
  
  .pargraph {
    padding-top: 10px;
  }
  /* this is main */
  .maindiv{
    /* border:3px solid red; */
    background-color: #FFFFFF;
  width: 100%;
  height: 24rem;
  border-radius: 6px;
  padding-top: 10px;
  box-shadow: 0px 0px 5px 0px grey;
  }
  .maindivContent{
      display: flex;
      justify-content: center;
      flex-direction: row;
  }
  .span1{
    /* margin-left: 420px; */
    color:#fcb902
  }
  .maindiv div{
    padding-top: 0px;
    font-weight: 500;
  }
  .maindiv div p{
    font-weight: 500;
    height: 7px;
    font-size: 15px;
    
  }
  
  /* this is form section */
  .projectmaindiv{
    width: 100%;
    margin-top: 20px;
    display: flex;
  }
  .projectformSection{
    width: 40%;
    display: flex;
    flex-direction: column;
  }
  .projctdesSection{
    width: 60%;
  }
  .proHeader{
    display: flex;
    flex-direction: row;
  }
  .inputbutton{
    margin-left: 90%;
  }
  .inputbutton  button{
    width:80px;
    margin-left: -35px;
    margin-top: 14px;
    height:38px;
    border-radius: 6px;
    background-color: #fcb902;
  
  }
  .desc{
    margin-left: 33px;
  }
