.react-responsive-modal-modal {
  border-radius: 10px;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-responsive-modal-modal {
  max-width: 1200px;
}