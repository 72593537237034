.container {
  box-shadow: 0 0px 5px 2px rgb(0 0 0 / 20%);
border-radius: 10px;
background-color: #fff;
padding-top: 5px;
padding-bottom: 10px;
margin: 10px 20px;
}
.title {
  display: flex;
  justify-content: center;
  color: #202424;
  font-weight: 700;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  margin-left: 30px;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.form_row {
  display: flex;
  justify-content: left;
  gap: 30px;
  margin: 0 1%;
}
.input_field {
  box-shadow: 0 3px 9px rgba(8, 7, 7, 0.161);
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  background: transparent 0 0 no-repeat padding-box;
  background-color: #f1f5fd;
  border: none;
  padding: 5px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  height: 2.3rem;
  outline: none;
  display: flex;
}
.label {
  font-size: 14px;
  font-weight: 600;
  color: #585656;
  margin-left: 10px;
}
.submit_btn {
  display: flex;
  justify-content: space-between;
}
.select{
  font-size: 14px;
  font-weight: 400;
  color: #1b1818;
  box-shadow: 0 3px 9px rgba(8, 7, 7, 0.161);
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  background: transparent 0 0 no-repeat padding-box;
  background-color: #f1f5fd;
  padding: 5px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  height: 2.3rem;
  outline: none;
  display: flex;
  border: 1px solid #fff;
}
.form{
  padding:5px 10px 0px 5px;
}
.buttons{
  display: flex;
  justify-content: end;
  gap: 20px;
}
.selectStyling{
  outline: none;
  border: none;
  width: 100%;
  background: transparent;
}
.hideError{
  display: none;
}
.errorText{
  color: red;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  margin-left: 18px;
  margin-top: -20px;
}
.showError{
  display: block;
}
.form_row>div>div>.select>input[type="date"]::-webkit-calendar-picker-indicator{

  background: url('../../../../../assets/Appraisal_Cal.png');
  background-size:contain;
  cursor: pointer;
  background-repeat: no-repeat;
}