/* .tabletaskTitle {
    color: #65a15e;
    cursor: pointer;
    text-decoration-line: underline;
    text-underline-position: under;
} */
.description {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* max-width: 100px; */
    overflow: hidden;
    text-overflow: ellipsis;
}
.backColor{
color: #172A3E !important;
}

.tabletaskTitle {
    width: 45%;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* color: #65a15e; */
    
   
    /* white-space: nowrap; */
}