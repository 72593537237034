.tooltipctainer {
    background-color: #fff;
    position: absolute;
    font-weight: 400;
    z-index: 10000;
    transition: opacity 1s;
    /* right: 8%; */
    /* top: 0%; */
    bottom: -16%;
    left: 17rem;
    min-width: 5rem;
    min-height: 2rem;
    border: 2px solid rgb(252, 187, 8);
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    font-size: 14px;
}



/* position: absolute;
z-index: 1000;
width: 10rem;

right: 3.5%;
top: 60%;
visibility: hidden;
border: 1px solid #289e98;
border-radius: 5px;
background: #fff; */