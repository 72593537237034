.filter,
.createBtn {
  border: 1px solid #855ea1;
  border-radius: 4px;
  color: white;
  background-color: #855ea1;
  width: 7vw;
  font-size: 15px;
}

.cancelBtn {
  border: 1px solid #855ea1;
  border-radius: 4px;
  color: #855ea1;
  background-color: #fcfcfc;
  width: 7vw;
  font-size: 15px;
}

.filterBtn {
  /* display: flex;
  grid-gap: 20px;
  height: 20%;
  gap: 20px;
  justify-content: flex-end;
  align-items: flex-end; */
  display: flex;
  /* grid-gap: 20px; */
  /* height: 20%; */
  margin-top: 1rem;
  gap: 20px;
  justify-content: flex-end;
  align-items: flex-end;
}

.filterModal {
  max-width: 70rem;
  /* height: 12rem; */
}

.filterCards {
  display: flex;
  height: 100%;
  /* justify-content: space-between; */
  gap: 10px;
  flex-wrap: wrap;
}

.filte_heading {
  background-color: #e0d8d8;
  color: black;
  font-weight: 600;
  width: inherit;
  justify-content: center;
  display: flex;
  align-items: center;
  /* margin: -5px -5px 5px -5px; */
  width: 100%;
  height: 2rem;
  font-size: 15px;
  border-radius: 5px;
}

.firstCard {
  width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #e0d8d8;
  /* padding: 5px; */
  border-radius: 5px;
  position: relative;
}

.input {
  width: 91%;
  border: 1px solid #6c757d;
  outline: none;
  margin: 0.6rem 0.5rem;
  border-radius: 0.4rem;
  padding: 0.25rem 0.2rem;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 17%;
  margin-left: 82%;
  width: 21px;
  cursor: pointer;
}

/* .checkbox {
  visibility: visible !important;
} */

.list {
  /* display: flex;
  gap: 10px;
  padding-left: 0.7rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center; */
  /* justify-content: center; */
}

/* .vh {
  position: absolute !important;

  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
} */

/*  Add a :before to each label and style this as the checkbox you want to have */
/* 
.label:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #f2f2f2;
  border: 1px solid rgba(75, 101, 132, 0.3);
  display: inline-block;
  margin-right: 16px;
 } */

/*  Change the styling of the :before when the hidden checkbox is checked, you also can add a background-image  */

/* input[type="checkbox"]:checked~label:before {
  background: #855ea1 no-repeat center;
  background-size: 12px 12px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
} */

/*  Show when label and checkbox have focus, to make this keyboard accessible > press spacebar to select the checkbox */

/* input[type="checkbox"]:focus~label {
  color: #855ea1;
} */
