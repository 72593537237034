.mainSection {
    min-height:100vh;
    height: auto;
    display: flex;
    gap: 10px;
  }
   .content{
    width: 80%;
    padding: 0px 15px;
  }
  