.createBtn {
  width: auto;
  background-color: var(--invoiceColor);
  color: white;
  border: 1px solid var(--invoiceColor);
  border-radius: 5px;
  padding: 0.4rem 0.7rem;
}
.cancelBtn {
  width: 80px;
  background-color: white;
  color: var(--invoiceColor);
  border: 1px solid var(--invoiceColor);
  border-radius: 5px;
  padding: 0.4rem 0.7rem;
}
