.IssueList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 96.5%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 10px;
  font-size: 14px;

}
.setFilter{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.th,
.td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 767px) {
  .th,
  .td {
    padding: 4px;
    font-size:14px;
  }
}

.IssueList1 {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 96.5%;
  margin: 10px auto;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 10px;
  min-height: 5rem;
}
.th {
  
display: flex; 
justify-content: space-around;
background-color: #f5f7f9;
border-radius: 10px;
align-items: center;
height: 40px;
color: #666666;
font-size: 14px;
font-weight: 600;

}
.td {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  font-weight: 600;
}
.tableContent {
  display: flex;
  justify-content: space-around;
  text-align: start;
  min-width:7.7%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;

}
.tableContentt {
  width:5.1rem;
  text-align: start;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  color:#9c57a5
}
.th1 {
  background-color: #f5f7f9;
  border-radius: 10px;

  height: 40px;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.tableContent1 {
  width: 10%;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableContent2 {
  width: 6%;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.td1 {
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
}
.main {
  width: 80%;
}
.pp {
  padding-left: 21px;
  font-weight: 500;
  padding-top: 5px;
}
.select {
  position: absolute;
  right: 18rem;
}
.div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.link {
  font-size: 16px;
  cursor: pointer;
}
.link:hover{
  color: #9c57a5;
}
.div {
  display: flex;
  justify-content: space-between;
  align-items: center; /* optional: center the items vertically */
}

.pp {
  text-align: left;
}

.Select {
  width: 200px; /* adjust to fit your design */
}

.cu_col1 {
  flex: 0 0 32%;
  padding-right: 1.1rem;
  padding-left: 5rem;
  padding-bottom: 7px;
}

.IssueList1 table {
  border-collapse: collapse;
  width: 100%;
}

.IssueList1 th {
  background-color: #f5f7f9;
  border: 1px solid #ddd;
  font-weight: bold;
  text-align: center;
  padding: 8px;
}

.IssueList1 td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.IssueList1 .link {
  cursor: pointer;
  text-decoration: underline;
}

.IssueList1 .td1 {
  font-size: 14px;
  font-weight: 500;
}

.IssueList1 .th1 {
  font-size: 14px;
  font-weight: bold;
}
