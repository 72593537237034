.header {
  background-color: #172a3e;
  width: 100%;
  height: 10vh;
  display: flex;
  border-bottom-right-radius: 15px;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.profileImage {
  height: 50px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  opacity: 1;
}

.username {
  color: white;
  float: left;
  padding-top: 13px;
  padding-right: 8px;
  /* font-weight: 700;
    font-family: "poppins";
   margin-left: 11px;
   color: #FFFFFF;
   text-decoration: underline;
   cursor: pointer;*/
  transform: scale(0.98);
}

.header p {
  color: white;
}

.dashboardImage {
  margin: 7px 0px 10px 13px;
  margin-left: 30px !important;
  height: 45px;
  cursor: pointer;
}

.horizontalBar {
  display: none;
}

@media screen and (max-width: 768px) {
  .horizontalBar {
    display: block;
  }
  .dashboardImage {
    display: none;
  }
}
