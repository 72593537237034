* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.count {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(252, 187, 8);
  /* border: 2px ; */
  margin-left: 0px !important;
  margin-top: 16px;
}

.backColor1 {
  color: rgb(252, 187, 8) !important;
}
.main_client_body {
  width: 100%;
  /* height: 95vh; */
  display: flex;
}
.noData {
  position: absolute;
  top: 15%;
  left: 50%;
}
.btn_add {
  width: 20%;
  padding-left: 5.5%;
}

@media screen and (max-width: 1280px) {
  .btn_add {
    width: 20%;
    padding-left: 4.5%;
  }
}

.s1 {
  position: relative;
  width: 230px;
}
.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 84%;
  width: 21px;
  cursor: pointer;
}

.check {
  position: absolute;
  top: -12px;
  right: 2px;
  border-radius: 50%;
}

.filter_box {
  position: absolute;
  top: 0px;
  width: 100px;
  height: 100%;
  background-color: red;
  z-index: 1;
}

.modalStyle {
  position: absolute;
  font-size: 11pt;
  display: grid;
  background-color: #ffffff;
  border: 1px solid gainsboro;
  width: 115px;
  height: 107px;
  top: 100%;
  right: 0%;
  left: -5%;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 9px;
}

.modalStyle .label1 {
  padding: 0% 33% 0% 15%;
  color: #009245;
  cursor: pointer;
}

.modalStyle .label2 {
  padding: 0% 15% 0% 15%;
  color: rgb(221, 90, 90);
  cursor: pointer;
}

.modalStyle .label3 {
  padding: 0% 15% 0% 15%;
  color: rgb(252, 187, 8);
  cursor: pointer;
}

.project_heading .prmheading {
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.side_bar .side_buttons_div {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  padding: 10px;
}

.dashboard_btn {
  border: 1px solid red;
}

.heading {
  color: white;
  font-weight: 400;
}

.table_div {
  width: 80%;
  padding: 0px 20px;
}

.active_btn {
  background-color: white;
  padding: 4px 15px;
  border-radius: 10px;
  border: 1px solid green;
}

.table_div_head {
  /* border: 1px solid red; */
  /* padding: 15px; */
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.noData {
  /* border: 1px solid green; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noData_header {
  color: #989898;
}

.original_table {
  /* border: 2px solid red; */
  /* padding: 15px; */
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 10px;
  height: 65%;
}

.right_side {
  display: flex;
  justify-content: space-between;
}

.breadscrumb {
  font-size: 10px;
  font-weight: bold;
}

.appliedcheck {
  /* position: absolute; */
  left: 63%;
  /* top: 63px; */
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}

.recent_tab {
  color: rgb(252, 187, 8);
}

.arrow {
  font-size: 13px;
  font-weight: bold;
  color: rgb(252, 187, 8);
}

.second_child {
  display: flex;
  /* justify-content: space-evenly; */
  padding-bottom: 10px;
}

.filter_btn {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid rgb(252, 187, 8);
  background-color: #ffffff;
  outline: none;
  color: rgb(252, 187, 8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 28%;
}

.create_btn {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.icon {
  color: white;
}

.btn_menu:hover .icon {
  color: rgb(252, 187, 8);
}

.plus {
  font-size: 20px;
  padding-right: 4px;
  font-weight: 700;
}
