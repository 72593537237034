.emp_details {
  text-align: center;
  color: #4b59c3;
  font-weight: 500;
  font-size: var(--inoiceHeading);
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  padding-bottom: 0.5rem;
}
.label {
  font-weight: bold;
}
.border {
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  margin: 1rem 0px;
}

.Container {
  width: 95%;
  margin: 0.8rem auto;
  display: flex;
  justify-content: space-between;
}
.col1 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.col2 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col3 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col4 {
  width: 23.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.col212 {
  width: 25.5%;
}
.values1 {
  /* color: grey;
  font-weight: 500; */
  border-left: 4px solid red;
  margin-top: 11px !important;
}
.values2 {
  /* color: grey;
  font-weight: 500; */
  border-left: 4px solid green;
  margin-top: 11px !important;
}
.values {
  /* color: grey;
  font-weight: 500; */
  margin-top: 11px !important;
  width: 22rem;
}
/* .valueFolderLink {
  margin-left: -42rem !important;
} */
.labelFolderLink {
  font-weight: bold;
  /* margin-left: -42rem !important; */
}
.heading {
  margin-left: 28px;
  font-weight: 500;
}
.lastBtn {
  display: flex;
  justify-content: end;
  /* width: 97.6%; */
  grid-column-gap: 2%;
  margin: 2% 1% 2% 0;
}
.addNoteBtn{
  background-color: #4B59C3;
  color: #fff;
  border: 1px solid var(--invoiceColor);
}