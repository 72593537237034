
*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.leftSection {
  width: 20%;
  background-color: #172A3E;
  min-height: 45rem;
  /* border: 2px solid blue; */
}

.dashboardImage {
  margin-left: 30px !important;
  height: 45px;
  margin-top: 7px;
  margin-bottom: 10px;
  margin-right: 13px;
  cursor: pointer;
}
.leftSection button {
  width: -webkit-fill-available;
  margin-left: 10px;
  margin-right: 10px;
  
  border-radius: 15px;
  opacity: 1;
 outline: none;
 /* border: none; */
  margin-bottom: 10px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}

.leftSection Link {
  
  width: -webkit-fill-available;
  margin-left: 10px;
  margin-right: 10px;
  
  border-radius: 15px;
  opacity: 1;
 outline: none;
 /* border: none; */
  margin-bottom: 10px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}

.leftSection .taskTracker {
  background-color:#964431;
  height: 70px; 
  padding-left: 15px;
  border: none;
}

.leftSection .taskTracker span {
  text-align: left;
  font: normal normal 600 16px/20px Poppins, sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}

.leftSection .taskTracker div {
  background-color: white;
  width: 54px;
  height: 54px;
  border-radius: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.leftSection .myTask {
  background-color:#172A3E;
  display: flex;
  height: 50px;
  color: #ffffff;
  padding-left: 15px;
  border: none;
  
}

.leftSection .myTask span {
  font: normal normal medium 18px/27px ;
  letter-spacing: 0px;
  opacity: 1;
  padding-left: 15px;
}

.leftSection .devices {
  background-color:#172A3E;
  /* display: flex; */
  /* height: 50px; */
  color: #ffffff;
  padding-left: 15px;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 15px;
  border-radius: 20Px;

}

.leftSection .devices .subButton {
  background-color:#2F4052;
  display: flex;
  height: 50px;
  color: #ffffff;
  padding-left: 15px;
  border: none;

}
.leftSection .devices .devicesMain {
  display: flex;
}

.leftSection .devices .devicesMain span {
  font: normal normal medium 18px/27px ;
  letter-spacing: 0px;
  opacity: 1;
  padding-left: 15px;
  width: 70%;
  text-align: left;
}



@media screen and (max-width: 800px) {
  .leftSection {
      display: none;
  }
}

.btn_menu{
  margin-top: 10px;
  display: flex;
  padding: 5%;
  justify-content: center;
  border-radius: 10px;
  font-weight: 500;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;

}


.btn_menu img{
  height:1em;
  width:2em;
}

.btn_menu div{
  color: white;
  margin-left : 10%;
  width: 100%;
}


.btn_menu:hover{
  cursor:pointer;
  background-color: white;
  color: red;

}

.btn_menu:hover .heading{
  color:#964431;
}

.icon{
  color:white;
}

.btn_menu:hover .icon{
  color:#964431;
}

.accordion-button {
  background-color:black !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: white;
}

.side_bar{

   width:20%;
  min-height: 45rem; 
  /* padding: 5px; */
  /* height: 100%; */
  background-color: #172A3E;
}

.side_buttons_div{
  /* min-height: 20rem; */
  width: 100%;
  padding: 10px;
}

.project_heading{
  background-color:#964431;
  padding: 2%;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  margin-left: 15px;
  margin-right: 15px;
}

.img_div{
  width: 70px;
  height: 70px;
  padding: 20px;
  background-color: white;
  border-radius: 50%;
}

.img{
  width: 2em;
  height: 2em;
}

.prmheading{
  color:white;
  font-size: 20px;
  font-weight: 500;
}

.active_btn{
  background-color: white;
  margin-top: 10px;
  display: flex;
  padding: 5%;
  justify-content: center;
  border-radius: 10px;
  font-weight: 500;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.active_btn div{
  color:#964431;
  margin-left : 10%;
  width: 100%;
}

.active_btn .icon{
  color:#964431;
}




