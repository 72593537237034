.smallInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 93%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
    margin-left: 10px;
}

.upload_resume::-webkit-file-upload-button {
    border: none;
    padding: 5px;
    cursor: pointer;
}

.upload_resume {
    min-width: 10%;
    border-radius: 6px;
}

.chooseFile {
    height: 38px;
    padding-left: 0px;
    padding-top: 3px;
    margin-left: 14px;
    margin-right: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px grey;
    /* margin-top: 10px; */
}

.cu_label1 {
    font-size: 14px;
    font-weight: 500;
    padding-left: 24px;
    color: #000000;
}

.label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 12px !important;
}

.label1 {
    /* margin-top: 1px !important; */
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 12px !important;
}

.buttonAdd {
    background-color: #c3b14b;
    border-color: #c3b14b;
    width: 100px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 36px;
    margin-bottom: 12px;
}

.primaryContact {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    border-top: 2px solid rgba(8, 8, 8, 0.1);
}

.SecondaryContact {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    border-top: 2px solid rgba(8, 8, 8, 0.1);
}

.errorText {
    color: red;
    font-size: 12px;
    visibility: visible;
    padding-left: 10px;
    margin-top: 3px;
    /* padding: 0.4rem 0.5rem 0.2rem 0.5rem; */
}

.errorTextHidden {
    color: red;
    font-size: 12px;
    margin-left: 15px;
    visibility: hidden;
}

.errorText1 {
    color: red;
    font-size: 12px;
    visibility: visible;
    padding-left: 18px;
    margin-top: 3px;
    /* padding: 0.4rem 0.5rem 0.2rem 0.5rem; */
}

.errorTextHidden1 {
    color: red;
    font-size: 12px;
    margin-left: 15px;
    visibility: hidden;
}

.smallInput1 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 90%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-left: 15px;
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
}

.buttonAdditem {
    background-color: #c3b14b;
    border-color: #c3b14b;
    width: 81px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    margin-right: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.buttonCancle {
    width: 81px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    background: transparent 0% 0% no-repeat padding-box;
    color: #c3b14b;
    border: 1px solid #c3b14b;
    margin-top: 10px;
    margin-bottom: 10px;
}

.buttonText {
    display: flex;
    justify-content: end;
}

.label_item1 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    font-size: 12px;
    margin-left: 15px !important;
}

.remove_icon {
    color: #c3b14b;
    margin-top: 2.9rem;
    cursor: pointer;
    margin-left: -25px;
}

.cu_field23Last {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 93%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
    margin-left: 10px;
}

/* New styling */

.module_heading {}

/* .container {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    width: 95%;
    border-radius: 5px;
    margin: 0 auto;
  } */


.label2 {
    /* margin-top: 1px !important; */
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 12px !important;
}

.smallInput2Domain {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 91%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
    margin-left: 10px;
    margin-bottom: 20px;
}