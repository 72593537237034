.description_box1 {
    /* border: 1px solid red; */
    padding: 0px;
    /* height: auto; */
    margin-top: 15px;
    box-shadow: 0px 0px 5px 0px grey;
    border-radius: 7px;
    background-color: white;
  }
  .span2 {
    text-align: center;
    color: #fcb902;
    font-weight: 600;
  }
  .maindivContent {
    display: flex;
    flex-direction: row;
  }