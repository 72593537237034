.dropdown{
    position: inherit !important;
}
.custom-dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    padding : 0px 6px !important;
}

.custom-dropdown-toggle2 {
    background-color: transparent !important;
    border: none !important;
    padding : 0px 6px !important;
}

.custom-dropdown-toggle3 {
    border: none !important;
    padding : 0px 6px !important;
    border-radius: 5px !important;
}

.custom-dropdown-toggle:hover {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}
.custom-dropdown-toggle:active{
    border: none !important;
    box-shadow: none !important;
}
.custom-dropdown-toggle:focus:not(:focus-visible){
    outline: none !important;
    box-shadow: none !important;
}
.custom-dropdown-toggle::after {
    display: none !important;
}
.custom-dropdown-toggle3:hover {
    border: none !important;
    box-shadow: none !important;
}
.custom-dropdown-toggle3:active{
    border: none !important;
    box-shadow: none !important;
}
.custom-dropdown-toggle3:focus:not(:focus-visible){
    outline: none !important;
    box-shadow: none !important;
}
.custom-dropdown-toggle3::after {
    display: none !important;
}
.custom-dropdown-menu{
    min-width: 6rem !important;
    inset: 0px auto auto -58px !important;
    padding: 0 !important;
    border: 1px solid grey !important;
}
.custom-dropdown-menu-items{
    text-align: center !important;
    z-index: 1 !important;
}
.custom-dropdown-menu-items-border{
    border-bottom: 1px solid grey !important;
}
.custom-dropdown-menu-items:active{
    background: pink !important;
}
.custom-dropdown-menu2{
    min-width: 6rem !important;
    inset: 0px auto auto 15px !important;
    padding: 0 !important;
    border: 1px solid grey !important;
    z-index: 2 !important;
}
.custom-dropdown-menu-items-border-conditional{
    border-bottom: none !important;
}
.custom-dropdown-menu3{
    inset: 4px 0px auto auto !important;
    padding: 0 !important;
    border: 1px solid grey !important;
}
.custom-dropdown-menu3 a:nth-child(1){
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}
.custom-dropdown-menu3 a:last-child{
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-bottom: none !important;
}
.custom-dropdown-menu3 a:hover{
    background-color: #f8f9fa !important;
    color: black !important;
}