.mainSection{
    display: flex;
}
.invoicecontainer {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px 0px;
  font-size: 14px;
  z-index: 0;
}
.th {
    display: flex;
    justify-content: space-around;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    color: #666666;
    font-size: 12px;
    font-weight: 600;
  }
  
  .count {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid blue;
    margin-left: 7px !important;
    margin-top: 16px;
  }
  
  .backColor1 {
    color: blue !important;
  }
  
  .search_bar_div {
    position: relative;
  }
  
  .search_icon {
    background-color: blue;
    position: absolute;
    right: 0%;
    top: 0%;
    height: 100%;
    width: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  
  .search_icon_img {
    width: 50%;
  }
  
  .search_bar_input::placeholder {
    font-size: 0.8em;
  }
  
  .search_bar_input {
    border: 2px solid blue;
    border-radius: 5px;
    padding: 7px 10px;
    width: 19vw;
    outline: none;
    padding-right: 56px;
  }
  
  .no_data {
    text-align: center;
    padding: 3%;
  }
  
  .th p:nth-child(1) {
    width: 6rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
    padding-left: 8px;
  }
  
  .th p:nth-child(2) {
    width: 8rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(3) {
    width: 8rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(4) {
    width: 7rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(5) {
    width: 7rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(6) {
    width: 6rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(7) {
    width: 6rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(8) {
    width: 5rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
  }
  
  .th p:nth-child(9) {
    width: 5rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
    margin-right: 10px !important;
  }
  .th p:nth-child(10) {
    width: 5rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
    margin-right: 10px !important;
  }
  .th p:nth-child(11) {
    width: 3rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 600;
    font-size: 12px;
    margin-right: 10px !important;
  }
  .td {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 12px;
  }
  
  .td:last-child {
    border-bottom: none;
  }
  
  .td p:nth-child(1) {
    width: 6rem;
    text-align: start;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 10px;
    font-size: 12px;
    color: blue !important;
  }
  
  .td p:nth-child(2) {
    width: 8rem;
    text-align: start;
    color: #855ea1 !important;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
  }
  
  .td p:nth-child(3) {
    width: 8rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
  }
  
  .td p:nth-child(4) {
    width:7rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
  }
  
  .td p:nth-child(5) {
    width: 7rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
  }
  
  .td p:nth-child(6) {
    width: 6rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
  }
  
  /* .td p:nth-child(6) a {
    color: blue !important;
    text-decoration: underline;
  } */
  
  .td p:nth-child(7) {
    width: 6rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  
  .td p:nth-child(8) {
    width: 5rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  
  .td p:nth-child(9) {
    width: 5rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    margin-right: 10px !important;
  }
  .td p:nth-child(10) {
    width: 5rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    margin-right: 10px !important;
  }
  .td p:nth-child(11) {
    width: 3rem;
    text-align: start;
    display: block;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    margin-right: 10px !important;
  }
  .add {
    border: 2px solid #855ea1;
    color: #855ea1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .tooltip {
    margin-left: 10px;
  }
  
  .add>p {
    font-size: 14px;
    font-weight: bold;
  }
  
  .add:hover {
    cursor: pointer;
    background-color: #855ea1;
    color: #ffffff;
  }
  
  p {
    margin: 0;
  }
  
  .header {
    width: 95%;
    margin-left: 30px;
    margin-top: 10px;
  }
  
  .tableContent {
    width: 155px;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #666666;
  }
  
  .tableContentStatus {
    display: flex;
    align-items: center;
    column-gap: 5px;
    width: 155px;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    cursor: pointer;
  }
  
  .tableContent1 {
    width: 155px;
    padding-left: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .tableContent3 {
    width: 155px;
    padding-left: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: blue !important;
    cursor: pointer;
    text-decoration: underline;
  }
  .modal_notes_container {
    min-height: 5rem;
    overflow: auto;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 1rem;
  }
  .modal_notes_container .heading{
    text-align: center;
    font-weight: 600;
  }
  .modal_notes_container .modal_btn_container{
    display: flex;
    justify-content: end;
  }
  .modal_notes_container .modal_btn{
    background-color: #4B59C3;
    border: 2px solid #4B59C3;
  }
  .modal_notes_container .modal_table thead tr th{
    font-weight: 400px;
  }
  .modal_notes_container .modal_table_container{
    max-height: 20rem;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }


  .upper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    width: 95%;
    margin-left: 27px;
  }
  .countI{
    display: flex;
    align-items: center;
  }
  .rightSide{
    width: 80%;
  }
  .table{
    width: 80vw;
  }
  .confirmPopup {
    width: 386px;
    min-height: 4rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
    max-height: 600px;
    overflow: auto;
    padding: 1rem;
  }
  .delete {
    width: 90px;
    height: 40px;
    border: 1px solid #289e98;
    background-color: #289e98;
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
  }
  .fnf_count_container {
    width: 95%;
    margin: 0 auto;
    border-radius: 15px;
    display: flex;
    justify-content: start;
    gap: 30px;
    margin-top: 1rem;
  }
  .countCircle{
    border: 2px solid #4B59C3;
    padding: 2px 7px;
  }
  .empDetails{
    font-weight: 500;
  }