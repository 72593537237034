.progressive {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 1rem;
  /* padding-bottom: 1rem; */
  align-items: center;
  padding-left: 13rem;
  padding-right: 2rem;
  font-size: 14px;
}

.p {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666666;
  font-weight: 600;
}

.pSelected {
  display: flex;
  align-items: center;
  color: #65a15e;
  font-weight: 600;
  gap: 5px;
}

.pnumSelected {
  border-radius: 50%;
  width: 30px;
  line-height: 6px;
  padding: 10px;
  background: #65a15e;
  color: white;
  text-align: center;
}

.pnum {
  border-radius: 50%;
  width: 30px;
  line-height: 6px;
  padding: 10px;
  background: #666;
  color: white;
  text-align: center;
}

.divider {
  flex-grow: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.418);
  margin: 5px;
  color: white;
}

.dividercolor {
  flex-grow: 1;
  border-bottom: 1px solid #65a15e;
  margin: 5px;
  color: #65a15e;
}