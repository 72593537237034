*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.breadscrumb{
    font-size: 10px;
    font-weight: bold;
}

.arrow{
    font-size: 13px;
    font-weight: 700;
}

.arrow2{
    font-size: 13px;
    font-weight: 600;
    color: rgb(252, 187, 8);
}

.main_client_body{
    width: 100%;
    /* height: 90vh; */
    display: flex;
}

.container{
    margin-top: 10px;
    width: 100%;
    float: right;
    text-align:right;
}
.container button{
    display: inline-block;
}
#btn1{
    float:left;
}
#btn3{
    float:right;
}

.center_div{
    width: 80%;
    padding: 0px 20px;
}

.description_box{
    
    padding: 17px;
    box-shadow: 0px 0px 5px 0px grey;
    border-radius: 7px;
    background-color: white;
    margin-top: 25px;
}

.recent_tab{
    color: rgb(252, 187, 8);
    
}

.arrow {
    font-size: 13px;
    font-weight: bold;
    color: rgb(252, 187, 8);
  }

.btn_box{

    padding: 10px 0px;
    display: flex;
    justify-content: flex-end;
}

.archive_btn{
    width: 90px;
    height: 40px;
    border: 1px solid rgb(252, 187, 8);
    background-color: white;
    margin-right: 10px;
    border-radius: 6px;
    color: rgb(252, 187, 8);
    font-weight: 500;
}

.edit_btn{
    width: 90px;
    height: 40px;
    border: 1px solid rgb(252, 187, 8);
    background-color: rgb(252, 187, 8);
    /* margin-right: 10px; */
    border-radius: 6px;
    color: white;
    font-weight: 500;
}
.active_btn{
    width: 140px;
    height: 40px;
    border: 1px solid rgb(252, 187, 8);
    background-color: rgb(252, 187, 8);
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}
.inActive_btn{
    width: 150px;
    height: 40px;
    border: 1px solid rgb(252, 187, 8);
    background-color: rgb(252, 187, 8);
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}
.inActive_btn:disabled{
    background-color: gray;
    color: white;
    border: 1px solid gray;
  }
  .inActive_btn:disabled {
    opacity: 1.7;
    cursor: not-allowed;
  }
  .active_btn:disabled{
    background-color: gray;
    color: white;
    border: 1px solid gray;
  }
  .active_btn:disabled {
    opacity: 1.7;
    cursor: not-allowed;
  }

  .edit_btn:disabled{
    background-color: gray;
    color: white;
    border: 1px solid gray;
  }
  .edit_btn:disabled {
    opacity: 1.7;
    cursor: not-allowed;
  }

  .delete_btn:disabled{
    background-color: gray;
    color: white;
    border: 1px solid gray;
  }
  .delete_btn:disabled {
    opacity: 1.7;
    cursor: not-allowed;
  }
.confirmPopupTitle {
    text-align: center;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
  }

  .yesBtn {
    padding: 5px 30px;
    outline: none;
    background:rgb(252, 187, 8) 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 6px;
    color: #fff;
    opacity: 1;
  }
  .noBtn {
    margin-left: 10px;
    padding: 5px 30px;
    outline: none;
    font: normal normal 600 18px/27px Poppins;
    background: #fff;
    letter-spacing: 0px;
    color: rgb(252, 187, 8);
    opacity: 1;
    border: 1px solid rgb(252, 187, 8);
    border-radius: 6px;
  }
  .btnCont {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
.confirmPopup {
    /* top: 441px;
  left: 767px;*/
    width: 386px;
    height: 198px;
    text-align: center;
    padding-top: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
  }
.delete_btn{
    width: 90px;
    height: 40px;
    border: 1px solid rgb(252, 187, 8);
    background-color: rgb(252, 187, 8);
    margin-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}