.container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0rem 1rem;
  margin-top: 1.5em;
  width: 100% !important;
  /* gap: 2.5em; */
}
.colum {
  width: 29% !important;
}
.container1 {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 1rem 0rem 1rem;
  margin-top: 1.5em;
  width: 100% !important;
  grid-gap: 4em;
}
.container2 {
  padding: 1rem 1rem 1rem 1rem;
  margin-top: 1.5em;
}

.submitBtn:disabled {
  background-color: gray;
  color: white;
  border: 1px solid gray;
}
.submitBtn:disabled {
  opacity: 1.7;
  cursor: not-allowed;
}

.clients {
  padding-bottom: 1.5em;
}

.error {
  color: red;
  font-weight: 500;
  padding-top: 4px;
  padding-left: 4px;
}

.error2 {
  color: red;
  font-weight: 500;
  padding-left: 4px;
}

.projectmaindiv {
  width: 100%;
  margin-top: 20px;
  display: flex;
}

.projectformSection {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.headlabel {
  color: #0000;
  font-weight: 500;
}

.projctdesSection {
  width: 60%;
}

.proSecton select {
  width: 90%;
  padding: 5px;
  height: 35px;
  margin-top: 10px;
  background-color: #f5f6fa;
  border-radius: 4px;
  border: none;
}

input[type="date"]:not(.has-value):before {
  color: lightgray;
  content: attr(placeholder);
}

.proSecton label {
  color: #000;
  font-weight: 500;
  font-size: 1rem;
}

.proHeader {
  display: flex;
  flex-direction: row;
}

.dateLabel {
  grid-area: enddate;
}

.gridContainer input1 {
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  width: 90%;
  min-width: 250px;
  height: 30px;
  font-weight: 500;
  /* padding: 0px 0px 0px 10px; */
  /* background-color: #f9fafc; */

  box-shadow: 0px 3px 6px #0000001a;
  padding-left: 10px;
  /* padding-top: 5px; */
  /* border: none; */
  /* border-color: #65A15E; */
  /* border: 1px solid #65a15e; */
  border-radius: 6px;
  /* margin-top: 8px; */
  /* background-color: white; */
  background-color: #f5f6fa;
}

.input1 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 7px;
  opacity: 1;
  padding: 5px;
  width: 90%;
  height: 35px;
  margin-top: 6px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
}

.gridContainer input:focus {
  outline: none;
  color: black;
}

.proleftSection {
  display: flex;
  flex-direction: row;
  padding: 6px;
}

.prosubHeader {
  margin-left: 85px;
  margin-top: -15px;
  font-weight: 500;
  font-size: 13px;
  padding: 6px;
}

.subElement {
  margin-top: 3px;
  font-weight: 500;
  font-size: 13px;
  padding: 6px;
  margin-left: 85px;
  border: none;
}

.inputbutton {
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 20px;
}

.submitBtn {
  width: 100px;
  border-radius: 10px;
}

.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  height: 2.3em;
  margin-top: 6px;
  /* min-width: 20em; */
  width: 100%;
  padding: 7px;
  outline: none;
  border: none;
  background-color: #f5f6fa !important;
  font-weight: 500;
  background: transparent 0% 0% no-repeat padding-box;
}

.date {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  height: 2.3em;
  margin-top: 6px;
  /* width: 21.3em; */
  width: 100%;
  padding: 7px;
  padding-top: 5px !important;
  border: none;
  font-size: 15px;
  background-color: #f5f6fa !important;
  font-weight: 500;
  background: transparent 0% 0% no-repeat padding-box;
}

/* .inputbutton{
    margin-right:10px
  } */
label {
  font-weight: 500;
}

h6 {
  font-weight: 500;
}

.text {
  top: 383px;
  left: 899px;
  height: 90px;
  outline: none;
  margin-top: 5px;
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  width: 100%;
  padding: 8px;
  border: none;
  resize: none;
  font-weight: 500;
}

.desc {
  margin-top: 22px;
  font-weight: 500;
}

.textarea {
  width: "88px";
}
