.BreadCrum {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  width: 95%;
  /* margin-left: 30px; */
  /* margin-top: 10px; */
  margin: 10px auto;
}

.otherChild {
  display: flex;
  align-items: center;
  /* width: 50%; */
  /* justify-content: space-between; */
}

.BreadCrumBootstrap {
  font-size: 15px;
  min-width: 13rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  /* float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color); */
  content: "->" !important;
}

.blackColor {
  color: #855ea1;
  cursor: default;
}

.decoration {
  color: black;
}

/* .decoration:hover {
  color: #855ea1;
  font-weight: 700;
  text-decoration: underline;
} */
