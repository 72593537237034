/* .ViewTask {
    margin-top: 30px;
    margin-left: 14px;
  }

  .DetailView {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    overflow-y: hidden;
    padding-top: 10px;
    font-size: 14px;
  }
  .ViewEmployeeDetails {
    display: flex;
    justify-content: center;
    color: #9c58a5;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  }
  .ProjectEmployee {
    margin-top: 10px !important;
  }

  .buttonAdd {
    background-color: #9c58a5;
    border-color: #9c58a5;
    width: 145px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
  }
  .ViewCancel {
    width: 145px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
    background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
    border: 1px solid #9c58a5;

  } */

  .MyTimeSheet {
    margin-top: 30px;
    margin-left: 14px;
  }
  
  .timesheetdate {
    margin-top: 15px;
  }
  
  .TimeSheet {
    margin: 15px 15px !important;
    color: black;
    font-weight: 700;
  }
  .searchbar {
    position: relative;
    width: 13rem;
    margin-left: 0.6rem;
  }
  
  .topBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 27rem;
  }
  .topBar1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 12px;
  }
  .cp_serchIcon {
    position: absolute;
    z-index: 100;
    top: 15%;
    margin-left: 84%;
    width: 21px;
    cursor: pointer;
  }
  .s1 {
    position: relative;
    width: 13rem;
    margin-left: 0.6rem;
  }
  
  .ViewsheetcolorPos {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    /* overflow-y: hidden; */
    padding-top: 10px;
    font-size: 14px;
    padding-bottom: 15px;
  }
  .ViewEmployeeDetails {
    display: flex;
    justify-content: center;
    color: #9c58a5;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
    /* width: 65rem;
    position: fixed; */
  }
  .projectheight {
    margin-top: 10px;
  }
  
  .tableList {
    /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    min-height: 8rem;
    /* overflow-y: scroll; */
    /* margin-top: 13px; */
    /* padding: 10px; */
    margin-left: 27px;
    margin-right: 27px;
    overflow-x: auto;
    overflow-y: hidden;
    /* box-sizing: border-box; */
    white-space: nowrap;
  }

  .th {
    display: flex;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    padding: 0px 0.5rem;
    /* min-width: 85rem; */
    gap: 1rem;
    box-sizing: border-box;
    white-space: nowrap;
    font-weight: 700;
  }
  
  .th:last-child {
    border-bottom: none;
  }
  
  .th p:nth-child(1) {
    width: 11rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  
  .th p:nth-child(2) {
    width: 13rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  
  .th p:nth-child(3) {
    width: 7rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    transform: translate(-40px, 0px);
  }
  
  .th p:nth-child(4) {
    width: 7rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    transform: translate(30px,0px);
  }
  
  .th p:nth-child(5) {
    width: 10rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    transform: translate(25px, 0px);
  }
  
  .th p:nth-child(6) {
    width: 7rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    text-align: start;
  }
  
  .th p:nth-child(7) {
    
    transform: translate(9px, 0px);

    width: 7rem;
      color: #666666;
    font-weight: 400;
  
    font-size: 12px;
  }
  
  .th p:nth-child(8) {
    width: 7rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    padding-left: 44px;
  }
  
  .th p:nth-child(9) {
    width: 3rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  
  .th p:nth-child(10) {
    width: 8rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  
  .th p:nth-child(11) {
    width: 2rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  .th p:nth-child(12) {
    width: 3rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  .td {
    display: flex;
    border-radius: 10px;
    /* align-items: center; */
    height: 28px;
    padding: 0px 0.5rem;
    gap: 1rem;
    box-sizing: border-box;
    /* white-space: nowrap; */
  }
  
  .td:last-child {
    border-bottom: none;
  }
  
  .td p:nth-child(1) {
    width: 11rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  }
  
  .td p:nth-child(2) {
    width: 10rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    
  }
  .td p:nth-child(3) {
    width: 10rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  }
  
  .td p:nth-child(4) {
    width: 7rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    transform: translate(30px,0px);
  }
  
  .td p:nth-child(5) {
    width: 10rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    transform: translate(35px, 0px);
  }
  
  .td p:nth-child(6) {
    width: 7rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    text-align: start;
  }
  
  .td p:nth-child(7) {
    width: 8rem;
      color: #666666;
    font-weight: 400;
  
    font-size: 12px;
  }
  
  .td p:nth-child(8) {
    width: 6rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    padding-left: -25px;
  }
  
  .td p:nth-child(9) {
    width: 3rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  
  .td p:nth-child(10) {
    width: 8rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  
  .td p:nth-child(11) {
    width: 2rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  .td p:nth-child(12) {
    width: 3rem;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
  .Approved {
    float: right;
    transform: translate(-45px, 0px);
  }
  
  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-top: 10px;
    /* margin-bottom: 12px; */
    cursor: pointer;
    /* font-size: 22px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .buttonAdd {
    background-color: #9c58a5;
    border-color: #9c58a5;
    width:6rem;
    height: 40px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
  }

  .addMore{
    background-color: #9c58a5;
    border-color: #9c58a5;
    left: 92%;
    position: absolute;
    width: 75px;
    padding-top: 1px;
    height: 28px;
    margin-top: -6px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }
  
.buttonAdd4 {
  float: right;
    margin-left: 55px;
    margin-right: 26px;
    background-color: #9c58a5;
    border-color: #9c58a5;
    width: 81px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.buttonCancle4 {
  width: 81px;
  height: 38px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  position: relative;
  left: 53%;
  margin-top: -38px;
  margin-bottom: 25px;
}

.buttonAdd3 {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 62px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  position: relative;
  margin-top: 20px;
  left: 72%;
  margin-bottom: 10px;
}
.buttonCancle3 {
  width: 62px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px;
  background: transparent 0% 0% no-repeat padding-box;
  color: #9c58a5;
  border: 1px solid #9c58a5;
  position: relative;
  left: 72%;
  margin-top: -30px;
  margin-bottom: 25px;
}
.no_data {
  text-align: center;
  padding: 3%;
}
