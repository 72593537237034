.projectmaindiv{
    width: 100%;
    margin-top: 20px;
    display: flex;
  }
  .projectformSection{
    width: 40%;
    display: flex;
    flex-direction: column;
  }
  .headlabel{
     color:#0000;
     font-weight:500;
  } 
  .projctdesSection{
    width: 60%;
  }
  .proSecton select{
       width:90%;
       height:35px;
       margin-top: 10px;
       background-color: #F5F6FA;
       border-radius: 4px;
       border: none;
      
       
  }
  input[type="date"]:not(.has-value):before{
    color: lightgray;
    content: attr(placeholder);
  }
  .proSecton label{
    color:#000;
    font-weight:500;
    font-size: 1rem;
  }
  .proHeader{
    display: flex;
    flex-direction: row
  }
  .proleftSection{
    display: flex;
    flex-direction: row;
    padding: 6px;
  }
  .prosubHeader{
    margin-left: 85px;
    margin-top:-7px;
    font-weight:500;
    font-size: 13px;
    padding: 6px;
  
    
  }
  .subElement{
    margin-top:-5px;
    font-weight:500;
    font-size: 13px;
    padding: 6px;
    border: none;
  }
  
  .inputbutton{
    margin-left: 85%;
    margin-top: 2%;
    cursor: pointer;
  }
  
  .input {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1.7;
    width: 460px;
    height: 50px;
    margin-top: 6px;
    font-weight: 500;
    outline: none;
    background: transparent 0% 0% no-repeat padding-box;
    
  }
  .error {
    color: red;
    font-weight: 500;
    padding-top: 4px;
    padding-left: 4px;
  }
  /* .inputbutton{
    margin-right:10px
  } */
  label {
    font-weight: 500;
  }
  h6{
    font-weight: 500;
  }
  .text {
    top: 383px;
    left: 899px;
    height: 90px;
    margin-top:5px;
    background: transparent 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1.7;
    border: none;
    resize: none;
    font-weight: 500;
  
  }
  .desc{
    margin-top: 22px;
    font-weight: 500;
  }
  .textarea {
width: "88px";
  }
  
  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
    }  
    .projectresource_mainSection{
      width: 100%;
      height: 90vh;
      display: flex;
      /* border: 1px solid red; */
    }
    .projectresource_sidebarsection{
      /* border: 10px solid red; */
   }
   .projectresource_mainsection{
    width:80%;
    margin-left:2em;
    /* border: 1px solid red; */
  }
  .head{color:#fcb902}
  
  
  /* this is main */
  .maindiv{
    /* border:3px solid red; */
    background-color: #FFFFFF;
    width: 125%;
    height: 160px;
    margin-left: -31px;
    border-radius:6px;
    box-shadow: 0px 0px 5px 0px grey;
  }
  .maindivContent{
      display: flex;
      flex-direction: row;
  }
  .span1{
    margin-left: 320px;
    color:#fcb902
  }
  .span2{
    text-align: center;
    color:#fcb902;
    font-weight: 600;
    padding-top: 15px;
    
  }
  .maindiv div{
    padding-top: 6px;
    padding-left:25px;
  }
  .maindiv div p{
    /* font-weight: bold; */
    height: 7px;
  }
  
  /* this is form section */
  .projectmaindiv{
    width: 100%;
    margin-top: 20px;
    display: flex;
  }
  .projectformSection{
    width: 40%;
    display: flex;
    flex-direction: column;
  }
  .projctdesSection{
    width: 60%;
  }
  .proHeader{
    display: flex;
    flex-direction: row;
  }
  .inputbutton{
    margin-left: 90.7%;
  }
  .inputbutton  button{
    width:80px;
    margin-left: -5px;
    margin-top: 14px;
    height:38px;
    border-radius: 6px;
    background-color: #fcb902;
  
  }
  .desc{
    margin-left: 33px;
  }
