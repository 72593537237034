@import '../../pageLayout/mixing/MixingScss.module.scss';

.companyBox {
  padding: .6rem 1rem 0px 1rem;
  display: flex;
  gap: 2rem;
}

.cp_label {
  font-size: 15px;
  font-weight: 600;
  padding-left: 4px;
}

.mandatory {
  color: red;
}

.col1 {
  width: 24%;
}

.submit {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
}

.cp_field {
  border: 1px solid #00000061;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  padding: 5px 5px;
  outline: none;
  padding-left: 14.5px;     // Make left to the icon - i.e "Company Name"
  padding-bottom: 6px;
}

.cp_field:focus {
  border: 2px solid rgb(95, 95, 245);
}

.cp_field::placeholder {
  color: rgb(134, 134, 134);
}


.errorText {
  color: red;
  font-size: 12px;
  visibility: visible;
  padding: 0.4rem 0.5rem 0.2rem 0.3rem;
}

.errorTextHidden {
  color: red;
  font-size: 12px;
  margin-left: 15px;
  visibility: hidden;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
}

.th {
  @include headerWithoutWidth();
  justify-content: start;
  position: sticky;
  top: 0;
  z-index: 10;
}

.th div:nth-child(1) {
  @include headerTH(15);
  padding: 0.5rem;
}

.th div:nth-child(2) {
  @include headerTH(10);
}
.th div:nth-child(3) {
  @include headerTH(15);
}

.th div:nth-child(4) {
  @include headerTH(15);
}

.th div:nth-child(5) {
  @include headerTH(2);
}

.td {
  @include tableDataWithOutWidth();
  justify-content: start;
}

.td div:nth-child(1) {
  @include TableDataTD(15);
  padding: 0.5rem;
}

.td div:nth-child(2) {
  @include TableDataTD(10);
}

.td div:nth-child(3) {
  @include TableDataTD(15);
}

.td div:nth-child(4) {
  @include TableDataTD(15);
}

.td div:nth-child(5) {
  @include TableDataTD(2);
  cursor: pointer;
}

.no_data {
  text-align: center;
}

.pagination {
  margin-top: 10px;
  margin-right: 30px;
}

.add {
  border: 2px solid #65a15e;
  color: #65a15e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 1rem;
}

.add:hover {
  cursor: pointer;
  background-color: #65a15e;
  color: #ffffff;
}

.plus {
  font-size: 20px;
  padding-bottom: 5px
}

@media only screen and (max-width: 1280px) {
  .add {
    width: 25px;
    height: 25px;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}