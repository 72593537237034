.main_div {
  background-color: #172a3e;
  height: 100%;
  padding: 0% 4%;
}

.img_div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: white;
  width: 50px;
  height: 50px;
}

.price_revision_img {
  width: 70%;
  height: 70%;
}

.div_text {
  color: white;
  font-size: 17px;
  font-weight: 600;
}

.small_div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00979d;
  border-radius: 14px;
  padding: 5% 0%;
  gap: 4%;
  cursor: pointer;
}

.white_div {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: transparent;
  color: white;
  border-radius: 10px;
  padding: 5% 20%;
  margin-top: 5%;
  cursor: pointer;
  font-weight: 600;
}

.white_div:hover {
  background-color: white;
  color: #00979d;
}

.filled_div {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: white;
  color: #00979d;
  border-radius: 10px;
  padding: 5% 20%;
  margin-top: 5%;
  cursor: pointer;
  font-weight: 600;
}
