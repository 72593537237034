*{
    margin:0;
    padding:0;
    box-sizing: border-box;
  }
  
  .leftSection {
    width: 20%;
    background-color: #172A3E;
  }
  
  .dashboardImage {
    height: 45px;
    margin: 10px 20px;
    cursor: pointer;
  }
  .leftSection .btn {
    width: -webkit-fill-available;
    border-radius: 15px;
    opacity: 1;
    outline: none;
    margin: 0 10px 10px 10px;
    display: flex;
    align-items: center;
    background-color: #562679;
    height: 70px;
    padding-left: 15px;
    cursor: auto;
    border: none;
  }
  .leftSection .btn div {
    background-color: white;
    width: 54px;
    height: 54px;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .leftSection .btn span {
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding-left: 10px;
  }
  .deactivate_btn img,
  .active_btn img {
  height: 24px;
  width: 24px;
  }
  .side_buttons_div {
    width: 100%;
    padding: 10px;
  }
  .deactivate_btn,
  .active_btn {
  margin-top: 10px;
  display: flex;
  padding: 5%;
  justify-content: center;
  border-radius: 10px;
  font-weight: 500;
  align-items: center;
  font-size: 16px;
}
.active_btn {
  background-color: white;
}
.heading {
  margin-left: 10%;
  width: 100%;
}
.deactivate_btn .heading {
  color: white;
}

.active_btn .heading {
  color: #562679;
}






  