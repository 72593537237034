.revision_dues_main_div {
  display: flex;
  height: 90vh;
}

.sidebar_div {
  width: 20%;
}

.main_div {
  width: 80%;
  padding: 2%;
  height: 90vh;
  overflow-y: auto;
}

.main_div_heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main_left_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.price_heading {
  font-size: 1rem;
  font-weight: 600;
}

.price_count {
  border: 2px solid #00979d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-weight: 600;
  color: #00979d;
  width: 40px;
  height: 40px;
  margin-left: 3% !important;
}

.table_div {
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  overflow-x: scroll;
  box-sizing: border-box;
  white-space: nowrap;
  margin: 1.5% 0%;
}

.table {
  width: 100%;
  font-size: 12px;
  border: none;
}

.table_heading {
  position: sticky;
  top: 0px;
  background-color: #f5f6fa;
  color: #666666;
}

.table_head {
  padding: 10px;
  font-size: 0.8rem;
}

.table_column {
  padding: 10px;
  font-size: 0.8rem;
}

.sticky_head_col1 {
  position: sticky;
  left: 7.8%;
  top: 0px;
  background-color: #f5f6fa;
}

.sticky_head_code {
  position: sticky;
  left: 0px;
  top: 0px;
  background-color: #f5f6fa;
}

.sticky_head_col2 {
  position: sticky;
  left: 19%;
  top: 0px;
  background-color: #f5f6fa;
}

.sticky_column1 {
  position: sticky;
  left: 7.8%;
  top: 0px;
  background-color: white;
  color: #666666;
}

.sticky_column_code {
  position: sticky;
  left: 0px;
  top: 0px;
  background-color: white;
}

.resource_para {
  width: 14ch;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 0.8rem;
}

.sticky_column2 {
  position: sticky;
  left: 19%;
  top: 0px;
  background-color: white;
}

.no_data_div {
  padding: 1% 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 100%;
  height: 10%;
  border-radius: 5px;
  margin: 7px 0px;
  font-weight: 600;
  color: grey;
}

.reason_img {
  width: 18px;
  height: 21px;
  cursor: pointer;
}

.modal_para_div {
  padding: 1% 2%;
  text-align: start;
}

.guideIcon {
  width: 30px;
  cursor: pointer;
  margin-left: 20px;
}