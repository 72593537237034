.QuestionList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 98%;
  margin: 10px auto;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 10px;
  min-height: 5rem;
}
.QuestionList table {
  border-collapse: collapse;
  width: 100%;
}

.QuestionList th {
  background-color: #f5f7f9;
  font-weight: bold;
  text-align: start;
  padding: 8px;
}


.QuestionList td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: start;
}

.QuestionList .link {
  cursor: pointer;
  text-decoration: underline;
  color: #964431;
}

.QuestionList .td1 {
  font-size: 14px;
  font-weight: 500;
}

.QuestionList .td3 {
  font-size: 14px;
  font-weight: 500;
  width: 5%;
}

.QuestionList .td2 {
  font-size: 14px;
  font-weight: 500;
  width: 10%;
}

.filteralign {
  display: flex;
  width: 63.4%;
}

.QuestionList .th1 {
  font-size: 14px;
  font-weight: bold;
}

.main {
  width: 80%;
}

.pp {
  padding-left: 10px;
  font-weight: 500;
}

.s1 {
  position: relative;
  width: 34%;
}

.twoFilter {
  display: flex;
  justify-content: end;
  margin-right: 0.7rem;
  margin-top: 1rem;
}

.cp_serchIcon2 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 83%;
  width: 21px;
  cursor: pointer;
}

.addQuestionnairebtn {
  border-radius: 5px;
  border: 1px solid #964431;
  background-color: #964431;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.loading {
  display: flex;
  justify-content: space-around;
}

.noData {
  display: flex;
  justify-content: center;
}