* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .leftSection {
    height: 100%;
    background-color: #172a3e;
  }
  
  .dashboardImage {
  height: 45px;
  margin: 7px 0px 10px 30px;
  cursor: pointer;
  }
  .leftSection button {
    width: -webkit-fill-available;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  opacity: 1;
  outline: none;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  }
  .leftSection .btn {
    background-color:#4B59C3;
  height: 70px;
  padding-left: 15px;
  cursor: auto;
  border: none;
  display: flex;
  gap: 15px;
  }
  
  .leftSection .btn span {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
  }
  
  .leftSection .btn div {
    background-color: white;
    width: 54px;
    height: 54px;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .deactivate_btn img,
  .active_btn img {
    height: 24px;
    width: 24px;
  }
  
  .heading {
    margin-left: 10%;
    width: 100%;
  }
  
  .deactivate_btn .heading {
    color: white;
  }
  
  .active_btn .heading {
    color:#4b59c3;
  }
  
  .side_buttons_div {
    width: 100%;
  padding: 10px;
  height: 90vh;
  }
  
  .deactivate_btn,
  .active_btn {
    margin-top: 10px;
    display: flex;
    padding: 5%;
    justify-content: center;
    border-radius: 10px;
    font-weight: 500;
    align-items: center;
  }
  
  .active_btn {
    background-color: white;
  }
  .btn:hover { 
    transform: scale(1);
  }
  