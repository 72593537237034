.timesheetdate {
    margin-top: 30px;
    margin-left: 14px;
  }
  
  .ViewsheetcolorPos {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    overflow-y: hidden;
    padding-top: 10px;
    font-size: 14px;
  }
  .ViewEmployeeDetails {
    display: flex;
    justify-content: center;
    color: #9c58a5;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  }
  
  .cu_col1 {
    flex: 0 0 22%;
  }
  .cu_label {
    font-size: 15px;
    font-weight: 500;
    padding-left: 4px;
    color: #000000;
  }
  .mand {
    color: red;
  }
  .Container {
    width: 95%;
    margin: 0.8rem auto;
    display: flex;
    justify-content: space-between;
  }
  
  .copy_expence {
    text-align: center;
    color: var(--invoiceColor);
    font-weight: 500;
    font-size: var(--inoiceHeading);
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
    padding-bottom: 0.5rem;
  }
  
  .smallInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    /* margin-top: 10px; */
    /* margin: 1% 1% 1% 1%; */
    /* padding-left: 10px; */
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
  }
  
  .label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
  }
  .label_item {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    font-size: 12px;
  }
  
  .radioitem {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    /* margin: 10px 0 0; */
  }
  
  .radioitem label {
    cursor: pointer;
  }
  
  .radioitem input[type="radio"] {
    display: none;
  }
  
  .radioitem label {
    font-weight: 600;
  }
  
  .radioitem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid var(--invoiceColor);
    background-color: transparent;
  }
  
  .radioitem input[type="radio"]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: var(--invoiceColor);
  }
  .lastBtn {
    display: flex;
    justify-content: end;
    width: 97.6%;
    grid-column-gap: 2%;
    margin: 2% 0 3% 0;
  }
  .remove_icon {
    color: #855ea1;
    margin-top: 3%;
    cursor: pointer;
    transform: translate(0px, 9px);
  }
  
  .errorText {
    color: red;
    font-size: 12px;
    visibility: visible;
    padding: 0.4rem 0.5rem 0.2rem 0.5rem;
  }
  
  .errorTextHidden {
    color: red;
    font-size: 12px;
    margin-left: 15px;
    visibility: hidden;
    padding: 0.4rem 0.5rem 0.2rem 0.5rem;
  }
  
  .buttonAdd {
    background-color: #9c58a5;
    border-color: #9c58a5;
    width:6rem;
    height: 40px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
  }
  .buttonCancle {
    width:6rem;
    height: 40px;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
    background: transparent 0% 0% no-repeat padding-box;
    color: #9c58a5;
    border: 1px solid #9c58a5;
  }

  .buttonAdd3 {
    background-color: #9c58a5;
    border-color: #9c58a5;
    width: 62px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 24px;
    position: relative;
    margin-top: 20px;
    left: 72%;
    margin-bottom: 10px;
  }
  