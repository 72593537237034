@mixin daywiseTH($value) {
    width: $value+rem;
    text-align: center;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-right: 1px solid #666666;
}



@mixin daywiseTD($value) {
    width: $value+rem;
    text-align: center;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-right: 1px solid #666666;

}

@mixin headerTH($value) {
    width: $value+rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

}

@mixin TableDataTD($value) {
    width: $value+rem;
    text-align: start;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    // height: rem;
}


@mixin header($value) {
    display: flex;
    justify-content: space-between;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 50px;
    width: #{if($value > 0, $value + rem, "100%")};
    font-size: var(--atsFontSize);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@mixin headerWithoutWidth() {
    display: flex;
    justify-content: space-between;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 45px;
    font-size: var(--atsFontSize);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@mixin tableData($value) {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    width: #{if($value > 0, $value + rem, "100%")};
    font-size: var(--atsFontSize);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@mixin tableDataWithOutWidth() {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    font-size: var(--atsFontSize);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@mixin clickable() {
    cursor: pointer;
    text-decoration: underline;
}