@use "../../../GenericComponent/css/common.module.scss" as global;
@import "../../../GenericComponent/css/CommonTable.module.scss";


.th p:nth-child(1),
.td p:nth-child(1) {
  padding-left: 1rem;
}
.th p:nth-child(1),
.td p:nth-child(1) {
width: 15%;
}

.th p:nth-child(2),
.td p:nth-child(2) {
  width: 20%;
} 

.th p:nth-child(3),
.td p:nth-child(3) {
  width: 70%;
}



.titleBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0;
}

.title {
  font-size: 1rem;
  font-weight: bold;
}
.modal-header .btn-close{
  display: none !important;
}

.card {
  background: white;

  -webkit-box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.06);
  box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
}

.form {
  padding: 1rem;
}
.heading {
  border-bottom: 1px solid black;
  padding: 5px;
  text-align: center;
  color: global.$client_contract_theme;
  font-weight: bold;
}

.row1,
.row2,
.row3,
.row4,
.row5 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.flex {
  width: 25%;
}

.flex > p {
  opacity: 0.8;
}

.btn {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.btn > button {
  background-color: global.$client_contract_theme;
  border: none;
  border-radius: 10px;
  padding: 8px 1.5rem;
  text-wrap:nowrap
}
.changeStatusBtn {
  width: 24% !important;
}

.link {
  color: global.$client_contract_theme !important;
  width: 1.8rem;
  cursor: pointer;
}

.link:hover {
  font-weight: 500 !important;
}
