.confirmPopup {
    /* top: 441px;
  left: 767px;*/
    width: 386px;
    height: 198px;
    text-align: center;
    padding-top: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
  }
  .count{
    /* width: 27px;
    height: 27px;
    border-radius: 20px; */
    cursor: pointer;
    /* border: 2px solid rgb(252, 187, 8); */
    /* border: 2px ; */
    /* margin-left: -20px !important;
    margin-top: 16px; */
}
.backColor1{
  color: rgb(252, 187, 8) !important;
  }
  .plus{
    padding-left: 7px;
    color:rgb(252, 187, 8);
  }

  .confirmPopupTitle {
    text-align: center;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
  }

  .yesBtn {
    padding: 5px 30px;
    outline: none;
    background: #fcb902 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 6px;
    color: #fff;
    opacity: 1;
  }

  .noBtn {
    margin-left: 10px;
    padding: 5px 30px;
    outline: none;
    font: normal normal 600 18px/27px Poppins;
    background: #fff;
    letter-spacing: 0px;
    color: #fcb902;
    opacity: 1;
    border: 1px solid #fcb902;
    border-radius: 6px;
  }