.mainSection {
  width: 100%;
  height: 100vh;
  position: absolute;
  bottom: 0;
  display: flex;
}
.rightSection {
  /* border: 2px solid blue; */
  width: 80%;
  background-color: rgb(246, 250, 243);
}
.circle{
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #65a15e;
  margin-left: 7px !important;
}
.alignGroupIssue{
  display: flex;
  justify-content: center;
  align-items: center;
}
.header{
  display: flex;
  width: 96%;
  margin-left: 2%;
  margin-top: 2%;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.filter {
  width: 110px;
  height: 38px;
  border-radius: 30px;
  border: 2px solid #65a15e;
  background-color: #ffffff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appliedcheck {
  /* position: absolute; */
  left: 56%;
  /* top: 120px; */
  width: 15px;
  z-index: 10;
  border-radius: 6px;
}
.polygon {
  display: block;
  position: absolute;
  left: 54%;
  top: 76px;
}
.createTask {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  background-color: #65a15e;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.createTask2 {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  background-color: #65a15e;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.IssueList {
    /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
    width: 98%;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    max-height: 72vh;
    overflow-y: scroll;
  }
  .tableContent {
    /* width: 9.3rem; */
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tabletaskTitle b {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
 
  .th {
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    color: #666666;
    font-size: 14px;
  }
  .td {
    display: flex;
    justify-content: space-around;
    width: 100%;
    /* border-radius: 10px; */
    align-items: center;
    height: 40px;
    min-width: 95%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 15px;
  }
  .loader {
    width: 17%;
    margin: 5% 13% 0% 42%;
  }
  
  .loaderPara {
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    color: #65a15e;
    margin-top: -4% !important;
    font-style: italic;
    padding-left: 36%;
    margin-left: 34.5%;
  }
  .tooltips{
    text-overflow: ellipsis;
    width: 17rem;
    overflow: hidden;
  }
  .tabletaskTitle {
    overflow: hidden;
    max-width: 315px;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
  }
  .asigned_color {
    color: #fcb902;
  }
  
  .done_color {
    color: #65a15e;
  }
  
  .onhold_color {
    color: #f94244;
  }
  .Closed_color {
    color: red;
  }
  /* .tabletaskTitle {
    color: #65a15e;
    cursor: pointer;
    text-decoration-line: underline;
  } */
  /* .taskTitle{
    color: #65A15E;
    font-weight: 500;
    cursor: pointer;
   
  } */
  .hed {
    margin-left: 16px;
    font-weight: 600;
    width: 90% !important;
  }
  .laptops {
    padding-left: 23px !important;
    font-weight: 700;
    font-size: 18px;
  }
  .filterr {
    width: 150px;
    height: 40px;
    border-radius: 30px;
    border: 2px solid #9c57a5;
    background-color: #ffffff;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 85px;
  }
  .filterImg {
    position: absolute;
    left: 58%;
    top: 6%;
    width: 12px;
    z-index: 10;
    border-radius: 6px;
  }
  .filter_img2 {
    position: absolute;
    left: 52%;
    top: 26%;
  }
  .main{
    width: 100%;
  }
  .filterr span {
    padding-left: 12px;
    font-size: 1rem;
    color: #9c57a5;
  }
  .btnColor {
    background-color: #9c57a5;
    border: 2px solid #9c57a5;
    font-weight: 500;
    border-radius: 9px;
  }
  .btnColor hover {
    opacity: 0;
  }
  .link{
    color: #65a15e;
    font-size: 16px;
    cursor: pointer;
  }
  .AllIssueP{
    font-weight: 500;
    padding-top:10px;
  }
  .goBack{
    background-color: white;
    border-radius: 9px;
    height: 38px;
    width: 5rem;
    color: #65a15e;
    border: 1px solid #65a15e;
    margin-left: 10px;
    margin-top: 20px;
  }
  .paginationalign{
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
  }
  .filteralign{
    display:flex;
    justify-content:space-between;
    width:100%
  }
  .filterColor{
color: #65A15E;
padding-left: 12px;
  }
  
  .s1 {
    margin-top: 20px;
    position: relative;
    /* left:10%; */
    width:23%;
  }
  .cp_serchIcon2 {
    position: absolute;
    z-index: 100;
    top:15%;
    margin-left: 83%;
    width: 21px;
    cursor: pointer;
  }
  .noData{
    font-weight: 500;
    font-size: 14px;
  }
  .paragraph{
    display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 500;
  }
  .loading{
    display: flex;
    justify-content: center;
  }
  .noDataFound{
    position: relative;
    left: 43%;
    font-weight: 500;
      padding-top: 8px;
  }
  .modal_group_member {
    width: 25rem;
    min-height: 8rem;
    border-radius: 5px;
    padding: 1rem;
    background: #fff;
  }
  .w_inactive {
    border: 1px solid #65a15e;
    border-radius: 4px;
    color: black;
    background-color: #fff;
    width: 5vw;
    font-size: 15px;
    cursor: pointer;
    /* height: 30px; */
  }
  .modalHeading{
    font-size: 16px;
    font-weight: 600;
  }
  .modal_btn{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upperPortion{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .checkboxContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
  }

  .label {
    margin-bottom: 5px;
  }

  .showAllCheckBox {
    visibility: visible;
    accent-color: green;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    margin-bottom: 9px;
  }

  .coreSkillInput {
    border-radius: 5px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    width: 100%;
    height: 35px;
    border: 1px solid #00000061;
    padding: 0 8px;
    outline: none;
}

.coreSkillInput::placeholder {
    color: hsl(0, 0%, 5%);
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 12%;
  margin-left: 87%;
  width: 21px;
  cursor: pointer;
}
.toggleButtonContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1.5rem 0px;
}
.toggleButtons{
  display: flex;
  width: 37.5rem;
  border-radius: 20px;
}
.toggleButtonItem{
  display: flex;
  justify-content: center;
  width: 9.375rem;
  border: 1px solid rgb(170, 148, 148);
  padding: 4px 0px;
  font-size: 16px;
  cursor: pointer;
}
.memberModalHeading{
  display: flex;
  justify-content: center;
  width: 25rem;
  font-size: 24px;
  font-weight: 600;
}
.addMembers{
  margin-top: 0.5rem;
  font-weight: 600;
}
.members{
  margin-top: 0.5rem;
  font-weight: 600;
  margin-bottom: 8px;
}
.existingMembersList{
  display: flex;
  justify-content: space-between;
}
.memberName{
  width: 9rem;
}
.memberEmail{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 13rem;
}
.notify{
  margin-top: 8px;
  margin-bottom: 0.5rem;
}
.doneButton{
  width: 60px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  background-color: #65a15e;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.deleteModalBtns{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
}
.cancelButton{
  width: 60px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #65a15e;
  background-color: #ffffff;
  outline: none;
  color: #65a15e;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.paginationmobileContainer {
  display: block;
}
.errorTextHidden {
  visibility: hidden;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}

.errorText {
  visibility: visible;
  color: red;
  font-size: 12px;
  margin-left: 15px;
}
