.card {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 100px;
  /* overflow-y: hidden; */
  padding: 10px;
}

.candidateOffer {
  min-height: 85vh;
}

.link {
  color: rgb(44, 116, 44);
  cursor: pointer;
  text-decoration: underline;
}
.links {
  color:blue !important;
  cursor: pointer;
  text-decoration: underline;
}
.createOfferBtn {
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  padding: 6px 10px;
}

.th1>p {
  text-align: center;
  color: rgb(44, 116, 44);
  font-weight: bold;
}

.td_item>a {
  color: #48729e !important;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 7vw;
  row-gap: 2vw;
  padding: 0 20px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.td_item {
  width: 200px;
}

.td_item_1 {
  font-weight: 600;
}

.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  min-width: 95%;
}

.th p:nth-child(1) {
  width: 18rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: var(--atsFontSize);
  padding-left: 8px;
}

.th p:nth-child(2) {
  width: 18rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: var(--atsFontSize);
}

.th p:nth-child(3) {
  width: 18rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: var(--atsFontSize);
}

.th p:nth-child(4) {
  width: 18rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: var(--atsFontSize);
}

.th p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: var(--atsFontSize);
}

.th p:nth-child(6) {
  width: 13rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: var(--atsFontSize);
}

.td {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2px 0 10px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 18rem;
  text-align: start;
  font-size: 15px;
  color: var(--atsLinkColor);
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding-left: 8px;
  font-size: var(--atsFontSize);
}

.td p:nth-child(2) {
  width: 18rem;
  text-align: start;
  font-size: 20px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
}

.td p:nth-child(3) {
  width: 18rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
}

.td p:nth-child(4) {
  width: 18rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
}

.td p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
}

.td p:nth-child(6) {
  width: 13rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--atsFontSize);
}

.tableContent {
  width: 120px;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.tableContent2 {
  font-size: 14px;
}

.tableContent1 {
  width: 120px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.plus {
  margin-right: 5px;
  font-size: 16px;
  font-weight: bold;
}

.cu_col1 {
  flex: 0 0 23%;
  width: 23%;
}

.cu_col2 {
  flex: 1 0 29%;
  width: 29%;
  position: relative;
}

.cu_col3 {
  flex: 0 0 25%;
  width: 25%;
  position: relative;
}

.cu_col4 {
  flex: 0 0 23%;
  width: 23%;
}

.Cu_row1 {
  display: flex;
  justify-content: space-between;
  margin: 0px 0.5rem;
  width: auto;
}

.cu_label {
  font-size: 15px;
  font-weight: 500;
  padding-left: 4px;
  color: #000000;
}

.cu_field2 {
  margin-top: 10px;
  padding-left: 5px;
  padding: 7px 5px 10px 4px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cu_field3 {
  width: 15vw;
  margin-top: 10px;
  padding-left: 5px;
  padding: 7px 5px 10px 4px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no_data {
  text-align: center;
  padding: 1%;
}

.modal_container {
  width: 18rem;
  height: 8rem;
  border-radius: 5px;
}

.textM {
  text-align: center;
}

.M_button {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  width: 10rem;
  justify-content: space-around;
}

.b_inactive {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 5vw;
  font-size: 15px;
  cursor: pointer;
}

.vp_history {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  font-size: 15px;
}

.vp_main_card_history {
  border-radius: 9px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-top: 30px;
}

.vp_header_history {
  display: flex;
  justify-content: center;
  color: green;
  font-weight: 500;
  padding: 10px 0px;
}

.PositionList {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  height: auto;
  overflow-y: hidden;
  padding: 0px 10px 10px 10px;
  margin-top: 20px;
}

.NoData {
  text-align: center;
  color: green;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.3rem;
}

.Modaldata {
  color: limegreen;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
}

.data {
  color: #666666;
  font-weight: 400;
}

.pagination {
  margin-top: 25px;
  margin-right: 30px;
}

.vp_desc {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  width: 5rem;
  font-size: 15px;
  margin: 2rem 3rem 1rem 3rem;
}

.descContainer {
  height: auto;
}

.descContainer::-webkit-scrollbar {
  width: 3px;
}

.descContainer::-webkit-scrollbar-track {
  background: green;
}

.descContainer::-webkit-scrollbar-thumb {
  background: #888;
}

.th2 {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 50px;
  min-width: 95%;
}

.th2 p:nth-child(1) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  padding-left: 9px;
}

.th2 p:nth-child(2) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
}

.th2 p:nth-child(3) {
  width: 15rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
}

.th2 p:nth-child(4) {
  width: 40rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
}

.td2 {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2px 0 10px;
  font-size: var(--atsFontSize);
}

.td2:last-child {
  border-bottom: none;
}

.td2 p:nth-child(1) {
  width: 8rem;
  text-align: start;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding-left: 9px;
}

.td2 p:nth-child(2) {
  width: 8rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td2 p:nth-child(3) {
  width: 15rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.td2 p:nth-child(4) {
  width: 40rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  height: auto;
}

.buttonContainer {
  display: flex;
  column-gap: 10px;
}

.btnCotainer {
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  width: 95%;
}

.vp_history {
  border: 1px solid #65a15e;
  border-radius: 4px;
  color: white;
  background-color: #65a15e;
  font-size: 15px;
}

.buttonDropped {
  border: 1px solid red;
  border-radius: 4px;
  color: white;
  background: red;
  font-size: 15px;
}



.center {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #65a15e;
  font-weight: 500;
  border-bottom: 1px solid #d6d2d2;
  padding-bottom: 0.3rem;
}



.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 8px;
  padding-left: 10px;
  outline: none;
  padding-top: 5px;
  z-index: 1000;
}

.textarea {
  width: 100%;
  margin-top: 0.5rem;
  min-height: 4.7rem;
  outline: none;
}

.cancelBtn {
  background: #fff;
  color: #65a15e;
  border: 1px solid #65a15e;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px 10px;
}



.createOfferBtn {
  background: #65a15e;
  color: rgb(255, 255, 255);
  border: 1px solid #65a15e;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px 10px;
  margin-left: 1rem;
}

.docOffer1 input[type="file"]::file-selector-button {
  border: 2px solid #65a15e;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #65a15e;
  transition: 1s;
  color: #ffffff;
}

.docOffer1 {
  display: flex;
  width: 108%;
  margin-top: 6px;
}

.Cu_resume {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 1%;
  position: relative;
  grid-column-gap: 5px;
  width: 90%;
}

.rmpdf {
  width: 108%;
  border: 1px solid black;
  border-radius: 10px;
  text-align: start;
  font-size: 15px;
  color: #65a15e;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 5px;
}

.rmpdfcross {
  color: black;
  border: 1px solid #000000;
  background-color: #65a15e;
  position: absolute;
  right: -3%;
  top: -20%;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
}

.upload_resume {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 92%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  outline: none;
}

.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px -10px;
  height: 38px;
}

.errorText {
  color: red;
  font-size: 12px;
  margin-left: 7px !important;
  visibility: visible;
  padding-top: 2px;
}

.errorTextHidden {
  color: red;
  font-size: 12px;
  margin-left: 7px !important;
  visibility: hidden;
  padding-top: 2px;
}

.confirmPopup1 {
  width: 46rem;
  max-height: 50rem;
  min-height: 32rem;
  overflow-y: auto;
  padding-top: 0.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.sharedCase {
  width: 23rem;
  height: 19rem;
  padding-top: 0.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.sharedCantainer {
  margin: 1rem auto;
  width: 18rem;
}

.modalBtn1 {
  float: right;
  margin: 0px 1.5rem 1rem 1rem;
}

.cantent {
  margin: 0.7rem 1.7rem;
  width: 20rem;
}

.FutureHire {
  display: flex;
  justify-content: space-between;
}