@use "../../../GenericComponent/css/common.module.scss" as global;
@import "../../../GenericComponent/css/CommonTable.module.scss";

.th p:nth-child(1),
.td p:nth-child(1) {
  padding-left: 1rem;
}
.th p:nth-child(1) {
width: 9%;
}

.th p:nth-child(2) {
  width: 9%;
}

.th p:nth-child(3) {
  width: 10%;
}

.th p:nth-child(4) {
  width: 10%;
}

.th p:nth-child(5) {
  width: 9%;
}
.th p:nth-child(6) {
  width: 14%;
}
.th p:nth-child(7) {
  width: 10%;
}
.th p:nth-child(8) {
  width: 10%;
}

.td p:nth-child(1) {
width: 9%;
}

.td p:nth-child(2) {
  width: 9%;
}

.td p:nth-child(3) {
  width: 10%;
}

.td p:nth-child(4) {
  width: 10%;
}

.td p:nth-child(5) {
  width: 9%;
}
.td p:nth-child(6) {
  width: 14%;
}
.td p:nth-child(7) {
  width: 10%;
}
.td p:nth-child(8) {
  width: 10%;
}

.link {
  color: global.$client_contract_theme !important;
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.titleBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 7px;
}

.titleBtn button {
  border: 1px solid global.$client_contract_theme; /* Assuming $client_contract_theme is a variable */
  color: global.$client_contract_theme;
  background-color: transparent;
  border-radius: 50%;
  height: 2rem; /* Increase the height for a larger circle */
  width: 2rem; /* Increase the width for a larger circle */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem; /* Adjust  the font size to center content */
}

.titleBtnRight,
.titleBtnLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Default styles for the table */
.table {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.th, .td {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
  .th, .td {
    flex-direction: column;
    align-items: flex-start;
  }

  .td p {
    width: 100%;
  }
}
