.mainSection {

  width: 100%;

  /* position: fixed; */
  /* position: absolute; */

  display: flex;
}
.rightSection{
width: 80%;
}