@import "../../../../atsDashboard/pageLayout/mixing/MixingScss.module.scss";

.radioitem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  /* margin-left: 1%; */
  width: 8rem;
  /* margin: 10px 0 0; */
}

.radioitem label {
  cursor: pointer;
}

.radioitem input[type="radio"] {
  display: none;
}

.radioitem label {
  font-weight: 600;
}

.radioitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #65a15e;
  background-color: transparent;
}

.radioitem input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #65a15e;
  border: 2px solid #65a15e;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 44rem;
}

.s1 {
  position: relative;
  width: 25rem;
  margin-left: 0.6rem;
}

.s2 {
  position: relative;
  width: 14rem;
  margin-left: 0.6rem;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100000;
  top: 15%;
  margin-left: 87%;
  width: 21px;
  cursor: pointer;
}

.no_data {
  text-align: center;
}

.th1 {
  @include header(70);
  height: 4rem;
}

.th1 div:nth-child(1) {
  @include daywiseTH(13);
  text-align: start;
  padding-left: 0.3rem;
}

.th1 div:nth-child(2) {
  @include daywiseTH(9);
}

.th1 div:nth-child(3) {
  @include daywiseTH(7);
}

.th1 div:nth-child(4) {
  @include daywiseTH(7);
}

.th1 div:nth-child(5) {
  @include daywiseTH(7);
}

.th1 div:nth-child(6) {
  @include daywiseTH(7);
}

.th1 div:nth-child(7) {
  @include daywiseTH(7);
}

.th1 div:nth-child(8) {
  @include daywiseTH(7);
}

.th1 div:nth-child(9) {
  @include daywiseTH(7);
  border: none;
}

.td1 {
  @include tableData(70);
}

.td1 div:nth-child(1) {
  @include daywiseTD(13);
  text-align: start;
  padding-left: 0.3rem;
}

.td1 div:nth-child(2) {
  @include daywiseTD(9);
  @include clickable();
}

.td1 div:nth-child(3) {
  @include daywiseTD(7);
  @include clickable();
}

.td1 div:nth-child(4) {
  @include daywiseTD(7);
  @include clickable();
}

.td1 div:nth-child(5) {
  @include daywiseTD(7);
  @include clickable();
}

.td1 div:nth-child(6) {
  @include daywiseTD(7);
  @include clickable();
}

.td1 div:nth-child(7) {
  @include daywiseTD(7);
  @include clickable();
}

.td1 div:nth-child(8) {
  @include daywiseTD(7);
  @include clickable();
}

.td1 div:nth-child(9) {
  @include daywiseTD(7);
  @include clickable();
  border: none;
}

.weekth1 {
  @include header(0);
  height: 4rem;
}

.weekth1 div:nth-child(1) {
  @include daywiseTH(13);
  text-align: start;
  padding-left: 0.3rem;
  border: none;
}

.weekth1 div:nth-child(2) {
  @include daywiseTH(9);
  border: none;
}

.weekth1 div:nth-child(3) {
  @include daywiseTH(7);
  border: none;
}

.weekth1 div:nth-child(4) {
  @include daywiseTH(7);
  border: none;
}

.weekth1 div:nth-child(5) {
  @include daywiseTH(7);
  border: none;
}

.weekth1 div:nth-child(6) {
  @include daywiseTH(7);
  border: none;
}

.weektd1 {
  @include tableData(0);
}

.weektd1 div:nth-child(1) {
  @include daywiseTD(13);
  text-align: start;
  padding-left: 0.3rem;
  border: none;
}

.weektd1 div:nth-child(2) {
  @include daywiseTD(9);
  @include clickable();
  border: none;
}

.weektd1 div:nth-child(3) {
  @include daywiseTD(7);
  border: none;
  @include clickable();
}

.weektd1 div:nth-child(4) {
  @include daywiseTD(7);
  @include clickable();
  border: none;
}

.weektd1 div:nth-child(5) {
  @include daywiseTD(7);
  @include clickable();
  border: none;
}

.weektd1 div:nth-child(6) {
  @include daywiseTD(7);
  @include clickable();
  border: none;
}

.table_th {
  @include header(65);
}

.table_th div:nth-child(1) {
  @include daywiseTH(10);
  border: none;
  text-align: start;
  padding-left: 0.3rem;
}

.table_th div:nth-child(2) {
  @include daywiseTH(10);
  border: none;
  text-align: start;
}

.table_th div:nth-child(3) {
  @include daywiseTH(8);
  border: none;
  text-align: start;
}

.table_th div:nth-child(4) {
  @include daywiseTH(15);
  border: none;
  text-align: start;
  width: 11rem;
}
.table_th div:nth-child(5) {
  @include daywiseTH(15);
  border: none;
  text-align: start;
  width: 11rem;
}
.table_th div:nth-child(6) {
  @include daywiseTH(15);
  border: none;
  text-align: start;
  width: 11rem;
}

.table_th div:nth-child(7) {
  @include daywiseTH(15);
  border: none;
  text-align: start;
}
.table_th div:nth-child(8) {
  @include daywiseTH(7);
  border: none;
  text-align: start;
}
.table_td {
  @include tableData(65);
  height: 45px;
}

.table_td div:nth-child(1) {
  @include daywiseTD(10);
  border: none;
  text-align: start;
  padding-left: 0.3rem;
}

.table_td div:nth-child(2) {
  @include daywiseTD(10);
  border: none;
  text-align: start;
}

.table_td div:nth-child(3) {
  @include daywiseTD(8);
  border: none;
  text-align: start;
}

.table_td div:nth-child(4) {
  @include daywiseTD(15);
  border: none;
  text-align: start;
  width: 11rem;
}
.table_td div:nth-child(5) {
  @include daywiseTD(15);
  border: none;
  text-align: start;
  width: 11rem;
}
.table_td div:nth-child(6) {
  @include daywiseTD(15);
  border: none;
  text-align: start;
  width: 11rem;
}

.table_td div:nth-child(7) {
  @include daywiseTD(15);
  border: none;
  text-align: start;
}
.table_td div:nth-child(8) {
  @include daywiseTD(7);
  border: none;
  text-align: start;
}
.monthth1 {
  @include header(0);
  height: 4rem;
}

.monthth1 div:nth-child(1) {
  @include daywiseTH(13);
  text-align: start;
  padding-left: 0.3rem;
  border: none;
}

.monthth1 div:nth-child(2) {
  @include daywiseTH(9);
  border: none;
}

.monthth1 div:nth-child(3) {
  @include daywiseTH(7);
  border: none;
}

.monthth1 div:nth-child(4) {
  @include daywiseTH(7);
  border: none;
}

.monthth1 div:nth-child(5) {
  @include daywiseTH(7);
  border: none;
}

.monthd1 {
  @include tableData(0);
}

.monthd1 p:nth-child(1) {
  @include daywiseTD(13);
  text-align: start;
  padding-left: 0.3rem;
  border: none;
}

.monthd1 p:nth-child(2) {
  @include daywiseTD(9);
  @include clickable();
  border: none;
}

.monthd1 p:nth-child(3) {
  @include daywiseTD(7);
  border: none;
  @include clickable();
}

.monthd1 p:nth-child(4) {
  @include daywiseTD(7);
  @include clickable();
  border: none;
}

.monthd1 p:nth-child(5) {
  @include daywiseTD(7);
  @include clickable();
  border: none;
}

.linkedin_icon {
  color: #0072b1;
  font-size: 1.5rem;
  cursor: pointer;
}
