.loaderImg {
    margin-left:46%;
    margin-top: 8%;
    width: 12%;
    background: transparent;
  }
  
  .loadingText {
    font-size: 24px;
    font-weight: bold;
    color: #562679;
    margin-top: 0.2rem !important;
    margin-left:47% !important;
  }