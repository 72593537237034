.HrFeedback {
  width: 100%;
  height: 100%;
  padding: 20px 30px;
}

.card {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;

  margin: 20px 0px;
  border-radius: 5px;
  background-color: #ffffff;
  overflow-y: hidden;
  /* padding: 20px; */
  font-size: 14px;
}

.cardDiv {
  padding: 0 20px;
}

.radio {
  display: flex;
  gap: 50px;
  font-weight: 600;
}

.title {
  display: flex;
  justify-content: center;
  color: rgb(123, 187, 123);
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
  font-weight: bold;
}

.feedback {
  display: flex;
  align-items: center;
}

.feedback>p {
  width: 200px;
}

.textArea {
  height: 100px;
  padding: 5px;
}

/* textArea placholder bold */
.textArea::placeholder {
  font-weight: bold;
}

.warn {
  width: 100%;
}

.btn {
  display: flex;
  justify-content: end;
  width: 95%;
  margin-bottom: 30px;
}

.CancelBtn {
  background-color: white;
  color: red;
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
}

.SumbitBtn {
  background-color: rgba(44, 116, 44, 0.815);
  color: rgb(255, 255, 255) red;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
}

.HrFeedback p {
  font-weight: 500;
  margin: 5px 0 !important;
}

.radioitem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  /* margin: 10px 0 0; */
}

.radioitem label {
  cursor: pointer;
}

.radioitem input[type="radio"] {
  display: none;
}

.radioitem label {
  font-weight: 600;
}

.radioitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #65a15e;
  background-color: transparent;
}

.radioitem input[type="radio"]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #65a15e;
}

.mand {
  color: red;
}

.modal>button {
  background-color: rgba(44, 116, 44, 0.815);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  margin: 10px auto;
}

.modal {
  width: 200px;
  margin: 0 auto;
  text-align: center;
}

.errorText {
  color: red;
  font-size: 12px;
  margin-left: 15px;
  visibility: visible;
}

.errorTextHidden {
  color: red;
  font-size: 12px;
  margin-left: 15px;
  visibility: hidden;
}

.imageupload {
  display: block;
  display: flex;
  position: relative;
}

.rmpdf {
  border: 1px solid rgba(44, 116, 44, 0.815);
  margin-top: 7px;
  width: 75%;
  border-radius: 10px;
  padding: 5px;
  padding-right: 50px;
  color: rgba(44, 116, 44, 0.815);
  height: 38px;
}

.rmpdfcross {
  color: black;
  border: 1px solid #000000;
  background-color: rgba(44, 116, 44, 0.815);
  position: absolute;
  right: -22%;
  top: -29%;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  z-index: 11;

}

.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px;
}

.upload_resume {
  width: 100%;
  border: 1px solid #999;
  border-radius: 10px;
  margin-top: 5px;

}

.Cu_row1 {
  width: 32rem;
  display: flex;
  align-items: center;

}

.Cu_row1 input[type="file"]::file-selector-button {
  border: 1px solid rgba(44, 116, 44, 0.815);
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: rgba(44, 116, 44, 0.815);
  transition: 1s;
  color: #ffffff;
  height: 38px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}