/* .timeline {
    position: relative;
    padding-left: 20px;
  }
  
  .timeline::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #ccc;
  }
  
  .timeline-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    position: relative;
  }
  
  .timeline-item::before {
    content: "";
    position: absolute;
    left: -15px;
    width: 12px;
    height: 12px;
    background-color: #999;
    border-radius: 50%;
  }
  
  .cp_rowcol123 {
    padding-left: 17px;
  }
  
  .cp_label {
    font-weight: bold;
    color: #333;
  }
  
  .cp_value {
    margin-left: 10px;
    color: #666;
  }
   */

   /* Timeline Container */
.timeline {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 25px;
}

/* Vertical Connecting Line */
.timeline-line {
  position: absolute;
  top: 0;
  left: 30px;
  width: 2px;
  height: 100%;
  background-color: #ccc;
}

/* Timeline Item */
.timeline-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

/* Circle Styles */
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  margin-right: 10px;
}

.green {
  background-color: #4caf50;
}

.gray {
  background-color: #9e9e9e;
}

.red {
  background-color: #f44336;
}

/* Content Styles */
.content {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.title {
  font-weight: 700;
  font-size: 15px;
}

.date {
  font-size: 15px;
  font-weight: 500;
  color: #666;
}
