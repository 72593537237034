.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(111, 111, 111, 0.5);
  z-index: 9;
}

.content {
  z-index: 10;
}

.img {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
