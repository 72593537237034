.PositionList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  /* height: 500px; */
  overflow-y: hidden;
  padding: 10px;
  font-size: 14px;
  /* margin-top: 26px; */
}
/*  */

.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}

.th p:nth-child(1) {
  width: 12rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  padding-left: 8px;
  background-color: #f5f7f9;
}

.th p:nth-child(2) {
  width: 5rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(3) {
  width: 5rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(4) {
  width: 5rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  /* padding-left: 5px; */
}

.th p:nth-child(5) {
  width: 7rem;
  text-align: start;
  color: #666666;
  font-weight: 600;
  /* padding-right: 14px; */
  font-size: 12px;
}

.th p:nth-child(6) {
  width: 6rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(7) {
  width: 7rem;
  text-align: start;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(8) {
  width: 10rem;
  text-align: start;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.td {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 12rem;
  text-align: start;
  color: #a15e5e !important;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* position: relative; */
  padding-left: 8px;
  font-size: 12px;
}

.td p:nth-child(2) {
  width: 5rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.td p:nth-child(3) {
  width: 5rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.td p:nth-child(4) {
  width: 5rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* padding-left: 8px; */
  font-size: 12px;
}

.td p:nth-child(5) {
  width: 7rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* margin-right: 13px !important; */
  font-size: 12px;
}

.td p:nth-child(6) {
  width: 6rem;
  text-align: start;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.td p:nth-child(7) {
  width: 7rem;
  text-align: start;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.td p:nth-child(8) {
  width: 10rem;
  text-align: start;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

/* .td:last-child {
  border-bottom: none;
}

/* last .td */
/* .td:last-child {
  border-bottom: none;
}
.showStatus > p:hover {
  scale: 1.05;
} */

.add {
  border: 2px solid #a15e5e;
  color: #a15e5e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.count {
  padding: 17px;
  border: 2px solid #a15e5e;
  color: #a15e5e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;
}
.count p{
  font-weight: 500;
}

.tooltip {
  cursor: pointer;
  margin-left: 10px;
}

.add > p {
  font-size: 14px;
  font-weight: bold;
}

.add:hover {
  cursor: pointer;
  background-color: #a15e5e;
  color: #ffffff;
}

p {
  margin: 0 !important;
}

.header {
  width: 95%;
  margin-left: 30px;
  margin-top: 10px;
}

.tableContent {
  width: 155px;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
}

.tableContentStatus {
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 155px;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  cursor: pointer;
}

.tableContent1 {
  width: 155px;
  /* text-align: center; */
  padding-left: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
  cursor: pointer;
}

.tableContent1:hover {
  color: green;
  /* font-size: 1rem; */
}

.cross {
  position: absolute;
  right: 10%;
  top: 0%;
}

.link {
  text-decoration: underline;
  color: limegreen;
  cursor: pointer;
}

.link:visited {
  color: #a15e5e;
}

.pagination {
  margin-top: 10px;
  margin-right: 30px;
  /* margin-bottom: 150px; */
  background-color: #a15e5e;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 36rem;
}

.topBarNav {
  position: absolute;
  top: 17%;
  right: 2%;
  display: flex;
}

.input {
  margin: 0 10px;
  border-radius: 5px;
  padding: 0 20px 0 10px;
  border: 1px solid rgba(0, 0, 0, 0.192);
}

.button {
  border: 1px solid #a15e5e;
  border-radius: 4px;
  color: white;
  background-color: #a15e5e;
  padding: 10px;
  font-size: 14px;
  float: right;
  margin-top: -100px;
  margin-right: 3%;
}

.input1,
.input2 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 90vw;
}

.search {
  position: absolute;
  right: 6%;
  width: 15px;
  top: 20%;
  background-color: white;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 84%;
  width: 21px;
  cursor: pointer;
}

.s1 {
  position: relative;
  width: 18rem;
  margin-left: 0.6rem;
}

/* .create_position__Control {
  width: 200px;
} */

.coreSkillInput {
  border-radius: 5px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  width: 99%;
  height: 38px;
  border: 1px solid #00000061;
  padding: 0 19px;
  outline: none;
  /* cursor: pointer; */
}

.coreSkillInput::placeholder {
  color: hsl(0deg 2% 18%);
}

.showStatus {
  /* width: 200px;
  height: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showStatus > p {
  padding: 10px;
}

.showStatus > p:hover {
  /* border-bottom: 1px solid #a15e5e
; */

  cursor: pointer;
}

.no_data {
  text-align: center;
  padding: 3%;
}

.plus {
  font-size: 18px;
}

/* media query for mac */
@media only screen and (max-width: 1280px) {
  .add {
    border: 2px solid #a15e5e;
    color: #a15e5e;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .tooltip {
    /* position: absolute !important;
    top: 11.9%;
    left: 32%; */
  }

  .plus {
    font-size: 20px;
  }
}

.filter {
  border: 1px solid #a15e5e;
  border-radius: 4px;
  color: white;
  background-color: #a15e5e;
  width: 7vw;
  font-size: 15px;
}

.tableContent:nth-child(1) {
  position: sticky;
  top: 0;
  left: 0;
}

.tableContent1 {
  position: sticky;
  top: 0;
  left: 0;
}
