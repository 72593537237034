* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.breadscrumb {
  font-size: 10px;
  font-weight: bold;
}

.active_btn {
  width: 140px;
  height: 40px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
}

span {
  font-weight: 500;
  font-size: 1rem;
}

.containerr {
  margin-top: 10px;
  width: 100%;
  float: right;
  text-align: right;
}

.containerr button {
  display: inline-block;
}

.arrow {
  font-weight: 600;
  color: rgb(252, 187, 8);
}

.arrow2 {
  font-size: 13px;
  font-weight: 600;
  color: rgb(252, 187, 8);
}

.main_client_body {
  width: 100%;
  display: flex;
  /* height: auto; */
  /* overflow-y: hidden;
overflow-x: hidden;
min-height: 40rem; */
  /* justify-content: space-between; */
  min-height: 46rem; 
}

.center_div {
  width: 80%;
  padding: 0px 20px;
  margin-top: 25px;
  margin-bottom: 20px;

  /* border: 1px solid red; */
}

.maindivContent {
  display: flex;
  flex-direction: row;
}

.h6 {
  margin-top: 15px;
}

.description_box {
  /* border: 1px solid red; */
  padding: 17px;
  height: auto;
  /* height: 350px; */
  margin-top: 9px;

  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 7px;
  background-color: white;
}

.description_box1 {
  /* border: 1px solid red; */
  padding: 17px;
  /* height: auto; */
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 7px;
  background-color: white;
}

.description_box2 {
  /* border: 1px solid red; */
  padding: 17px;
  height: auto;
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 7px;
  background-color: white;
}

.recent_tab {
  color: rgb(252, 187, 8);
  text-transform: capitalize;
}

.btn_box {
  /* border: 1px solid red; */
  padding: 10px 0px;
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 2%;
}

.span1 {
  margin-left: 400px;
  color: #fcb902;
}

.span2 {
  text-align: center;
  color: #fcb902;
  font-weight: 600;
}

.p1 {
  margin-left: 450px;
  color: grey;
}

.edit_btn {
  width: 80px;
  height: 40px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  border-radius: 6px;
  color: white;
  font-weight: 500;
}

.plus {
  font-size: 20px;
  padding-right: 4px;
  font-weight: 700;
}

.Add_Application_btn {
  width: 17%;
  height: 40px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  border-radius: 6px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.markAsClose_btn {
  width: auto;
  height: 40px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  border-radius: 6px;
  color: white;
  font-weight: 500;
}

.markAsClose_btn1 {
  width: auto;
  height: 40px;
  padding: 0px 0.7rem;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  border-radius: 6px;
  color: white;
  font-weight: 500;
}

.btnCont {
  width: auto;
  height: 40px;
  border: 1px solid rgb(252, 187, 8);
  background-color: rgb(252, 187, 8);
  border-radius: 6px;
  color: white;
  font-weight: 500;
}


.confirmPopupTitle {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin-top: -35px;
  margin-right: 62px;
}

.confirmPopupTitle1 {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin-top: -35px;
  margin-right: 182px;
}

.confirmPopupTitle2 {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin-top: 1px;
  margin-right: 130px;
}

.csd {
  width: 68%;
  height: 38px;
  font-weight: 500;
  box-shadow: 0px 3px 9px #00000029 !important;
  margin: 8px 1% 0px 1.5%;
  border-radius: 9px;
  background-color: #F5F6FA;
}

.csd1 {
  width: 75%;
  height: 100px;
  font-weight: 500;
  /* box-shadow: 0px 3px 9px #00000029 !important; */
  margin: 8px 0% 0px 0.5%;
  border-radius: 9px;
  /* background-color: #F5F6FA; */
}


.btnCont1 {
  display: flex;
  width: 10rem;
  justify-content: space-between;
  float: right;
  margin-right: 4rem;
}

.btnCont2 {
  display: flex;
  width: 15rem;
  justify-content: space-between;
  float: right;
  margin-right: 4rem;
}

.btnCont3 {
  display: flex;
  width: 10rem;
  justify-content: space-between;
  float: right;
  margin-right: 4rem;
}

.confirmPopup {
  width: 386px;
  min-height: 198px;
  text-align: center;
  padding-top: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.confirmPopup1 {
  width: 386px;
  height: 350px;
  text-align: center;
  padding-top: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}

.errorText2 {
  color: red;
  font-size: 12px;
  margin-left: -7.6rem !important;
  visibility: visible !important;
  padding: 5px 0;
}

.errorTextHidden2 {
  color: red;
  font-size: 12px;
  /* margin-left: 15px; */
  visibility: hidden !important;
  padding: 5px 0;
}