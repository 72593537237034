.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 21vw;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin: 1% 1% 1% 1%;
  margin-top: 10px;
  /* padding-left: 10px; */
  padding: 5px 5px;
  height: 2.3rem;
  outline: none;
  position: relative;
}
.listContaine {
  background-color: rgb(253, 253, 253);
  width: 18.2%;
  z-index: 999;
  margin-top: 10px;
  position: absolute;
  cursor: pointer;
  padding-left: 0px;
  height: 200px;
  overflow-y: scroll;
}
/* .listContaine::-webkit-scrollbar {
  height: 24px;
  width: 7px;
  background: #ffffff;
}

.listContaine::-webkit-scrollbar-thumb {
  background: #a15e5e;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.75);
}
.listContaine::-webkit-scrollbar-corner {
  background: #fffefe;
} */

.lists {
  padding: 7px 0px 7px 5px !important;
}
li {
  list-style-type: none;
}
.listContaine :hover {
  background-color: #a15e5e;
  color: #f5f6fa;
}
.crossBtn {
  position: absolute;
  margin: -3.2% 0px 0 16.5%;
  cursor: pointer;
  font-size: 25px;
  margin-left: 20%;
}

@media only screen and (max-width: 1280px) {
  .crossBtn {
    position: absolute;
    /* margin: -0.5% 0 0 -2.5%; */
    margin-top: -47px;
    cursor: pointer;
    font-size: 31px;
  }
}
/* ::-webkit-scrollbar {
  width: 12px;
} */
