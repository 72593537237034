.table{
    margin-top: 1rem;
}
.tableContent1 {
    width: 120px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
.th {
    display: flex;
    justify-content: space-around;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 50px;
    min-width: 95%;
  }
  
  .th p:nth-child(1) {
    width: 18rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: var(--atsFontSize);
    padding-left: 8px;
  }
  
  .th p:nth-child(2) {
    width: 18rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: var(--atsFontSize);
  }
  
  .th p:nth-child(3) {
    width: 18rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: var(--atsFontSize);
  }
  
  .th p:nth-child(4) {
    width: 18rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: var(--atsFontSize);
  }
  
  .th p:nth-child(5) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: var(--atsFontSize);
  }
  
  .th p:nth-child(6) {
    width: 13rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 600;
    font-size: var(--atsFontSize);
  }
  
  .td {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px 0 10px;
  }
  
  .td:last-child {
    border-bottom: none;
  }
  
  .td p:nth-child(1) {
    width: 18rem;
    text-align: start;
    font-size: 15px;
    color: var(--atsLinkColor);
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding-left: 8px;
    font-size: var(--atsFontSize);
  }
  
  .td p:nth-child(2) {
    width: 18rem;
    text-align: start;
    font-size: 20px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: var(--atsFontSize);
  }
  
  .td p:nth-child(3) {
    width: 18rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: var(--atsFontSize);
  }
  
  .td p:nth-child(4) {
    width: 18rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: var(--atsFontSize);
  }
  
  .td p:nth-child(5) {
    width: 10rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: var(--atsFontSize);
  }
  
  .td p:nth-child(6) {
    width: 13rem;
    text-align: start;
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: var(--atsFontSize);
  }
  
  .tableContent {
    width: 120px;
    text-align: center;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .hideContainer{
    height: 3rem;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: height 0.3s ease-in-out;
  }
  /* .hideContainerOpen{
    max-height: auto;
  } */