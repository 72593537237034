.circleDesign {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a15e5e;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.circleClick {
  width: 2.5rem;
  height: 2.5rem;
  background: #a15e5e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: #fff;
}

.skillRating {
  width: 25vw;
  text-align: start;
  margin: -9px 0 0 0 !important;
}
