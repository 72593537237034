.mainSection {

    width: 100%;
    height: 100vh;
    /* position: fixed; */
    /* position: absolute; */
    top: 100px;
    bottom: 0;
    display: flex;
  }

  .asigned_color {
    color: #fcb902;
  }
  .titlee{
    margin-right: 25px !important;
  }

  .done_color {
    color: #65a15e;
  }

  .onhold_color {
    color: #f94244;
  }

  /* .container{
    margin-left: 2%;
    width: 96%;
}
.container span{
    margin-top: 10px;
}
.createTask {
    margin-top: 30px;
    
    border-collapse: collapse;
    
    
    box-shadow: 0 4px 8px 8px rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
padding: 16px;
  display: block;
    overflow-x: auto;
  }
  .createTask label{
      display: block;
  }
  .gridContainer {
      display: flex;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
  }
  .gridContainer label {
      margin-left: 10px;
  }
  .gridContainer input {
      width: 90%;
      min-width: 250px;
      height: 30px;
      background-color: #0000001A;
      border: none;
  }
  .gridContainer textarea{
    width: 350px;
    height: 30px;
    background-color: #0000001A;
    border: none;
  } */

  .loader {
    width: 17%;
    margin: 5% 13% 0% 42%;
  }
  
  .loaderPara {
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    color: #65a15e;
    margin-top: -4% !important;
    font-style: italic;
    padding-left: 36%;
    margin-left: 34.5%;
  }

  .mainSection {
    width: 100%;
    height: 100vh;
    /* position: absolute; */
    top: 70px;
    bottom: 0;
    display: flex;
  }

  .rightSection {
    /* border: 2px solid blue; */
    width: 80%;
  }

  .taskAssigned {
    background-color: #ffffff;
    width: 96%;
    margin-left: 1%;
    box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    margin-top: 10px;
    /* height: 330px; */
  }

  .taskAssigned::-webkit-scrollbar {
    width: 15px;
  }

  .taskAssigned::-webkit-scrollbar-track {
    background: #ffffff;
    /* color of the tracking area */
  }

  .taskAssigned::-webkit-scrollbar-thumb {
    background-color: #d3d1d1;
    border-radius: 20px;
    border: 4px solid #ffffff;
  }

  .taskAssigned .container {
    display: block;
    padding-bottom: 0px;
    position: relative;
  }

  .req_msg {
    margin-left: 1%;
    text-align: left;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.5px;
    color: #f84142;
    opacity: 1;
    top: 47%;
    left: 23.5%;
    bottom: 0%;
    right: 42%;
    /* position: absolute; */
  }

  /* .formtable{
    height: 200px;
    background-color: aqua;
  } */
  @media screen and (max-width: 768px) {
    .taskAssigned .container {
      display: block;

      position: relative;
    }
  }

  .taskAssigned .container .subcontainer {
    /* width: 70%;
    display: grid;
    gap: 20px 10px;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    text-align: left; */

    /* border: 1px solid red; */

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* padding-top: 5%; */
    /* margin-bottom: 62px; */
  }

  .subcontainerinner {
    margin-bottom: 10px;
    padding-top: 2%;
    /* width: 25%; */
  }

  .subcontainerinner p {
    text-align: justify;
    margin-right: 5px;
  }

  .subcontainer h5 {
    font-size: 17px;
    /* font-weight: 500; */
    font-weight: bold;
    /* opacity: .8; */
  }

  .subcontainer p {
    font-size: 15px;

    /* opacity: .7; */
  }

  .tabl {
    /* margin-top: 40px; */
  }

  /* .table{
    margin-top: 5px;
  } */

  .rr {
    height: initial;
    width: 78%;
    display: inline-block;
    position: absolute;
    text-align: right;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    .taskAssigned .container .subcontainer {
      margin-bottom: 10px;
      width: 98%;
    }

    .rr {
      /* display: grid;
      gap: 20px 10px; */
      height: initial;
      width: 35%;
      display: contents;
      /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
      /* border: 1px solid red; */
      position: absolute;
      bottom: 10px;
      right: 0;
    }
  }

  .rr button {
    bottom: 0;
    margin: 0 5px 0 5px;
  }

  .rr .taskHistory {
    background-color: #65a15e;;
    outline: none;
    border: 1px solid #65a15e;
    border-radius: 5px;
    /* padding: 11px 20px 11px 20px; */
    color:#ffffff;
    margin-bottom: 4px;
    width: 160px;

  }
  .rr .closed {
    background-color: #65a15e;;
    outline: none;
    border: 1px solid #65a15e;
    border-radius: 5px;
    /* padding: 11px 20px 11px 20px; */
    color:#ffffff;
    margin-bottom: 4px;
    width: 105px;

  }

  
  .rr .TaskDone {
    background-color: #a65959;;
    outline: none;
    border: 1px solid #65a15e;
    border-radius: 5px;
    /* padding: 11px 20px 11px 20px; */
    color:#ffffff;
    margin-bottom: 4px;
    width: 140px;

  }

  .rr .delete {
    /* background-color: #ffffff; */
    background-color: #bf5b5b;
    outline: none;
    border: 1px solid #bf5b5b;
    border-radius: 5px;
    /* padding: 11px 20px 11px 20px; */
    color: #ffffff;
    margin-bottom: 4px;
    width: 80px;
  }

  .rr .edit {
    background-color: #65a15e;
    outline: none;
    border: none;
    border-radius: 5px;
    /* padding: 11px 20px 11px 20px; */
    color: #ffffff;
    margin-bottom: 4px;
    width: 70px;
  }

  .text-area-container {
    margin-bottom: 10px;
    /* height: 50px; */
    width: 100%;
    background-color: white;
    /* box-shadow: 0px 3px 6px #0000001a; */
    border-radius: 10px;
    padding-left: 3px;
    padding-top: 5px;
    font-size: 15px;
    font-family: sans-serif;
    /* border: 1px solid #65a15e; */
  }

  .text-area-container textarea {
    border: none;
    width: calc(100% - 2px);
    height: 100%;
    resize: none;
    padding-left: 10px;
  }

  /* .taskAssigned textarea {
  overflow-y: scroll;
  overflow-x: hidden;

  height: 50px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 5px;
  font-size: 15px;
  font-family: sans-serif;
  border-color: #65a15e;
  resize: none;
} */

  /* .textArea{
    background-color: white;
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 10px;
    padding-left: 10px;
    padding-top: 5px;
    font-size: 15px;
    font-family: sans-serif; 
    border-color: #65A15E;
  }
   */
  .text-area-container textarea:focus {
    outline: none;
  }

  /* .addScroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70%;
    
  } */

  .text-area-container textarea::-webkit-scrollbar {
    width: 12px;
    /* position: ; */
  }

  .text-area-container textarea::-webkit-scrollbar-track {
    background: #ffffff;
    border-top: #65a15e;
    /* background: linear-gradient(to bottom, #f5f6fa 10%, white 9%) */
  }

  .text-area-container textarea::-webkit-scrollbar-thumb {
    background-color: #d3d1d1;
    border-radius: 20px;
    border: 4px solid #ffffff;
  }

  .comment {
    margin-top: 5px;
    /* font-family: "Poppins"; */
    font-weight: bold;
    color: #000000;
    font-size: 18px;
    margin-bottom: -10px;
    /* opacity: .85; */
  }

  .addComment {
    background-color: #65a15e;
    outline: none;
    border: none;
    border-radius: 5px;
    /* margin-top: 10px; */
    padding: 11px 20px 11px 20px;
    color: #ffffff;
    float: right;
  }

  .Closed_color {
    color: #163bbe;
    /* #296d98 */
  }

  /* .tbody{
    overflow: scroll;
  } */

  /* .tabl {
    margin-top: 30px;
  }
  .tabl span {
    font-size: 15px;
    font-weight: 500;
  }
  .tabl table {
    width: 100%;
    margin-top: 5px;
  } */
  .mobileContainer {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .rightSection {
      width: 96%;
    }

    .taskAssigned textarea {
      height: 150px;
      font-size: 18px;
      padding: 10px;
    }

    .taskAssigned {
      margin-left: 4%;
    }

    .addComment {
      float: none;
    }

    .tabl table {
      display: none;
    }

    .mobileContainer {
      display: block;
      padding: 20px;
    }
  }

  .tabl table {
    border-collapse: collapse;
    width: 100%;
  }

  .tabl table th {
    text-align: left;
    padding: 16px;
    font-size: 15px;
    font-weight: 500;
    background-color: #f5f6fa;
  }

  .tabl table tr {
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    padding: 16px;
  }

  .tabl table td {
    /* border: 1px solid blue; */
    border: none;
    text-align: left;
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid #ccc;
  }

  .tabletaskId {
    color: #65a15e;
    font-style: italic;
    /* font-size: 14px; */
    text-decoration-line: underline;
  }

  .tabletaskTitle {
    color: #65a15e;
    font-style: italic;
    /* font-size: 14px; */
    text-decoration-line: underline;
    text-underline-position: under;
  }

  .tabl table .tabletitle2 {
    font-family: italic;
  }

  /* .tablheader {
     
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    padding: 16px;
  } */

  .asigned_color {
    color: #fcb902;
  }

  .done_color {
    color: #65a15e;
  }

  .onhold_color {
    color: #f94244;
  }

  .userName {
    color: #65a15e;
  }

  .confirmPopup {
    /* top: 441px;
  left: 767px;*/
    width: 386px;
    height: 198px;
    text-align: center;
    padding-top: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 4px 8px #00000029;
    border-radius: 9px;
    opacity: 1;
  }

  .confirmPopupTitle {
    text-align: center;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
  }

  .btnCont {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .yesBtn {
    padding: 5px 30px;
    outline: none;
    background: #65a15e 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 6px;
    color: #fff;
    opacity: 1;
  }

  .noBtn {
    margin-left: 10px;
    padding: 5px 30px;
    outline: none;
    font: normal normal 600 18px/27px Poppins;
    background: #fff;
    letter-spacing: 0px;
    color: #65a15e;
    opacity: 1;
    border: 1px solid #65a15e;
    border-radius: 6px;
  }

  .ttAddBtn {
    background-color: #65A15E;
    border-color: #65A15E;

  }

  .ttcancelBtn {
    margin-right: 1rem;
    background-color: #65A15E;
    border-color: #65A15E;
  }

  .btnROW {
    margin-top: 1rem;
    text-align: center;
  }

  .inpBox {
    width: 24rem;
    height: 9rem;
    padding: 5px;
  }

  .modalInput {
    margin-top: 1rem;
  }  

  .modalHead {
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  .blueLink{
    color: blue !important;
    cursor: pointer;
  }