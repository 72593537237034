.label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
  }
  .label1 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
    margin-top: 15px !important;
  }
.partnerlabal{
    margin-left: 12px !important;
}
.primaryContact{
    border-bottom:2px solid rgba(8,8,8,.1) ;
    text-align: center;
    color: #C3B14B;
    padding-bottom: 10px;

  }

  .label3 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 12px !important;
  }
  .label4 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 4px !important;
  }
  .label5 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 10px !important;
  }

  .label6 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 15px !important;
  }


  .cu_field23Last {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 96%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 6px;
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
  }

  .cu_field23Last1 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 96%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 6px;
    padding-left: 10px;
    margin-left: 7px;
    outline: none;
    padding-top: 5px;
  }

  .smallInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 93%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
    margin-left: 10px;
  }
  .buttonAdd {
    background-color:#C3B14B;
    border-color: #C3B14B;
    width: 100px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-bottom: 10px;
    margin-top: 17px;
  }

  .buttonAdd4 {
    background-color:#C3B14B;
    border-color: #C3B14B;
    width: 100px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-bottom: 10px;
    margin-right: 25px;
  }

  
  .th {
    display: flex;
    justify-content: normal;
    background-color: #f5f7f9;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    color: #666666;
    font-size: 14px;
    font-weight: 600;
  }
  
  .th p:nth-child(1) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
    padding-left: 14px;
  }
  
  .th p:nth-child(2) {
    width: 10rem;
    text-align: start;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    left: 35%;
  }
  
 

  
 
  .td {
    display: flex;
    justify-content: normal;
    border-radius: 10px;
    align-items: center;
    height: 40px;
    min-width: 95%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
  }
  
  .td:last-child {
    border-bottom: none;
  }
  
  .td p:nth-child(1) {
    width: 10rem;
    text-align: start;
    font-size: 14px;
    color: #666666;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 14px;
    color: #C3B14B !important;
    font-weight: bolder;
  }
  
  .td p:nth-child(2) {
    width: 10rem;
    text-align: start;
    font-size: 14px;
    color: #666666;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
    position: relative;
    left: 35%; 
  }
  
  
  
 

