.container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0rem 1rem;
  margin-top: 1.5em;
  width: 100% !important;
  /* gap: 2.5em; */
}
.colum {
  width: 29% !important;
}
.container1 {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 1rem 0rem 1rem;
  margin-top: 1.5em;
  width: 100% !important;
  grid-gap: 4em;
}
.container2 {
  padding: 1rem 1rem 1rem 1rem;
  margin-top: 1.5em;
}

.inputbutton {
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 20px;
}

.submitBtn {
  width: 100px;
  border-radius: 10px;
}
.submitBtn:disabled {
  background-color: gray;
  color: white;
  border: 1px solid gray;
}
.submitBtn:disabled {
  opacity: 1.7;
  cursor: not-allowed;
}

.clients {
  padding-bottom: 1.5em;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.projectresource_mainSection {
  width: 100%;
  /* height: 90vh; */
  display: flex;
  /* border: 1px solid red; */
}

.projectresource_sidebarsection {
  /* border: 10px solid red; */
}

.projectresource_mainsection {
  width: 80%;
  /* margin-left: 2em; */
  margin-top: 25px;
  padding: 0px 20px;
  /* border: 1px solid red; */
}

.head {
  color: #fcb902;
}

/* this is main */
.maindiv {
  /* border:3px solid red; */
  background-color: #ffffff;
  width: 100%;
  height: 24rem;
  border-radius: 6px;
  padding-top: 10px;
  box-shadow: 0px 0px 5px 0px grey;
}

.maindivContent {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.span1 {
  color: #fcb902;
}

.maindiv div {
  padding-top: 0px;
  font-weight: 500;
  /* padding-left:2em; */
}

.maindiv div p {
  font-weight: 600;
  height: 7px;
}

/* this is form section */
.projectmaindiv {
  width: 100%;
  margin-top: 20px;
  display: flex;
}

.projectformSection {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.projctdesSection {
  width: 60%;
}

.proHeader {
  display: flex;
  flex-direction: row;
}

/* .inputbutton {
  margin-left: 90%;
}

.inputbutton button {
  width: 80px;
  margin-left: -35px;
  margin-top: 14px;
  height: 38px;
  border-radius: 6px;
  background-color: #fcb902;

} */

.desc {
  margin-left: 4px;
}

.projectmaindiv {
  width: 100%;
  margin-top: 20px;
  display: flex;
}

.projectformSection {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.headlabel {
  color: #0000;
  font-weight: 500;
}

.projctdesSection {
  width: 60%;
}

.proSecton select {
  width: 90%;
  height: 35px;
  background-color: #f5f6fa;
  border-radius: 4px;
  border: none;
  margin-left: 60px;
}

.proSecton label {
  color: #000;
  font-weight: 500;
  font-size: 1rem;
  margin-left: 60px;
}

.proHeader {
  display: flex;
  margin-left: -6px;
  flex-direction: row;
}

.input1 {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  margin-left: 65px;
  padding: 5px;
  width: 88%;
  height: 35px;
  margin-top: 6px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
}

.proleftSection {
  display: flex;
  flex-direction: row;
  padding: 6px;
}

.prosubHeader {
  margin-left: 85px;
  margin-top: -7px;
  font-weight: 500;
  font-size: 13px;
  padding: 6px;
}

.subElement {
  margin-top: -5px;
  font-weight: 500;
  font-size: 13px;
  margin-left: 80px;
  padding: 6px;
  border: none;
}

/* .inputbutton {
  margin-left: 87%;
  margin-top: 3%;
  cursor: pointer;
} */

.error {
  color: red;
  font-weight: 500;
  padding-top: 4px;
  padding-left: 4px;
}

.error2 {
  color: red;
  font-weight: 500;
  padding-left: 4px;
}

.input {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  height: 2.3em;
  margin-top: 6px;
  /* min-width: 20em; */
  width: 100%;
  padding: 7px;
  outline: none;
  border: none;
  background-color: #f5f6fa !important;
  font-weight: 500;
  background: transparent 0% 0% no-repeat padding-box;
}

.date {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  height: 2.3em;
  margin-top: 6px;
  /* width: 21.3em; */
  width: 100%;
  padding: 7px;
  padding-top: 5px !important;
  border: none;
  font-size: 15px;
  background-color: #f5f6fa !important;
  font-weight: 500;
  background: transparent 0% 0% no-repeat padding-box;
}

/* .inputbutton{
    margin-right:10px
  } */
label {
  font-weight: 500;
}

h6 {
  font-weight: 500;
}

.text {
  top: 383px;
  left: 899px;
  outline: none;
  height: 90px;
  margin-top: 5px;
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1.7;
  border: none;
  padding: 8px;
  width: 100%;
  /* resize: none; */
  font-weight: 500;
}

.desc {
  margin-top: 22px;
  font-weight: 500;
}

.textarea {
  width: "88px";
}
