.project_main_div {
  padding: 2%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.right_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2%;
  width: 33%;
}

.dropdown_div {
  display: flex;
  gap: 5px;
}

.cursor {
  cursor: pointer;
  font-weight: 600;
  color: #c3b14b;
  text-decoration: underline;
  font-size: 0.8rem;
}

.dropdown_div2 {
  width: 11vw;
}

.dropdown_div3 {
  width: 8vw;
}

.table_div {
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  overflow-x: auto;
  box-sizing: border-box;
  white-space: nowrap;
  margin: 1.5% 0%;
}

.table {
  width: 100%;
  font-size: 12px;
  border: none;
}

.table_heading {
  position: sticky;
  top: 0px;
  background-color: #f5f6fa;
  color: #666666;
}

.table_head {
  padding: 10px;
  font-size: 0.8rem;
}

.table_column {
  padding: 10px;
  font-size: 0.8rem;
}

.create_btn {
  border: 2px solid #c3b14b;
  font-size: 2rem !important;
  margin-left: 2%;
  color: #c3b14b;
  border-radius: 100%;
  cursor: pointer;
}

.create_btn:hover {
  color: white;
  background-color: #c3b14b;
}

.left_div {
  width: 33%;
  display: flex;
  align-items: center;
}

.count_div {
  border: 2px solid #c3b14b;
  color: #c3b14b;
  min-width: 4rem;
  height: 2rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 1rem;
  padding: 0.4rem;
  font-weight: 700;
}

.input_box {
  box-shadow: 0px 3px 9px #00000029 !important;
  background-color: #f5f6fa;
  padding: 5px 5px;
  width: 100%;
  height: 2.3rem;
  outline: none;
  border: none;
  border-radius: 9px;
}

.filter_div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c3b14b;
  width: 10vw;
  height: 40px;
  border-radius: 30px;
  cursor: pointer;
  color: #c3b14b;
  font-weight: 600;
}

.modal_div {
  display: flex;
  justify-content: center;
  width: 75vw;
  gap: 2%;
}

.left_modal_div,
.right_modal_div {
  width: 50%;
  border: 1px solid #c3b14b;
}

.checkbox {
  visibility: visible !important;
  width: 15px !important;
  height: 15px !important;
  accent-color: #c3b14b !important;
  color: white !important;
  outline: none !important;
}

.modal_heading {
  background-color: #c3b14b;
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2%;
  padding: 3% 5%;
}

.btn_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3%;
  margin-top: 2%;
}

.btn1,
.btn2 {
  width: 100px;
  border: 1px solid #c3b14b;
}

.btn1 {
  background-color: white;
  color: #c3b14b;
}

.btn2 {
  background-color: #c3b14b;
}

.s1 {
  position: relative;
  width: 10rem;
}

.cp_serchIcon {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 84%;
  width: 21px;
  cursor: pointer;
}

.coreSkillInput {
  border-radius: 5px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: transparent;
  width: 100%;
  height: 38px;
  border: 1px solid #00000061;
  padding: 0 8px;
  outline: none;
}

.coreSkillInput::placeholder {
  color: hsl(0deg 2% 18%);
}