.customSelect__control {
    width: 96px; /* Set the width of the control */
    height: 20px; /* Set the height of the control */
    border: 1px solid #ccc; /* Border color */
    box-shadow: none; /* Remove shadow */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    padding: 0; /* Remove default padding */
    min-height: 20px !important;
    cursor: pointer !important;
  }

  .customSelect__control--is-focused {
    box-shadow: none !important;
  }

  .customSelect__value-container{
    display: block !important;
    padding: 0px !important;
  }

  .customSelect__indicators{
    display: flex;
    align-items: center;
    height: 20px;
    margin-right: 2px;
  }
  
  .customSelect__single-value {
    line-height: 20px; /* Center text vertically in the control */
    font-size: 14px; /* Font size for the selected value */
    display: flex;
    justify-content: center;
  }
  
  .customSelect__menu {
    width: 108px !important; /* Set the width of the dropdown menu */
    z-index: 3;
    margin-top: 2px !important;
    border: 1px solid green !important;
    transform: translateX(-6px);
  }
  
  .customSelect__option {
    height: 20px !important;
    font-size: 14px !important;
    padding: 0px !important; /* Set the height of each option */
    display: flex !important; /* Use flexbox for alignment */
    align-items: center;
    justify-content: center !important; /* Center text vertically in the option */
    padding: 0 8px; /* Optional padding for the option */
  }
  
  .customSelect__option--is-focused {
    background-color: #f0f0f0; /* Background color for focused option */
    color: #000; /* Text color for focused option */
  }