/* .input_file{
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
} */
.Cu_file {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0 1%;
    position: relative;
    grid-column-gap: 5px;
    margin-top: 0.3rem !important;
}

/* .rmpdf {
    border: 1px solid #65a15e;
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    padding: 0.5rem;
    color: #65a15e;
}

.cross {
    color: black;
    position: absolute;
    top: 47%;
    right: 30px;
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
} */
.rmpdf {
    /* border: 1px solid #65a15e; */
    /* width: 3rem; */
    /* height: 3rem; */
    /* border-radius: 10px; */
    /* padding: 0.5rem; */
    color: #65a15e;
}
.rmpdf svg{
    font-size: 30px;
    color: #4B59C3;
    vertical-align: baseline;
}

.pdfname {
    color: #666;
    background: rgb(240, 240, 240);
    padding: 5px 10px;
    border-radius: 25px;
    text-overflow: ellipsis;
}

.rmpdfcross {
    color: black;
    border: 1px solid #000000;
    background-color: white;
    position: absolute;
    left: 44%;
    top: -35%;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
}
.rmpdfcross:hover{
    background-color: black;
    color: white;
}

.close_icon {
    width: 20px;
    height: 20px;
    color: #666;
}

.close_icon:hover {
    border: 1px solid #666;
    border-radius: 50%;
}

.upload_file::-webkit-file-upload-button {
    border: none;
    padding: 7px 10px;
}

.upload_file {
    width: 100%;
    border-radius: 7px;
    box-shadow: 0px 3px 9px #00000029 !important;
    opacity: 1;
    /* height: 36px; */
    /* background: transparent 0% 0% no-repeat padding-box; */
    /* background-color: #f5f6fa; */
    border: none;
    /* padding: 5px 5px; */
    height: 2.3rem;
    outline: none;
    cursor: pointer;
    margin: 0 10px;
}

/* input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 20px;
    cursor: pointer;
    background-color: #f3f4f6;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  } */