*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .main_client_body{
    width: 100%;
    /* height: 90vh; */
    display: flex;
  }
  
  .table_div{
    width:80%;
    padding: 0px 35px;
  }
  
  .table_div_head{
    /* border: 1px solid red; */
    /* padding: 15px; */
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
  }
  
  .breadscrumb{
    font-size: 10px;
    font-weight: bold;
  }
  
  .second_child{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-bottom: 0.5rem;
  }
  
  .recent_tab{
    color: rgb(252, 187, 8);
  }
  
  .arrow{
    font-size: 13px;
    font-weight: bold;
  }

  .noData{
    /* border: 1px solid green; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.noData_header{
    color:#989898;
}
  .scroll{
    height: 33%;
    overflow-y: scroll;
  }
  
  .table_div_body{
    /* border: 1px solid red; */
    margin-top: 5px;
    border-radius: 10px;
    height: 88%;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px grey;
  }
  
  .header{
    display: flex;
    /* height: 5%; */
    border-bottom: 1px solid grey;
    
  }
  
  .content{
    margin-left: 25px;
    font-weight: bold;
  }
  
  /* .form_div{
    height: 30%;
    border: 1px solid red;
  } */
  
  .form_first_div{
    display: flex;
  }
  
  .form_content{
    width: 25%;
  }


  
  textarea {
    width: 90%;
    border: 2px solid white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px grey;
  }
  
  .btn_div{
    margin-top: 0px;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
    border-radius: 10px
  }
  
  button{
    background-color: rgb(252, 187, 8);
    border: 5px solid rgb(252, 187, 8);
    font-weight: 500;
    padding: 5px;
    color: white;
    border-radius: 7px;
  }
/*   
input{
    border-radius: 5px;
    outline: none;
    border: 2px solid white;
    box-shadow: 0px 0px 3px 0px grey;
  } */

  .form_second_div{
    margin-top:15px;
    display:flex;
    /* border: 1px solid red; */
  }
  .input {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 460px;
    height: 32px;
    margin-top: 6px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #F5F6FA;
  }
  /* .inputbutton{
    margin-right:10px
  } */
/* span{
  font-weight: 500;
  font-size: 1rem;
} */
  .text {
    top: 383px;
    left: 899px;
    height: 105px;
    margin-top:5px;
    background: transparent 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    border: none;
    resize: none;
    width: 475px;
    background-color: #F5F6FA;
  }