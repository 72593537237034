.mainSection {

  width: 100%;
  height: 100vh;
  /* position: fixed; */
  /* position: absolute; */
  top: 100px;
  bottom: 0;
  display: flex;
}



/* flexbox */
.flex-container {
  margin-left: 50px;
  padding: 20px;
  font-size: 25px;
}

/*  */
.ahref {
  color: #65a15e;
  text-decoration-line: underline;
  /* padding-left: 12px; */
}

.taskhistory {
  margin-top: 2%;
}

.back_btn {
  float: right;
  /* margin-right: 3%; */
  /* margin-top: 12px; */
  font-size: 20px;
  font-style: italic;
  text-decoration: underline;
  color: #65A15E;
  margin-right: 22px;
  margin-bottom: 10px;
}

.backbutton {
  margin-bottom: 14px;
}

.back_btn_img {
  padding-right: 5px;
}

.rightSection {
  /* border: 2px solid blue; */
  width: 80%;
}

.container {
  box-shadow: 0 4px 8px 8px rgb(0 0 0 / 20%);
  width: 96%;
  margin-left: 2%;
  border-radius: 5px;
  margin-top: 15px;
  background-color: #ffffff;
  height: 31rem;
  overflow-y: hidden;
}

.addScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: auto !important;
}

.addScroll::-webkit-scrollbar {
  width: 15px;
}

.addScroll::-webkit-scrollbar-track {
  background: #ffffff;
  /* background: linear-gradient(to bottom, #f5f6fa 10%, white 9%) */
}

.addScroll::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
}

.container span {
  padding-top: 10px;
  font-size: 21px;
  opacity: .85;
  font-weight: 500;
}

.sppan {
  margin-top: 10px !important;
  font-size: 1.3125rem;
  font-weight: 500;
  padding: 10px 0px 10px 20px;
}

.mobileHeader {
  display: none !important;
}

.noTasks {
  display: flex;
  height: 350px;
  justify-content: center;
  align-items: center;
}

.noTasks img {
  background: transparent 0% 0% no-repeat padding-box;
  margin-left: 27%;
}

.noTasks h2 {
  font-size: 26px;
  opacity: 0.35;
}


.mobileContainer {
  display: none;
}

.container table {
  border-collapse: collapse;
  width: 100%;
  /* margin-top: 10px; */
}

.container table thead {
  background-color: #F5F6FA;
}

.container table thead tr {
  text-align: left;
  opacity: .85;
  font-size: 15px;
  font-weight: 600;
}

.container table thead tr th {
  /* padding-left: 22px; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.loader {
  width: 17%;
  margin: 9% 13% 0% 42%;
}

.loaderPara {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  color: #65A15E;
  margin-top: -4%;
  font-style: italic;
  margin-left: 34.5%;
  padding-left: 36%;
}

.taskid {
  width: 25%;
  padding-left: 13px;
}

.taskTitle {
  width: 25%;
  padding-left: 7px;
  /* padding-left: 13px; */
}

.taskdesc {
  width: 25%;
  padding-left: 3px;
  /* padding-left: 12px; */

}

.taskAssigned {
  width: 25%;
  padding-left: 0px;
  /* padding-left: 13px; */

}


.tabletaskid {
  width: 25%;
  /* padding-left: 25px; */
  /* padding-left: 11px; */
  padding-top: 16px;
}

.tabletaskname {
  width: 24%;
  padding-top: 7px;
  /* padding-left: 25px; */
}

.tabletaskfield {
  width: 25%;
  padding-top: 7px;
}

.tabletaskdesc {
  width: 26%;
  padding-top: 7px;
  /* padding-right: 20px; */
}

/* scroll */

.scrollAdd {
  /* box-shadow: 0 4px 8px 8px rgba(0,0,0,0.2); */
  /* width: 96%;
  margin-left: 2%;
  padding: 0px 0px 0px 0px; */
  border-radius: 5px;
  /* margin-top: 15px; */
  background-color: #ffffff;
  height: 401px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* display: none; */
}

.scrollAdd::-webkit-scrollbar {
  width: 12px;
}

.scrollAdd::-webkit-scrollbar-track {
  background: #ffffff;
  /* color of the tracking area */
}

.scrollAdd::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
}

/* end */


/* .scrolldown {
  width: 100%;
  border-spacing: 0;
  border-radius: 5px;
  background-color: #ffffff;
  height: 401px;
  overflow-y: auto;
  overflow-x: hidden;
} */


/* .scrolldown::-webkit-scrollbar-track {
  background: #ffffff;       
}

.scrolldown::-webkit-scrollbar-thumb {
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;
}  */

/* .scrolldown tbody, .scrolldown thead::-webkit-scrollbar{
  width: 15px;
}
.scrolldown thead tr th::-webkit-scrollbar-track{
  background: #ffffff;
} 
.scrolldown tbody::-webkit-scrollbar-thumb{
  background-color: #d3d1d1;
  border-radius: 20px;
  border: 4px solid #ffffff;  */
/* }  */
/*  */



.container table tbody .tabledata td {
  padding: 10px 5px 10px 14px;
}

@media screen and (max-width: 800px) {
  .rightSection {
    /* border: 2px solid blue; */
    width: 100%;
  }

  table {
    display: none;
  }

  .taskTracker table {
    display: none
  }

  .mobileContainer {
    display: block;
    padding: 20px
  }
}





/* To display the block as level element */
.scrolldown tbody,
.scrolldown thead {
  display: block;
}

.scrolldown thead tr th {
  height: 40px;
  /* line-height: 40px; */
}

.scrolldown tbody {
  padding-left: 15px;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrolldown tbody {
  /* border-top: 2px solid black; */
}

.scrolldown tbody td,
thead th {
  /* width : 259px; */
  /* border-right: 2px solid black; */
}

.scrolldown td {
  /* text-align:center; */
}

.scrolldown th {
  text-align: left;
}