.mainSection{
    display: flex;
}
.upper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-left: 27px;
    margin-right: 28px;
  }
  .rightSide{
    width: 80%;
  }
  .body{
    margin-top: 10px;
  }
  .editables{
    overflow-y: auto;
    height: 75vh;
    scrollbar-width: none;
  }
  .action{
    display: flex;
    justify-content: end;
    margin-right: 27px;
    gap: 10px;
  }
  .button{
    width: auto;
    background-color: #4B59C3;
    color: white;
    border: 1px solid var(--invoiceColor);
    border-radius: 5px;
    padding: 0.4rem 2.75rem;
  }

   .emp_details {
    text-align: center;
    color: #4b59c3;
    font-weight: 500;
    font-size: var(--inoiceHeading);
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
    padding-bottom: 0.5rem;
  }
  .label {
    font-weight: bold;
  }
  .border {
    border-bottom: 2px solid rgba(8, 8, 8, 0.1);
    margin: 1rem 0px;
  }
  
  .Container {
    width: 95%;
    margin: 0.8rem auto;
    display: flex;
    justify-content: space-between;
  }
  .col1 {
    width: 23.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col2 {
    width: 23.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .col3 {
    width: 23.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .col4 {
    width: 23.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col212 {
    width: 25.5%;
  }
  .values1 {
    border-left: 4px solid red;
    margin-top: 11px !important;
  }
  .values2 {
    border-left: 4px solid green;
    margin-top: 11px !important;
  }
  .values {
    margin-top: 11px !important;
  }
  .labelFolderLink {
    font-weight: bold;
  }
  .heading {
    margin-left: 27px;
    font-weight: 500;
  }
  .lastBtn {
    display: flex;
    justify-content: end;
    grid-column-gap: 2%;
    margin: 2% 1% 2% 0;
  }
  .addNoteBtn{
    background-color: #4B59C3;
    color: #fff;
    border: 1px solid var(--invoiceColor);
  }
  .payrollStatus {
    width: 23.5%;
    display: flex;
    align-items: center;
  }
  .payrollName2{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .payrollStatus2 {
    width: 23.5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .statusContainer {
    display: flex;
    align-items: center;
  }

  .radioitem {
    display: inline-block;
    position: relative;
    padding: 0 6px;
  }
  .radioitem input[type="radio"] {
    display: none;
  }
  
  .radioitem label {
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
  }
  
  .radioitem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid blue;
    background-color: transparent;
  }
  
  .radioitem input[type="radio"]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: blue;
  }
  .inputDate{
    border: 1px solid blue;
    border-radius: 6px;
    margin-right: 5px;
    padding: 0px 2px;
  }
  .selectTag{
    border: 1px solid blue;
    border-radius: 5px;
  }
  .statusContainer > .blueCalendar > input[type="date"]::-webkit-calendar-picker-indicator {
    background: url('../../../../assets/calblue.png');
    background-size:contain;
    cursor: pointer;
    background-repeat: no-repeat;
}
.confirmPopup1 {
  width: 30rem;
  min-height: 16rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
  max-height: 600px;
  padding: 1rem;
}
.confirmPopup1 > div{
  margin-bottom: 10px;
}
.delete {
  width: 90px;
  height: 40px;
  border: 1px solid #4B59C3;
  background-color: #4B59C3;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
}
.delete_btn {
  width: 90px;
  height: 40px;
  border: 1px solid #4B59C3;
  background-color: #fff;
  margin-right: 10px;
  border-radius: 6px;
  color: #4B59C3;
  font-weight: 500;
}
.textarea {
  width: 100%;
  height: 8rem;
  outline: none;
}
.modalMainHeading{
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px!important;
}