.card {
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 93%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 8rem;
  /* overflow-y: hidden; */
  /* padding-top: 10px; */
  /* padding: 10px; */
  margin-bottom: 20px;
}
.title {
  display: flex;
  justify-content: center;
  color: #964431;
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(8, 8, 8, 0.1);
}
.Cu_row1 {
  display: flex;
  /* justify-content: space-between; */
  margin: 10px 12px;
}
.Cu_row1 input[type="file"]::file-selector-button {
  border: 2px solid #65a15e;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #65a15e;
  transition: 1s;
  color: #ffffff;
}

.generateLinkDisable {
  border-radius: 5px;
  border: 1px solid #d18b7b;
  background-color: #d18b7b;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  height: 38px;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  cursor: not-allowed !important;
  /* left: 3.1rem; */
}
.generateLink {
  border-radius: 5px;
  border: 1px solid #964431;
  background-color: #964431;
  outline: none;
  height: 38px;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  /* left: 3.1rem; */
}

.genericComments {
  border-radius: 5px;
  border: 1px solid #964431;
  background-color: #964431;
  outline: none;
  color: #ffffff;
  margin-top: 15px;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 40px;
}
.textArea {
  outline: none;
  width: 98%;
  padding-left: 5px;
  height: 11rem;
  resize: none;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
}
.cp_field {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 10px;
  /* padding-left: 10px; */
  padding: 5px 5px;
  outline: none;
}
.errorTextHidden {
  visibility: hidden;
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}
.errorText1 {
  color: red;
  margin-top: 3px !important;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
  padding-left: 0.8rem !important;
  font-size: 15px;
  display: flex;
  font-weight: 500;
  visibility: visible;
}
.cp_label2 {
  font-size: 15px;
  font-weight: 600;
  text-align: start;
  padding-left: 7px;
}
.confirmPopup {
  /* top: 441px;
left: 767px;*/
  width: 43rem;
  height: 16rem;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 2px 4px 8px #00000029; */
  border-radius: 9px;
  opacity: 1;
}
.confirmPopup2 {
  /* top: 441px;
left: 767px;*/
  width: 43rem;
  height: 16rem;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 2px 4px 8px #00000029; */
  border-radius: 9px;
  opacity: 1;
  overflow-y: auto;
}
.container {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
}

.leftsection {
  display: flex;
}

.rightsection {
  display: flex;
  justify-content: flex-end;
}

.commentsBtn {
  width: 70%;
  display: flex;
  justify-content: end;
  position: relative;
  right: 27px;
}
.cu_col1 {
  flex: 0 0 24%;
}

.cu_label {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}
.cu_label1 {
  font-size: 14px;
  font-weight: 500;
  padding-left: 24px;
  color: #000000;
}
.cu_col2 {
  flex: 0 0 26%;
}

.cu_col2_ac {
  flex: 0 0 31%;
  /* margin-right: 33px; */
}

.cu_col2_to {
  flex: 0 0 31%;
  /* margin-left: -50px; */
}

.cu_col3 {
  flex: 0 0 16%;
}
.cu_col4 {
  flex: 0 0 18%;
  display: flex;
  justify-content: center;
}
.mandatory {
  color: red;
}
.chooseFile {
  height: 34px;
  padding-left: 0px;
  padding-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px grey;
}
.main {
  width: 80%;
}
.buttonDiv {
  position: absolute;
  right: 0.8%;
}
.submitbtn {
  background-color: #964431;
  border-radius: 9px;
  height: 38px;
  width: 6rem;
  border: 1px solid #964431;
}
.cancelbtn {
  background-color: white;
  border-radius: 9px;
  height: 38px;
  width: 6rem;
  color: #964431;
  border: 1px solid #964431;
  margin-right: 10px;
}

.viewHistoryBtn {
  color: white;
  border-radius: 9px;
  height: 38px;
  background-color: #964431;
  border: 1px solid #964431;
  margin-right: 10px;
}

.copybtn {
  background-color: white;
  border-radius: 9px;
  height: 38px;
  position: absolute;
  right: 1px;
  width: 4.5rem;
  color: #964431;
  border: 1px solid #964431;
  margin-right: 10px;
}
.upload_resume::-webkit-file-upload-button {
  border: none;
  padding: 5px;
}

.upload_resume {
  min-width: 10%;
  border-radius: 6px;
}

.errorTextHidden {
  visibility: hidden;
  color: red;
  margin-top: 3px !important;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
 
}

.errorText {
  color: red;
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 15px;
  font-weight: 500;
  visibility: visible;
  padding: 0.8rem 0.5rem 0.2rem 0.5;
}
.createissue {
  font-weight: 500;
  padding-left: 15px;
}

.QuestionList {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 98%;
  margin: 10px auto;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #ffffff;
  /* height: 500px; */
  margin-top: 4.5rem;
  overflow: hidden;
  padding: 0px 10px 10px 10px;
  min-height: 5rem;
  /* margin: 10px 20px 20px 20px; */
  /* margin-top: 26px; */
}

.textAreaBody {
  /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%);
  width: 98%;
  margin: 10px auto;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #ffffff;
  /* height: 500px; */
  margin-top: 4.5rem;
  overflow: hidden;
  padding: 0px 10px 10px 10px;
  min-height: 5rem;
  /* margin: 10px 20px 20px 20px; */
  /* margin-top: 26px; */
}
.QuestionList table {
  border-collapse: collapse;
  width: 100%;
}

.QuestionList th {
  background-color: #f5f7f9;
  border: 1px solid #ddd;
  font-weight: bold;
  text-align: start;
  padding: 8px;
}

.QuestionList td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: start;
}
.copyIcon {
  width: 20px !important;
  position: relative;
  left: 10px;
}
.noData {
  display: flex;
  justify-content: center;
}


.add {
  width: 2.1rem;
  height:2.1rem;
  border-radius: 50%;
  border: 2px solid #964431;

  align-items: center;
  justify-content: center;

  /* /* moz-border-radius: 50%; 
        WebkitBorderRadius: 50%; */
  margin-left:1.3rem;
  margin-top: 2rem;
  display: inline-block;
  cursor: pointer;
}

.add:hover {
  cursor: pointer;
  background-color: #964431;
  color: #ffffff;
  
}
.plus {
  font-size:16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #964431;
  /* padding-bottom: 1rem; */
}

.plus:hover {
  color: #ffffff;
  background-color: #964431;
  border-radius: 50%;
}

.commentHistorypara {
  display: flex;
  justify-content: center;
  color: #964431;
  align-items: center;
  padding-bottom: 4px;
}
.submitBtn {
  display: flex;
  padding-right: 8px;
  justify-content: end;
}
.yesBtn {
  border-radius: 5px;
  border: 1px solid #964431;
  background-color: #964431;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  /* left: 3.1rem; */
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 38px;
  /* margin-bottom: 20px; */
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  visibility: visible;
}
.container1 {
  display: block;
  position: relative;
  padding-left: 38px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  visibility: visible;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 13px;
  height: 20px;
  width: 20px;
  border: 2px solid #964431;
}
@media (max-width: 1200px) {
  .checkmark {
    position: absolute;
    top: 0;
    left: 4px;
    height: 20px;
    width: 20px;
    border: 2px solid #964431;
  }
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
  border: 2px solid #964431;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #964431;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkmark1 {
  position: absolute;
  top: 0;
  left: -26px;
  height: 20px;
  width: 20px;
  border: 2px solid #964431;
}

/* On mouse-over, add a grey background color */
.container1:hover input ~ .checkmark1 {
  background-color: #ccc;
  border: 2px solid #964431;
}

/* When the checkbox is checked, add a blue background */
.container1 input:checked ~ .checkmark1 {
  background-color: #964431;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container1 input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the checkmark/indicator */
.container1 .checkmark1:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.DateAlign {
  font-weight: 500;
  /* align-self: flex-start; */
  padding-left: 11px;
  color: #964431;
}
.edit {
  border-radius: 9px;
  border: none;
  background-color: #964431;
}
.cancel {
  color: #964431;
  margin-right: 10px;
  border-radius: 9px;
  background-color: white;
  border: 1px solid #964431;
}
.buttonAlign {
  display: flex;
  justify-content: end;
  padding-right: 18px;
  padding-bottom: 7px;
}

.confirmPopupTitle {
  text-align: center;
  font: normal normal normal 16px/25px Poppins, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
}
.btnCont{
  display: flex;
  justify-content: center;
}
.confirmPopup1{
  /* top: 441px;
left: 767px;*/
  width: 386px;
  height: 198px;
  text-align: center;
  padding-top: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 8px #00000029;
  border-radius: 9px;
  opacity: 1;
}
.yesBtn {
  padding: 5px 30px;
  outline: none;
  background:#964431 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  color: #fff;
  opacity: 1;
}
.noBtn {
  margin-left: 10px;
  padding: 5px 30px;
  outline: none;
  font: normal normal 600 18px/27px Poppins, sans-serif;
  background: #fff;
  letter-spacing: 0px;
  color:#964431;
  opacity: 1;
  border: 1px solid #964431;
  border-radius: 6px;
}
.s1 {
  position: relative;
  width: 19%;
  margin-top: 1rem;
  margin-left: 1rem;
}
.cp_serchIcon2 {
  position: absolute;
  z-index: 100;
  top: 15%;
  margin-left: 83%;
  width: 21px;
  cursor: pointer;
}