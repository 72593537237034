* {
    margin: 0;
    padding: 0;
  }
  
  .mainSection {
    width: 100%;
    top: 100px;
    bottom: 0;
    display: flex;
  }
  
  .mainSection .rightSection {
    width: 80%;
  }
  
  .ATScontainer {
    width: 80%;
  }
  
  .header {
    margin-left: 30px;
    margin-top: 10px;
  }
  
  @media screen and (max-width: 800px) {
    .mainSection .rightSection {
      width: 100%;
    }
  }
  
  .ATScontainer::-webkit-scrollbar {
    width: 3px;
  }
  
  .ATScontainer::-webkit-scrollbar-track {
    background: green;
  }
  
  .ATScontainer::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  @media screen and (max-width: 1280px) {
    .header {
      margin-left: 30px;
      margin-top: 15px;
    }
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 77vw;
    height: 80vh;
    gap: 20px;
  }
  
  .card > p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #4b59c3;
  }
  
  .card {
    background: white;
    padding: 10px 0;
    -webkit-box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.06);
    box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    width: 30vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
  }
  
  .card:hover {
    opacity: 0.8;
  }
  
  .card img {
    width: 60px;
    height: 60px;
  }
  
  .card p:first-of-type {
    width: 50px;
    height: 50px;
    display: flex;
    border: 1px solid #4b59c3;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
  }
  