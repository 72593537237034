.onboardStatus {
    transition: 0.3s;
    background-color: white;
    margin-left: 1.7rem;
    width: 19rem;
    height: 28px;
    border-radius: 7px;
    box-shadow: 1px 1px 14px rgba(0,0,0,.1607843137254902);
    opacity: 1;

}
.label {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
  }
  .label1 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-left: 12px !important;
    margin-top: 15px !important;
  }
.partnerlabal{
    margin-left: 12px !important;
}
.primaryContact{
    border-bottom:2px solid rgba(8,8,8,.1) ;
    text-align: center;
    color: #C3B14B;
    padding-bottom: 10px;

  }

  .smallInput {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 93%;
    height: 36px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    padding: 5px 5px;
    height: 2.3rem;
    outline: none;
    margin-left: 10px;
  }
  .label3 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 12px !important;
  }
  .label4 {
    font-weight: 500;
    color: var(--invoiceTextColor);
    margin-bottom: 0.5rem !important;
    margin-left: 4px !important;
  }

  .cu_field23Last {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 96%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 6px;
    padding-left: 10px;
    outline: none;
    padding-top: 5px;
  }

  .cu_field23Last1 {
    box-shadow: 0px 3px 9px #00000029 !important;
    border-radius: 9px;
    opacity: 1;
    width: 96%;
    height: 38px;
    background: transparent 0% 0% no-repeat padding-box;
    background-color: #f5f6fa;
    border: none;
    margin-top: 6px;
    padding-left: 10px;
    margin-left: 7px;
    outline: none;
    padding-top: 5px;
  }
  .buttonAdd {
    background-color:#C3B14B;
    border-color: #C3B14B;
    width: 100px;
    height: 38px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 26px;
  }

