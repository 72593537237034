.TeamSheet {
  margin-top: 30px;
  margin-left: 14px;
  font-weight: 700;
}
.s1 {
  margin-top: 20px;
  position: relative;
  /* left:10%; */
  /* width:23%; */
}
.cp_serchIcon{
  position: absolute;
  z-index: 100;
  top: 19%;
  margin-left:85%;
  width: 19px;
  cursor: pointer;
}
.cu_field23Last {
  box-shadow: 0px 3px 9px #00000029 !important;
  border-radius: 9px;
  opacity: 1;
  width: 26%;
  height: 38px;
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #f5f6fa;
  border: none;
  margin-top: 15px;
  padding-left: 10px;
  margin-left: 16px;
  outline: none;
  padding-top: 5px;
}
.buttonAdd2 {
  background-color: #999999;
  border-color: #999999;
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 15px;
  margin-top: 15px;
}
.buttonAdd {
  background-color: #9c58a5;
  border-color: #9c58a5;
  width: 145px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 15px;
  margin-top: 15px;
}

.th {
  display: flex;
  justify-content: space-around;
  background-color: #f5f7f9;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}

.tableContent {
  width: 80px;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
}
.th p:nth-child(1) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  padding-left: 8px;
}

.th p:nth-child(2) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  /* padding-left: 3px; */
}

.th p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(4) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(6) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(7) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}

.th p:nth-child(8) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  display: block;
  color: #666666;
  font-weight: 600;
  font-size: 12px;
}
.td {
  display: flex;
  /* justify-content: space-around; */
  border-radius: 10px;
  align-items: start;
  /* padding-left: 7px; */
  height: 40px;
  min-width: 95%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  margin-top: 10px;
}

.td:last-child {
  border-bottom: none;
}

.td p:nth-child(1) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  font-size: 12px;
  color: #855ea1 !important;
  /* cursor: pointer; */
  font-weight: 700;
  font-weight: bolder;
}

.td p:nth-child(2) {
  color: #666666;
  width: 10rem;
  text-align: start;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  transform: translate(7px, 0px);
}

.td p:nth-child(3) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  transform: translate(14px, 0px);
}

.td p:nth-child(4) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transform: translate(21px, 0px);

  font-size: 12px;
}

.td p:nth-child(5) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  transform: translate(28px, 0px);
}

.td p:nth-child(6) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  transform: translate(38px, 0px);
}

.td p:nth-child(7) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  display: block;

  color: #666666;
  font-weight: 600;
  font-size: 12px;
  transform: translate(46px, 0px);
}

.td p:nth-child(8) {
  width: 10rem;
  text-align: start;
  font-size: 15px;
  display: block;
  /* padding-left: 63px; */
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  transform: translate(54px, 0px);
}

.no_data {
  text-align: center;
}
.plus {
  font-size: 20px;
  font-size: 20px;
  margin-top: -2px;
  padding-right: 3px;
}
