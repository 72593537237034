.table {
  background: white;
  padding: 10px 0;
  -webkit-box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.06);
  box-shadow: 5px 2px 11px 5px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  width: 77vw;
}

.th,
.td {
  display: flex;
  align-items: center;
  height: 40px;
}

.th {
  background-color: #f5f7f9;
  color: #666;
}

.td:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.no_data {
  text-align: center;
  padding: 3%;
}

.th p {
  font-weight: 600;
  background-color: #f5f7f9;
}

.td p {
  background-color: white;
}

.td p,
.th p {
  text-align: start;
  font-size: 12px;
  color: #666;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tooltip {
  margin-left: 10px;
}

p {
  margin: 0;
}

.link:hover {
  font-weight: 500 !important;
  cursor: pointer;
}

.pagination {
  margin-top: 1rem;
}

.dropdownContainer16 {
  width: 16vw;
}

.dropdownContainer12 {
  width: 12vw;
}

.dropdownContainer8 {
  width: 8vw;
}
