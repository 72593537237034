$blue_primary: #172a3e;
$blue_light: #289e98;
$green_secondary: #65a15e;
$yellow_ternary: #fcb902;
$black_header: #172a3e;
$light_background: #f5f6fa;
$sub_text: #707070;
$erp_brown: #a15f5f;
$ets_burpule: #9c58a5;
$CC_color: #562679;
$pra_color: #00979d;
$pcm_brown: #954B00;

%dashboard-buttons {
  height: 70px;
  border-radius: 16px;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-family: "Poppins";
}

.optionsText {
  text-align: center;
}

.container {
  width: 85%;
  margin: 0 auto;
  margin-top: 8px;
  padding-bottom: 1rem;

  .topHeading {
    line-height: 1;

    .heading {
      color: $blue_primary;
      font-size: 19px;
      margin-bottom: 1px;
    }

    .date {
      font-size: 13px;
      color: $sub_text;
      font-family: "Poppins";
    }
  }

  // welcomeSection--------------------------------------------------------------------------
  .welcomeContainer {
    width: 100%;
    position: relative;
    padding: 0px 50px 0px 55px;
    background-color: #fff;
    border-radius: 10px;
    height: 5rem;
  }

  h1 {
    font: size 1.625rem;
    color: $green_secondary;
  }

  .welcomeHeading {
    color: $sub_text;
    font-size: 1.125rem;
    font-style: italic;
    font-weight: 600;
    margin-bottom: -6px;
  }

  .secondCircle {
    background-color: $yellow_ternary;
    height: 84px;
    border-radius: 5px;
    width: 67px;
    position: absolute;
    top: 0;
    left: 0;
    clip-path: circle(50% at 10% 20%);
  }


  .welcomePara {
    width: 100%;
    color: #707070;
    font-size: .875rem;
    font-weight: 500;
  }

  .expInvDashboard {
    @extend %dashboard-buttons;
    color: #855ea1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid #855ea1;
  }
  .exitFormalityDashboard {
    @extend %dashboard-buttons;
    color: #4B59C3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  .hrHelpDeskDashBoard {
    @extend %dashboard-buttons;
    color: #9c57a5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid #9c57a5;
  }

  .pcmDashboard {
    @extend %dashboard-buttons;
    color: $pcm_brown;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .dashboardImage {
    position: absolute;
    bottom: 0;
    right: 35px;
    height: 80px;
  }

  .welcomeHeader {
    color: rgb(12, 182, 12);
    font-size: 1.27rem;
    padding-top: 0.7rem;
    margin-bottom: 0 !important;
  }
}

// dashboard --------------------------------------------------------------------------
.dashboardOptions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 11px;
  margin-top: 11px;
  margin-bottom: 1.5rem;

  .taskTracker {
    @extend %dashboard-buttons;
    color: $green_secondary;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid $green_secondary;
  }

  .atsDashboard {
    @extend %dashboard-buttons;
    color: $blue_primary;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid $blue_primary;
  }

  .erpDashboard {
    @extend %dashboard-buttons;
    color: $erp_brown;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid $erp_brown;
  }

  .etsDashboard {
    @extend %dashboard-buttons;
    color: $ets_burpule;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid $ets_burpule;
  }

  .CCDashBoard {
    @extend %dashboard-buttons;
    color: $CC_color;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid $CC_color;
  }

  .PRADashBoard {
    @extend %dashboard-buttons;
    color: $pra_color;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid $pra_color;
  }

  .userMDashboard {
    @extend %dashboard-buttons;
    color: $blue_light;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid $blue_light;
  }

  .grievancePortal {
    color: #41cbb6;
    @extend %dashboard-buttons;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid #41cbb6;
  }

  .projectResource {
    color: rgb(252, 187, 8);
    @extend %dashboard-buttons;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid rgb(252, 187, 8);
  }

  .itManagement {
    @extend %dashboard-buttons;
    color: $blue_primary;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid $blue_primary;
  }

  .partnerDashboard {
    @extend %dashboard-buttons;
    color: #c3b14b;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // border: 2px solid #c3b14b;
  }

  .taskTracker:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    cursor: pointer;
    background-color: $green_secondary;
    color: white;
  }

  .expInvDashboard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: #855ea1;
    cursor: pointer;
    color: white;
  }
  .exitFormalityDashboard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: #4B59C3;
    cursor: pointer;
    color: white;
  }

  .projectResource:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: rgb(252, 187, 8);
    cursor: pointer;
    color: white;
  }

  .itManagement:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: $blue_primary;
    cursor: pointer;
    color: white;
  }

  .atsDashboard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: $blue_primary;
    cursor: pointer;
    color: white;
  }

  .partnerDashboard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: #c3b14b;
    cursor: pointer;
    color: white;
  }

  .hrHelpDeskDashBoard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: #9c57a5;
    cursor: pointer;
    color: white;
  }

  .CCDashBoard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: $CC_color;
    cursor: pointer;
    color: white;
  }

  .PRADashBoard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: $pra_color;
    cursor: pointer;
    color: white;
  }

  .erpDashboard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: $erp_brown;
    cursor: pointer;
    color: white;
  }

  .etsDashboard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    background-color: $ets_burpule;
    cursor: pointer;
    color: white;
  }

  .userMDashboard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    color: white;
    background-color: $blue_light;
    cursor: pointer;
  }

  .pcmDashboard:hover {
    transform: scale(0.989);
    margin: 0 0.2rem;
    color: white;
    background-color: $pcm_brown;
    cursor: pointer;
  }

  .imageDiv {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
  }

  .optionsImage {
    height: 60px;
    width: 60px;
  }
}

@media only screen and (max-width: 700px) {
  .dashboardOptions {
    grid-template-columns: 1fr;

    .taskTracker,
    .projectResource,
    .itManagement,
    .atsDashboard {
      height: 150px;
    }

    .imageDiv {
      height: 80px;
      width: 80px;
    }

    .optionsImage {
      height: 50px;
      width: 50px;
    }
  }

  .welcomeContainer {
    .dashboardImage {
      position: absolute;
      bottom: -8px;
      right: 35px;
      height: 90px;
      width: 150px;
    }

    .welcomePara {
      width: 100%;
    }
  }
}